/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import React from "react";
import ModalComponent from "@common/modal";
import {useTranslation} from "react-i18next";
import {BackIconModal, Close} from "@src/assets/SvgComponents";
import {withRouter} from "react-router";
import ButtonComponent from "@src/common/button";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {actions as importActions, actions as createImports} from "@sagas/imports/create";
import Lottie from "react-lottie";
import loadingLottieLong from "@assets/ordersLoader.json";
import RadioGroupInput from "@src/common/radioButton/radioGroupInput";
import RadioInput from "@src/common/radioButton";
import CustomSelect from "@src/common/Select";
import UploadCard from "../../../../common/UploadCard/UploadCard";

function ImportsModal({
	showModal,
	setShowModal,
	loading,
	setQuery,
	query,
	formik,
	inputs,
	setImportChangeStatus,
	setRequiredFields,
	setSelectedFile,
	selectedFile,
}) {
	const {t} = useTranslation();
	const bytesToMB = (bytes) => {
		const megabytes = bytes / (1024 * 1024);
		return megabytes.toFixed(2);
	};
	const useStyles = makeStyles({
		popper: {
			width: "100%",
		},
	});
	const classes = useStyles();
	const handleCloseModal = () => {
		setShowModal(false);
	};
	const handleChangeRadioButton = (name, value, label) => {
		setFieldValue(name, value);
		setRequiredFields({
			name: label,
			value,
		});
	};
	const {values, handleSubmit, setFieldValue, errors, touched} = formik;
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: loadingLottieLong,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	return (
		<ModalComponent
			open={showModal}
			handleClose={handleCloseModal}
			disableEnforceFocus
			customClassNames="statistics__upload__documents"
			width={600}
			positionModal="right"
		>
			<>
				{loading ? (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							width: "100%",
							height: "100%",
						}}
					>
						<Lottie options={defaultOptions} height={60} width={150} />
					</div>
				) : (
					<>
						<div className="statistics__upload__documents-header">
							<div onClick={handleCloseModal} style={{cursor: "pointer"}}>
								<BackIconModal fill="var(--color-primary)" />
							</div>
							<span className="statistics__upload__documents-header-text">
								{t("Import")}
							</span>
							<div onClick={handleCloseModal} style={{cursor: "pointer"}}>
								<Close fill="var(--color-primary)" />
							</div>
						</div>
						<div className="statistics__upload__documents-body" style={{width: "100%"}}>
							<UploadCard
								setFieldValue={setFieldValue}
								setSelectedFile={setSelectedFile}
								errors={errors.uploadedCsv}
								accept=".xlsx, .xls, .csv"
								importing={true}
							/>
							{values?.uploadedCsv !== "" && !document?.id && (
								<div className="statistics__upload__documents-body-files">
									<div className="statistics__upload__documents-body-files-upload">
										<span className="statistics__upload__documents-body-files-upload-file">
											{t("UploadedFiles")}
										</span>
									</div>
									<div className="statistics__upload__documents-body-files__file">
										<div
											style={{
												display: "flex",
												alignItems: "center",
												gap: 10,
												maxWidth: "260px",
											}}
										>
											<div className="statistics__upload__documents-body-files__xlsxBox">
												<span>XLSX</span>
											</div>
											<span className="statistics__upload__documents-body-files__xlsxBox-span">
												{selectedFile}
											</span>
										</div>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												gap: 10,
												marginRight: 10,
											}}
										>
											<span>{bytesToMB(values?.uploadedCsv.size)}Mb</span>

											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													cursor: "pointer",
												}}
												onClick={() => setFieldValue("uploadedCsv", "")}
											>
												<Close fill="#979797" />
											</div>
										</div>
									</div>
								</div>
							)}
							<div className="statistics__upload__documents-body-dropdown">
								{inputs &&
									inputs.map((item) => {
										if (item?.type === "Dropdown") {
											return (
												<>
													<CustomSelect
														label={item.placeholder}
														options={item?.options}
														onClear={() => {
															setFieldValue(item?.name, null);
															if (item?.name === "client") {
																setQuery({
																	...query,
																	clientId: null,
																});
															}
															if (item.name === "status") {
																setImportChangeStatus(null);
															}
														}}
														onSelect={(value, option) => {
															setFieldValue(
																item?.name,
																option?.[item?.value],
															);
															if (item?.name === "client") {
																setQuery({
																	...query,
																	clientId: option?.value,
																});
															}
															if (item.name === "status") {
																setImportChangeStatus(option.value);
															}
														}}
														showSearch
														allowClear
														value={values[item?.name]}
														placeholder={item?.placeholder}
														touched={touched?.[item?.name]}
														errors={errors?.[item?.name]}
													/>
												</>
											);
										}
										if (item?.type === "RadioButton") {
											return (
												<RadioGroupInput
													disabled={false}
													name={item?.name}
													value={values?.[item?.value]}
													title={item.placeholder}
													defaultValue={item?.defaultValue}
													fontSize={14}
													justifyContent="flex-start"
												>
													<div
														style={item.style}
														className="radio__inputs"
													>
														{item.options.map(
															({value, label}, index) => (
																<RadioInput
																	key={index}
																	checked={
																		values?.[item?.value] ===
																		value
																	}
																	label={label}
																	value={value}
																	onChange={(event) =>
																		handleChangeRadioButton(
																			item?.name,
																			event?.target.value,
																			label,
																		)
																	}
																	name={item?.name}
																/>
															),
														)}
													</div>
												</RadioGroupInput>
											);
										}
									})}
							</div>
						</div>
						<div className="statistics__upload__documents-footer">
							<ButtonComponent
								type="submit"
								buttonText={t("Upload")}
								onClick={handleSubmit}
							/>
						</div>
					</>
				)}
			</>
		</ModalComponent>
	);
}
const mapStateToProps = (state) => ({
	loading: state.app.imports.create.loading,
	query: state.app.imports.create.query,
	selectedFile: state.app.imports.create.fileName,
});

const mapDispatchToProps = {
	setQuery: importActions.setQuery,
	setImportChangeStatus: importActions.setImportChangeStatus,
	setRequiredFields: createImports.setRequiredFields,
	setSelectedFile: createImports.setSelectedFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ImportsModal));
