/* eslint-disable max-len */
import BigSearchBar from "@src/common/bigSearchBar/BigSearchBar";
import CustomAccordion from "@src/common/CustomAccordion";
import CustomFieldItem from "@src/common/customFieldItem";
import React, {useState, useMemo} from "react";
import {useTranslation} from "react-i18next";
import ButtonComponent from "@src/common/button";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as clientActions} from "@sagas/client";
import {ShowInfoIcon} from "@src/assets/SvgComponents";
import {searchCustomFields} from "@src/utils/searchCustomFields";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import SimpleDropDownInput from "@src/common/dropDown/simpleDropDown";
import {filteringStatusOptions} from "@src/components/dashboard/orders/staticData";
import InputComponent from "@src/common/input";
import {filterFieldsBasedOnSelection} from "./utils";

const ClientFieldsSettings = ({
	clientFields,
	fieldSettings,
	updateSettings,
	loading,
	setFieldsSettingsData,
}) => {
	const {t} = useTranslation();
	const {date, fields, statusNameList} = fieldSettings;
	const [searchQuery, setSearchQuery] = useState("");
	const handleCheckbox = (value, item) => {
		const {checked} = value.target;
		const isSelected = fields?.includes(item.name);
		// if a field is checked and if it hasn't been checked before we add it to array
		// otherwise we should filter it out of the array
		setFieldsSettingsData({
			...fieldSettings,
			fields:
				checked && !isSelected
					? [item.name, ...fields]
					: fields?.filter((field) => field !== item.name),
		});
	};
	const handleSubmit = () => {
		if (!date) {
			ToastErrorComponent(t("CantDeleteWithoutDate"));
		} else {
			updateSettings();
		}
	};

	// this will return an object with two arrays which will be used to render
	// selected and unselected fields
	const filteredFields = useMemo(() => {
		const sourceFields = searchQuery
			? searchCustomFields(clientFields, searchQuery)
			: clientFields;
		return filterFieldsBasedOnSelection(sourceFields, fields);
	}, [searchQuery, clientFields, fields]);

	return (
		<CustomAccordion title={t("FieldDeletionSettings")} className="contracts__accordion">
			<div className="clientFields__wrapper">
				<div className="clientFields__wrapper-title">
					<ShowInfoIcon fill="#C1C1C1" />
					<span>{t("ClientFieldsInfo")}</span>
				</div>
				<div className="clientFields__wrapper__filters">
					<div className="clientFields__wrapper__filters-container">
						<div className="dropdown--container">
							<p className="dropdown--container-label">{t("ChooseStatus")}</p>
							<SimpleDropDownInput
								options={filteringStatusOptions}
								value={filteringStatusOptions
									.filter(
										(item) =>
											statusNameList?.includes(item.value) ||
											statusNameList?.includes(item.statusValue),
									)
									?.map((itm) => ({
										value: itm.statusValue,
										name: itm.name,
									}))}
								title="name"
								multiple
								onChange={(_, value) => {
									setFieldsSettingsData({
										...fieldSettings,
										statusList: value?.map((item) =>
											item?.value?.includes("CANCELLED") ||
											item?.statusValue?.includes("CANCELLED")
												? "CANCELLED"
												: item?.value || item?.statusValue,
										),
										statusNameList: value?.map(
											(it) => it.value || it.statusValue,
										),
									});
								}}
							/>
						</div>
						<InputComponent
							values={date}
							type="number"
							hasInfoIcon
							label={t("ChooseDays")}
							infoText={t("FieldsDeletionDays")}
							min={1}
							handleChange={(e) => {
								setFieldsSettingsData({
									...fieldSettings,
									date: Number(e.target.value),
								});
							}}
						/>
					</div>

					<BigSearchBar
						handleSearch={(event) => {
							setSearchQuery(event.target.value);
						}}
						value={searchQuery}
						disabled={statusNameList?.length === 0}
					/>
				</div>
				<p className="client__fields__title">{t("DeleteFields")}</p>
				<div className="client__fields__container">
					{filteredFields.selected?.map((field, index) => (
						<CustomFieldItem
							item={field}
							key={index}
							fromCustomField={false}
							disabled={statusNameList?.length === 0}
							checked={fields?.includes(field.name)}
							handleCheckbox={handleCheckbox}
						/>
					))}
				</div>
				<div className="client__fields__breakLine" />
				<p className="client__fields__title">{t("SaveFields")}</p>
				<div className="client__fields__container">
					{filteredFields.unselected?.map((field, index) => (
						<CustomFieldItem
							item={field}
							key={index}
							disabled={statusNameList?.length === 0}
							checked={false}
							fromCustomField={false}
							handleCheckbox={handleCheckbox}
						/>
					))}
				</div>
				<div className="clientFields__wrapper-actions">
					<ButtonComponent
						buttonText={t("Save")}
						disabled={loading || statusNameList?.length === 0}
						loading={loading}
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</CustomAccordion>
	);
};

const mapStateToProps = (state) => ({
	fieldSettings: state.app.client.index.client.fieldSettings,
	loading: state.app.client.index.isLoading,
});
const mapDispatchToProps = {
	updateSettings: clientActions.updateSettings,
	setFieldsSettingsData: clientActions.setFieldsSettingsData,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientFieldsSettings));
