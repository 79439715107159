/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from "react";
import RangePicker from "@src/common/Range-Picker";
import _ from "lodash";
import Map from "@src/components/dashboard/sales-tracker/components/maps";
import SubCategoryItem from "@src/components/dashboard/Trainings/All/category/SubCategoryItem";
import Activities from "@src/components/dashboard/sales-tracker/components/Activities";
import moment from "moment";
import PdfNameInformation from "@src/components/generic-pdf/createPdfComponents/PdfNameInformation";
import {Close} from "@src/assets/SvgComponents";
import InputComponent from "../input";
import CustomSelect from "../Select";
import CustomDatePicker from "../Date-Picker";
import TextAreaComponent from "../TextArea/TextAreaComponent";
import GroupedSelect from "../Grouped-Select";
import ColorPickerPopOver from "../ColorPickerComponent";
import RadioInput from "../radioButton";
import RadioGroupInput from "../radioButton/radioGroupInput";
import CustomSwitch from "../CustomSwitch";
import UploadCard from "../UploadCard/UploadCard";
import ButtonInput from "../ButtonInput";
import CheckBoxComponentAntd from "../checkbox/antdCheckbox";

const bytesToMB = (bytes) => {
	const megabytes = bytes / (1024 * 1024);
	return megabytes.toFixed(2);
};

export const renderFormInputs = (
	input,
	formik,
	inputs,
	setDatesModal,
	innerModal,
	renderInnerModal,
	datesModal,
	toggleInfoVisibility,
	modal,
	id,
) => {
	const {
		inputType,
		field,
		width,
		title,
		options,
		multiple,
		dependentField,
		disableWhen,
		disabled,
		fields,
		onChange,
		dependentFrom,
		dependentFields,
		hidden,
		picker,
		inputValue,
		additionalField,
		dependentOption,
		maxLength,
		value,
		defaultValue,
		onKeyDown,
		placeholder,
		filterOptions,
		disableOldDates,
		type,
		optionFilterProp,
		newClassName,
		customRender,
		getOptionsBy,
		entityToGetOptionsBy,
		customWidth,
		showOnly,
		additionalInfo,
		disableEndDateOnEdit,
		clearedBy,
		hideLabel,
		showTime,
		fetchOnChange,
		accept,
		dependentFromValue,
	} = input;
	const {values, touched, errors, setFieldValue, handleChange, handleBlur} = formik;
	if (hidden || (dependentFromValue && !dependentFromValue?.includes(values?.[dependentFrom])))
		return null;
	if (dependentFrom && showOnly && !showOnly.includes(values?.[dependentFrom])) return null;
	const handleInputChange = (value, option) => {
		const valueToInsert = inputValue === "obj" ? option : value;
		if (multiple) {
			setFieldValue(field, [...(values[field] || []), valueToInsert]);
		} else {
			setFieldValue(field, valueToInsert);
		}
		if (onChange) {
			onChange(valueToInsert);
		}
		if (additionalField) {
			setFieldValue(additionalField, option[additionalField]);
		}
		if (dependentFields) {
			dependentFields.forEach((field) => setFieldValue(field, null));
		}
		if (fetchOnChange) {
			fetchOnChange(value);
		}
	};
	const onClearInput = () => {
		setFieldValue(field, multiple ? [] : null);
		if (dependentFields) {
			dependentFields.forEach((field) => setFieldValue(field, null));
		}
		if (additionalField) {
			setFieldValue(additionalField, null);
		}
		if (onChange) {
			onChange(multiple ? [] : null, true);
		}
	};
	const handleDeselect = (obj) => {
		const currentVals =
			multiple && inputValue === "obj"
				? values?.[field]?.map((item) => ({
						...item,
						label: item?.name,
						value: item?.value || item?.id,
				  }))
				: values?.[field];
		const filterCondition = (val) => (inputValue === "obj" ? val?.value !== obj : val !== obj);
		const filteredArr = currentVals?.filter(filterCondition);
		setFieldValue(field, filteredArr);
		if (onChange) {
			onChange(filteredArr, true);
		}
	};
	const handleSetLead = (obj) => {
		const filteredArray = values[field].filter(
			(item) => item?.id !== obj?.id || item?.userId !== obj?.userId,
		);
		setFieldValue(dependentFrom, obj); // here we change the leader with the selecte user
		setFieldValue(field, filteredArray); // here we remove the user from the user list
	};
	const handleSetTeamLead = (obj) => {
		const updatedArray = values?.[field].map((item) => {
			if (item.id === obj) {
				return {
					...item,
					teamLeader: !item?.teamLeader,
				};
			}
			return {...item, teamLeader: false};
		});
		setFieldValue(field, updatedArray);
	};
	const handleRemoveFromTeam = (obj) => {
		if (obj?.trainer) {
			const findInterns = values?.[field]
				?.filter((item) => item?.trainerId === obj.id)
				?.map((item) => item?.id);
			const updatedArray = values?.[field]?.map((item) => {
				if (findInterns?.includes(item?.id)) {
					return {
						...item,
						internship: false,
						trainerId: null,
						fromDate: null,
						toDate: null,
						startDate: null,
						endDate: null,
					};
				}
				if (item?.id === obj.id) {
					return {
						...item,
						trainer: false,
						fromDate: null,
						toDate: null,
						startDate: null,
						endDate: null,
					};
				}
				return item;
			});
			setFieldValue(field, updatedArray);
		} else if (obj.internship) {
			const updatedArray = values?.[field]?.map((item) => {
				if (item?.id === obj.id) {
					return {
						...item,
						internship: false,
						trainerId: false,
						fromDate: null,
						toDate: null,
						startDate: null,
						endDate: null,
					};
				}
				return item;
			});
			setFieldValue(field, updatedArray);
		} else {
			const updatedArray = values?.[field]?.filter((item) => item?.id !== obj?.id);
			setFieldValue(field, updatedArray);
		}
	};
	const handleDates = (field, value) => {
		if (onChange) {
			onChange(field, value);
		}
		if (dependentFields) {
			dependentFields.forEach((field) => {
				const foundedDep = inputs?.find((it) => it.field === field);
				if (foundedDep) {
					setFieldValue(foundedDep?.field, foundedDep?.multiple ? [] : null);
				}
			});
		}
		setFieldValue(field, value);
	};
	const handleColor = (value) => {
		setFieldValue(field, value);
	};
	const handleKeyDown = (e) => {
		if (onKeyDown) {
			const {key, keyCode, target} = e;
			const value = target.value.trim();
			if (keyCode === "13" || key === "Enter") {
				e.preventDefault();
				if (value) {
					const newValue = values?.[additionalField]
						? [{name: value}, ...values?.[additionalField]]
						: [{name: value}];
					setFieldValue(additionalField, newValue);
					setFieldValue(field, "");
				}
			}
		}
	};

	switch (inputType) {
		case "text":
		case "number":
		case "email":
		case "password":
			return (
				<InputComponent
					name={field}
					values={_.get(values, field)}
					errors={_.get(errors, field)}
					touched={_.get(touched, field)}
					errorClass="errorClass"
					handleChange={handleChange}
					handleBlur={handleBlur}
					style={{width: width || "100%"}}
					onKeyDown={handleKeyDown}
					toggleInfoVisibility={() => toggleInfoVisibility(input)}
					additionalInfo={additionalInfo}
					label={title}
					type={inputType}
					disabled={
						disabled ||
						(dependentField &&
							(values?.[dependentField] === null ||
								(disableWhen
									? disableWhen === true ||
									  disableWhen?.includes(values?.[dependentField])
									: false)))
					}
				/>
			);
		case "dropdown":
			return (
				<CustomSelect
					options={
						multiple
							? options?.map((item) => ({
									...item,
									label: item?.label || item?.name,
									value: item?.value || item?.id,
							  }))
							: options
					}
					toggleInfoVisibility={() => toggleInfoVisibility(input)}
					additionalInfo={additionalInfo}
					onSelect={handleInputChange}
					mode={multiple ? "multiple" : undefined}
					label={title}
					onClear={onClearInput}
					value={
						multiple
							? inputValue === "obj"
								? values?.[field]?.map((item) => ({
										...item,
										label: item?.label || item?.name,
										value: item?.value || item?.id,
								  }))
								: values?.[field] || []
							: values?.[field]?.id || values?.[field]
					}
					errors={errors?.[field]}
					touched={touched?.[field]}
					disabled={
						disabled ||
						(dependentFrom &&
							(Array.isArray(dependentFrom)
								? dependentFrom.some((key) => !values[key])
								: !values[dependentFrom])) ||
						(clearedBy && values?.[clearedBy])
					}
					handleBlur={handleBlur}
					onDeselect={handleDeselect}
					optionFilterProp={optionFilterProp}
					innerModal={innerModal}
					renderInnerModal={renderInnerModal}
					datesModal={datesModal}
					setDatesModal={setDatesModal}
					placeholder={placeholder}
					width={customWidth}
				/>
			);
		case "rangePicker":
			return (
				<RangePicker
					setFieldValue={handleDates}
					picker={picker}
					startDateProps={{
						field: fields?.[0],
						value: values?.[fields[0]],
						disabled: disabled?.[0] || false,
						label: title?.[0] || "",
					}}
					endDateProps={{
						field: fields?.[1],
						value: values?.[fields?.[1]],
						disabled:
							values?.startDate === null ||
							disabled?.[1] ||
							(disableEndDateOnEdit && values?.[fields?.[1]]),
						label: title?.[1] || "",
					}}
					touched={touched?.[fields[0]] || touched?.[fields[1]]}
					error={errors?.[fields[0]] || errors?.[fields[1]]}
					disableOldDates={disableOldDates}
					type={type}
				/>
			);
		case "checkbox":
			return (
				<CheckBoxComponentAntd
					name={field}
					handleChange={handleChange}
					checked={values?.[field]}
					label={title}
				/>
			);
		case "datepicker":
			return (
				<CustomDatePicker
					label={title}
					value={_.get(values, field)}
					disabled={disabled}
					field={field}
					setFieldValue={setFieldValue}
					touched={_.get(touched, field) || true}
					error={_.get(errors, field)}
					showTime={showTime}
					hidden={
						hidden ||
						(dependentOption &&
							dependentFrom &&
							values?.[dependentFrom] !== dependentOption)
					}
				/>
			);
		case "textarea":
			return (
				<TextAreaComponent
					maxLength={maxLength}
					value={_.get(values, field) || ""}
					errors={_.get(errors, field) || ""}
					touched={_.get(touched, field) || false}
					setFieldValue={setFieldValue}
					inputName={field}
					breakLine={false}
					width="100%"
					label={title}
					hasDescription={!hideLabel}
					toggleInfoVisibility={() => toggleInfoVisibility(input)}
					additionalInfo={additionalInfo}
					errorClass="errorClass"
				/>
			);
		case "groupedSelect":
			const filteredOptions = () => {
				if (!filterOptions) return options;

				const valuesIds = values?.[field]?.map((val) => val.id);

				const filterItems = (itm) => {
					const itemIdMatches = itm?.id === values?.[filterOptions]?.id;
					const itemInValuesIds = valuesIds?.includes(itm.id);
					return !itemIdMatches && !itemInValuesIds;
				};
				const checkedGroup = (option) => {
					const valuesIds = values?.[field] ? values?.[field]?.map((val) => val.id) : [];
					const childrenIds = option?.options?.map((opt) => opt?.id);
					if (childrenIds?.every((element) => valuesIds?.includes(element))) {
						return {
							...option,
							checked: true,
						};
					}
					return option;
				};
				return options?.map((opt) => {
					if (opt.type === "USERS") {
						return {...opt, options: opt.options?.filter(filterItems)};
					}
					if (opt.type === "TEAM" || opt.type === "AGENCY") {
						return {...opt, options: opt?.options.map((item) => checkedGroup(item))};
					}
					return {
						...opt,
						options: opt.options.map((it) => ({
							...it,
							options: it.options?.filter(filterItems),
						})),
					};
				});
			};
			return (
				<GroupedSelect
					options={filteredOptions()}
					label={title}
					values={_.get(values, field)}
					touched={_.get(touched, field)}
					errors={_.get(errors, field)}
					field={field}
					setFieldValue={setFieldValue}
					onDeselect={handleDeselect}
					onSetLead={handleSetLead}
					customRender={customRender}
					disabled={disabled || (dependentFrom && !values[dependentFrom])}
					newClassName={newClassName}
					innerModal={innerModal}
					renderInnerModal={renderInnerModal}
					datesModal={datesModal}
					placeholder={placeholder}
					setDatesModal={setDatesModal}
					width={customWidth}
					id={id}
				/>
			);
		case "map":
			return <Map {...value} />;
		case "activities":
			return <Activities lead={value} usersOptions={options} />;
		case "colorPicker":
			return (
				<ColorPickerPopOver
					values={values}
					onChange={handleColor}
					color={values?.[field]}
					textColor={values?.[field]}
					name={field}
					label={title}
					showArrow
					touched={_.get(touched, field)}
					error={_.get(errors, field)}
				/>
			);
		case "radioGroup":
			return (
				<RadioGroupInput
					disabled={disabled}
					name={field}
					onChange={handleChange}
					value={_.get(values, field)}
					defaultValue={defaultValue}
				>
					<div className="form-element-row">
						{options?.map((opt, idx) => (
							<RadioInput
								key={`${opt.value}--${idx}`}
								checked={values?.[field]}
								label={opt?.title}
								value={opt?.value}
							/>
						))}
					</div>
					<span className="errorClass">{errors?.[field]}</span>
				</RadioGroupInput>
			);
		case "subCategoriesRender":
			return (
				<SubCategoryItem
					hidden={!values?.[field]?.length}
					items={values?.[field]}
					setFieldValue={setFieldValue}
					field={field}
					deleteFn={input.deleteFn}
				/>
			);
		case "teamDropdown":
			const getOptionsTeamDropdown = () => {
				const filterOptionsIds = values?.[filterOptions]?.[0]
					? values?.[filterOptions]?.map((item) => item?.id)
					: [];
				if (multiple) {
					const filterOptionsIds = values?.[field]?.map((item) => item?.id);
					return options.filter(
						(item) =>
							item.id !== values?.[dependentFrom]?.id &&
							!filterOptionsIds?.includes(item.id),
					);
				}
				if (filterOptionsIds?.[0]) {
					return options?.filter(
						(item) =>
							!filterOptionsIds?.includes(item.id) &&
							item?.id !== values?.[field]?.id,
					);
				}
				return options.filter((item) => item?.id !== values?.[field]?.id);
			};
			const getOptionsByFields = (fields, entityToGetOptionsBy, options) => {
				const fieldsValues = fields.map((item) => values?.[item]);
				const startDate = moment(fieldsValues[0]);
				const endDate = fieldsValues[1] ? moment(fieldsValues[1]) : null;

				const filteredTeams = entityToGetOptionsBy.filter((item) => {
					const fromDate = moment(item.from);
					const toDate = item.to ? moment(item.to) : null;
					if (values?.id && item.id === values?.id) {
						return false;
					}
					if (toDate === null) {
						if (endDate !== null && endDate.isBefore(fromDate)) {
							return false;
						}
						return true;
					}
					const hasConflict = !endDate
						? fromDate.isSameOrBefore(startDate) &&
						  (!toDate || toDate.isAfter(startDate))
						: fromDate.isBefore(endDate) && (!toDate || toDate.isAfter(startDate));

					return hasConflict;
				});
				const occupiedMemberIds = filteredTeams
					.map((obj) => obj.members)
					.flat()
					.filter((mem) => (field !== "teamMembers" ? !mem.headOfDepartment : true))
					.map((member) => member.id);
				const optionsFilteredOccupiedMemberIds = options.filter(
					(item) => !occupiedMemberIds.includes(item?.id),
				);
				const filterOptionsIds = values?.[filterOptions]?.[0]
					? values?.[filterOptions]?.map((item) => item?.id)
					: [];
				if (multiple) {
					const filterOptionsIds = values?.[field]?.map((item) => item?.id);
					return optionsFilteredOccupiedMemberIds.filter(
						(item) =>
							item.id !== values?.[filterOptions]?.id &&
							!filterOptionsIds?.includes(item.id),
					);
				}
				if (filterOptionsIds?.[0]) {
					return optionsFilteredOccupiedMemberIds?.filter(
						(item) =>
							!filterOptionsIds?.includes(item.id) &&
							item?.id !== values?.[field]?.id,
					);
				}
				return optionsFilteredOccupiedMemberIds.filter(
					(item) => item?.id !== values?.[field]?.id,
				);
			};
			return (
				<CustomSelect
					options={
						getOptionsBy
							? getOptionsByFields(getOptionsBy, entityToGetOptionsBy, options)
							: getOptionsTeamDropdown()
					}
					onSelect={handleInputChange}
					mode={undefined}
					label={title}
					onClear={onClearInput}
					value={values?.[field]}
					errors={errors?.[field]}
					touched={touched?.[field]}
					disabled={
						disabled ||
						(dependentFrom &&
							(Array.isArray(dependentFrom)
								? dependentFrom.some((key) => !values[key])
								: !values[dependentFrom]))
					}
					handleBlur={handleBlur}
					customValueRender={true}
					allowClear={false}
					placeholder={placeholder}
					onDeselect={handleDeselect}
					onSetLead={handleSetLead}
					optionFilterProp={optionFilterProp}
					innerModal={innerModal}
					renderInnerModal={renderInnerModal}
					datesModal={datesModal}
					setDatesModal={setDatesModal}
					onSetTeamLead={handleSetTeamLead}
					onRemoveFromTeam={handleRemoveFromTeam}
					modal={modal}
					width={customWidth}
				/>
			);
		case "switch":
			return (
				<CustomSwitch
					setFieldValue={setFieldValue}
					field={field}
					title={title}
					disabled={disabled}
					value={_.get(values, field)}
					toggleInfoVisibility={() => toggleInfoVisibility(input)}
					additionalInfo={additionalInfo}
				/>
			);
		case "pdfNameInfo":
			return <PdfNameInformation />;
		case "upload":
			return (
				<>
					<UploadCard
						setFieldValue={setFieldValue}
						errors={_.get(errors, field)}
						accept={accept}
						setSelectedFile={() => {}}
					/>
					{_.get(values, field) && (
						<div className="statistics__upload__documents-body-files__file">
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: 10,
									maxWidth: "260px",
								}}
							>
								<span className="statistics__upload__documents-body-files__xlsxBox-span">
									{values?.[field]?.[0]?.name}
								</span>
							</div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: 10,
									marginRight: 10,
								}}
							>
								<span>{bytesToMB(values?.[field]?.[0]?.size)}MB</span>

								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										cursor: "pointer",
									}}
									onClick={() => setFieldValue(field, "")}
								>
									<Close fill="#979797" />
								</div>
							</div>
						</div>
					)}
				</>
			);
		case "button-input":
			return (
				<ButtonInput
					options={options}
					title={title}
					onChange={handleInputChange}
					value={_.get(values, field)}
				/>
			);
		default:
			return null;
	}
};
