import i18n from "@src/i18n";
import {checkRoles} from "@src/utils/checkRoles";
import * as yup from "yup";

// Add Ojects (like orderStatus) for each
// entity u want to add to the import feature.

// Uploaded Csv field is a must add field
// for every import (also name it as "uploadedCsv" everywhere)!
export const orderStatus = {
	inputs: (options) => [
		{
			type: "Dropdown",
			name: "client",
			options: options?.optionsClients.filter((option) => option?.name !== i18n.t("All")),
			value: "value",
			placeholder: i18n.t("ChooseClient"),
		},
		{
			type: "Dropdown",
			name: "status",
			options: options?.importStatusOptions.map((item) => ({...item, label: item?.name})),
			value: "value",
			placeholder: i18n.t("ChooseStatus"),
		},
		{
			type: "RadioButton",
			name: "matchType",
			defaultValue: "clientOrderId",
			placeholder: i18n.t("ChooseMatchType"),
			options: [
				{value: "clientOrderId", label: i18n.t("ClientOrderID")},
				{value: "orderId", label: i18n.t("OrderID")},
			],
			style: {
				justifyContent: "flex-start",
			},
		},
	],
	validationSchema: yup.object().shape({
		client: yup
			.string()
			.label(i18n.t("client"))
			.required(i18n.t("Client") + i18n.t("isRequired"))
			.nullable(),
		status: yup
			.string()
			.label(i18n.t("status"))
			.required(i18n.t("Status ") + i18n.t("isRequired"))
			.nullable(),
		uploadedCsv: yup.string().required(i18n.t("pdfReq")).nullable(),
		matchType: yup.string().required(i18n.t("pdfReq")).nullable(),
	}),
	initialValues: {
		client: null,
		uploadedCsv: "",
		status: null,
		matchType: "clientOrderId",
	},
	submit: ({submitFunction, payload}) => {
		submitFunction(payload);
	},
};
export const importTypes = [
	{
		title: i18n.t("OrdersStatus"),
		description: i18n.t("ChangeOrderByImport"),
		path: "change-order-status",
		show: checkRoles("createOrderStatusImport"),
	},
	{
		title: i18n.t("CreateOrders"),
		description: i18n.t("CreateOrdersByImport"),
		path: "create-order",
		show: checkRoles("createOrderImport"),
	},
	{
		title: i18n.t("CreateLeads"),
		description: i18n.t("CreateLeadsByImport"),
		path: "create-leads",
		show: checkRoles("createLeadImport"),
	},
];
