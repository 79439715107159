/* eslint-disable max-len */
import React from "react";
import {withRouter} from "react-router";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import Dashboard from "@components/dashboard/Dashboard";
import Users from "@components/dashboard/Users/Users";
import UserDetails from "@components/dashboard/UserDetails/UserDetails";
import Orders from "@components/dashboard/orders";
import Imports from "@components/dashboard/orders/imports/imports";
import NewPassword from "@components/auth/components/NewPassword";
import UserProfile from "@components/dashboard/userProfile";
import Clients from "@components/dashboard/clients";
import Client from "@components/dashboard/clients/Client";
import Tenants from "@components/dashboard/Tenants";
import TrainingsComp from "@components/dashboard/Trainings/All";
import SingleTraining from "@components/dashboard/Trainings/Single";
import AddTraining from "@components/dashboard/Trainings/Add";
import Messages from "@components/messages";
import CreateTariff from "@components/dashboard/CreateTariff";
import PrivacyPolicy from "@components/PrivacyPolicy/index";
import CreateUser from "@components/dashboard/Users/CreateUser";
import calculations from "@components/dashboard/calculations";
import SalesTracker from "@components/dashboard/sales-tracker";
import CalculationDetails from "@components/dashboard/calculations/CalculationDetails/CalculationDetails";
import ImportCsv from "@components/dashboard/orders/imports/index";
import InsideImport from "@components/dashboard/orders/imports/InsideImport";
import CreatePdf from "@components/generic-pdf/CreatePdf";
import SalesTrackerInsideImport from "@components/dashboard/sales-tracker/components/SalesTrackerInsideImport";
import ContactlessSignature from "@src/components/dashboard/ContactlessSignature";
import {checkRoles} from "@src/utils/checkRoles";
import ProtectedRoute from "../../common/protected-route";
import history from "../../utils/history";
// route
import PdfViewerForMobile from "../dashboard/calculations/PdfViewer";
import Auth from "../auth";
import Logout from "../auth/components/logout";
import Home from "../home";
import UpdatedStatuses from "../dashboard/orders/UpdatedStatuses";
import TenantSettings from "../dashboard/Tenants/TenantSettings";
import CalculationImportTable from "../dashboard/calculations/CalculationDetails/CalculationImportTable";
import InsideOrderImport from "../dashboard/orders/OrderImports/InsideOrderImport";
import PinValidation from "../dashboard/PinValidation";
import GenerateTokenComponent from "../dashboard/PinValidation/components/generateTokenComponent";
import Energy from "../dashboard/Energy";
import ImportsPage from "../dashboard/Imports";
import OrderTables from "../dashboard/Imports/components/OrderTables";
import PreviewCsv from "../dashboard/Imports/components/PreviewCsv";
import PreviewCsvById from "../dashboard/Imports/components/PreviewCsvById";
import CreateImport from "../dashboard/Imports/components/CreateImport";
import Support from "../dashboard/Support";
import LockedComponent from "../dashboard/Users/LockedUsersComponent/LockedComponent";

const features = localStorage.getItem("features");
const tenantFeatures = JSON.parse(features);
const Routes = () => (
	<Router history={history}>
		<Switch>
			<Route exact component={Auth} path="/auth/:toggle" />
			<Route exact component={NewPassword} path="/auth/new-password/:token" />
			<Route
				exact
				component={GenerateTokenComponent}
				path="/pinvalidation/generateToken/:orderId"
			/>
			<Route exact component={PinValidation} path="/pinvalidation/:space/:token/:tokenPin" />
			<Route exact component={PinValidation} path="/pinvalidation/:token/:tokenPin" />
			<Route exact component={ContactlessSignature} path="/order/contactless" />
			<ProtectedRoute
				// roles={[
				// 	"TENANT",
				// 	"BACK-OFFICE",
				// 	"MANAGEMENTINFO",
				// 	"AGENCY-SP",
				// 	"SP",
				// 	"EMPLOYEE",
				// 	"AGENCY",
				// 	"AGENCY_70",
				// 	"EMPLOYEE_SHOP",
				// 	"EMPLOYEE_TL",
				// 	"EMPLOYEE_AB",
				// 	"ADMIN",
				// ]}
				show={checkRoles("seeDashboard")}
				exact
				component={Dashboard}
				path="/admin/dashboard/"
			/>

			<ProtectedRoute
				exact
				// roles={["ADMIN"]}
				show={checkRoles("seeTenants")}
				component={Tenants}
				path="/admin/tenants/"
			/>
			<ProtectedRoute
				exact
				// roles={["ADMIN"]}
				show={checkRoles("seeTenantSettings")}
				component={TenantSettings}
				path="/admin/tenants/client/:id"
			/>

			<ProtectedRoute
				exact
				// roles={["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "AGENCY", "ADMIN", "AGENCY_70"]}
				show={checkRoles("seeUsers")}
				component={Users}
				path="/admin/users/"
			/>
			<ProtectedRoute
				exact
				// roles={["TENANT", "MANAGEMENTINFO", "AGENCY", "BACK-OFFICE", "ADMIN", "AGENCY_70"]}
				show={checkRoles("seeCreateEditUsers")}
				component={CreateUser}
				path="/admin/users/create"
			/>
			<ProtectedRoute
				exact
				// roles={["TENANT", "MANAGEMENTINFO", "AGENCY", "BACK-OFFICE", "ADMIN", "AGENCY_70"]}
				show={checkRoles("seeCreateEditUsers")}
				component={CreateUser}
				path="/admin/users/edit/:id"
			/>
			<ProtectedRoute
				exact
				// roles={["SP", "ADMIN"]}
				show={checkRoles("seeSPUserDetails")}
				component={UserDetails}
				path="/admin/user/SP/"
			/>
			<ProtectedRoute
				exact
				// roles={["EMPLOYEE", "EMPLOYEE_SHOP", "EMPLOYEE_TL", "EMPLOYEE_AB"]}
				checkRoles={checkRoles("seeEmployeeUserDetails")}
				component={UserDetails}
				path="/admin/user/EMPLOYEE/"
			/>

			<ProtectedRoute
				exact
				// roles={["AGENCY-SP", "ADMIN"]}
				checkRoles={checkRoles("seeAgencyUserDetails")}
				component={UserDetails}
				path="/admin/user/AGENCY-SP/"
			/>

			<ProtectedRoute
				exact
				// roles={["TENANT", "MANAGEMENTINFO", "AGENCY", "BACK-OFFICE", "ADMIN", "AGENCY_70"]}
				checkRoles={checkRoles("seeUserDetails")}
				component={UserDetails}
				path="/admin/users/:id"
			/>
			<ProtectedRoute
				exact
				// roles={[
				// 	"TENANT",
				// 	"MANAGEMENTINFO",
				// 	"BACK-OFFICE",
				// 	"ADMIN",
				// 	"SP",
				// 	"AGENCY",
				// 	"AGENCY-SP",
				// 	"EMPLOYEE",
				// 	"CLIENT",
				// 	"EMPLOYEE_SHOP",
				// 	"EMPLOYEE_TL",
				// 	"EMPLOYEE_AB",
				// 	"AGENCY_70",
				// ]}
				checkRoles={checkRoles("seeUserProfile")}
				component={UserProfile}
				path="/admin/user-profile/"
			/>
			{/* <ProtectedRoute
				exact
				roles={[
					"TENANT",
					"MANAGEMENTINFO",
					"BACK-OFFICE",
					"ADMIN",
					"SP",
					"AGENCY",
					"AGENCY-SP",
					"EMPLOYEE",
					"CLIENT",
				]}
				// path="/admin/support-info/"
			/> */}
			<ProtectedRoute
				exact
				// roles={["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "ADMIN"]}
				show={checkRoles("seeClients")}
				component={Clients}
				path="/admin/clients/"
			/>
			<ProtectedRoute
				exact
				show={checkRoles("seeClient")}
				// roles={["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "ADMIN"]}
				component={Client}
				path="/admin/clients/:clientId/:id"
			/>
			<ProtectedRoute
				exact
				// roles={[
				// 	"TENANT",
				// 	"MANAGEMENTINFO",
				// 	"BACK-OFFICE",
				// 	"SP",
				// 	"AGENCY-SP",
				// 	"AGENCY",
				// 	"AGENCY_70",
				// 	"EMPLOYEE",
				// 	"EMPLOYEE_SHOP",
				// 	"EMPLOYEE_TL",
				// 	"EMPLOYEE_AB",
				// 	"ADMIN",
				// ]}
				show={checkRoles("seeCalculations")}
				component={calculations}
				path="/admin/calculations/"
			/>
			<ProtectedRoute
				exact
				// roles={[
				// 	"TENANT",
				// 	"MANAGEMENTINFO",
				// 	"BACK-OFFICE",
				// 	"SP",
				// 	"AGENCY-SP",
				// 	"AGENCY",
				// 	"AGENCY_70",
				// 	"EMPLOYEE",
				// 	"AGENCY",
				// 	"EMPLOYEE_SHOP",
				// 	"EMPLOYEE_TL",
				// 	"EMPLOYEE_AB",
				// 	"ADMIN",
				// ]}
				show={checkRoles("seeCalculationDetails")}
				component={CalculationDetails}
				path="/admin/calculations/:id/:userId"
			/>

			<ProtectedRoute
				exact
				// roles={[
				// 	"TENANT",
				// 	"MANAGEMENTINFO",
				// 	"BACK-OFFICE",
				// 	"CLIENT",
				// 	"AGENCY-SP",
				// 	"SP",
				// 	"EMPLOYEE",
				// 	"AGENCY",
				// 	"AGENCY_70",
				// 	"EMPLOYEE_SHOP",
				// 	"EMPLOYEE_TL",
				// 	"EMPLOYEE_AB",
				// 	"ADMIN",
				// ]}
				show={checkRoles("seeOrders")}
				component={Orders}
				path="/admin/orders/"
			/>
			<ProtectedRoute
				exact
				// roles={["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "ADMIN", "EMPLOYEE"]}
				show={checkRoles("seeImports")}
				component={Imports}
				path="/admin/orders/imported"
			/>
			<ProtectedRoute
				exact
				show={checkRoles("seeInsideImport")}
				// roles={["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "ADMIN"]}
				component={InsideImport}
				path="/admin/orders/imported/:id"
			/>

			<ProtectedRoute
				exact
				show={checkRoles("seeUpdatedStatuses")}
				// roles={["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "CLIENT", "ADMIN"]}
				component={UpdatedStatuses}
				path="/admin/orders/:id"
			/>
			<ProtectedRoute
				exact
				// roles={["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "CLIENT", "ADMIN"]}
				show={checkRoles("seeUpdatedStatuses")}
				component={UpdatedStatuses}
				path="/admin/imports/order/:id"
			/>
			<ProtectedRoute
				// roles={[
				// 	"TENANT",
				// 	"BACK-OFFICE",
				// 	"MANAGEMENTINFO",
				// 	"SP",
				// 	"AGENCY-SP",
				// 	"EMPLOYEE",
				// 	"AGENCY",
				// 	"AGENCY_70",
				// 	"EMPLOYEE_SHOP",
				// 	"EMPLOYEE_TL",
				// 	"EMPLOYEE_AB",
				// 	"ADMIN",
				// ]}
				component={ImportCsv}
				show={checkRoles("seeImportCsv")}
				path="/orders/import"
			/>
			<ProtectedRoute
				// roles={["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "ADMIN"]}
				component={InsideOrderImport}
				show={checkRoles("seeInsideOrderImport")}
				path="/admin/imports/create-order/:id"
			/>
			<ProtectedRoute
				exact
				// roles={[
				// 	"TENANT",
				// 	"BACK-OFFICE",
				// 	"MANAGEMENTINFO",
				// 	"SP",
				// 	"AGENCY-SP",
				// 	"EMPLOYEE",
				// 	"AGENCY",
				// 	"AGENCY_70",
				// 	"EMPLOYEE_SHOP",
				// 	"EMPLOYEE_TL",
				// 	"EMPLOYEE_AB",
				// 	"ADMIN",
				// ]}
				component={TrainingsComp}
				path="/admin/trainings"
				show={tenantFeatures?.Trainings && checkRoles("seeTrainings")}
			/>
			<ProtectedRoute
				exact
				// roles={[
				// 	"TENANT",
				// 	"MANAGEMENTINFO",
				// 	"BACK-OFFICE",
				// 	"SP",
				// 	"AGENCY-SP",
				// 	"EMPLOYEE",
				// 	"AGENCY",
				// 	"AGENCY_70",
				// 	"EMPLOYEE_SHOP",
				// 	"EMPLOYEE_TL",
				// 	"EMPLOYEE_AB",
				// 	"ADMIN",
				// ]}
				component={SingleTraining}
				path="/admin/trainings/:id"
				show={tenantFeatures?.Trainings && checkRoles("seeSingleTraining")}
			/>
			<ProtectedRoute
				exact
				// roles={["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "ADMIN"]}
				component={AddTraining}
				path="/admin/trainings/add/training"
				show={tenantFeatures?.Trainings && checkRoles("seeAddEditTraining")}
			/>
			<ProtectedRoute
				exact
				// roles={["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "ADMIN"]}
				component={AddTraining}
				path="/admin/trainings/edit/training/:id"
				show={tenantFeatures?.Trainings && checkRoles("seeAddEditTraining")}
			/>
			<ProtectedRoute
				exact
				// roles={[
				// 	"TENANT",
				// 	"MANAGEMENTINFO",
				// 	"BACK-OFFICE",
				// 	"SP",
				// 	"ADMIN",
				// 	"AGENCY-SP",
				// 	"AGENCY_70",
				// 	"EMPLOYEE",
				// 	"AGENCY",
				// 	"EMPLOYEE_SHOP",
				// 	"EMPLOYEE_TL",
				// 	"EMPLOYEE_AB",
				// ]}
				component={Messages}
				path="/admin/messages"
				show={tenantFeatures?.Messages && checkRoles("seeMessages")}
			/>
			<ProtectedRoute
				exact
				// roles={[
				// 	"TENANT",
				// 	"MANAGEMENTINFO",
				// 	"BACK-OFFICE",
				// 	"SP",
				// 	"ADMIN",
				// 	"AGENCY-SP",
				// 	"EMPLOYEE",
				// 	"AGENCY",
				// 	"AGENCY_70",
				// 	"EMPLOYEE_SHOP",
				// 	"EMPLOYEE_TL",
				// 	"EMPLOYEE_AB",
				// ]}
				component={Messages}
				path="/admin/messages/:id"
				show={tenantFeatures?.Messages && checkRoles("seeMessages")}
			/>
			<ProtectedRoute
				exact
				// roles={[
				// 	"TENANT",
				// 	"MANAGEMENTINFO",
				// 	"BACK-OFFICE",
				// 	"SP",
				// 	"ADMIN",
				// 	"AGENCY-SP",
				// 	"EMPLOYEE",
				// 	"AGENCY",
				// 	"AGENCY_70",
				// 	"EMPLOYEE_SHOP",
				// 	"EMPLOYEE_TL",
				// 	"EMPLOYEE_AB",
				// ]}
				component={SalesTracker}
				path="/admin/salestracker"
				show={tenantFeatures?.SalesTracker && checkRoles("seeSalesTracker")}
			/>
			<ProtectedRoute
				exact
				// roles={["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "ADMIN"]}
				component={Energy}
				path="/admin/energy"
				show={tenantFeatures?.Energy && checkRoles("seeEnergy")}
			/>
			<ProtectedRoute
				exact
				// roles={[
				// 	"TENANT",
				// 	"BACK-OFFICE",
				// 	"MANAGEMENTINFO",
				// 	"AGENCY-SP",
				// 	"SP",
				// 	"EMPLOYEE",
				// 	"AGENCY",
				// 	"AGENCY_70",
				// 	"EMPLOYEE_SHOP",
				// 	"EMPLOYEE_TL",
				// 	"EMPLOYEE_AB",
				// ]}
				component={ImportsPage}
				path="/admin/imports"
				show={tenantFeatures?.Imports && checkRoles("seeImportsPage")}
			/>
			<ProtectedRoute
				exact
				// roles={[
				// 	"TENANT",
				// 	"BACK-OFFICE",
				// 	"MANAGEMENTINFO",
				// 	"AGENCY-SP",
				// 	"SP",
				// 	"EMPLOYEE",
				// 	"AGENCY",
				// 	"AGENCY_70",
				// 	"EMPLOYEE_SHOP",
				// 	"EMPLOYEE_TL",
				// 	"EMPLOYEE_AB",
				// ]}
				component={CreateImport}
				path="/admin/imports/:id"
				show={tenantFeatures?.Imports && checkRoles("seeCreateImport")}
			/>
			<ProtectedRoute
				exact
				// roles={["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "ADMIN"]}
				component={OrderTables}
				path="/admin/imports/change-order-status/tables"
				show={tenantFeatures?.Imports && checkRoles("seeOrderTables")}
			/>
			<ProtectedRoute
				exact
				// roles={["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "ADMIN"]}
				component={PreviewCsv}
				path="/admin/imported/previewCsv"
				show={tenantFeatures?.Imports && checkRoles("seePreviewCsv")}
			/>
			<ProtectedRoute
				exact
				// roles={["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "ADMIN"]}
				component={PreviewCsvById}
				path="/admin/imports/change-order-status/:id"
				show={tenantFeatures?.Imports && checkRoles("seePreviewCsvById")}
			/>
			<ProtectedRoute
				exact
				// roles={[
				// 	"TENANT",
				// 	"BACK-OFFICE",
				// 	"MANAGEMENTINFO",
				// 	"AGENCY-SP",
				// 	"SP",
				// 	"EMPLOYEE",
				// 	"AGENCY",
				// 	"AGENCY_70",
				// 	"EMPLOYEE_SHOP",
				// 	"EMPLOYEE_TL",
				// 	"EMPLOYEE_AB",
				// ]}
				component={SalesTrackerInsideImport}
				path="/admin/imports/create-leads/:id"
				show={tenantFeatures?.SalesTracker && checkRoles("seeSalesTrackerInsideImport")}
			/>
			<ProtectedRoute
				exact
				roles={["TENANT", "BACK-OFFICE", "ADMIN"]}
				component={CreatePdf}
				path="/admin/clients/:clientId/:clientStringId/createPdf/:id"
				show={checkRoles("seeCreatePdf")}
			/>
			<ProtectedRoute
				exact
				// roles={["TENANT", "BACK-OFFICE", "ADMIN"]}
				component={CalculationImportTable}
				path="/admin/calculations/:id/import"
				show={checkRoles("seeCalculationImportTable")}
			/>
			<ProtectedRoute
				exact
				// roles={["TENANT", "MANAGEMENTINFO", "BACK-OFFICE", "ADMIN"]}
				component={Support}
				path="/admin/support"
				show={tenantFeatures?.Tickets && checkRoles("seeSupport")}
			/>
			<ProtectedRoute
				exact
				// roles={[
				// 	"TENANT",
				// 	"BACK-OFFICE",
				// 	"MANAGEMENTINFO",
				// 	"AGENCY-SP",
				// 	"SP",
				// 	"EMPLOYEE",
				// 	"AGENCY",
				// 	"AGENCY_70",
				// 	"EMPLOYEE_SHOP",
				// 	"EMPLOYEE_TL",
				// 	"EMPLOYEE_AB",
				// ]}
				component={LockedComponent}
				path="/locked"
				show={checkRoles("seeLockedComponent")}
				// show={tenantFeatures?.Tickets}
			/>
			{/* <Route component={NewPdfIndex} path="/pdf" /> */}
			<Route component={Logout} path="/logout" />
			<Route
				component={CreateTariff}
				path="/admin/clients/:id/:clientId/edit/tariff/:tariffId"
			/>
			<Route component={CreateTariff} path="/admin/clients/:id/:clientId/create/tariff" />
			<Route component={PrivacyPolicy} path="/privacy-policy/:lang" />
			<Route component={PdfViewerForMobile} path="/pdf-mobile/:settlementId/:token" />
			<Route exact component={Home} path="/" />
			<Redirect to="/" />
		</Switch>
	</Router>
);

export default withRouter(Routes);
