/* eslint-disable no-nested-ternary */
import {DATE_FORMAT} from "@common/constants";
import SeeMore from "@common/SeeMore";
import {DraftIcon, PublishedIcon, ScheduleIcon} from "@src/assets/SvgComponents";
import StatusBox from "@src/common/components/status-box";
import {formatNumber} from "@src/utils/convertNumber";
import {getCurrentUser} from "@src/utils/currentUser";
import {t} from "i18next";
import moment from "moment";
import React from "react";
import {SelectColumn} from "react-data-grid";
import i18n from "../../../i18n";
export const statusData = {
	PUBLISHED: {icon: <PublishedIcon />, color: "#157AE5"},
	VERÖFFENTLICHT: {icon: <PublishedIcon />, color: "#157AE5"},
	DRAFT: {icon: <DraftIcon />, color: "#EC8C3A"},
	ENTWURF: {icon: <DraftIcon />, color: "#EC8C3A"},
	Scheduled: {icon: <ScheduleIcon fill="#fff" />, color: "#3B505D"},
	Geplant: {icon: <ScheduleIcon fill="#fff" />, color: "#3B505D"},
};
const currentUser = getCurrentUser();
const defaultStatus = currentUser?.role === "AGENCY-SP" ? i18n.t("PUBLISHED") : i18n.t("DRAFT");

export const calculationColumns = [
	{...SelectColumn, cellClass: "custom__cell__selector"},
	{
		name: i18n.t("UserSPName"),
		key: "spName",
		sortable: true,
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("SettlementNumber"),
		key: "uuid",
		sortable: true,
		headerCellClass: "headerStyles",
	},
	{
		name: i18n.t("UserRoles"),
		key: "role",
		headerCellClass: "headerStyles",
		sortable: true,
		formatter: ({row}) => <span>{i18n.t(row?.role)}</span>,
	},
	{
		name: i18n.t("Zuordnung"),
		key: "team",
		headerCellClass: "headerStyles",
		sortable: true,
		formatter: ({row}) => (
			<span>{row?.team === "HeadOfSales" ? i18n.t("HeadOfSales") : row?.team}</span>
		),
	},
	{
		name: i18n.t("SettlementDate"),
		key: "settledAt",
		sortable: true,
		headerCellClass: "headerStyles",
		formatter: ({row}) => (
			<span>{row?.settledAt ? moment(row?.settledAt).format(DATE_FORMAT) : ""}</span>
		),
	},
	{
		name: i18n.t("Total"),
		key: "calculationData.totalProvision",
		headerCellClass: "headerStyles",
		sortable: true,
		formatter: ({row}) => (
			<span>{`${formatNumber(row.calculationData?.totalProvision)} `} €</span>
		),
	},

	{
		name: `${i18n.t("Status")}`,
		key: "status",
		sortable: true,
		headerCellClass: "headerStyles",
		width: 160,
		formatter: ({row}) => (
			<StatusBox
				row={
					row?.status !== null
						? row.scheduleTime
							? t("Scheduled")
							: t(row?.status)
						: defaultStatus
				}
				statusData={statusData}
			/>
		),
	},
	{
		name: `${i18n.t("PayoutModel")}`,
		key: "settlementPayoutModel",
		formatter: ({row}) => <span>{i18n.t(row?.clientPayoutModel)}</span>,
	},
	{
		name: `# ${i18n.t("Contracts")}`,
		key: "calculationData.totalContracts",
		headerCellClass: "headerStyles",
		sortable: true,
		formatter: ({row}) => <div>{row?.calculationData?.totalContracts}</div>,
		cellStyle: {color: "red", "background-color": "green"},
	},
	{
		name: `# ${i18n.t("NumberofCanceled")}`,
		key: "calculationData.totalCancelled",
		headerCellClass: "headerStyles",
		sortable: true,
		formatter: ({row}) => (
			<span>{`${
				row?.calculationData?.totalCancelled ? row?.calculationData?.totalCancelled : "-"
			} `}</span>
		),
	},
	{
		name: i18n.t("Points"),
		key: "calculationData.points",
		headerCellClass: "headerStyles",
		sortable: true,
		formatter: ({row}) => (
			<span>{`${row?.calculationData?.points ? row?.calculationData?.points : "-"} `}</span>
		),
	},
	{
		name: i18n.t("DatePaid"),
		key: "paidAt",
		headerCellClass: "headerStyles",
		sortable: true,
		formatter: ({row}) => (
			<span>{row?.paidAt ? moment(row?.paidAt).format(DATE_FORMAT) : "-"}</span>
		),
	},
	{
		name: i18n.t("TotalPayout"),
		key: "calculationData.totalPayOut",
		cellClass: "price",
		headerCellClass: "headerStyles",
		sortable: true,
		formatter: ({row}) => <span>{`${formatNumber(row.calculationData?.totalPayOut)} `} €</span>,
	},

	{
		name: i18n.t("TotalCancellationPayout"),
		key: "calculationData.totalCancellationReservePayOut",
		sortable: true,
		cellClass: "price",
		headerCellClass: "headerStyles",
		formatter: ({row}) => (
			<span>
				{row.calculationData?.totalCancellationReservePayOut &&
					`${formatNumber(row.calculationData?.totalCancellationReservePayOut)} €`}
			</span>
		),
	},
	{
		name: i18n.t("Details"),
		key: "See More",
		headerCellClass: "headerStyles",
		formatter: ({row}) => <SeeMore id={row.uuid} userId={row.spId} total={row?.totalPayOut} />,
	},
];
