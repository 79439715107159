import {ShowInfoIcon} from "@src/assets/SvgComponents";
import ButtonComponent from "@src/common/button";
import SimpleDropDownInput from "@src/common/dropDown/simpleDropDown";
import {filteringStatusOptions} from "@src/components/dashboard/orders/staticData";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {actions as clientActions} from "@sagas/client";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import CustomAccordion from "@src/common/CustomAccordion";

const DISALLOWED_STATUSES = ["SETTLED", "CONTRACT_REACTIVATED", "BONUS_SETTLED", "BONUS"];

const DefaultOrderStatus = ({updateSettings, loading, client}) => {
	const [status, setStatus] = useState(null);
	const {t} = useTranslation();

	const filteredOptions = filteringStatusOptions?.filter(
		({statusValue}) => !DISALLOWED_STATUSES.includes(statusValue),
	);

	const handleSave = () => {
		updateSettings({
			...client,
			defaultOrderStatus: {
				status: status?.includes("CANCELLED") ? "CANCELLED" : status,
				statusName: status,
			},
		});
	};

	useEffect(() => {
		if (client?.defaultOrderStatus?.statusName) {
			setStatus(client.defaultOrderStatus.statusName);
		}
	}, []);

	return (
		<CustomAccordion title={t("orderStatusSelection")} className="contracts__accordion">
			<div className="generated__pdf__container">
				<div className="generated__pdf__container-title">
					<ShowInfoIcon fill="#C1C1C1" />
					<span>{t("orderStatusSelectionInfo")}</span>
				</div>

				<div className="generated__pdf__container-content">
					<div className="dropdown--container">
						<p className="dropdown--container-label">{t("ChooseStatus")}</p>
						<SimpleDropDownInput
							options={filteredOptions}
							value={{
								value: filteredOptions.find((item) => item.statusValue === status)
									?.statusValue,
								name: filteredOptions.find((item) => item.statusValue === status)
									?.name,
							}}
							title="name"
							onChange={(_, value) => {
								setStatus(value.statusValue);
							}}
						/>
					</div>
				</div>
				<div className="generated__pdf__container-actions">
					<ButtonComponent
						buttonText={t("Save")}
						disabled={loading || !status}
						loading={loading}
						onClick={handleSave}
					/>
				</div>
			</div>
		</CustomAccordion>
	);
};

const mapStateToProps = (state) => ({
	loading: state.app.client.index.isLoading,
	client: state.app.client.index.client,
	deliverPdfWithStatusName: state.app.client.index.client.deliverPdfWithStatusName,
});
const mapDispatchToProps = {
	updateSettings: clientActions.updateSettings,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DefaultOrderStatus));
