import { ENDPOINT } from "./endpoint";
import axios from "@utils/axios";
import _ from "lodash";

// in this array are stored all inputs which shouldn't be exported
const NON_EXPORTABLE_INPUTS = ["TITLE","IMAGE","UPLOAD"];

export const getSortedOrderFields = async (id) => {
    // this request will get order fields for a particular or all clients
    const orderFieldsResponse = await axios.get(id ? `/client/order_fields/${id}` : ENDPOINT.GET_ALL_ORDERS_FIELDS);
    // we filter out fields who shouldn't be exported
    const filteredFields = orderFieldsResponse?.data?.data?.orderFieldViewList?.filter((item) => {
         // Exclude filtering for items with name 'orderProductNumber'
         if (item.name === 'orderProductNumber') {
            return true;
        }
        return !(item.hideWeb || item?.hideMobile || item.parentNameEn === "Section Hide" || item.dropDownParentId !== null || NON_EXPORTABLE_INPUTS.includes(item.inputType));
    });
    // we group fields by clientId
    const groupedFields = _.groupBy(filteredFields, 'clientId');
    const result = {};
    for (const clientId in groupedFields) {
        // we sort fields based on exportSortNr which value should be set on UI
        if(clientId) {
            result[clientId] = groupedFields[clientId].sort((a, b) => a.exportSortNr - b.exportSortNr);
        }
    }
    return result;
};
