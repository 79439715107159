/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import React from "react";
import {StyleSheet, Text, View} from "@react-pdf/renderer";
import {useTranslation} from "react-i18next";
import {countContracts, formatNumber} from "@src/utils/convertNumber";
const CalculationTablePdf = (props) => {
	const {data, contracts} = props;
	const {t} = useTranslation();
	const splitArrays = (array, targetName) => {
		const myContracts = [];
		const teamContracts = [];
		array.forEach((item) => {
			if (item.writerName === targetName || item?.writerName === null) {
				myContracts.push(item);
			} else {
				teamContracts.push(item);
			}
		});

		return {myContracts, teamContracts};
	};

	const {myContracts, teamContracts} = splitArrays(contracts, data.spFullName);
	const getConditionName = (client) => {
		if (client?.conditionName !== null) {
			return t(client?.conditionName);
		}
		if (client.clientName === "MANUAL_CORRECTION") {
			return "Manuelle-Buchung";
		}
		if (client?.isSupervisor) {
			return t("DifferenceProvision");
		}
		return t("OwnSales");
	};
	return (
		<>
			<View style={styles.mainTableContainer} wrap>
				<View style={styles.mainTableTitles} fixed>
					<Text style={{...styles.mainTitle, width: 165}}>Produkt</Text>
					<Text style={{...styles.mainTitle, width: 65}}>Anzahl</Text>
					<Text style={{...styles.mainTitle, width: 50}}>{t("Points")}</Text>
					<Text style={{...styles.mainTitle, width: 120}}>{t("Commission")}</Text>
					<Text style={{...styles.mainTitle, width: 130}}>{t("Cancellations")}</Text>
					<Text style={{...styles.mainTitle, width: 130, borderRight: "none"}}></Text>
				</View>
				<View style={styles.subHeader}>
					<Text style={{...styles.subHeaderItem, width: 70}}>{t("ProductProvider")}</Text>
					<Text style={{...styles.subHeaderItem, width: 95}}>{t("TypeOfRevenue")}</Text>
					<Text style={{...styles.subHeaderItem, width: 65}}>{t("NewContracts")}</Text>
					<Text style={{...styles.subHeaderItem, width: 50}}></Text>
					<Text style={{...styles.subHeaderItem, width: 65}}>{t("NewContracts")}</Text>
					<Text style={{...styles.subHeaderItem, width: 55}}>Produkt</Text>
					<Text style={{...styles.subHeaderItem, width: 65}}>{t("Contracts")}</Text>
					<Text style={{...styles.subHeaderItem, width: 65}}>Produkt</Text>
					<Text style={{...styles.subHeaderItem, width: 55}}>Bonus</Text>
					<Text
						style={{
							...styles.subHeaderItem,
							borderRight: "none",
							fontFamily: "Helvetica-Bold",
						}}
					>
						{t("total")}
					</Text>
				</View>
				{data?.clientD?.map((client, index) => (
					<View style={styles.subHeader} key={index}>
						<Text style={{...styles.subHeaderItem, width: 70}}>
							{client.clientName !== "MANUAL_CORRECTION" && !client?.isSupervisor
								? t(client.clientName)
								: ""}
						</Text>
						<Text style={{...styles.subHeaderItem, width: 95, paddingLeft: 3}}>
							{getConditionName(client)}
						</Text>
						<Text style={{...styles.subHeaderItem, width: 65}}>
							{!data?.teamLeaderAgencyD?.[0]
								? countContracts(
										contracts,
										client?.clientName,
										client?.isSupervisor,
								  )
								: countContracts(myContracts, client?.clientName)}
						</Text>
						<Text style={{...styles.subHeaderItem, width: 50}}>{client?.points}</Text>
						<Text style={{...styles.subHeaderItem, textAlign: "right", width: 65}}>
							{formatNumber(client?.provision, true)} €
						</Text>
						<Text style={{...styles.subHeaderItem, width: 55, textAlign: "right"}}>
							{formatNumber(client?.addOnsProvision, true)} €
						</Text>
						<Text style={{...styles.subHeaderItem, textAlign: "right", width: 65}}>
							{client.cancelledProvision === 0
								? `${formatNumber(client.cancelledProvision)} €`
								: ` -${formatNumber(client.cancelledProvision)} €`}
						</Text>
						<Text style={{...styles.subHeaderItem, textAlign: "right", width: 65}}>
							{client.cancelledAddOnsProvision === 0
								? `${formatNumber(client?.cancelledAddOnsProvision)} €`
								: ` -${formatNumber(client?.cancelledAddOnsProvision)} €`}
						</Text>
						<Text style={{...styles.subHeaderItem, width: 55, textAlign: "right"}}>
							{formatNumber(client.bonuses, true)} €
						</Text>
						<Text
							style={{
								...styles.subHeaderItem,
								borderRight: "none",
								// paddingLeft: 5,
								paddingRight: 2,
								textAlign: "right",
							}}
						>
							{formatNumber(client.sum)} €
						</Text>
					</View>
				))}
				{/* check if there's an agency or team leader */}
				{/* {data?.teamLeaderAgencyD?.[0] && (
					<View style={styles.subHeader}>
						<Text style={{...styles.subHeaderItem}}></Text>
						<Text style={{...styles.subHeaderItem, width: 90}}>
							{data?.role === "TEAM" ? "Overhead/Teamleiter" : "Agentur - Umsatz"}
						</Text>
						<Text style={styles.subHeaderItem}></Text>
						<Text style={styles.subHeaderItem}></Text>
						<Text style={{...styles.subHeaderItem, width: 60}}></Text>
						<Text style={styles.subHeaderItem}></Text>
						<Text style={styles.subHeaderItem}></Text>
						<Text style={{...styles.subHeaderItem, width: 65}}></Text>
						<Text style={{...styles.subHeaderItem, borderRight: "none"}}></Text>
					</View>
				)} */}
				{data?.teamLeaderAgencyD?.map((client, index) => (
					<View style={styles.subHeader} key={index}>
						<Text style={{...styles.subHeaderItem, width: 70}}>
							{client.clientName}
						</Text>
						<Text
							style={{
								...styles.subHeaderItem,
								width: 95,
							}}
						>
							{data?.role === "TEAM" ? "Overhead/Teamleiter" : "Agentur - Umsatz"}
						</Text>
						<Text style={{...styles.subHeaderItem, width: 65}}>
							{countContracts(teamContracts, client?.clientName)}
						</Text>
						<Text style={{...styles.subHeaderItem, width: 50}}>{client?.points}</Text>
						<Text style={{...styles.subHeaderItem, textAlign: "right", width: 65}}>
							{formatNumber(client.provision, true)} €
						</Text>
						<Text style={{...styles.subHeaderItem, width: 55, textAlign: "right"}}>
							{formatNumber(client?.addOnsProvision, true)} €
						</Text>
						<Text style={{...styles.subHeaderItem, textAlign: "right", width: 65}}>
							- {formatNumber(client.cancelledProvision, true)} €
						</Text>
						<Text style={{...styles.subHeaderItem, textAlign: "right", width: 65}}>
							- {formatNumber(client?.cancelledAddOnsProvision, true)} €
						</Text>
						<Text style={{...styles.subHeaderItem, width: 55, textAlign: "right"}}>
							{formatNumber(client.bonuses, true)} €
						</Text>
						<Text
							style={{
								...styles.subHeaderItem,
								borderRight: "none",
								textAlign: "right",
								paddingRight: 2,
							}}
						>
							{formatNumber(client.sum)} €
						</Text>
					</View>
				))}
				<View style={{...styles.subHeader, width: "100%"}}>
					<Text style={{...styles.subHeaderItem, width: 590}}>Summe Netto</Text>
					<Text
						style={{
							...styles.subHeaderItem,
							borderRight: "none",
							paddingRight: 2,
							textAlign: "right",
						}}
					>
						{formatNumber(data?.sum)} €
					</Text>
				</View>
				<View style={{...styles.subHeader, width: "100%"}}>
					<Text style={{...styles.subHeaderItem, width: 590}}>zzgl. 19% USt.</Text>
					<Text
						style={{
							...styles.subHeaderItem,
							borderRight: "none",
							paddingRight: 2,
							textAlign: "right",
						}}
					>
						{data?.taxBoolean === true
							? `${formatNumber(data?.tax, true)} €`
							: `${formatNumber(0, true)} €`}{" "}
					</Text>
				</View>
				<View style={{...styles.subHeader, width: "100%", fontFamily: "Helvetica-Bold"}}>
					<Text style={{...styles.subHeaderItem, width: 590}}>Auszahlungsbetrag</Text>
					<Text
						style={{
							...styles.subHeaderItem,
							borderRight: "none",
							paddingRight: 2,
							textAlign: "right",
						}}
					>
						{formatNumber(data?.totalValue)} €
					</Text>
				</View>
			</View>
		</>
	);
};

export default CalculationTablePdf;
const styles = StyleSheet.create({
	viewContainer: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		borderWidth: 1,
		borderColor: "#ececec",
		backgroundColor: "#fff",
		borderTop: "#000",
	},
	mainTableContainer: {
		borderTop: "1px solid #000",
		borderLeft: "1px solid #000",
		borderRight: "1px solid #000",
		marginTop: 10,
	},
	viewContainerWeb: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		borderWidth: 1,
		borderColor: "#ececec",
		backgroundColor: "#fff",
	},
	mainTableTitles: {
		display: "flex",
		flexDirection: "row",
		fontSize: 8,
		borderBottom: "1px solid #000",
		fontFamily: "Helvetica-Bold",
	},
	mainTableHeader: {
		display: "flex",
		flexDirection: "row",
		backgroundColor: "#e7e6e6",
		fontSize: 8,
		borderBottom: "1px solid #000",
	},
	mainTableHeaderTitle: {
		width: 215,
		textAlign: "center",
		borderLeft: "1px solid #000",
	},
	mainTableSubHeader: {
		display: "flex",
		flexDirection: "row",
		fontSize: 8,
		color: "#46619b",
		fontWeight: "bold",
		borderBottom: "1px solid #000",
		maxHeight: 14,
		minHeight: 14,
	},
	mainTableSubHeaderTitle: {
		width: 107.5,
		textAlign: "center",
		borderLeft: "1px solid #000",
		fontSize: 8,
	},
	mainTableRow: {
		display: "flex",
		flexDirection: "row",
		fontSize: 8,
		borderBottom: "1px solid #000",
		minHeight: 12,
	},
	mainTitle: {
		fontWeight: 600,
		borderRight: "1px solid #000",
		fontSize: 8,
		width: 150,
		backgroundColor: "#EFEFEF",
		padding: 5,
		fontFamily: "Helvetica-Bold",
	},
	subHeader: {
		display: "flex",
		flexDirection: "row",
		borderBottom: "1px solid #000",
	},
	subHeaderItem: {
		width: 75,
		fontSize: 7,
		fontWeight: 400,
		borderRight: "1px solid #000",
		padding: 2,
		paddingLeft: 3,
		fontFamily: "Helvetica",
	},
});
