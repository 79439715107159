import React, {useState} from "react";
import ConditionAccordion from "@src/components/dashboard/Users/TeamConditions/Accordion/Accordion";
import {AddUploadIcon, EditRowIcon} from "@src/assets/SvgComponents";
import {useTranslation} from "react-i18next";
import {checkRoles} from "@src/utils/checkRoles";
import {useUrlParams} from "@src/utils/useUrlParams";
import SalesHistory from "./components/salesHistory";
import UploadDocument from "./components/uploadDocument";
import "./styles.scss";
import UploadDocumentModal from "./components/uploadDocument/UploadDocumentModal";
import ClientInfoModal from "./components/uploadDocument/ClientInfoModal";

const StatisticAndDocuments = ({navigate}) => {
	const {t} = useTranslation();
	const [expanded, setExpanded] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const handleExpandedChange = () => {
		setExpanded(!expanded);
	};

	const {setModal, getUrlParams} = useUrlParams();
	const {modal} = getUrlParams("modal");

	return (
		<div className="statistic-and-documnets">
			<SalesHistory />
			<ConditionAccordion
				title={
					<div className="statistic-and-documnets--bottom-table-header">
						<p className="statistic-and-documnets--bottom-table-header--title">
							{t("documentUploads")}
						</p>
						<div className="statistic-and-documnets--bottom-table-header--icons">
							{checkRoles("uploadStatisticAndDocument") && (
								<div className="add-icon" onClick={() => setModal("modal")}>
									<AddUploadIcon />
								</div>
							)}
							{checkRoles("editOnStatisticAndDocument") && (
								<div className="add-icon" onClick={() => setModal("edit")}>
									<EditRowIcon />
								</div>
							)}
							{/* <DeleteRowIcon /> */}
						</div>
					</div>
				}
				expanded={expanded}
				onChange={() => handleExpandedChange()}
			>
				<UploadDocument navigate={navigate} setShowModal={setModal} />
			</ConditionAccordion>
			<UploadDocumentModal showModal={modal} setShowModal={setModal} />
			<ClientInfoModal showModal={modal} setShowModal={setModal} />
		</div>
	);
};

export default StatisticAndDocuments;
