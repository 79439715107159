import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import Modal from "@common/modal";
import CustomSelect from "@src/common/Select";
import {statusOptions} from "@src/components/dashboard/orders/staticData";
import Button from "@src/common/components/button";
import {EditRowIcon} from "@src/assets/SvgComponents";

function StatusBox({
	row,
	statusData,
	percentage,
	showText = true,
	translateText = false,
	flag = false,
	editable = false,
	orderIdsAndClientTypes = null,
	changeOrdersStatus,
	loading,
	name,
	height = "80%",
	containerHeight = "100%",
}) {
	const [width, setWidth] = useState("");
	const [editModal, setEditModal] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState("");
	const remainingWidth = `${100 - percentage}%`;
	const {t} = useTranslation();
	const parentRef = useRef(null);
	useEffect(() => {
		if (parentRef.current) {
			const parentWidth = parentRef.current.offsetWidth;
			setWidth(`${parentWidth}px`);
		}
	}, []);
	function capitalizeWords(text) {
		return text
			?.toLowerCase()
			?.split(" ")
			?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
			?.join(" ");
	}
	const handleEdit = () => {
		setEditModal(true);
	};
	const handleChangeStatus = () => {
		changeOrdersStatus({
			orders: [orderIdsAndClientTypes],
			status: selectedStatus.val,
			statusName: selectedStatus.statusValue,
			statusValue: selectedStatus.statusValue,
			setSelectedStatus,
			setEditModal,
		});
	};
	return (
		<div
			className="container"
			style={{
				height: containerHeight,
			}}
		>
			<div
				className="status-container"
				ref={parentRef}
				style={{
					backgroundColor: `${statusData?.[row]?.color}${
						percentage && percentage < 100 ? "66" : "bf"
					}`,
					border: !name && `1px solid ${statusData?.[row]?.color}`,
					position: percentage && "relative",
					height,
				}}
			>
				{percentage && percentage < 100 && percentage > 0 ? (
					<div
						className="status-progressing"
						style={{
							width: width !== "" && `calc(${width} - ${remainingWidth})`,
							backgroundColor: `${statusData?.[row]?.color}bf`,
							maxWidth: width,
						}}
					></div>
				) : (
					<></>
				)}
				<div
					className="status-icon-container"
					style={{borderRight: `1px solid ${statusData?.[row]?.color}`}}
				>
					{statusData[row]?.icon}
				</div>
				{showText && (
					<span className="status-text">
						{name ||
							t(
								`${
									translateText ? row : capitalizeWords(row?.replaceAll("_", " "))
								}`,
							)}
					</span>
				)}
				{flag && <div className="flag" />}
				{editable && (
					<div onClick={handleEdit} className="editable">
						{" "}
						<EditRowIcon width={12} height={12} stroke="#fff" />
					</div>
				)}
			</div>
			<Modal
				overflow="visible"
				disableEnforceFocus
				className="change-status-modal"
				open={editModal}
				borderRadius="10px 10px 10px 10px"
				handleClose={() => setEditModal(false)}
			>
				<div className="change-status-modal-container">
					<div className="change-status-modal-container_body">
						<span className="change-status-modal-container_title">
							{t("ChangeStatus")}
						</span>
						<CustomSelect
							options={statusOptions
								.map((item) => ({
									...item,
									val: item?.value,
									value: item?.name,
								}))
								.filter((item) => item?.statusValue !== "CONTRACT_REACTIVATED")}
							onClear={() => setSelectedStatus("")}
							onSelect={(value, option) => setSelectedStatus(option)}
							showSearch
							allowClear
							value={selectedStatus?.name}
							placeholder={t("ChooseStatus")}
							optionFilterProp="value"
						/>
					</div>
					<div className="change-status-modal-container_buttons">
						<Button
							handleChange={() => handleChangeStatus()}
							text={t("ChangeStatus")}
							disabled={selectedStatus === "" || loading}
							borderRadius="8px"
							width="100%"
							height="52px"
							leftRounded={true}
							fontSize="14px"
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
}

export default StatusBox;
