/* eslint-disable max-len */
import {ShowInfoIcon} from "@src/assets/SvgComponents";
import ButtonComponent from "@src/common/button";
import CustomAccordion from "@src/common/CustomAccordion";
import React from "react";
import {useTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as clientActions} from "@sagas/client";
import SimpleDropDownInput from "@src/common/dropDown/simpleDropDown";
import {filteringStatusOptions} from "@src/components/dashboard/orders/staticData";
import InputComponent from "@src/common/input";
import {useFormik} from "formik";
import {pdfDeleteSchema} from "./utils";

const GeneratedPdfsSettings = ({loading, updateSettings, scheduleDeletePdf}) => {
	const {t} = useTranslation();
	const formik = useFormik({
		initialValues: scheduleDeletePdf || {
			date: "",
			status: "",
		},
		validationSchema: pdfDeleteSchema,
		onSubmit: (values) => {
			updateSettings({
				scheduleDeletePdf: {
					...values,
					status: values?.status?.includes("CANCELLED") ? "CANCELLED" : values?.status,
					statusName: values?.status,
				},
			});
		},
	});
	const {values, touched, errors, setFieldValue, handleChange, handleSubmit} = formik;
	return (
		<CustomAccordion title={t("PDFDeletionSettings")} className="contracts__accordion">
			<div className="generated__pdf__container">
				<div className="generated__pdf__container-title">
					<ShowInfoIcon fill="#C1C1C1" />
					<span>{t("PDFDeletionInfo")}</span>
				</div>
				<form onSubmit={handleSubmit}>
					<div className="generated__pdf__container-content">
						<div className="dropdown--container">
							<p className="dropdown--container-label">{t("ChooseStatus")}</p>
							<SimpleDropDownInput
								options={filteringStatusOptions}
								value={{
									value: filteringStatusOptions.find(
										(item) => item.statusValue === values?.status,
									)?.statusValue,
									name: filteringStatusOptions.find(
										(item) => item.statusValue === values?.status,
									)?.name,
								}}
								title="name"
								touched={touched?.status}
								errors={errors?.status}
								onChange={(_, value) => {
									setFieldValue("status", value.statusValue);
								}}
							/>
						</div>
						<InputComponent
							type="number"
							min={0}
							hasInfoIcon
							name="date"
							values={values?.date}
							touched={touched?.date}
							errors={errors?.date}
							errorClass="errorClass"
							label={t("ChooseDays")}
							infoText={t("PDFDeletionDays")}
							handleChange={handleChange}
						/>
					</div>
					<div className="generated__pdf__container-actions">
						<ButtonComponent
							buttonText={t("Save")}
							disabled={loading}
							loading={loading}
						/>
					</div>
				</form>
			</div>
		</CustomAccordion>
	);
};

const mapStateToProps = (state) => ({
	loading: state.app.client.index.isLoading,
	scheduleDeletePdf: state.app.client.index.client.scheduleDeletePdf,
});
const mapDispatchToProps = {
	updateSettings: clientActions.updateSettings,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GeneratedPdfsSettings));
