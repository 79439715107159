export const DateTypes = {
	week: "WEEKLY",
	month: "MONTHLY",
	quarter: "QUARTERLY",
	year: "YEARLY",
	custom: "CUSTOM",
};
export const DateTypes1 = {
	WEEKLY: "week",
	MONTHLY: "month",
	QUARTERLY: "quarter",
	YEARLY: "year",
	CUSTOM: "custom",
};
export const TypeList = {
	// usersIdList: "USERS",
	userList: "USERS",
	clientList: "CLIENTS",
	teamList: "TEAMS",
	agencyIdList: "AGENCIES",
};
export const TypeList1 = {
	// USERS: "usersIdList",
	USERS: "userList",
	// CLIENTS: "clientIdList",
	CLIENTS: "clientList",
	TEAMS: "teamList",
	AGENCIES: "agencyIdList",
};

export const TypeList2 = {
	USERS: "userList",
	CLIENTS: "clientList",
	TEAMS: "teamList",
	AGENCIES: "agencyList",
};
