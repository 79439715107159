/* eslint-disable react/react-in-jsx-scope */
import {
	PriorityNone,
	PriorityLow,
	PriorityMedium,
	PriorityHigh,
	ApprovedIcon,
	PaidIcon,
	PendingIcon,
	CancelledIcon,
	IconDelivered,
	IconFinished,
	IconBonus,
	SignatureIcon,
	SettledIcon,
	ShowOnDashboardIcon,
	DeleteCardsIcon,
	EditCardsIcon,
	RemoveFromDashboardIcon,
	ReactivatedIcon,
	MobileIcon,
	Close,
	SelectIcon,
	BonusSettledIcon,
} from "@src/assets/SvgComponents";
import i18n from "@src/i18n";
import * as yup from "yup";
import React from "react";
import moment from "moment";
import {checkRoles} from "@src/utils/checkRoles";
const timezone = new Date().getTimezoneOffset();

export const HeaderAnalyticArray = [{label: i18n.t("costumizeAlerts"), key: "costumizeAlerts"}];

export const AnalyticCardAction = ({
	showDashboard,
	showMobile,
	showEdit,
	multiple,
	selected,
	removeMobile,
	fromDashboard,
	expired,
}) => {
	const items = [
		{
			key: "selectAll",
			label: i18n.t("SelectAll"),
			icon: <SelectIcon stroke="var(--color-primary)" />,
			show: multiple && selected?.length === 0,
			className: "no-actions",
		},
		{
			label: i18n.t("showOnDashboard"),
			icon: <ShowOnDashboardIcon width={18} />,
			modalTitle: `${i18n.t("AreYouSureTo")} ${i18n.t("addCard")}${
				multiple ? "s" : ""
			} ${i18n.t("toDashboard")} ?`,
			key: "addCardToDashboard",
			show: multiple
				? selected.length > 0 && checkRoles("addCardToDashboardGoals") && !showDashboard
				: checkRoles("addCardToDashboardGoals") && !showDashboard && !expired,
		},
		{
			label: i18n.t("ShowMobile"),
			icon: <MobileIcon width={18} />,
			modalTitle: `${i18n.t("AreYouSureTo")} ${i18n.t("displayCard")}${
				multiple ? "s" : ""
			} ${i18n.t("toMobile")} ?`,
			key: "addCardToMobile",
			show: multiple
				? selected.length > 0 && checkRoles("addCardToMobileGoals") && showMobile
				: checkRoles("addCardToMobileGoals") && !removeMobile && !expired,
		},
		{
			label: i18n.t("removeFromDashboard"),
			icon: <RemoveFromDashboardIcon width={18} />,
			modalTitle: `${i18n.t("AreYouSureTo")} ${i18n.t("removeCard")}${
				multiple ? "s" : ""
			} ${i18n.t("fromDashboard")} ?`,
			key: "removeCardToDashboard",
			show: multiple
				? checkRoles("removeCardToDashboardGoals") && selected.length > 0
				: checkRoles("removeCardToDashboardGoals") && showDashboard,
		},
		{
			label: i18n.t("removeFromMobile"),
			icon: <RemoveFromDashboardIcon width={18} />,
			modalTitle: `${i18n.t("AreYouSureTo")} ${i18n.t("removeCard")}${
				multiple ? "s" : ""
			} ${i18n.t("fromMobile")}`,
			key: "removeCardToMobile",
			show: multiple
				? checkRoles("removeCardToMobileGoals") && selected.length > 0
				: checkRoles("removeCardToMobileGoals") && removeMobile,
		},
		{
			label: i18n.t("editCard"),
			icon: <EditCardsIcon width={18} />,
			modalTitle: "Title",
			key: "edit",
			show: multiple
				? checkRoles("editGoals") && selected?.length > 0 && showEdit
				: checkRoles("editGoals") && !fromDashboard && !expired,
		},
		{
			label: i18n.t("deleteCard"),
			icon: <DeleteCardsIcon width={18} />,
			modalTitle: `${i18n.t("AreYouSureTo")} ${i18n.t("deleteCard")}${multiple ? "s" : ""} ?`,
			key: "deleteCard",
			show: multiple
				? checkRoles("deleteGoal") && selected?.length > 0
				: checkRoles("deleteGoal"),
		},
		{
			key: "deselect",
			icon: <Close fill="#252525" width={18} height={10} />,
			label: i18n.t("DeselectAll"),
			show: multiple && selected?.length > 0,
		},
	];
	return items.filter((item) => item.show);
};

// Validation Schema

export const validationSchema = yup.object().shape({
	// usersIdList: yup.array().when(["typeList"], {
	userList: yup.array().when(["typeList"], {
		is: (type) => type === "USERS",
		then: yup.array().min(1, i18n.t("userIsReq")).nullable(),
		otherwise: yup.array().optional().nullable(),
	}),
	clientList: yup.array().when(["typeList"], {
		is: (type) => type === "CLIENTS",
		then: yup.array().min(1, i18n.t("clientIsReq")).nullable(),
		otherwise: yup.array().optional().nullable(),
	}),
	teamList: yup.array().when(["typeList"], {
		is: (type) => type === "TEAMS",
		then: yup.array().min(1, i18n.t("teamIsReq")).nullable(),
		otherwise: yup.array().optional().nullable(),
	}),
	agencyList: yup.array().when(["typeList"], {
		is: (type) => type === "AGENCIES",
		then: yup.array().min(1, i18n.t("agencyIsReq")).nullable(),
		otherwise: yup.array().optional().nullable(),
	}),
	name: yup.string().when(["step"], {
		is: (step) => step === 1,
		then: yup.string().max(70, i18n.t("txtTooLong")).required(i18n.t("titleReq")),
		otherwise: yup.string().optional(),
	}),
	startDate: yup.string().when(["step"], {
		is: (step) => step === 1,
		then: yup.string().label(i18n.t("startDate")).required(i18n.t("DateIsReq")).nullable(),
		otherwise: yup.string().optional().nullable(),
	}),
	endDate: yup.string().when(["step"], {
		is: (step) => step === 1,
		then: yup.string().label(i18n.t("endDate")).required(i18n.t("DateIsReq")).nullable(),
		otherwise: yup.string().optional().nullable(),
	}),
	goalType: yup.string().when(["step"], {
		is: (step) => step === 1,
		then: yup.string().required(i18n.t("goalReq")),
		otherwise: yup.string().optional(),
	}),
	amount: yup.string().when(["step"], {
		is: (step) => step === 1,
		then: yup.string().required(i18n.t("amountReq")).nullable(),
		otherwise: yup.string().optional().nullable(),
	}),
	targetClients: yup.array().when(["step", "typeList"], {
		is: (step, type) => step === 1 && type === "CLIENT",
		then: yup.array().required(i18n.t("targetClientsReq")).nullable(),
		otherwise: yup.array().nullable(),
	}),
	targetClientsList: yup.array().when(["step"], {
		is: (step) => step === 1,
		then: yup.array().optional().nullable(),
		otherwise: yup.array().optional().nullable(),
	}),
	targetTariffsList: yup.array().optional().nullable(),
	targetProductsList: yup.array().optional().nullable(),
	targetLocationsList: yup.array().optional().nullable(),
	priority: yup.string().when(["step"], {
		is: (step) => step === 2,
		then: yup.string().required(i18n.t("priorityReq")).nullable(),
		otherwise: yup.string().optional().nullable(),
	}),
	orderStatusList: yup.array().when(["step"], {
		is: (step) => step === 2,
		then: yup.array().min(1, i18n.t("orderStatusReq")).nullable().required(),
		otherwise: yup.array().optional().nullable(),
	}),
	description: yup.string().when(["step"], {
		is: (step) => step === 2,
		then: yup.string().required(i18n.t("descriptionReq")).nullable(),
		otherwise: yup.string().optional().nullable(),
	}),
	textColor: yup.string().when(["step"], {
		is: (step) => step === 2,
		then: yup.string().optional().nullable(),
		otherwise: yup.string().optional().nullable(),
	}),
	bgColor: yup.string().when(["step"], {
		is: (step) => step === 2,
		then: yup.string().optional().nullable(),
		otherwise: yup.string().optional().nullable(),
	}),
});

export const sizeTypes = [
	{
		label: "Medium",
		value: "MEDIUM",
	},
];

export const goalsArray = [
	{
		label: i18n.t("NewContracts"),
		value: "NEW_CONTRACTS",
	},
];

export const pritorities = [
	{
		label: i18n.t("priorityNone"),
		value: "NONE",
		color: "#B7B7B7",
		icon: <PriorityNone />,
	},
	{
		label: i18n.t("priorityLow"),
		value: "LOW",
		color: "#31B073",
		icon: <PriorityLow />,
	},
	{
		label: i18n.t("priorityMedium"),
		value: "MEDIUM",
		color: "#EC8C3A",
		icon: <PriorityMedium />,
	},
	{
		label: i18n.t("priorityHigh"),
		value: "HIGH",
		color: "#D00A12",
		icon: <PriorityHigh />,
	},
];

export const analyticsStatusOptions = [
	{
		name: i18n.t("Approved"),
		value: i18n.t("APPROVED"),
		statusValue: "APPROVED",
		icon: <ApprovedIcon />,
	},
	{
		name: i18n.t("Clarification"),
		value: i18n.t("CLARIFICATION"),
		statusValue: "CLARIFICATION",
		icon: <ApprovedIcon />,
	},
	{
		name: i18n.t("BillingNextMonth"),
		value: i18n.t("BILLING_NEXT_MONTH"),
		statusValue: "BILLING_NEXT_MONTH",
		icon: <ApprovedIcon />,
	},
	{
		name: i18n.t("Paid"),
		value: i18n.t("PAID"),
		statusValue: "PAID",
		icon: <PaidIcon />,
	},
	{
		name: i18n.t("Pending"),
		value: i18n.t("PENDING"),
		statusValue: "PENDING",
		icon: <PendingIcon />,
	},
	{
		name: i18n.t("Cancelled"),
		value: i18n.t("CANCELLED"),
		statusValue: "CANCELLED",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("Delivered"),
		value: i18n.t("DELIVERED"),
		statusValue: "DELIVERED",
		icon: <IconDelivered />,
	},
	{
		name: i18n.t("Finished"),
		value: i18n.t("FINISHED"),
		statusValue: "FINISHED",
		icon: <IconFinished />,
	},
	{
		name: i18n.t("Bonus"),
		value: i18n.t("BONUS"),
		statusValue: "BONUS",
		icon: <IconBonus />,
	},
	{
		name: i18n.t("BONUS_SETTLED"),
		value: i18n.t("BONUS_SETTLED"),
		statusValue: "BONUS_SETTLED",
		icon: <BonusSettledIcon />,
	},
	{
		name: i18n.t("NoSignature"),
		value: i18n.t("NOSIGNATURE"),
		statusValue: "NOSIGNATURE",
		icon: <SignatureIcon />,
	},
	{
		name: "Settled",
		value: "SETTLED",
		statusValue: "SETTLED",
		icon: <SettledIcon />,
	},
	{
		name: i18n.t("ContractReactivated"),
		value: i18n.t("CONTRACTREACTIVATED"),
		statusValue: "CONTRACT_REACTIVATED",
		icon: <ReactivatedIcon />,
	},
	{
		name: i18n.t("CANCELLED_CUSTOMER"),
		value: i18n.t("CANCELLED_CUSTOMER"),
		statusValue: "CANCELLED_CUSTOMER",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("CANCELLED_INCORRECT_ORDER"),
		value: i18n.t("CANCELLED_INCORRECT_ORDER"),
		statusValue: "CANCELLED_INCORRECT_ORDER",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("CANCELLED_PROVIDER"),
		value: i18n.t("CANCELLED_PROVIDER"),
		statusValue: "CANCELLED_PROVIDER",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("CancelledDouble"),
		value: i18n.t("CANCELLED_DOUBLE"),
		statusValue: "CANCELLED_DOUBLE",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("CancelledCreditWorth"),
		value: i18n.t("CANCELLED_CREDIT_WORTH"),
		statusValue: "CANCELLED_CREDIT_WORTH",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("excludeFromSettlement"),
		value: i18n.t("excludeFromSettlement"),
		statusValue: "EXCLUDE_SETTLEMENT",
		icon: <CancelledIcon />,
	},
];
export const getGoalsPayload = (query) => {
	const filters = {
		dateType:
			// eslint-disable-next-line no-nested-ternary
			query?.dateType && (query?.dateType === "CUSTOM" || query?.dateType === "custom")
				? "CUSTOM"
				: query?.dateType
				? `${query?.dateType?.toUpperCase()}LY`
				: null,
		startDate: query?.from
			? moment(query?.from).startOf("day").subtract(timezone, "minutes").valueOf()
			: null,
		endDate: query?.to
			? moment(query?.to).endOf("day").subtract(timezone, "minutes").valueOf()
			: null,
		search: query?.search || null,
		clientId: query?.clientId || null,
		locationId: query?.locationId || null,
		priority: query?.priority || null,
		teamId: query?.teamId || null,
		agencyId: query?.agencyId || null,
		status: query?.status || null,
		groupTypeId: query?.groupTypeId || null,
		userId: query?.userId || null,
		productId: query?.productId || null,
		tariffId: query?.tariffId || null,
		dateStatus: query?.dateStatus || null,
	};
	return filters;
};
