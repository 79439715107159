import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as calculationActions} from "@sagas/calculations";
import {actions as navigation} from "@sagas/navigation";
import DataGridTable from "@src/common/table/DataGridTable";
import {ToastInfoComponent} from "@src/common/ToastComponent/ToastComponent";
import ModalComponent from "@src/common/modal";
import {actions as fileActions} from "@sagas/messages/create";
import AddPrice from "../../orders/AddPrice";
import BackButton from "../../../../assets/img/back__icon.svg";

const CalculationImportTable = ({
	excelData,
	setExcelData,
	match,
	navigate,
	csvData,
	clearCsvData,
	removeFile,
	file,
}) => {
	const {activeTab, tabs} = excelData;
	const id = match?.params?.id;
	const [priceModal, setPriceModal] = useState({
		open: false,
		value: 0,
	});
	const [priceCounter, setPriceCounter] = useState(0);
	const [rows, setRows] = useState({
		data: csvData[0]?.data,
		columns: csvData[0]?.columns,
	});
	const {t} = useTranslation();
	useEffect(() => {
		if (csvData && activeTab) {
			const currentData = csvData[activeTab?.index];
			setRows({
				columns: currentData?.columns?.map((col) => ({
					...col,
					width: 100,
					resizable: true,
				})),
				data: currentData?.data,
			});
		}
	}, [activeTab, csvData]);
	const handleRowClick = (index, rows, col) => {
		const currentCell = rows[col?.idx];
		const numericValue = currentCell?.replace(/[^\d.-]/g, "");
		const convertedValue = parseFloat(numericValue);
		// eslint-disable-next-line no-restricted-globals
		if (!isNaN(convertedValue)) {
			setPriceModal({
				open: true,
				value: convertedValue,
			});
		} else {
			ToastInfoComponent(t("SelectCorrectCell"));
		}
	};

	const handleClose = () => {
		setPriceModal({
			open: false,
			value: 0,
		});
	};
	const handleGoBack = () => {
		navigate(`/admin/calculations/${id}`);
		clearCsvData();
		// eslint-disable-next-line max-len
		// TODO we should look for another solution for this delete , so it shouldn't be done on this go back button
		// if (priceCounter === 0) {
		// 	removeFile(file?.fileId);
		// }
	};
	if (!csvData?.[0]) {
		navigate(`/admin/calculations/${id}`);
		return null;
	}
	return (
		<>
			<ModalComponent
				open={priceModal?.open}
				handleClose={handleClose}
				className="add_edit_user"
			>
				<AddPrice
					initialValues={{
						description: "",
						price: priceModal?.value,
					}}
					setShowPriceModal={handleClose}
					currentSettlement={id}
					setCounter={setPriceCounter}
					counter={priceCounter}
					fileId={file?.fileId}
				/>
			</ModalComponent>
			<div className="dashboard__container">
				<div className="dashboard__left__text usersCalcDetails">
					<div className="header-component">
						<div className="header-component-wrapper" style={{marginLeft: "25px"}}>
							<img
								onClick={handleGoBack}
								className="back__button_client"
								src={BackButton}
								alt=""
							/>
							<p className="calculation__fileName">{csvData[0]?.fileName}</p>
						</div>
					</div>
				</div>
				<div className="calculation__import-container">
					<div className="calculation__import-container__tabs">
						{tabs?.map((tab, index) => (
							<p
								key={index}
								onClick={() => {
									setExcelData({
										...excelData,
										activeTab: {
											value: tab,
											index,
										},
									});
								}}
								className={`tab ${activeTab?.value === tab ? "active" : ""}`}
							>
								{tab}
							</p>
						))}
					</div>

					<div className="calculation__import-container-table">
						<DataGridTable
							columns={rows?.columns || []}
							data={rows?.data || []}
							pagination={false}
							rowHeight={50}
							onRowClick={handleRowClick}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	excelData: state.app.calculations.index.excelData,
	csvData: state.app.calculations.index.csvData,
	file: state.app.calculations.index.file,
});

const mapDispatchToProps = {
	navigate: navigation.navigate,
	setExcelData: calculationActions.setExcelData,
	clearCsvData: calculationActions.clearCsvData,
	removeFile: fileActions.removeFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CalculationImportTable));
