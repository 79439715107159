import React from "react";
import {connect} from "react-redux";
import {actions as navigation} from "@sagas/navigation/";
import {withRouter} from "react-router";
import {ReactComponent as Eye} from "../../assets/img/visibility_black_24dp.svg";

const EyeIcon = ({id, navigate, type}) => (
	<div className="img__see__more">
		<Eye onClick={() => navigate(`/admin/${type}/${id}`)} />
	</div>
);

const mapDispatchToProps = {
	navigate: navigation.navigate,
};

export default connect(null, mapDispatchToProps)(withRouter(EyeIcon));
