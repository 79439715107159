import React from "react";
import InputComponent from "@src/common/input";
import i18n from "@src/i18n";
import {useFeatures} from "@src/utils/useFeatures";
import IbanInput from "@src/components/dashboard/Energy/components/IbanValidator";
import _ from "lodash";
import Signature from "@src/common/signature";
import GenericDatePicker from "@src/common/CustomDatePicker";
import EmailInput from "@src/common/EmailInput";
import MediaComponent from "@src/common/messagesComponents/MediaComponent";
import CustomSelect from "@src/common/Select";
import CheckBoxComponentAntd from "@src/common/checkbox/antdCheckbox";
import TextAreaComponent from "@src/common/TextArea/TextAreaComponent";
import {DATE_INPUTS, shouldHideField, TEXT_INPUTS} from "../helpers";

const currentLanguage = i18n.language;

const FieldsRenderer = ({
	fields,
	values,
	errors,
	setFieldValue,
	orderFields,
	fieldOptions,
	uploadedFiles,
	setUploadedFiles,
	retrieveDataFromGetAg,
	options,
}) => {
	const {checkFeature} = useFeatures();
	const isGetAgAvailable = (requestType, targetFeature) => {
		if (!requestType) {
			return true;
		}
		return checkFeature(targetFeature);
	};
	const handleDependencies = (dependencyFields, value) => {
		if (dependencyFields) {
			dependencyFields.forEach((item) => {
				const foundedDep = orderFields.find((it) => it.name === item);
				if (foundedDep.inputType === "CHECKBOX") {
					if (
						value ||
						foundedDep?.dependentFrom?.split(",").some((val) => values[val?.trim()])
					) {
						setFieldValue(foundedDep.name, true);
					} else {
						setFieldValue(foundedDep.name, false);
					}
				}
			});
		}
	};
	const handleInput = (item, value) => {
		const {defaultValue, name, dependencyFields, assignInputTo, requestType} = item;
		if (!defaultValue || value.startsWith(defaultValue)) {
			setFieldValue(name, value, true);
		}
		handleDependencies(dependencyFields, value);
		if (assignInputTo?.[0]) {
			assignInputTo.forEach((childrenItems) => {
				setFieldValue(childrenItems, value);
			});
		}
		if (requestType && isGetAgAvailable(requestType, "CHECK_ADDRESS")) {
			retrieveDataFromGetAg({...item, type: "postCode"}, value);
		}
	};
	const handleUpload = (event, name) => {
		const uploadedFiles = Array.from(event.target.files);
		const processedFiles = uploadedFiles.map((file) => ({
			fileUrl: URL.createObjectURL(file),
			fileType: file.type.split("/")[1],
			fileName: file.name,
		}));
		setUploadedFiles((prev) => ({
			...prev,
			[name]: [...(prev[name] || []), ...processedFiles],
		}));
	};
	const handleRemoveFile = (file, name) => {
		setUploadedFiles((prev) => {
			const currentFiles = prev[name] || [];
			const filteredFiles = currentFiles.filter((item) => item.fileUrl !== file.fileUrl);
			return {
				...prev,
				[name]: filteredFiles,
			};
		});
	};
	const handleDropdown = (item, value) => {
		const {dependentFrom, dependencyFields, name, requestType, isMultiple} = item;
		setFieldValue(name, isMultiple ? [value, ...(values?.[name] || [])] : value);
		handleDependencies(dependencyFields, value);
		if (requestType && requestType !== "STREET") {
			retrieveDataFromGetAg({...item, type: "location"}, value, values?.[dependentFrom]);
		}
	};
	const handleDeselect = (item, value) => {
		const {name} = item;
		setFieldValue(
			name,
			values?.[name]?.filter((v) => v !== value),
		);
	};
	return (
		<>
			{fields?.map((item) => {
				const {
					name,
					inputType,
					requestType,
					labelEn,
					labelDe,
					fieldId,
					isMultiple,
					isDisabled,
				} = item;
				const label = currentLanguage === "en" ? labelEn : labelDe;
				if (shouldHideField({item, values})) return null;
				return (
					<div key={name} className="single__input">
						{TEXT_INPUTS.includes(inputType) && requestType !== "IBAN" && (
							<InputComponent
								label={label}
								values={values?.[name]}
								errors={errors?.[name]}
								touched
								handleChange={(e) => handleInput(item, e.target.value)}
								errorClass="errorClass"
								disabled={isDisabled}
							/>
						)}
						{requestType === "IBAN" && (
							<IbanInput
								element={{name, item}}
								setFieldValue={setFieldValue}
								value={_.get(values, name) ?? ""}
								fields={fields}
								canValidate={isGetAgAvailable(requestType, "CHECK_IBAN")}
								disabled={isDisabled}
							/>
						)}
						{inputType === "IMAGE" && (
							<div className="signature__wrapper">
								<span>{label}</span>
								<Signature
									setFieldValue={setFieldValue}
									element={{name, item}}
									disabled={isDisabled}
								/>
								{errors?.[name] && (
									<span className="errorClass">{errors[name]}</span>
								)}
							</div>
						)}
						{inputType === "EMAIL" && (
							<EmailInput
								handleInput={(e) => handleInput(item, e.target.value)}
								element={{name, item}}
								value={_.get(values, name)}
								label={label}
								touched
								error={errors?.[name]}
								disabled={isDisabled}
							/>
						)}
						{inputType === "UPLOAD" && (
							<MediaComponent
								totalFiles={{files: uploadedFiles?.[name] || []}}
								showTitle
								customClassName="edit-order-class"
								title={label}
								canDownload
								canUploadMore={!isDisabled}
								height="auto"
								downloadWithoutToken
								id={fieldId}
								canRemove
								onRemove={(file) => handleRemoveFile(file, name)}
								uploadInputHandler={(event) => handleUpload(event, name)}
							/>
						)}
						{inputType === "DROPDOWN" && (
							<>
								{!isGetAgAvailable(requestType, "CHECK_ADDRESS") ? (
									<InputComponent
										label={label}
										values={values?.[name]}
										errors={errors?.[name]}
										touched
										handleChange={(e) => handleInput(item, e.target.value)}
										errorClass="errorClass"
										disabled={isDisabled}
									/>
								) : (
									<CustomSelect
										options={
											requestType
												? options?.[name] || []
												: fieldOptions?.[fieldId]
										}
										mode={isMultiple ? "multiple" : undefined}
										onSelect={(value) => handleDropdown(item, value)}
										onClear={() => setFieldValue(name, isMultiple ? [] : null)}
										onDeselect={(value) => handleDeselect(item, value)}
										label={label}
										value={values?.[name]}
										disabled={isDisabled}
									/>
								)}
							</>
						)}
						{DATE_INPUTS.includes(inputType) && (
							<GenericDatePicker
								setFieldValue={setFieldValue}
								element={{name, item}}
								error={_.get(errors, name)}
								value={_.get(values, name)}
								label={label}
								disabled={isDisabled}
							/>
						)}
						{inputType === "TEXTAREA" && (
							<TextAreaComponent
								label={label}
								setFieldValue={setFieldValue}
								value={values?.[name]}
								touched
								errors={errors?.[name]}
								inputName={name}
								breakLine={false}
							/>
						)}
						{inputType === "CHECKBOX" && (
							<CheckBoxComponentAntd
								label={label}
								name={name}
								handleChange={(e) => handleInput(item, e.target.checked)}
								checked={values?.[name]}
								disabled={isDisabled}
							/>
						)}
					</div>
				);
			})}
		</>
	);
};

export default FieldsRenderer;
