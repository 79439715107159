/* eslint-disable react/button-has-type */
import React, {useEffect, useState} from "react";
import "./App.scss";
import {withRouter} from "react-router";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {actions as userActions} from "@sagas/users/users/index";
import {actions as drawerActions} from "@sagas/drawer";
import {connect, useDispatch} from "react-redux";
import {actions as languageActions} from "@sagas/language/index";
import {useTranslation} from "react-i18next";
import {ReactComponent as MenuIcon} from "@assets/img/hamburger-menu.svg";
import {useMediaQuery} from "@mui/material";
import Routes from "./components/routes";
import Drawer from "./components/drawer";
import {onMessageListener} from "./utils/firebase";
import DrawerSkeleton from "./components/drawer/DrawerSkeleton";

const App = ({
	history,
	getLoggedUser,
	clearLoggedUser,
	toggleSagaLanguage,
	billingDetails,
	loggedUser,
}) => {
	const isLargeScreen = useMediaQuery("(min-width:900px)");
	const dispatch = useDispatch();
	const {i18n} = useTranslation();

	const [collapse, setCollapse] = useState(true);
	const [width, setWidth] = useState("calc(100% - 200px)");
	const pathName = history.location.pathname.includes("admin");
	useEffect(() => {
		// handle foreground firebase messaging
		onMessageListener(history, dispatch);
	}, [dispatch]);
	const _language = localStorage.getItem("language");
	const roleData = localStorage.getItem("roleData");
	useEffect(() => {
		const tenantTitle = localStorage.getItem("title");
		document.title = tenantTitle !== "null" && tenantTitle !== null ? tenantTitle : "ONERI";
	}, [billingDetails?.title]);

	useEffect(() => {
		if (localStorage.token) {
			getLoggedUser();
		} else {
			clearLoggedUser();
		}
		i18n.changeLanguage(_language || "de");
		toggleSagaLanguage(_language || "de");
		// eslint-disable-next-line
	}, [localStorage.token]);

	useEffect(() => {
		const theme = localStorage.getItem("theme");
		const parsedTheme = JSON.parse(theme);
		document.documentElement.style.setProperty(
			"--color-primary",
			parsedTheme?.primary || "#6C1EB0",
		);
		document.documentElement.style.setProperty(
			"--color-secondary",
			parsedTheme?.secondary || "#B089D3",
		);
		document.documentElement.style.setProperty(
			"--color-hover",
			parsedTheme?.hover || "#E2D2EF",
		);
	}, [loggedUser]);
	useEffect(() => {
		switch (true) {
			case collapse:
				if (pathName) {
					setWidth("calc(100% - 200px)");
				} else {
					setWidth("100%");
				}
				break;
			case !collapse:
				if (pathName) {
					setWidth("calc(100% - 100px)");
				} else {
					setWidth("100%");
				}
				break;
			default:
				setWidth("calc(100% - 200px)");
				break;
		}
	}, [pathName, collapse]);
	useEffect(() => {
		setCollapse(isLargeScreen);
	}, [isLargeScreen]);
	return (
		<div className="parent">
			{pathName &&
				(roleData ? (
					<Drawer setCollapse={setCollapse} collapse={collapse} />
				) : (
					<DrawerSkeleton />
				))}
			<div
				className={`parent-container ${pathName ? "withDrawer" : ""}`}
				style={{
					width,
					transition: "0.5s ease-in-out",
					right: 0,
					position: "absolute",
					top: 0,
					height: "100%",
				}}
			>
				<Routes />
			</div>
			<ToastContainer position="top-right" autoClose={3000} />
		</div>
	);
};

const mapDispatchToProps = {
	getLoggedUser: userActions.getLoggedUser,
	clearLoggedUser: userActions.clearLoggedUser,
	toggleSagaLanguage: languageActions.toggleLanguage,
};
const mapStateToProps = (state) => ({
	loggedUser: state.app.users.index.loggedUser,
	showDrawer: state.app.drawer.showDrawer,
	billingDetails: state.app.userProfile.settings.billingDetails,
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
