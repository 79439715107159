import * as yup from "yup";
import React from "react";
import TableCustomColumn from "@common/tableCustomColumn/TableCustomColumn";
import i18n from "../../../../i18n";

export const teamElements = {
	columns: [
		{
			title: "Username",
			field: "usernameField",
			image: "imageField",
			render: (row) => (
				<TableCustomColumn
					row={row}
					link="users"
					navLink={`${row.firstName} ${row.lastName}`}
				/>
			),
			headerStyle: {
				backgroundColor: "#fbfbfb",
				fontFamily: "Montserrat",
				fontSize: "1.3rem",
			},
			cellStyle: {
				backgroundColor: "#fbfbfb",
				marginRight: "190px",
				borderRightStyle: "solid",
				borderRightColor: "#f5f5f5",
				borderRightWidth: "1px",
			},
		},
		{title: "Role", field: "role.name"},
		{title: "Email", field: "email"},
		{title: "Phone Number", field: "phoneNumber"},
		{title: "Address", field: "address"},
	],
	btnText: i18n.t("CreateTeam"),
	btnEditText: i18n.t("EditTeam"),
	validationSchema: {
		add: yup.object().shape({
			name: yup.string().label(i18n.t("Name")).required(i18n.t("NameIsReqired")),
			from: yup.string().nullable().label(i18n.t("from")).required(i18n.t("DateIsReq")),
			to: yup.string().nullable().label(i18n.t("to")).optional(),
			users: yup
				.array()
				.min(1, i18n.t("PleaseAddUsers"))
				.label(i18n.t("Users"))
				.required(i18n.t("PleaseAddUsers")),
		}),
		edit: yup.object().shape({
			name: yup.string().label(i18n.t("Name")).required(i18n.t("NameIsReqired")),
			from: yup.string().nullable().label(i18n.t("from")).required(i18n.t("DateIsReq")),
			to: yup.string().nullable().label(i18n.t("to")).optional(),
			users: yup
				.array()
				.min(1, i18n.t("PleaseAddUsers"))
				.label(i18n.t("Users"))
				.required(i18n.t("PleaseAddUsers")),
		}),
	},
	lists: {
		add: [
			{
				title: "Name",
				type: "input",
				field: "name",
				inputType: "text",
				bottomBreak: true,
			},
			{
				title: "datepicker",
				type: "datepicker",
				field: "datepicker",
				inputType: "date",
				bottomBreak: true,
			},
			{
				title: i18n.t("SelectHeadOfDepartment"),
				type: "formikDropdown",
				field: "headOfDepartment",
				inputType: "text",
				name: "headOfDepartment",
				newClass: "userName__dropdown",
				useFilteredUsers: false,
				isFormikInput: true,
			},
			{
				title: "Overhead",
				type: "conditionDropdown",
				field: "overhead",
				inputType: "text",
				name: "overhead",
				newClass: "userName__dropdown",
			},
			{
				title: "SelfProduction",
				type: "conditionDropdown",
				field: "selfProduction",
				inputType: "text",
				name: "selfProduction",
				newClass: "userName__dropdown",
			},
			{
				title: i18n.t("AddUser"),
				type: "dropdown",
				field: "users",
				inputType: "text",
				name: "users",
				newClass: "userName__dropdown",
				useFilteredUsers: true,
			},
		],
		edit: [
			{
				title: "Name",
				type: "input",
				field: "name",
				inputType: "text",
				bottomBreak: true,
			},
			{
				title: "datepicker",
				type: "datepicker",
				field: "datepicker",
				inputType: "date",
				bottomBreak: true,
			},
			{
				title: i18n.t("SelectHeadOfDepartment"),
				type: "formikDropdown",
				field: "headOfDepartment",
				inputType: "text",
				name: "headOfDepartment",
				newClass: "userName__dropdown",
				useFilteredUsers: false,
				isFormikInput: true,
			},
			{
				title: "Overhead",
				type: "conditionDropdown",
				field: "overhead",
				inputType: "text",
				name: "overhead",
				newClass: "userName__dropdown",
			},
			{
				title: "SelfProduction",
				type: "conditionDropdown",
				field: "selfProduction",
				inputType: "text",
				name: "selfProduction",
				newClass: "userName__dropdown",
			},
			{
				title: "Add User",
				type: "dropdown",
				field: "users",
				inputType: "text",
				name: "users",
				newClass: "userName__dropdown",
			},
		],
	},

	hasID: "name",
	modalTitle: "User",
};

export const userValidationSchema = yup.object().shape({
	groupName: yup.string().label("GroupName").required("LastNameIsReq"),
	users: yup.array().label("Users").required("LastNameIsReq"),
});
