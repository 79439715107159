/* eslint-disable max-len */
import {ToastErrorComponent, ToastSuccesComponent} from "@src/common/ToastComponent/ToastComponent";
import i18n from "@src/i18n";
import {getSortedOrderFields} from "@src/utils/getSortedOrderFields";
import _ from "lodash";
import moment from "moment";
import * as XLSX from "xlsx";
import {DATE_FORMAT} from "@src/common/constants";
import {columnsObjEXPORT, firstAndLastNameColumns} from "./staticData";
import {toCamelCase} from "../Energy/OrderForm/helper";

export function getNestedValue(obj, key, splitBy = ".") {
	return key?.split(splitBy)?.reduce((result, key) => result?.[key], obj);
}

const currentLanguage = localStorage.getItem("language");

export const exportCsvHandler = async ({data, query}) => {
	try {
		const orderFields = await getSortedOrderFields(query?.clientId);
		const updatedData = data?.map((obj) => {
			if (obj?.clientName === "2locate") {
				return {
					...obj,
					viaMFH: obj?.viaMFH ? i18n.t("Yes") : i18n.t("No"),
					viaET: obj?.viaET ? i18n.t("Yes") : i18n.t("No"),
					viaPapier:
						(obj?.viaET === null && obj?.viaMFH === null) ||
						(!obj?.viaET && !obj?.viaMFH)
							? i18n.t("Yes")
							: i18n.t("No"),
				};
			}
			return obj;
		});
		const wb = XLSX.utils.book_new();
		_.chain(updatedData)
			.groupBy("clientName")
			.toPairs()
			.map(function (currentItem) {
				const array = [];
				const firstItem = currentItem[1]?.[0];
				if (firstItem?.customFieldsValue) {
					// only order client fields
					const customColumns = orderFields[firstItem?.clientId] || [];
					customColumns.map((element) => {
						// Convert objectType to camelCase and use it to determine the source of the value
						const fieldObj = toCamelCase(element.objectType);
						let fieldValue = "";
						if (fieldObj === "order") {
							fieldValue = element.name;
						} else if (fieldObj === "customFieldValue") {
							fieldValue = `customFieldsValue?.${element?.name}?.value`;
						} else if (fieldObj === "createOrderDetails") {
							fieldValue = `viewOrderDetails.${element?.name}`;
						}
						const obj = {
							title: currentLanguage === "en" ? element?.labelEn : element?.labelDe,
							field: fieldValue,
						};
						array.push(obj);
						return array;
					});
				}
				if (firstItem?.addonsViews) {
					const customColumns = Object.keys(firstItem?.addonsViews);
					customColumns.map((element) => {
						const obj = {
							title: element,
							field: `addonsViews?.${element}`,
						};
						if (currentItem?.[0] === "HomeNet") {
							array.push({}, {}, {}, {}, {}, {}, {}, {}, obj);
						} else {
							array.push(obj);
						}
						return array;
					});
				}
				let filteredColumns;
				if (currentItem[0] === "TNG") {
					filteredColumns = columnsObjEXPORT?.TNG;
				} else if (currentItem[0] === "HomeNet") {
					filteredColumns = columnsObjEXPORT?.HNT;
				} else if (currentItem[0] === "2locate") {
					filteredColumns = columnsObjEXPORT?.TER;
				} else if (currentItem[0] === "Vodafone Kabel Deutschland GmbH") {
					filteredColumns = columnsObjEXPORT?.VFK;
				} else if (currentItem[0] === "DGN" || currentItem[0] === "Deutsche Giganetz") {
					filteredColumns = columnsObjEXPORT?.DGN;
				} else if (currentItem[0] === "DGF" || currentItem[0] === "Deutsche Glasfaser") {
					filteredColumns = [
						columnsObjEXPORT?.DEFAULT.filter(
							(i) => !i?.hideForClient?.includes(currentItem?.[0]),
						),
						...firstAndLastNameColumns,
					];
				} else {
					filteredColumns = columnsObjEXPORT?.DEFAULT.filter(
						(i) => !i?.hideForClient?.includes(currentItem?.[0]),
					);
				}
				const extraColumns = [...filteredColumns, ...array];
				const columnTitles = extraColumns.map((columnDef) => columnDef.title);
				const csvData = currentItem?.[1].map((rowData) =>
					extraColumns.map((columnDef) => {
						const MAX_LENGTH_ALLOWED = 32767;
						if (columnDef?.field === "orderStatus") {
							return i18n.t(rowData.orderStatusName) || i18n.t(rowData?.orderStatus);
						}
						if (
							columnDef?.field?.includes("addonsViews") ||
							columnDef?.field?.includes("customFieldsValue")
						) {
							const nestedValue = getNestedValue(rowData, columnDef.field, "?.");
							if (nestedValue === "true") {
								return i18n.t("Yes");
							}
							if (nestedValue === "false") {
								return i18n.t("No");
							}
							if (nestedValue?.length >= MAX_LENGTH_ALLOWED) {
								return "";
							}
							return nestedValue;
						}
						const nestedValue = getNestedValue(rowData, columnDef.field);
						if (nestedValue?.length >= MAX_LENGTH_ALLOWED) {
							return "";
						}

						return nestedValue;
					}),
				);
				const exportData = [columnTitles, ...csvData];
				const ws = XLSX.utils.aoa_to_sheet(exportData);
				const wscols = columnTitles?.map(() => ({wch: 25}));
				ws["!cols"] = wscols;
				const currentItemString =
					currentItem[0]?.length > 30 ? currentItem[0]?.substring(0, 31) : currentItem[0];
				XLSX.utils.book_append_sheet(wb, ws, currentItemString);
				return csvData;
			})
			.value();
		const formattedStartDate = query?.fromDate
			? moment(query?.fromDate).format(DATE_FORMAT)
			: "";
		const formattedEndDate = query?.toDate ? moment(query?.toDate).format(DATE_FORMAT) : "";

		let fileName = "Auftragsliste.xlsx"; // Default filename

		if (formattedStartDate) {
			fileName = `Auftragsliste ${formattedStartDate}`;
			if (formattedEndDate) {
				fileName += `-${formattedEndDate}`;
			}
			fileName += ".xlsx";
		}
		XLSX.writeFileXLSX(wb, fileName);
		ToastSuccesComponent(i18n.t("DownloadDone"));
	} catch (error) {
		ToastErrorComponent(i18n.t("DownloadFailed"));
	}
};
