import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as navigation} from "../../store/sagas/app/navigation";

function OrderIdCustom(props) {
	const {row} = props;
	// const handleClick = () => {
	// 	navigate(`${row?.orderId}`);
	// };

	return (
		<div className="user__table__photo__and__name">
			<p>{row.orderId}</p>
		</div>
	);
}
const mapStateToProps = (state) => ({
	singleOrder: state.app.orders.index.singleOrder,
});
const mapDispatchToProps = {
	navigate: navigation.navigate,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderIdCustom));
