import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Button from "@src/common/components/button";
import BigSearchBar from "@common/bigSearchBar/BigSearchBar";
import {actions as goalsActions} from "@sagas/analytics/index";
import {
	actions as createGoalAction,
	actions as createGoalActions,
} from "@sagas/analytics/goals/create";
import {OpenActionsIcon} from "@src/assets/SvgComponents";
import {actions as clientActions} from "@sagas/client";
import {useTranslation} from "react-i18next";
import {getSelectOptionsFromDataTeams} from "@src/utils/select";
import {actions as locationActions} from "@sagas/locations";
import {actions as goalViewsActions} from "@sagas/analytics/goals/views";
import CustomPopover from "@src/common/Popover";
import Form from "@src/common/Form";
import {Dropdown} from "antd";
import folderPlus from "@assets/img/folder-plus.svg";
import {actions as teamActions} from "@sagas/teams";
import {actions as usersActions} from "@sagas/users/users/index";
import {actions as productActios} from "@sagas/products";
import {actions as tariffActions} from "@sagas/tariff";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import {actions as deleteGoals} from "@sagas/analytics/goals/delete";
import HtmlTooltip from "@src/common/htmlTooltip";
import {checkRoles} from "@src/utils/checkRoles";
import viewIcon from "../../../../assets/img/set_view.svg";
import addGoalIcon from "../../../../assets/img/add_goal.svg";
import {HeaderAnalyticArray, pritorities} from "../staticData";
import AnalyticsModal from "../modals/AnalyticsModal";
import GoalActions from "./AlertsCardComponent.js/components/GoalActions";
import {salesTrackerButtonProps} from "../../sales-tracker/staticData";
import {filterItems, inputs, items, listViewItems, modalInitialValue} from "./staticData";
import {filteringStatusOptions} from "../../orders/staticData";
const HeaderAnalyticActions = ({
	analyticState,
	clearInitialValues,
	setQuery,
	cleanQuery,
	setModal,
	modal,
	fetchClientsByDrowdown,
	optionsClients,
	editLocationSize,
	locations,
	fetchLocations,
	addView,
	id,
	editView,
	agencies,
	teams,
	usersOptions,
	fetchProductsByDropdown,
	fetchTariffsByDropdown,
	optionsProducts,
	optionsTariffs,
	query,
	setUrlParams,
	groupViewType,
	selected,
	deleteGoal,
	toggleOnDashboard,
	setActiveKey,
	viewsState,
	setSelected,
	goals,
}) => {
	const {t} = useTranslation();
	const {views, loading: viewsLoading} = viewsState;
	const [showPopover, setShowPopover] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState({
		...modalInitialValue,
	});
	const handleCloseModal = () => {
		setShowDeleteModal({...modalInitialValue});
	};
	const showMobile = (item) => {
		switch (item) {
			case "addCardToMobile":
				return true;
			case "removeCardToMobile":
				return false;
			default:
				return false;
		}
	};
	const handleConfirm = () => {
		setSelected([]);
		if (showDeleteModal.type === "deleteCard") {
			deleteGoal(selected);
		} else {
			toggleOnDashboard({
				ids: selected,
				showDashboard: showDeleteModal?.type === "addCardToDashboard" && true,
				showMobile: showMobile(showDeleteModal?.type),
				mobile:
					showDeleteModal?.type === "addCardToMobile" ||
					showDeleteModal?.type === "removeCardToMobile",
			});
		}
		handleCloseModal();
	};
	const handleVisibility = (visibility) => setShowPopover(visibility);
	const {loading} = analyticState;
	useEffect(() => {
		if (query?.clientId) {
			fetchLocations(query?.clientId);
			fetchTariffsByDropdown(query?.clientId);
			fetchProductsByDropdown(query?.clientId);
		}
		editLocationSize(1000);
	}, [query?.clientId]);
	const headerItems = HeaderAnalyticArray;
	const handleSearch = (event) => {
		const searchTerm = event.target ? event.target.value : event;
		setQuery({
			...query,
			search: searchTerm,
		});
	};
	const handleButtonClick = () => {
		setModal(headerItems[0]?.key);
	};
	const handleClose = () => {
		clearInitialValues();
		setModal(false);
	};

	const handleHeaderAction = (item) => {
		const {key, item: itemProps} = item;
		switch (key) {
			case "edit":
				setModal("costumizeAlerts", item.id);
				break;
			case "selectAll":
				setSelected(goals?.filter((it) => !it.hasExpired).map((it) => it.id) || []);
				break;
			case "deselect":
				setSelected([]);
				break;
			default:
				setShowDeleteModal({
					open: true,
					type: key,
					title: itemProps.props.modalTitle,
				});
				break;
		}
	};
	const formData = {
		inputs,
		title: id ? t("EditGroupView") : t("CreateGroupView"),
		id,
		submitFn: (values, actions) =>
			!id ? addView({values, setModal}) : editView({values, setModal}),
		getUrl: "/goals-groups/:id",
	};
	const getProductsByTariff = () => {
		if (query?.tariffId) {
			const findTariff = optionsTariffs.find((i) => i?.value === query?.tariffId);
			const filteredOptions = optionsProducts.filter((option) =>
				findTariff?.commissionProducts.includes(option.value),
			);
			return filteredOptions;
		}
		return [];
	};
	return (
		<>
			<DeleteModal
				setShowDeleteModal={handleCloseModal}
				open={showDeleteModal.open}
				handleClose={handleCloseModal}
				calculationModal
				newTitle={showDeleteModal.title}
				confirm={handleConfirm}
				disabled={loading}
			/>
			{checkRoles("setGroupViewGoals") && (
				<Dropdown
					menu={{
						items: listViewItems(groupViewType, setUrlParams, cleanQuery, setActiveKey),
					}}
					overlayClassName="createuseractions_popover__actions-wrapper"
					placement="bottomRight"
					trigger={["click"]}
				>
					<HtmlTooltip title={t("SelectGroupView")} placement="top-end">
						<div className="three-dots-icon">
							<Button
								icon={viewIcon}
								leftRounded={false}
								rightRounded={false}
								smallButtonWithTooltip
								hideTooltip
							/>
						</div>
					</HtmlTooltip>
				</Dropdown>
			)}

			{groupViewType === "ListView" && checkRoles("createGroupViewGoals") && (
				<CustomPopover
					customClass="custom-popover-leadstatuses"
					menu={{items: items(handleVisibility)}}
					open={showPopover}
					handleVisibility={handleVisibility}
					placement="bottomRight"
					setUrlParams={setUrlParams}
				>
					<HtmlTooltip title={t("CreateGroupView")} placement="top-end">
						<div>
							<Button
								{...salesTrackerButtonProps}
								icon={folderPlus}
								leftRounded={false}
								rightRounded={false}
								hideTooltip={true}
								smallButtonWithTooltip
							/>
						</div>
					</HtmlTooltip>
				</CustomPopover>
			)}
			{checkRoles("createGoals") && (
				<HtmlTooltip title={t("costumizeAlerts")} placement="top-end">
					<div>
						<Button
							handleChange={handleButtonClick}
							icon={addGoalIcon}
							leftRounded={false}
							rightRounded={false}
							hideTooltip={true}
							smallButtonWithTooltip
						/>
					</div>
				</HtmlTooltip>
			)}
			{checkRoles("searchGoals") && (
				<BigSearchBar
					value={query?.search}
					handleSearch={handleSearch}
					withFilters
					filterItems={filterItems(
						optionsClients,
						getSelectOptionsFromDataTeams,
						locations,
						pritorities,
						filteringStatusOptions,
						views,
						teams,
						agencies,
						usersOptions,
						getProductsByTariff(),
						optionsTariffs,
						groupViewType,
					)}
					filterValues={query}
					setFilterValues={setQuery}
					clearFilterValues={() => setQuery(null)}
					headerSearch
				/>
			)}

			{groupViewType === "GridView" && goals?.[0] && checkRoles("seeActionsGoals") && (
				<div style={{position: "relative"}} className="createuserExport">
					<GoalActions
						Icon={OpenActionsIcon}
						showDashboard={false}
						handleActions={handleHeaderAction}
						showMobile
						showEdit={false}
						multiple
						selected={selected}
					/>
					{selected?.length > 0 && (
						<span className="userActions-popper-length">
							{selected.length > 99 ? `99+` : selected.length}
						</span>
					)}
				</div>
			)}
			{modal !== "goalsViews" ? (
				<AnalyticsModal
					type={modal}
					showModal={!!modal}
					handleClose={handleClose}
					loading={loading}
					formData={modal === "goalsViews" && formData}
				/>
			) : (
				<Form
					loading={viewsLoading}
					handleClose={handleClose}
					open={modal === "goalsViews"}
					formData={formData}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	analyticState: state.app.analytics.index,
	optionsClients: state.app.client.index.optionsClients,
	locations: state.app.locations.index.locations,
	viewsState: state.app.analytics.view,
	teams: state.app.teams.index.teams,
	agencies: state.app.teams.index.agencies,
	usersOptions: state.app.users.index.usersOptions,
	optionsProducts: state.app.products.index.optionsProducts,
	optionsTariffs: state.app.tariffs.index.optionsTariffs,
});
const mapDispatchToProps = {
	clearInitialValues: createGoalAction.clearInitialValues,
	fetchClientsByDrowdown: clientActions.fetchClientsByDropdown,
	fetchLocations: locationActions.fetchLocations,
	editLocationSize: locationActions.editSize,
	getViews: goalViewsActions.getViews,
	addView: goalViewsActions.addView,
	editView: goalViewsActions.editView,
	getTeams: teamActions.fetchTeams,
	getAgencies: teamActions.fetchAgencies,
	getUserDropdown: usersActions.getUserDropdown,
	fetchProductsByDropdown: productActios.fetchProductsByDropdown,
	fetchTariffsByDropdown: tariffActions.fetchTariffsByDropdown,

	setInitialValues: createGoalActions.setInitialValues,
	setShowModal: goalsActions.setShowModal,
	deleteGoal: deleteGoals.deleteGoal,
	toggleOnDashboard: goalsActions.toggleGoalOnDashboard,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAnalyticActions);
