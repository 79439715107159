import React from "react";
import HtmlTooltip from "@src/common/htmlTooltip";
import {DeleteRowIcon, EditRowIcon, PreviewRowIcon} from "@src/assets/SvgComponents";
import {FORMAT_DATE} from "@src/common/constants";
import i18n from "@src/i18n";

export const rows = [
	{
		contractDocuments: "Hauptvertrag Telekom",
		from: "01.01.2024",
		to: "13.12.2023",
		id: 1,
	},
	{
		contractDocuments: "Hauptvertrag Telekom",
		validFrom: "01.01.2024",
		dateOfExpiry: "13.12.2023",
		id: 2,
	},
	{
		contractDocuments: "Hauptvertrag Telekom",
		validFrom: "01.01.2024",
		dateOfExpiry: "13.12.2023",
		id: 3,
	},
	{
		contractDocuments: "Hauptvertrag Telekom",
		validFrom: "01.01.2024",
		dateOfExpiry: "13.12.2023",
		id: 4,
	},
];

export const columns = [
	{
		key: "fileName",
		name: i18n.t("contractDocuments"),
	},
	{
		key: "from",
		name: i18n.t("validFrom"),
		formatter: ({row}) => FORMAT_DATE(row?.from),
	},
	{
		key: "to",
		name: i18n.t("dateExpiry"),
		formatter: ({row}) => FORMAT_DATE(row?.to),
	},
];
export const columnsSecond = [
	{
		key: "fileName",
		name: i18n.t("additionalAgreements"),
	},
	{
		key: "from",
		name: i18n.t("validFrom"),
		formatter: ({row}) => FORMAT_DATE(row?.from),
	},
	{
		key: "to",
		name: i18n.t("dateExpiry"),
		formatter: ({row}) => FORMAT_DATE(row?.to),
	},
];

export const ContactInfoItem = ({label, value}) => (
	<div className="upload-document-table--body--info--container--item">
		<span>{label}</span>
		<span
			style={{
				textAlign: value === "" ? "center" : "left",
				minWidth: 150,
			}}
		>
			{value !== "" ? value : "-"}
		</span>
	</div>
);
export const ActionsFormatter = ({row, navigate, handleDelete, handleEdit, setPreviewModal}) => (
	<div className="table-actions-wrapper" style={{marginLeft: "28px"}}>
		<HtmlTooltip title={<span>{i18n.t("editClient")}</span>} placement="bottom-end">
			<span onClick={() => handleEdit(row?.id)}>
				<EditRowIcon strokeWidth={2} stroke="#c1c1c1" />
			</span>
		</HtmlTooltip>
		<HtmlTooltip title={<span>{i18n.t("PreviewPDF")}</span>} placement="bottom-end">
			<span
				onClick={() =>
					setPreviewModal({
						previewFile: row?.uploadDocumentUrl,
						showModal: true,
					})
				}
			>
				<PreviewRowIcon />
			</span>
		</HtmlTooltip>
		<span onClick={() => handleDelete(row)}>
			<DeleteRowIcon stroke="#c1c1c1" />
		</span>
	</div>
);

export const actionsProperties = {
	key: "actions",
	name: i18n.t("Actions"),
	sortable: false,
	resizable: false,
	width: 120,
};
