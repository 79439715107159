import React from "react";
import i18n from "@src/i18n";
import {filteringStatusOptions} from "../orders/staticData";

export const structureTableColumns = [
	{
		key: "name",
		name: "Stufe",
	},
	{
		key: "provision",
		name: i18n.t("CommissionAmount"),
		formatter: ({row}) => (
			<span>{`${row?.provision}${
				row?.provisionType && row?.provisionType === "PERCENTAGE" ? "%" : "€"
			} ${row?.provisionName}`}</span>
		),
	},
	{
		key: "payoutModelTwoName",
		name: i18n.t("PayoutModel"),
	},
];

export const structureTableInputs = [
	{
		field: "name",
		required: true,
		title: i18n.t("Name"),
		inputType: "text",
	},
	{
		field: "provisionType",
		required: true,
		title: i18n.t("provisionType"),
		inputType: "dropdown",
		name: "label",
		value: "value",
		dependentFields: ["entrancePrice"],
		options: [
			{
				label: i18n.t("Percentage"),
				value: "PERCENTAGE",
			},
			{
				label: i18n.t("Fixed"),
				value: "FIXED",
			},
		],
	},
	{
		field: "entrancePrice",
		required: true,
		title: i18n.t("Entrance"),
		inputType: "number",
		dependentFrom: "provisionType",
		dependentFromValue: ["PERCENTAGE"],
	},
	{
		title: i18n.t("DifferenceProvision"),
		field: "differenceProvision",
		inputType: "checkbox",
	},
];
export const stufeInputs = [
	{
		field: "name",
		required: true,
		title: i18n.t("Name"),
		inputType: "text",
	},
	{
		field: "provision",
		required: true,
		title: i18n.t("Provision"),
		inputType: "number",
	},
	{
		field: "provisionName",
		required: true,
		title: i18n.t("ProvisionName"),
		inputType: "text",
	},
	{
		field: "payoutModelOneStatuses",
		required: true,
		title: i18n.t("PayoutModelOneStatuses"),
		multiple: true,
		options: filteringStatusOptions,
		inputType: "dropdown",
		name: "name",
		value: "statusValue",
	},
	{
		field: "payoutModelOneProvisionPercentage",
		required: true,
		title: i18n.t("PayoutModelOneProvisionPercentage"),
		inputType: "number",
	},
	{
		field: "payoutModelOneName",
		required: true,
		title: i18n.t("PayoutModelOneName"),
		inputType: "text",
	},
	{
		field: "payoutModelTwoStatuses",
		required: false,
		title: i18n.t("PayoutModelTwoStatuses"),
		multiple: true,
		options: filteringStatusOptions,
		inputType: "dropdown",
		name: "name",
		value: "statusValue",
	},
	{
		field: "payoutModelTwoName",
		required: false,
		title: i18n.t("PayoutModelTwoName"),
		inputType: "text",
	},
	{
		field: "payoutModelTwoProvisionPercentage",
		required: false,
		title: i18n.t("PayoutModelTwoProvisionPercentage"),
		inputType: "number",
	},
];
