import React, {useState, useEffect} from "react";
import "../../../common/styles/components/sales-tracker/index.scss";
import {actions as salesTrackerActions} from "@sagas/salesTracker/index";
import {actions as leadsAddressActions} from "@sagas/salesTracker/addresses";
import {actions as leadStatusesActions} from "@sagas/salesTracker/leadStatus";
import {connect} from "react-redux";
import {SalesTrackerIcon} from "@src/assets/SvgComponents";
import {useTranslation} from "react-i18next";
import {useUrlParams} from "@src/utils/useUrlParams";
import Tabs from "@src/common/components/tabs";
import {checkRoles} from "@src/utils/checkRoles";
import BigSearchBar from "@src/common/bigSearchBar/BigSearchBar";
import UploadInput from "./components/UploadInput";
import HeaderActions from "./components/HeaderActions";
import CreateStatus from "./components/statuses/CreateStatus";
import CreateLeadStatus from "./components/leadStatuses/CreateLeadStatus";
import SalesTrackerModal from "./components/SalesTrackerModal";
import {RenderTab} from "./helper";

const SalesTracker = (props) => {
	const {
		setActiveTab,
		activeTab,
		clearQuery,
		fetchAllLeadStatuses,
		editPage,
		initialQuery,
		clearPagination,
		setSearch,
		search,
		resetAddressPagination,
	} = props;
	const {t} = useTranslation();
	const [showUploadFileModal, setShowUploadModal] = useState(false);
	const resetPage = () => {
		editPage(1);
		resetAddressPagination();
	};
	const {getUrlParams, setUrlParams, setQuery, setModal} = useUrlParams(resetPage);
	const {query, modal, id, viewType} = getUrlParams("tab", "query", "modal", "id", "view-type");
	const initializeParams = () => {
		setUrlParams(
			"salesTracker",
			"tab",
			{
				paramName: "query",
				paramValue: initialQuery,
			},
			{
				paramName: "view-type",
				paramValue: viewType || "statusView",
			},
		);
	};
	const [openFilters, setOpenFilters] = useState(false);
	const [showActionsModal, setShowActionsModal] = useState({
		open: false,
		type: "",
		title: "",
	});
	const [selectedRows, setSelectedRows] = useState([]);
	useEffect(() => {
		fetchAllLeadStatuses();
		initializeParams();
		return () => {
			clearQuery();
		};
	}, []);
	return (
		<>
			<div className="dashboard__container">
				<div className="header-component">
					<div className="dashboard__left__text-header">
						<div className="header-component-wrapper">
							<SalesTrackerIcon />
							<span
								className="header-component-text"
								style={{cursor: "pointer"}}
								onClick={() => setActiveTab(t("SalesTracker"))}
							>
								{t("SalesTracker")}
							</span>
						</div>
						<Tabs
							items={
								checkRoles("seeStatistics")
									? ["SalesTracker", "statistics"]
									: ["SalesTracker"]
							}
							onClick={(e) => {
								if (activeTab === t("statistics")) {
									clearQuery();
									setOpenFilters(false);
								}
								clearPagination();
								setActiveTab(e.target.outerText);
							}}
							value={activeTab}
						/>
					</div>
					<div className="dashboard__left__text-actions salesTracker">
						{activeTab === t("SalesTracker") ? (
							<HeaderActions
								openFilters={openFilters}
								selectedRows={selectedRows}
								setOpenFilters={setOpenFilters}
								setShowActionsModal={setShowActionsModal}
								setShowUploadModal={setShowUploadModal}
								setShowModal={setModal}
								query={query}
								setQuery={setQuery}
								setSelectedRows={setSelectedRows}
							/>
						) : (
							<BigSearchBar
								value={search}
								handleSearch={(e) => {
									setSearch(e.target.value);
								}}
							/>
						)}
					</div>
				</div>
				<CreateStatus showModal={modal === "status"} id={id} setShowModal={setModal} />
				<CreateLeadStatus
					showModal={modal === "leadStatuses"}
					id={id}
					setShowModal={setModal}
				/>
				<SalesTrackerModal showModal={modal === "lead"} setShowModal={setModal} />
				<RenderTab
					setShowActionsModal={setShowActionsModal}
					showActionsModal={showActionsModal}
					currentTab={activeTab}
					openFilters={openFilters}
					setSelectedRows={setSelectedRows}
					selectedRows={selectedRows}
					setShowModal={setModal}
					query={query}
					viewType={viewType}
				/>
			</div>
			{showUploadFileModal && (
				<UploadInput
					setShowUploadModal={setShowUploadModal}
					showUploadFileModal={showUploadFileModal}
				/>
			)}
		</>
	);
};
const mapStateToProps = (state) => ({
	initialQuery: state.app.salesTracker.index.query,
	activeTab: state.app.salesTracker.index.activeTab,
	search: state.app.salesTracker.index.search,
});
const mapDispatchToProps = {
	clearQuery: salesTrackerActions.clearQuery,
	fetchAllLeadStatuses: leadStatusesActions.fetchAllLeadStatuses,
	editPage: salesTrackerActions.editPage,
	setActiveTab: salesTrackerActions.setActiveTab,
	clearPagination: salesTrackerActions.clearPagination,
	setSearch: salesTrackerActions.setSearch,
	resetAddressPagination: leadsAddressActions.resetPagination,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesTracker);
