/* eslint-disable no-nested-ternary */
import InputComponent from "@src/common/input";
import CustomSelect from "@src/common/Select";
import i18n from "@src/i18n";
import React from "react";

const currentLanguage = i18n.language;

const BaseFields = ({values, setFieldValue, baseFields, errors}) => {
	const clearDependencies = (dependencyFields) => {
		if (dependencyFields) {
			dependencyFields.forEach((field) => setFieldValue(field, null));
		}
	};

	return baseFields.map(
		({
			name,
			inputType,
			defaultValue,
			labelEn,
			labelDe,
			options,
			isMultiple,
			dependentFrom,
			dependencyFields,
		}) => (
			<div className="single__input" key={name}>
				{inputType === "TEXT" && (
					<InputComponent label={labelEn} values={defaultValue} disabled />
				)}
				{inputType === "DROPDOWN" && (
					<CustomSelect
						options={options}
						value={values?.[name] ? values?.[name] : isMultiple ? [] : null}
						label={currentLanguage === "en" ? labelEn : labelDe}
						disabled={dependentFrom && !values?.[dependentFrom]}
						onSelect={(value) => {
							if (isMultiple) {
								setFieldValue(name, [...(values?.[name] || []), value]);
							} else {
								setFieldValue(name, value);
							}
							clearDependencies(dependencyFields);
						}}
						mode={isMultiple ? "multiple" : undefined}
						onDeselect={(id) =>
							setFieldValue(
								name,
								values?.[name]?.filter((it) => it !== id),
							)
						}
						onClear={() => {
							setFieldValue(name, isMultiple ? [] : null);
							clearDependencies(dependencyFields);
						}}
						touched
						errors={errors?.[name]}
					/>
				)}
			</div>
		),
	);
};

export default BaseFields;
