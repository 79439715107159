import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
const logger = new Logger("Sagas>Orders>userOrders");

const PREFIX = "@app/orders/userOrders";

export const FETCH_SINGLE_USER_ORDERS = `${PREFIX}FETCH_SINGLE_USER_ORDERS`;
export const FETCH_SINGLE_USER_ORDERS_SUCCESS = `${PREFIX}FETCH_SINGLE_USER_ORDERS_SUCCESS`;
export const ADD_USERORDER_ON_STATE = `${PREFIX}ADD_USERORDER_ON_STATE`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const FILTERS = `${PREFIX}FILTERS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const TOTAL_SIZE_INCREASE = `${PREFIX}TOTAL_SIZE_INCREASE`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const GET_CASES = `${PREFIX}GET_CASES`;
export const GET_CASES_SUCCESS = `${PREFIX}GET_CASES_SUCCESS`;
export const SET_HANDLE_STATUS = `${PREFIX}SET_HANDLE_STATUS`;
export const EDIT_PAID_CASES = `${PREFIX}EDIT_PAID_CASES`;

const _state = {
	userOrders: [],
	loading: false,
	page: 1,
	size: 5,
	totalSize: 5,
	filters: {
		// fromDate: moment().add(-30, "days").valueOf(),
		// toDate: moment().valueOf(),
		fromDate: null,
		toDate: null,
		search: "",
	},
	cases: {},
	status: "DELIVERED",
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case EDIT_PAGE:
				draft.page = action.payload + 1;
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = action.payload;
				break;
			case SIZE_EDIT:
				draft.size = action.payload;
				break;
			case FETCH_SINGLE_USER_ORDERS_SUCCESS:
				draft.userOrders = action.payload;
				break;
			case ADD_USERORDER_ON_STATE:
				draft.userOrders = [action.payload].concat(state.userOrders);
				break;
			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.filters[action.payload?.name] = action.payload.value;
				break;
			case TOTAL_SIZE_INCREASE:
				draft.totalSize = state.totalSize + 1;
				break;
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case GET_CASES_SUCCESS:
				draft.cases = action.payload;
				break;
			case SET_HANDLE_STATUS:
				draft.status = action.payload;
				draft.page = 1;
				break;
			case EDIT_PAID_CASES:
				draft.cases = {
					...state.cases,
					paid: 0,
				};
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	fetchSingleUserOrdersSuccess: (payload) =>
		createAction(FETCH_SINGLE_USER_ORDERS_SUCCESS, {payload}),
	getSingleUserOrders: (payload) => createAction(FETCH_SINGLE_USER_ORDERS, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	filterOrders: (payload) => createAction(FILTERS, {payload}),
	addOrderOnState: (payload) => createAction(ADD_USERORDER_ON_STATE, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	totalSizeIncrease: (payload) => createAction(TOTAL_SIZE_INCREASE, {payload}),
	getCases: (payload) => createAction(GET_CASES, {payload}),
	getCasesSuccess: (payload) => createAction(GET_CASES_SUCCESS, {payload}),
	setHandleStatus: (payload) => createAction(SET_HANDLE_STATUS, {payload}),
	emptyPaidCases: (payload) => createAction(EDIT_PAID_CASES, {payload}),
};

export const sagas = {
	*getSingleUserOrders({payload}) {
		yield put(actions.setLoading(true));
		const {size} = yield select((state) => state.app.orders.userOrders);
		const {filters} = yield select((state) => state.app.orders.userOrders);
		const {page} = yield select((state) => state.app.orders.userOrders);
		const {status} = yield select((state) => state.app.orders.userOrders);

		try {
			const response = yield axios.post(`/order/all/status/sp?page=${page}&size=${size}`, {
				spId: +payload,
				status,
				fromDate: filters.fromDate,
				toDate: filters.toDate,
			});

			yield put(actions.editTotalSize(response?.data?.data?.totalSize));
			yield put(actions.fetchSingleUserOrdersSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getCases({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.post(`/order/status/sp`, {spId: payload});
			yield put(actions.getCasesSuccess(response.data.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(FETCH_SINGLE_USER_ORDERS, sagas.getSingleUserOrders);
	yield takeLatest(GET_CASES, sagas.getCases);
};
