/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import {put, select, takeLatest} from "redux-saga/effects";
import produce from "immer";
import axios from "axios";
import Logger from "@utils/logger";
import {actions as createActions} from "@sagas/salesTracker/create";
import {actions as leadAddressActions} from "@sagas/salesTracker/addresses";
import {write, read} from "xlsx";
import {ToastInfoComponent, ToastSuccesComponent} from "@common/ToastComponent/ToastComponent";
import _ from "lodash";
import i18n from "../../../../i18n";
import createAction from "../../../../utils/action-creator";
import {
	deleteLeadsOnState,
	leadInitialValue,
	mergeDataForAllStatuses,
	queryInitialValue,
	updateLeadsOnState,
	updateUserIdsInLeads,
} from "./utils";

const logger = new Logger("Sagas>salesTracker>Index");

const PREFIX = "@app/salesTracker/";
export const GET_LEAD_BY_ID = `${PREFIX}GET_LEAD_BY_ID`;
export const GET_LEAD_BY_ID_SUCCESS = `${PREFIX}GET_LEAD_BY_ID_SUCCESS`;
export const GET_LEADS = `${PREFIX}GET_LEADS`;
export const GET_LEADS_SUCCESS = `${PREFIX}GET_LEADS_SUCCESS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const TOTAL_SIZE_INCREASE = `${PREFIX}TOTAL_SIZE_INCREASE`;
export const CLEAR_LEAD = `${PREFIX}CLEAR_LEAD`;
export const SET_QUERY = `${PREFIX}SET_QUERY`;
export const EXPORT_LEADS = `${PREFIX}EXPORT_LEADS`;
export const SET_ACTIVE_TAB = `${PREFIX}SET_ACTIVE_TAB`;
export const CLEAR_QUERY = `${PREFIX}CLEAR_QUERY`;
export const SET_EXPORTS_LOADING = `${PREFIX}EXPORTS_LOADING`;
export const SET_MODAL_LOADING = `${PREFIX}SET_MODAL_LOADING`;
export const EDIT_LEAD_USERS = `${PREFIX}EDIT_LEAD_USERS`;
export const EDIT_LEAD_USERS_SUCCESS = `${PREFIX}EDIT_LEAD_USERS_SUCCESS`;
export const CHANGE_LEAD_STATUS = `${PREFIX}CHANGE_LEAD_STATUS`;
export const SET_TOTAL_PAGES = `${PREFIX}SET_TOTAL_PAGES`;
export const UPDATE_LEADS_ON_STATE = `${PREFIX}UPDATE_LEADS_ON_STATE`;
export const SET_SCROLL_LOADING = `${PREFIX}SET_SCROLL_LOADING`;
export const SET_SELECTED_STATUSES = `${PREFIX}SET_SELECTED_STATUSES`;
export const CLEAR_SELECTED_STATUSES = `${PREFIX}CLEAR_SELECTED_STATUSES`;
export const EDIT_LEAD_ON_STATE = `${PREFIX}EDIT_LEAD_ON_STATE`;
export const DELETE_LEADS_ON_STATE = `${PREFIX}DELETE_LEADS_ON_STATE`;
export const GET_LEAD_STATISTICS = `${PREFIX}GET_LEAD_STATISTICS`;
export const GET_LEAD_STATISTICS_SUCCESS = `${PREFIX}GET_LEAD_STATISTICS_SUCCESS`;
export const CLEAR_PAGINATION = `${PREFIX}CLEAR_PAGINATION`;
export const SET_SEARCH = `${PREFIX}SET_SEARCH`;
export const GET_RELATIONS = `${PREFIX}GET_RELATIONS`;
export const GET_RELATIONS_SUCCESS = `${PREFIX}GET_RELATIONS_SUCCESS`;
export const SET_RELATIONLOADING = `${PREFIX}SET_RELATIONLOADING`;
export const GET_LEAD_STATISTICS_BYID = `${PREFIX}GET_LEAD_STATISTICS_BYID`;
export const GET_LEAD_STATISTICS_BYID_SUCCESS = `${PREFIX}GET_LEAD_STATISTICS_BYID_SUCCESS`;
export const CLEAR_RELATIONS = `${PREFIX}CLEAR_RELATIONS`;
export const SET_LEAD_INITIAL_VALUES = `${PREFIX}SET_LEAD_INITIAL_VALUES`;

const _state = {
	lead: {...leadInitialValue},
	leads: [],
	loading: false,
	modalLoading: false,
	children: [],
	page: 1,
	size: 30,
	totalSize: 5,
	totalPages: 1,
	query: {...queryInitialValue},
	activeTab: i18n.t("SalesTracker"),
	exportsLoading: false,
	leadsTotalPages: 0,
	leadsPage: 1,
	scrollLoading: false,
	selectedStatuses: {
		ids: [],
		totalSize: 0,
	},
	leadStatistics: [],
	search: "",
	relations: [],
	relationLoading: false,
	singleLeadStatistic: null,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case GET_LEAD_BY_ID_SUCCESS:
				draft.lead = {
					...action.payload,
					firstName:
						action?.payload?.firstName === null ? "" : action?.payload?.firstName,
					lastName: action?.payload?.lastName === null ? "" : action?.payload?.lastName,
					status: action.payload?.statusId,
					client: action.payload?.clientId,
					projectId: action.payload?.projectId,
					userIds: action.payload?.userId,
					leadActivity: action.payload?.leadActivityData,
					createAddress: {
						zipCode: action.payload?.viewAddress.zipCode,
						street: action.payload?.viewAddress.street,
						houseNumber: action.payload?.viewAddress.houseNumber,
						location: action.payload?.viewAddress.location,
						addition: action.payload?.viewAddress.addition,
						partLocation: action.payload?.viewAddress.partLocation,
						latitude: action.payload?.viewAddress.latitude,
						longitude: action.payload?.viewAddress.longitude,
					},
				};
				break;
			case SET_QUERY:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.query = action.payload;
				break;
			case CLEAR_LEAD:
				draft.lead = {...leadInitialValue};
				break;
			case GET_LEADS_SUCCESS:
				if (state?.page === 1) {
					let maxTotalSize = 0;
					// eslint-disable-next-line no-restricted-syntax
					for (const key in action?.payload) {
						if (action?.payload.hasOwnProperty(key)) {
							const {totalPages} = action?.payload[key];
							if (totalPages > maxTotalSize) {
								maxTotalSize = totalPages;
							}
						}
					}
					draft.leads = action?.payload;
					draft.leadsTotalPages = maxTotalSize;
				} else {
					const data = mergeDataForAllStatuses(state.leads, action?.payload);
					const copiedData = Object.entries(data);
					const leadsObj = {};
					copiedData.forEach((item) => {
						const [key, value] = item;
						leadsObj[key] = {
							...value,
							data: _.uniqBy(value.data, "id"),
						};
					});
					draft.leads = leadsObj;
				}
				break;
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case EDIT_PAGE:
				draft.page = action.payload;
				break;
			case SET_TOTAL_PAGES:
				draft.totalPages = action.payload;
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = action.payload;
				break;
			case SIZE_EDIT:
				draft.size = action.payload;
				break;
			case TOTAL_SIZE_INCREASE:
				draft.totalSize = state.totalSize + 1;
				break;
			case SET_ACTIVE_TAB:
				draft.activeTab = action.payload;
				break;
			case CLEAR_QUERY:
				draft.query = {...queryInitialValue};
				break;
			case SET_EXPORTS_LOADING:
				draft.exportsLoading = action.payload;
				break;
			case SET_MODAL_LOADING:
				draft.modalLoading = action.payload;
				break;
			case CHANGE_LEAD_STATUS:
				const copiedLeads = [...state?.leads];
				const updatedLeads = copiedLeads?.map((lead) => {
					if (lead?.[action?.payload?.field] === action.payload.id) {
						return {
							...lead,
							statusId: action.payload?.statusId,
						};
					}
					return lead;
				});
				draft.leads = updatedLeads;
				break;
			case UPDATE_LEADS_ON_STATE:
				draft.leads = action.payload;
				break;
			case SET_SCROLL_LOADING:
				draft.scrollLoading = action.payload;
				break;
			case SET_SELECTED_STATUSES:
				draft.selectedStatuses = action.payload;
				break;
			case DELETE_LEADS_ON_STATE:
				const obj = deleteLeadsOnState(state?.leads, action.payload);
				draft.leads = obj;
				break;
			case CLEAR_SELECTED_STATUSES:
				draft.selectedStatuses = {ids: [], totalSize: 0};
				break;
			case EDIT_LEAD_ON_STATE:
				const {statuses, item} = action.payload;
				const data = updateLeadsOnState(state?.leads, item, statuses);
				draft.leads = data;
				break;
			case EDIT_LEAD_USERS_SUCCESS:
				draft.leads = updateUserIdsInLeads(state?.leads, action.payload);
				if (state?.lead?.id) {
					if (state?.lead && action.payload.removeId !== null) {
						draft.lead = {
							...state.lead,
							userIds: state?.lead?.userIds.filter(
								(item) => item !== action.payload.removeId,
							),
							userId: state?.lead?.userId?.filter(
								(item) => item !== action.payload.removeId,
							),
						};
					} else if (action.payload.addId !== null) {
						draft.lead = {
							...state.lead,
							userIds: action.payload.addId,
							userId: action.payload.addId,
						};
					}
				}
				break;
			case GET_RELATIONS_SUCCESS:
				const concatedArray = action?.payload?.parent
					? [{...action.payload.parent, isParent: true}, ...action.payload.children]
					: action.payload.children;
				draft.relations = concatedArray;
				break;
			case SET_RELATIONLOADING:
				draft.relationLoading = action.payload;
				break;
			case GET_LEAD_STATISTICS_SUCCESS:
				draft.leadStatistics =
					state?.page === 1
						? action.payload.data
						: [...state.leadStatistics, ...action.payload.data];
				break;
			case CLEAR_PAGINATION:
				draft.size = 30;
				draft.page = 1;
				draft.totalPages = 1;
				draft.totalSize = 5;
				draft.search = "";
				break;
			case SET_SEARCH:
				draft.page = 1;
				draft.size = 30;
				draft.search = action.payload;
				break;
			case GET_LEAD_STATISTICS_BYID_SUCCESS:
				draft.singleLeadStatistic = action.payload;
				break;

			case CLEAR_RELATIONS:
				draft.relations = [];
				break;
			case SET_LEAD_INITIAL_VALUES:
				draft.lead = {
					...state.lead,
					...action.payload,
				};
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	getLeadById: (payload) => createAction(GET_LEAD_BY_ID, {payload}),
	getLeadByIdSuccess: (payload) => createAction(GET_LEAD_BY_ID_SUCCESS, {payload}),
	getLeads: (payload) => createAction(GET_LEADS, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	getLeadsSuccess: (payload) => createAction(GET_LEADS_SUCCESS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	totalSizeIncrease: (payload) => createAction(TOTAL_SIZE_INCREASE, {payload}),
	clearLead: (payload) => createAction(CLEAR_LEAD, {payload}),
	setQuery: (payload) => createAction(SET_QUERY, {payload}),
	exportLeads: (payload) => createAction(EXPORT_LEADS, {payload}),
	setActiveTab: (payload) => createAction(SET_ACTIVE_TAB, {payload}),
	clearQuery: (payload) => createAction(CLEAR_QUERY, {payload}),
	setExportsLoading: (payload) => createAction(SET_EXPORTS_LOADING, {payload}),
	setModalLoading: (payload) => createAction(SET_MODAL_LOADING, {payload}),
	editLeadUsers: (payload) => createAction(EDIT_LEAD_USERS, {payload}),
	editLeadUsersSuccess: (payload) => createAction(EDIT_LEAD_USERS_SUCCESS, {payload}),
	changeLeadStatus: (payload) => createAction(CHANGE_LEAD_STATUS, {payload}),
	setTotalPages: (payload) => createAction(SET_TOTAL_PAGES, {payload}),
	updateLeadsOnState: (payload) => createAction(UPDATE_LEADS_ON_STATE, {payload}),
	setScrollLoading: (payload) => createAction(SET_SCROLL_LOADING, {payload}),
	setSelectedStatuses: (payload) => createAction(SET_SELECTED_STATUSES, {payload}),
	deleteLeadsOnState: (payload) => createAction(DELETE_LEADS_ON_STATE, {payload}),
	clearSelectedStatuses: (payload) => createAction(CLEAR_SELECTED_STATUSES, {payload}),
	editLeadOnState: (payload) => createAction(EDIT_LEAD_ON_STATE, {payload}),
	getLeadStatistics: (payload) => createAction(GET_LEAD_STATISTICS, {payload}),
	getLeadStatisticsSuccess: (payload) => createAction(GET_LEAD_STATISTICS_SUCCESS, {payload}),
	clearPagination: (payload) => createAction(CLEAR_PAGINATION, {payload}),
	setSearch: (payload) => createAction(SET_SEARCH, {payload}),
	getRelations: (payload) => createAction(GET_RELATIONS, {payload}),
	getRelationsSuccess: (payload) => createAction(GET_RELATIONS_SUCCESS, {payload}),
	setRelationLoading: (payload) => createAction(SET_RELATIONLOADING, {payload}),
	getLeadStatisticsById: (payload) => createAction(GET_LEAD_STATISTICS_BYID, {payload}),
	getLeadStatisticsByIdSuccess: (payload) =>
		createAction(GET_LEAD_STATISTICS_BYID_SUCCESS, {payload}),
	clearRelationsState: (payload) => createAction(CLEAR_RELATIONS, {payload}),
	setLeadInitialValues: (payload) => createAction(SET_LEAD_INITIAL_VALUES, {payload}),
};

function base64toFile(base64String, fileName) {
	const byteCharacters = atob(base64String);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	const workbook = read(byteArray, {type: "array"});
	const excelFile = write(workbook, {type: "base64"});
	const blob = new Blob([byteArray], {
		type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	});
	const url = URL.createObjectURL(blob);
	const link = document.createElement("a");
	link.href = url;
	link.download = fileName;
	link.click();
	URL.revokeObjectURL(url);
}

export const sagas = {
	*getLeadById({payload}) {
		yield put(actions.setModalLoading(true));
		try {
			const response = yield axios.get(`/lead/${payload?.id}`);
			yield put(actions.getLeadByIdSuccess(response?.data.data));
			if (response?.data?.data.children?.[0]) {
				const children = response?.data?.data.children;
				const parent = response?.data?.data;
				delete parent.children;
				yield put(
					createActions.setLeadChildren({
						parent,
						children,
					}),
				);
			} else {
				yield put(createActions.setLeadChildren({parent: null, children: []}));
			}
		} catch (error) {
			logger.error("generate error", error);
		} finally {
			yield put(actions.setModalLoading(false));
		}
	},
	*getLeads({payload}) {
		const {page, size} = yield select((state) => state.app.salesTracker.index);

		if (page === 1) {
			yield put(actions.setLoading(true));
		}
		if (page > 1) {
			yield put(actions.setScrollLoading(true));
		}
		try {
			const sort = localStorage.getItem("sort");
			const response = yield axios.post(`/lead/all/web?page=${page}&size=${size}`, {
				userId: payload?.query?.userId,
				from: payload?.query?.from || null,
				to: payload?.query?.to || null,
				statuses: payload?.query?.statuses,
				teamId: payload?.query?.teamId,
				agencyId: payload?.query?.agencyId,
				projectId: payload?.query?.projectId,
				clientId: payload?.query?.clientId,
				search: payload?.query?.search,
				addressSearch: false,
				sort: payload?.sort || JSON.parse(sort),
				salesOrganizationId: payload?.query?.salesOrganizationId,
				leadImportStatus: payload?.query?.leadImportStatus,
			});
			yield put(actions.getLeadsSuccess(response?.data?.data));
		} catch (error) {
			logger.error("generate error", error);
		} finally {
			yield put(actions.setLoading(false));
			yield put(actions.setScrollLoading(false));
		}
	},
	*exportLeads({payload}) {
		yield put(actions.setExportsLoading(true));
		try {
			const {leads} = yield select((state) => state.app.salesTracker.index);
			const allLeads = Object.values(leads)
				?.map((item) => item?.data)
				?.flat();
			if (allLeads.length > 0) {
				const response = yield axios.post(`/lead/export`, {
					userId: payload?.userId || null,
					from: payload?.from || null,
					to: payload?.to || null,
					statuses: payload?.statuses || null,
					teamId: payload?.teamId || null,
					agencyId: payload?.agencyId || null,
					clientId: payload?.clientId || null,
					projectId: payload?.projectId || null,
					salesOrganizationId: payload?.salesOrganizationId || null,
					leadImportStatus: payload?.leadImportStatus || null,
				});
				base64toFile(response.data, "Export.xlsx");
			} else {
				ToastInfoComponent(i18n.t("NoLeadsInfo"));
			}
		} catch (error) {
			logger.error("generate error", error);
		} finally {
			yield put(actions.setExportsLoading(false));
		}
	},
	*editLeadUsers({payload}) {
		try {
			const {query, data} = payload;
			const response = yield axios.put(`/lead/changeuser`, {...data, leadsFilter: query});
			if (response?.data?.data === "UserFound") {
				const {leadsIds, selectedStatuses} = data;
				const areMultiple = leadsIds?.length || selectedStatuses?.length;
				ToastInfoComponent(i18n.t(`UserExistsInLead${areMultiple ? "s" : ""}`));
			} else {
				yield put(actions.editLeadUsersSuccess(data));
				yield put(leadAddressActions.resetPagination());
			}
			yield put(actions.setSelectedStatuses({ids: [], totalSize: 0}));
			yield put(actions.clearQuery());
			ToastSuccesComponent(i18n.t("ChangeUser"));
		} catch (error) {
			logger.error(error);
		}
	},
	*getRelations({payload}) {
		yield put(actions.setRelationLoading(true));
		try {
			const response = yield axios.get(`/sales_tracker/lead/relations/${payload}`);
			yield put(actions.getRelationsSuccess(response.data.data));
			yield put(actions.setRelationLoading(false));
		} catch (error) {
			logger.error(error);
			yield put(actions.setRelationLoading(false));
		}
	},
	*getLeadStatistics() {
		yield put(actions.setLoading(true));
		try {
			const {size, page, search} = yield select((state) => state.app.salesTracker.index);
			const response = yield axios.get(
				`/sales_tracker/users/lead-counts?page=${page}&size=${size}&search=${search}`,
			);
			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.setTotalPages(response?.data?.data?.totalPages));
			yield put(
				actions.getLeadStatisticsSuccess({data: response.data?.data?.data, type: "get"}),
			);
			yield put(actions.setLoading(false));
		} catch (error) {
			yield put(actions.setLoading(false));
			logger.error(error);
		}
	},
	*getLeadStatisticsById({payload}) {
		yield put(actions.setRelationLoading(true));
		try {
			const response = yield axios.get(`/sales_tracker/users/lead-counts/${payload}`);
			yield put(actions.getLeadStatisticsByIdSuccess(response.data));
			yield put(actions.setRelationLoading(false));
		} catch (error) {
			yield put(actions.setRelationLoading(false));
			logger.error(error);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_LEAD_BY_ID, sagas.getLeadById);
	yield takeLatest(GET_LEADS, sagas.getLeads);
	yield takeLatest(EXPORT_LEADS, sagas.exportLeads);
	yield takeLatest(EDIT_LEAD_USERS, sagas.editLeadUsers);
	yield takeLatest(GET_LEAD_STATISTICS, sagas.getLeadStatistics);
	yield takeLatest(GET_RELATIONS, sagas.getRelations);
	yield takeLatest(GET_LEAD_STATISTICS_BYID, sagas.getLeadStatisticsById);
};
