import React, {useState, useEffect} from "react";
import ChannelUsers from "@src/common/messagesComponents/ChannelUsers";
import DropDownInputSp from "../../dropDown/DropDownSp";

function CustomSelectOptions({
	title,
	options,
	formik,
	formikName,
	placeholder,
	loggedUser,
	dropDowntitle,
	array,
	isAnalytics,
}) {
	const {setFieldValue, errors, touched, values} = formik;
	// we use this for DropDownInputSp
	const [inputValue, setInputValue] = useState("");
	//
	const [selectedOptions, setSelectedOptions] = useState([]);
	//
	const [newOptions, setNewOptions] = useState(options);

	const parentRemoveHandler = (item) => {
		setSelectedOptions(selectedOptions.filter((itm) => itm.id !== item?.id));
		setFieldValue(
			formikName,
			values[formikName].filter((itm) => itm.id !== item?.id),
		);
		setNewOptions([item, ...newOptions]);
	};
	useEffect(() => {
		const ids = values[formikName]?.map((item) => item.id);
		setNewOptions(options.filter((item) => !ids?.includes(item.id)));
		setSelectedOptions(
			array?.map((item) => ({
				id: item.id,
				[dropDowntitle]: item.name || item.firstName || item.clientName,
				role: item?.role || null,
			})),
		);
	}, [formikName, values?.[formikName]]);

	return (
		<div className="firstStep__container--items-clientList">
			<div className="firstStep__container--items-clientList-dropdown">
				<span>{title}</span>
				<DropDownInputSp
					setInputValue={setInputValue}
					inputValue={inputValue}
					className="firstStep__dropdown"
					onChange={(event, value) => {
						if (formikName === "clientList") {
							setFieldValue(formikName, [value]);
							setSelectedOptions([value]);
							setNewOptions(options?.filter((opt) => opt.id !== value.id));
							setFieldValue("targetClientsList", [value]);
							setFieldValue("targetClients", [value]);
							setFieldValue("targetLocationsList", []);
							setFieldValue("targetProductsList", []);
							setFieldValue("targetTariffsList", []);
						} else {
							setFieldValue(formikName, [...(values[formikName] || []), value]);
							setSelectedOptions((prevState) => [...prevState, value]);
							setNewOptions((prevState) =>
								prevState.filter((item) => item.id !== value.id),
							);
							setFieldValue("targetClientsList", []);
							setFieldValue("targetClients", []);
							setFieldValue("targetLocationsList", []);
							setFieldValue("targetProductsList", []);
							setFieldValue("targetTariffsList", []);
							setFieldValue("clientList", []);
						}
					}}
					filterSelectedOptions={false}
					options={newOptions}
					placeholder={placeholder}
					title={dropDowntitle}
					value={null}
				/>
			</div>
			<div className="firstStep__container--items-clientList-channelFilters">
				<ChannelUsers
					setFieldValue={setFieldValue}
					value={values?.users}
					users={selectedOptions}
					loggedUser={loggedUser?.id}
					usersFromState={selectedOptions}
					setUsersFromState={setSelectedOptions}
					isOwner
					removeAdmin
					title={dropDowntitle}
					parentRemoveHandler={parentRemoveHandler}
					isAnalytics={isAnalytics}
				/>
			</div>
			{touched[formikName] && errors[formikName] && (
				<span className="errorClass">*{errors[formikName]}</span>
			)}
		</div>
	);
}

export default CustomSelectOptions;
