import axios from "@utils/axios";
import React, {useEffect, useRef} from "react";
import TextField from "@material-ui/core/TextField";
import {Autocomplete} from "@material-ui/lab";
import "./index.scss";
import _ from "lodash";
import {ArrowDownIcon} from "@src/assets/SvgComponents";

const usePreviousValue = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

const AsyncDropDownInput = ({
	value,
	onChange,
	limitTags = 1,
	filterSelectedOptions = false,
	placeholder = "",
	multiple = false,
	touched,
	errors,
	disabled,
	className,
	errorClass = "validation-error",
	withTouched = true,
	noTouched = false,
	disablePortal = false,
	disableClearable = true,
	onBlur,
	element,
	dependencies,
	renderTags,
	label,
	asyncOptions,
	setAsyncOptions,
}) => {
	const dependency = _.get(dependencies, element.dependencies);
	const prevCount = usePreviousValue(dependency);
	useEffect(() => {
		if (dependency) {
			axios
				.get(element?.url.replace(`:${element?.dependencies}`, dependency?.value))
				.then(({data}) => {
					let options = data.data;
					if (element.name === "tariffId") {
						options = data.data.map((e) => ({name: e.name, value: e.id}));
						if (prevCount && prevCount.value !== dependency.value) {
							onChange(null, null);
						}
					}
					if (element.name === "locationId") {
						options = data.data.map((e) => ({
							name: e.locationName,
							value: e.id,
						}));
					}
					if (element.name === "products") {
						options = data.data.map((e) => ({name: e.name, value: e.id}));
						if (prevCount && prevCount.value !== dependency.value) {
							onChange(null, []);
						}
					}
					if (element.name === "projectCategoryId") {
						options = data.data.map((e) => ({name: e.name, value: e.id}));
					}
					setAsyncOptions((prev) => ({
						...prev,
						[element.name]: options,
					}));
				});
		}
	}, [dependency]);
	return (
		<div className="async-dropdown-autocomplete">
			{label && <label className="asyncDropDown-label">{placeholder}</label>}

			<Autocomplete
				popupIcon={<ArrowDownIcon />}
				renderTags={renderTags}
				className={`AutocompleteCl ${className}`}
				disableClearable={disableClearable}
				filterSelectedOptions={filterSelectedOptions}
				getOptionLabel={(option) => (option && option.name ? option.name : "")}
				getOptionSelected={(option, value) => option.value === value?.value}
				limitTags={limitTags}
				disableCloseOnSelect={element.multiple}
				multiple={multiple}
				onChange={onChange}
				value={value}
				onBlur={onBlur}
				options={asyncOptions?.[element?.name] || []}
				placeholder="Name"
				renderInput={(params) => (
					<TextField
						{...params}
						className="dropdown-option"
						label={!label ? placeholder : ""}
						placeholder={placeholder}
						variant="outlined"
					/>
				)}
				// value={value}
				disabled={disabled}
				style={{
					border: "none",
					outline: "none",
				}}
				disablePortal={disablePortal}
			/>
			{withTouched && touched && errors && (
				<span className={errorClass}>{touched && errors}</span>
			)}
			{noTouched && errors && <span className={errorClass}>{errors}</span>}
		</div>
	);
};

export default AsyncDropDownInput;
