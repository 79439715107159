import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as navigation} from "@sagas/navigation";
import {actions as clientActions} from "@sagas/client/index";
import DataGridTable from "@src/common/table/DataGridTable";
import {useFormik} from "formik";
import {actions as impActions} from "@sagas/imports/create";
import {actions as orderImportsActions} from "@sagas/orders/orderImports";
import {importStatusOptions} from "@src/components/dashboard/orders/staticData";
import {actions as importActions} from "@sagas/salesTracker/imports";
import HtmlTooltip from "@src/common/htmlTooltip";
import {DeleteRowIcon, PreviewRowIcon} from "@src/assets/SvgComponents";
import {actions as leadStatusesActions} from "@sagas/salesTracker/leadStatus";
import {actions as statusesActions} from "@sagas/salesTracker/statuses";
import {checkRoles} from "@src/utils/checkRoles";
import TableSkeleton from "@src/common/table/TableSkeleton";
import ImportsHeader from "./ImportsHeader";
import ImportsModal from "./ImportsModal";
import {orderStatus} from "./entityHelper";
import {importedColumns} from "../../columns";
import UploadInput from "../../sales-tracker/components/UploadInput";
import {importsColumns} from "../../orders/OrderImports/orderImportsColumns";
import DeleteImportedLeadModal from "./DeleteImportedLeadModal";
import {statusImportsColumns} from "./helper";

const CreateImport = ({
	getClients,
	navigate,
	parseCsv,
	optionsClients,
	match,
	draftLoading,
	importsLeads,
	getImportsLeads,
	leadsPage,
	leadsSize,
	leadsTotalSize,
	leadsTotalPages,
	leadsEditPage,
	leadsEditSize,
	leadsLoading,
	getImportsOrders,
	ordersEditPage,
	ordersEditSize,
	ordersPage,
	ordersSize,
	ordersTotalPages,
	ordersTotalSize,
	ordersImports,
	ordersLoading,
	fetchAllLeadStatuses,
	getAllStatuses,
	getDrafts,
	drafts,
	loggedUser,
}) => {
	const {t} = useTranslation();
	const [showModal, setShowModal] = useState(false);
	const [showCustomModal, setShowCustomModal] = useState(false);
	const [leadsReEditPage, leadsSetReEditPage] = useState(true);
	const [ordersReEditPage, ordersSetReEditPage] = useState(true);
	const [deleteModal, setDeleteModal] = useState(false);
	const [importItem, setImportItem] = useState(false);
	useEffect(() => {
		getClients();
		fetchAllLeadStatuses();
		getAllStatuses();
		getDrafts();
		if (match.params?.id === "create-leads") {
			getImportsLeads({leadsPage, leadsSize});
		} else if (match.params?.id === "create-order") {
			getImportsOrders();
		}
	}, [leadsPage, leadsSize, ordersPage, ordersSize]);
	const actionsFormatter = (row) => (
		<div className="table-actions-wrapper">
			<HtmlTooltip title={<span>View Import</span>} placement="bottom-end">
				<span onClick={() => navigate(`/admin/imports/create-leads/${row?.id}`)}>
					<PreviewRowIcon />
				</span>
			</HtmlTooltip>
			<HtmlTooltip title={<span>Delete Import</span>} placement="bottom-end">
				<span
					onClick={() => {
						setDeleteModal(true);
						setImportItem(row);
					}}
				>
					<DeleteRowIcon />
				</span>
			</HtmlTooltip>
		</div>
	);
	const getType = () => {
		switch (match.params?.id) {
			case "change-order-status":
				return {
					obj: orderStatus,
					submitFunction: parseCsv,
					extraPayload: {fromStatus: true},
					dropDownOptions: {
						optionsClients,
						importStatusOptions,
					},
					tableData: drafts,
					tableColumns: statusImportsColumns,
					onTableRowClick: (row) => {
						const {clientId} = row.importOrderJsonData;
						navigate(
							// eslint-disable-next-line max-len
							`/admin/imports/change-order-status/tables?id=${row.id}&fromDate=${row.fromDate}&toDate=${row.toDate}&clientId=${clientId}&status=${row?.importOrderType}`,
						);
					},
					tableLoading: draftLoading,
					path: t("ChangeOrderStatus"),
				};
			case "create-order":
				return {
					// added obj: 'custom' for old features
					obj: "custom",
					tableData: ordersImports,
					tableColumns: importsColumns,
					onTableRowClick: (row) => navigate(`/admin/imports/create-order/${row?.id}`),
					pagination: {
						editPage: ordersEditPage,
						editSize: ordersEditSize,
						page: ordersPage,
						size: ordersSize,
						totalSize: ordersTotalSize,
						totalPages: ordersTotalPages,
						reEditPage: ordersReEditPage,
						setReEditPage: ordersSetReEditPage,
					},
					tableLoading: ordersLoading,
					path: t("CreateOrders"),
				};
			case "create-leads":
				return {
					// added obj: 'custom' for old features
					obj: "custom",
					tableData: importsLeads,
					tableColumns: importedColumns,
					actions: {
						width: 50,
						resizable: false,
						sortable: false,
						name: t("Actions"),
						key: "actions",
						formatter: ({row}) => actionsFormatter(row),
					},
					pagination: {
						editPage: leadsEditPage,
						editSize: leadsEditSize,
						page: leadsPage,
						size: leadsSize,
						totalSize: leadsTotalSize,
						totalPages: leadsTotalPages,
						reEditPage: leadsReEditPage,
						setReEditPage: leadsSetReEditPage,
					},
					tableLoading: leadsLoading,
					path: t("CreateLeads"),
				};
			case "":
				// ex: when we add a import for another entity we put the case as the url,
				// and we create the object as needed.
				return null;
			default:
				return null;
		}
	};
	const type = getType();
	const formik = useFormik({
		validationSchema: orderStatus.validationSchema,
		initialValues: orderStatus.initialValues,
		enableReinitialize: true,
		onSubmit: (values) => {
			getType().obj.submit({
				submitFunction: getType()?.submitFunction,
				payload: {
					...values,
					...getType()?.extraPayload,
				},
			});
		},
	});
	if (!type) {
		return null;
	}
	const {
		obj,
		path,
		tableData,
		tableLoading,
		actions,
		tableColumns,
		onTableRowClick,
		pagination,
		dropDownOptions,
	} = type;
	return (
		<div className="dashboard__container">
			<ImportsHeader
				custom={obj === "custom"}
				setShowModal={setShowModal}
				setShowCustomModal={setShowCustomModal}
				showCustomModal={showCustomModal}
				path={path}
			/>
			{checkRoles("seeImports") && (
				<div className="importsPage-layout">
					<div className="loader__and__table-orderspage">
						<DataGridTable
							data={tableData}
							loading={tableLoading}
							columns={actions ? [...tableColumns, actions] : tableColumns}
							onRowClick={(rowIndex, row) =>
								onTableRowClick ? onTableRowClick(row) : null
							}
							pagination={Boolean(pagination) || false}
							editPage={pagination?.editPage}
							editSize={pagination?.editSize}
							page={pagination?.page}
							size={pagination?.size}
							totalSize={pagination?.totalSize}
							totalPages={pagination?.totalPages}
							reEditPage={pagination?.reEditPage}
							setReEditPage={pagination?.setReEditPage}
						/>
						{tableLoading &&
							(pagination?.page === 1 || pagination?.page === undefined) && (
								<div className="infinitscroll_loader center">
									<TableSkeleton />
								</div>
							)}
					</div>
				</div>
			)}
			<ImportsModal
				setShowModal={setShowModal}
				showModal={showModal}
				formik={formik}
				inputs={obj !== "custom" && obj?.inputs(dropDownOptions)}
			/>
			{showCustomModal && (
				<UploadInput
					setShowUploadModal={setShowCustomModal}
					showUploadFileModal={showCustomModal}
					importType={match.params?.id === "create-order" ? "fromOrder" : ""}
				/>
			)}
			<DeleteImportedLeadModal
				setDeleteModal={setDeleteModal}
				deleteModal={deleteModal}
				importItem={importItem}
			/>
		</div>
	);
};
const mapStateToProps = (state) => ({
	// change status
	optionsClients: state.app.client.index.optionsClients,
	drafts: state.app.imports.create.drafts,
	draftLoading: state.app.imports.create.loading,
	// create leads
	importsLeads: state.app.salesTracker.imports.imports,
	leadsPage: state.app.salesTracker.imports.page,
	leadsTotalSize: state.app.salesTracker.imports.totalSize,
	leadsSize: state.app.salesTracker.imports.size,
	leadsImports: state.app.salesTracker.imports.imports,
	leadsTotalPages: state.app.salesTracker.imports.totalPages,
	leadsLoading: state.app.salesTracker.imports.loading,
	// create orders
	ordersPage: state.app.orders.import.page,
	ordersSize: state.app.orders.import.size,
	ordersTotalPages: state.app.orders.import.totalPages,
	ordersTotalSize: state.app.orders.import.totalSize,
	ordersImports: state.app.orders.import.imports,
	ordersLoading: state.app.orders.import.loading,
	loggedUser: state?.app?.users.index.loggedUser,
});

const mapDispatchToProps = {
	navigate: navigation.navigate,
	getClients: clientActions.fetchClientsByDropdown,
	parseCsv: impActions.parseCsv,
	getDrafts: impActions.getDrafts,
	// create leads
	getImportsLeads: importActions.getImports,
	leadsEditSize: importActions.editSize,
	leadsEditPage: importActions.editPage,
	// create orders
	ordersEditPage: orderImportsActions.editPage,
	ordersEditSize: orderImportsActions.editSize,
	getImportsOrders: orderImportsActions.getImports,
	fetchAllLeadStatuses: leadStatusesActions.fetchAllLeadStatuses,
	getAllStatuses: statusesActions.getAllStatuses,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateImport));
