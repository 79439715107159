import HtmlTooltip from "@src/common/htmlTooltip";
import React from "react";
import {useSelector} from "react-redux";

const LeadUsers = ({userIds, backgroundColor = "#C1C1C1"}) => {
	const {usersOptions} = useSelector((state) => state.app.users.index);
	const filteredUsers = usersOptions?.filter((user) => userIds?.includes(user?.id));
	return (
		<HtmlTooltip
			placement="bottom-end"
			title={filteredUsers?.map((user, index) => (
				<p key={`${user.id}--${index}`} className="user__tooltip">
					{index + 1}. {user?.firstName} {user?.lastName}
				</p>
			))}
		>
			<div className="lead__users">
				{filteredUsers?.slice(0, 5).map((user, index) =>
					user?.imgUrl && user?.imgUrl !== null ? (
						<img src={user.imgUrl} alt="" className="user_img_avatar" key={index} />
					) : (
						<div className="lead__users-avatar" key={index} style={{backgroundColor}}>
							{user.firstName?.charAt(0)}
							{user.lastName?.charAt(0)}
						</div>
					),
				)}
				{filteredUsers?.length > 5 && <span>+{filteredUsers.length - 5}</span>}
			</div>
		</HtmlTooltip>
	);
};

export default LeadUsers;
