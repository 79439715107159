import React, {useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import {Autocomplete} from "@material-ui/lab";
import "./index.scss";

const SimpleDropDownInput = ({
	title,
	options,
	value,
	onChange,
	limitTags = 1,
	filterSelectedOptions = true,
	placeholder = "",
	multiple = false,
	touched,
	errors,
	disabled,
	className,
	errorClass = "validation-error",
	withTouched = true,
	noTouched = false,
	disablePortal = false,
	disableClearable = true,
	onBlur,
	name,
	hidden = false,
	setFieldValue,
	label,
	icon,
}) => {
	useEffect(() => {
		if (hidden) {
			setFieldValue(name, "");
		}
	}, [hidden]);
	if (hidden) return null;
	return (
		<div className="async-dropdown-autocomplete">
			{label && <label className="asyncDropDown-label">{placeholder}</label>}
			<Autocomplete
				popupIcon={icon}
				className={`AutocompleteCl ${className}`}
				disableClearable={disableClearable}
				filterSelectedOptions={filterSelectedOptions}
				getOptionLabel={(option) => (option && option[title] ? option[title] : "")}
				getOptionSelected={(option, value) => option.value === value.value}
				limitTags={limitTags}
				multiple={multiple}
				onChange={onChange}
				onBlur={onBlur}
				options={options}
				placeholder="Name"
				renderInput={(params) => (
					<TextField
						{...params}
						className="dropdown-option"
						label={!label ? placeholder : ""}
						placeholder={placeholder}
						variant="outlined"
					/>
				)}
				value={value}
				disabled={disabled}
				style={{
					border: "none",
					outline: "none",
				}}
				disablePortal={disablePortal}
			/>
			{withTouched && touched && errors && (
				<span className={errorClass}>{touched && errors}</span>
			)}
			{noTouched && errors && <span className={errorClass}>{errors}</span>}
		</div>
	);
};

export default SimpleDropDownInput;
