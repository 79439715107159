import React, {useEffect, useState} from "react";
import ModalComponent from "@src/common/CustomModal";
import {useTranslation} from "react-i18next";
import {useUrlParams} from "@src/utils/useUrlParams";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as clientActions} from "@sagas/client";
import {actions as locationActions} from "@sagas/locations";
import {actions as productActions} from "@sagas/products";
import {actions as tariffActions} from "@sagas/tariff";
import {actions as createOrderActions} from "@sagas/orders/create";
import {actions as categoryActions} from "@sagas/projectCategories/clientCategory";
import {useFormik} from "formik";
import useGenericLocations from "@src/utils/useGenericLocations";
import {generateYupSchema, generateInitialValues, preparePayload, VIEWS} from "./helpers";
import Form from "./Form";
import MobileView from "./components/MobileView";
import TabletView from "./components/TabletView";
import Tabs from "./components/Tabs";

const PreviewOrderForm = ({
	orderFieldsState,
	getOrderFields,
	getLocations,
	getProjectCategories,
	locations,
	projectCategories,
	getTariffs,
	tariffs,
	products,
	getProducts,
	createOrder,
	loading,
	language,
	withTabs = true,
}) => {
	const {fieldOptions, groupedFields, client, editedOrderFields} = orderFieldsState;
	const {t} = useTranslation();
	const {options, retrieveDataFromGetAg} = useGenericLocations();
	const [uploadedFiles, setUploadedFiles] = useState({});
	const {setModal, getUrlParams} = useUrlParams();
	const [collapsedSections, setCollapsedSections] = useState({});
	const [activeView, setActiveView] = useState(withTabs ? VIEWS.mobile : VIEWS.desktop);

	const handleView = (view) => setActiveView(view);
	const handleClose = () => setModal(false);

	const handleCollapse = (id) => setCollapsedSections((prev) => ({...prev, [id]: !prev[id]}));
	const {modal} = getUrlParams("modal");
	const isOpen = modal === "preview";

	const BASE_FIELDS = [
		{
			name: "clientName",
			defaultValue: client.clientName,
			labelEn: "Client",
			labelDe: "Produktgeber",
			inputType: "TEXT",
			isDisabled: true,
		},
		{
			name: "contractId",
			defaultValue: `${client.clientId}${client.clientContractId + 1}`,
			labelEn: "Contract Id",
			labelDe: "Auftrags-ID",
			inputType: "TEXT",
			isDisabled: true,
		},
		{
			name: "orderExternalId",
			defaultValue: language === "en" ? "External ID" : "Versorger Externe ID",
			labelEn: "External ID",
			labelDe: "Versorger Externe ID",
			inputType: "TEXT",
			isDisabled: true,
		},
		{
			name: "locationId",
			options: locations,
			labelEn: "Municipality",
			labelDe: "Gemeinde",
			inputType: "DROPDOWN",
			isRequired: true,
			dependencyFields: ["tariffId"],
		},
		{
			name: "tariffId",
			dependentFrom: "locationId",
			dependencyFields: ["productIds"],
			options: tariffs,
			labelEn: "Tariff",
			labelDe: "Tarif",
			inputType: "DROPDOWN",
			isRequired: true,
		},
		{
			name: "productIds",
			options: products,
			labelEn: "Product",
			labelDe: "Zusatzprodukt",
			inputType: "DROPDOWN",
			isMultiple: true,
		},
		{
			name: "projectCategoryId",
			options: projectCategories,
			labelEn: "Category",
			labelDe: "Projektkategorie",
			inputType: "DROPDOWN",
			isRequired: true,
		},
	];
	const mergedArray = [...BASE_FIELDS, ...editedOrderFields];
	const initialValues = generateInitialValues(mergedArray);
	const validationSchema = editedOrderFields?.length > 0 ? generateYupSchema(mergedArray) : {};
	const formik = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: true,
		enableReinitialize: true,
		onSubmit: (values) => {
			// if (activeView === VIEWS.desktop) {
			// 	const data = preparePayload(editedOrderFields, values, client.id);
			// 	createOrder({data, handleClose});
			// }
		},
	});

	const {values, resetForm} = formik;

	useEffect(() => {
		if (client?.id && isOpen) {
			getOrderFields(client.id);
			getLocations(client.id);
			getProjectCategories(client.id);
		} else if (!isOpen) {
			resetForm();
			setUploadedFiles({});
		}
	}, [client?.id, isOpen]);

	useEffect(() => {
		if (values?.locationId) {
			getTariffs(values.locationId);
		}
	}, [values?.locationId]);

	useEffect(() => {
		if (values?.tariffId) {
			getProducts(values.tariffId);
		}
	}, [values?.tariffId]);

	if (!isOpen) {
		return null;
	}
	const formProps = {
		formik,
		baseFields: BASE_FIELDS,
		groupedFields,
		handleCollapse,
		collapsedSections,
		fieldOptions,
		editedOrderFields,
		uploadedFiles,
		setUploadedFiles,
		retrieveDataFromGetAg,
		options,
		loading,
	};
	return (
		<ModalComponent
			open={isOpen}
			handleClose={handleClose}
			title={t("orderForm")}
			customClasss="order__form__modal"
			width={activeView === VIEWS.tablet ? 720 : 500}
		>
			{withTabs && <Tabs handleTab={handleView} activeTab={activeView} />}
			{activeView !== VIEWS.desktop ? (
				<div className="order__form__view">
					{activeView === VIEWS.mobile && <MobileView {...formProps} />}
					{activeView === VIEWS.tablet && <TabletView {...formProps} />}
				</div>
			) : (
				<Form {...formProps} />
			)}
		</ModalComponent>
	);
};

const mapStateToProps = (state) => ({
	orderFieldsState: state.app.client.index,
	locations: state.app.locations.index.allLocations,
	projectCategories: state.app.projectCategories.clientCategory.categoriesOptions,
	tariffs: state.app.tariffs.index.tariffByLocations,
	products: state.app.products.index.productsByTariff,
	loading: state.app.orders.create.loading,
	language: state.app.language.index.language,
});
const mapDispatchToProps = {
	getOrderFields: clientActions.fetchGenericOrderByID,
	getLocations: locationActions.fetchAllLocations,
	getProjectCategories: categoryActions.getClientCategoriesDropdown,
	getTariffs: tariffActions.fetchTariffsByLocationId,
	getProducts: productActions.fetchProductsByTariff,
	createOrder: createOrderActions.createOrder,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PreviewOrderForm));
