/* eslint-disable max-len */
export const ENERGY_PREFIX = "/v1/energy";

export const ENDPOINT = {
	// Conditions
	CREATE_TABLE: "v2/condition/create/:type",
	GET_CONDITIONS: "v2/condition/get/:type",
	DELETE_TABLE: "v2/condition/delete/:type/:id",
	UPDATE_TABLE: "v2/condition/update/:type",
	CREATE_SETTLEMENT_HEADOFSALES: "v2/condition/settlement/headOfSales",

	// Conditions Version 1
	CREATE_TABLE_V1: "/condition/create/:type",
	GET_CONDITIONS_V1: "/condition/get/:type",
	DELETE_TABLE_V1: "/condition/delete/:type/:id",

	// STFP TEST CONNECTION,
	TEST_CONNECTION: process.env.REACT_APP_SFTP_SERVER,

	// SFTP CONNECTION:
	CONNECT_SERVER: "/client_settings/update-config",

	// Analytics Goals

	CREATE_GOALS: "/goals/create",
	GET_GOALS: "/goals/all?page=:page&size=:size",
	EDIT_GOALS: "/goals/update/:id",
	DELETE_GOALS: "/goals/delete",
	TOGGLE_GOALS: "/goals/show-on-dashboard",
	TOGGLE_GOALS_MOBILE: "/goals/show-on-mobile",
	GOAL_VIEWS: "/goals-groups",
	GOAL_VIEWS_ID: "/goals-groups/:id",
	GOAL_BY_TYPE: "/goals/:type",
	// COntactless Order
	VERIFY_PIN: "/order/verifyPin",
	VALIDATE_TOKEN: "/order/verifyToken",
	GET_CONTACTLESS_ORDER: "/order/orderContactless",
	SUBMIT_SIGNATURE: "/order/submitSignature",
	RESEND_ORDER_PIN: "/order/resendOrderPin",
	// PDF FIELDS
	UPDATE_PDF_FIELD: "/client/:clientId/:pdfId/update",

	// CUSTOM FIELDS
	UPDATE_CUSTOM_FIELD: "client/update/order_field",
	GET_ALL_ORDERS_FIELDS: "client/order_fields",

	// Settlement,
	EXPORT_SETTLEMENTS: "order/all/calculate-orders-export",

	// Project Categories,
	TOGGLE_CATEGORY: "/projectCategory/toggle",

	// Locations,
	TOGGLE_LOCATION: "/location/toggle",

	// Tariffs,
	TOGGLE_TARIFF: "/tariff/toggle",

	// Products,
	TOGGLE_PRODUCT: "/product/toggle",
	// Monitoring
	GET_MONITORING_DATA: "/monitoring/:type",

	// CHAT
	DELETE_MESSAGE: "/messages/delete/:topicId/:messageId",

	// Dashboard
	GET_CLIENT_STATISTICS: "/order/clients/statistics",

	// CALCULATIONS:
	GET_EMPLOYEE_ROLE: "/order/employee/role/chart",
	GET_CALCULATIONS:
		"/order/all/settled_orders?page=:page&size=:size&search=:search&billingMonthId=:billingMonthId&clientPayoutModel=:clientPayoutModel",
	GET_SP_CALCULATIONS: "/order/settled_paid/sp",
	GET_CALCULATION_BY_ID: "/order/settled/?page=1&size=1000",
	GET_BILLING_MONTHS: "/v2/billing-months-settlements/all",

	// MARKETPLACE
	GET_MARKETPLACE_APPS: "/marketplace",
	SET_API_KEY: "/marketplace/update/:id",

	// BONUSES
	GET_CATEGORY_BONUSES: "/projectCategory/baugos/:id",

	// Structures,
	CREATE_STRUCTURE: `${ENERGY_PREFIX}/structure/create`,
	UPDATE_STRUCTURE: `${ENERGY_PREFIX}/structure/update/:id`,
	GET_STRUCTURE_TABLES: `${ENERGY_PREFIX}/structure/?page=:page&size=:size`,
	CREATE_STUFE: `${ENERGY_PREFIX}/stufe/create`,
	UPDATE_STUFE: `${ENERGY_PREFIX}/stufe/update`,
	DELETE_STRUCTURE: `${ENERGY_PREFIX}/structure/delete/:id`,
	DELETE_STUFE: `${ENERGY_PREFIX}/stufe/delete/:id`,
	FETCH_STUFES: `${ENERGY_PREFIX}/structure/getStufes/:id`,
	FETCH_ORDER_STATUSES: `${ENERGY_PREFIX}/structure/status/all`,
	FETCH_TARIFF_HISTORY: `${ENERGY_PREFIX}/tariff-history/all/:id`,
	FETCH_SINGLE_TARIFF_HISTORY: `${ENERGY_PREFIX}/tariff-history/:id`,
	GET_STRUCTURE_BY_ID: `${ENERGY_PREFIX}/structure/:id`,
	GET_STUFE_BY_ID: `${ENERGY_PREFIX}/stufe/:id`,
	CREATE_TARIFF_HISTORY: `${ENERGY_PREFIX}/tariff-history/create`,
	UPDATE_TARIFF_HISTORY: `${ENERGY_PREFIX}/tariff-history/update`,
	DELETE_TARIFF_HISTORY: `${ENERGY_PREFIX}/tariff-history/delete/:id`,
	GET_ALL_STRUCTURES: `${ENERGY_PREFIX}/structure`,
	FETCH_VALID_STUFFES: `${ENERGY_PREFIX}/stufe/validstufe/:id/:structureId`,

	// SUPERVISORS
	GET_AVAILABLE_SUPERVISORS: `${ENERGY_PREFIX}/stufe/supervisors/:structure/:stufe`,

	// statuses
	CREATE_STATUS: `${ENERGY_PREFIX}/status/create`,
	GET_ALL_STATUS: `${ENERGY_PREFIX}/status/all`,
	GET_BY_ID_STATUS: `${ENERGY_PREFIX}/status/:id`,
	UPDATE_STATUS: `${ENERGY_PREFIX}/status/update`,
	DELETE_STATUS: `${ENERGY_PREFIX}/status/:id`,

	// ORDER_FORM

	FETCH_PDF: `/client/:clientId/pdf_fields/:pdfId`,
	FETCH_ORDER_FIELDS: `/client/order_fields/:clientId`,
	DELETE_PDF_FIELD: `/client/pdf_field/:id`,
	CREATE_PDF_FIELD: `/client/pdf_field_dnd`,
	UPDATE_PDF_FIELD_DND: `/client/pdf_field_dnd`,
	GET_PDF_FIELD_BY_ID: `/client/pdf_field/:id`,

	// SALES TRACKER

	GET_LEAD_ADDRESSES: "/lead/addresses?page=:page&size=:size",
	GET_LEADS_BY_ADDRESS: "/lead/from-street?page=:page&size=:size",
	COUNT_LEAD_ADDRESSES: "/lead/count-addresses",

	// map view
	GET_LEADS_BY_MAP: `/lead/boundaries`,
};
