/* eslint-disable no-nested-ternary */
import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import moment from "moment";
import {saveAs} from "file-saver";
import {
	ToastErrorComponent,
	ToastInfoComponent,
	ToastSuccesComponent,
} from "@common/ToastComponent/ToastComponent";
import {getCurrentUser} from "@utils/currentUser";
import {ENDPOINT} from "@src/utils/endpoint";
import i18n from "../../../../i18n";
// eslint-disable-next-line import/no-cycle
import {actions as editActions} from "./editOrder";
import {actions as calculationActions} from "../calculations/index";
import {buildPayload, generateAndDownloadExcel} from "./utils";

const JSZip = require("jszip");
const zip = new JSZip();

const logger = new Logger("Sagas>Orders>Index");

const PREFIX = "@app/orders/index";
export const GET_ORDERS = `${PREFIX}GET_ORDERS`;
export const GET_ORDER_PREVIEW = `${PREFIX}GET_ORDER_PREVIEW`;
export const GET_ORDER_PREVIEW_SUCCESS = `${PREFIX}GET_ORDER_PREVIEW_SUCCESS`;
export const GET_SINGLE_ORDER = `${PREFIX}GET_SINGLE_ORDER`;
export const DOWNLOAD_ORDER = `${PREFIX}DOWNLOAD_ORDER`;
export const DOWNLOAD_ORDER_LOADING = `${PREFIX}DOWNLOAD_ORDER_LOADING`;
export const GET_SINGLE_ORDER__SUCCESS = `${PREFIX}GET_SINGLE_ORDER__SUCCESS`;
export const CLEAR_SINGLE_ORDER = `${PREFIX}CLEAR_SINGLE_ORDER`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SET_LOADING_CHART = `${PREFIX}SET_LOADING_CHART`;
export const FETCH_ORDERS_SUCCESS = `${PREFIX}FETCH_ORDERS_SUCCESS`;
export const ADD_ORDER_ON_STATE = `${PREFIX}ADD_ORDER_ON_STATE`;
export const EDIT_ORDER_ON_STATE = `${PREFIX}EDIT_ORDER_ON_STATE`;
export const DELETE_TARIFF_ON_STATE = `${PREFIX}DELETE_TARIFF_ON_STATE`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const FILTERS = `${PREFIX}FILTERS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const TOTAL_SIZE_INCREASE = `${PREFIX}TOTAL_SIZE_INCREASE`;
export const GET_ORDER_GRAPHS = `${PREFIX}GET_ORDER_GRAPHS`;
export const GET_SINGLE_ORDER_GRAPHS_SUCCESS = `${PREFIX}GET_SINGLE_ORDER_GRAPHS_SUCCESS`;
export const GET_REVENUES = `${PREFIX}GET_REVENUES`;
export const GET_REVENUES_SUCCESS = `${PREFIX}GET_REVENUES_SUCCESS`;
export const CHANGE_ORDER_STATUS_SUCCESS = `${PREFIX}CHANGE_ORDER_STATUS_SUCCESS`;
export const SET_ORDER_PAID_DATE = `${PREFIX}SET_ORDER_PAID_DATE`;
export const SET_QUERY = `${PREFIX}SET_QUERY`;
export const CLEAN_QUERY = `${PREFIX}CLEAN_QUERY`;
export const GET_USER_STATS = `${PREFIX}GET_USER_STATS`;
export const GET_USER_STATS_SUCCESS = `${PREFIX}GET_USER_STATS_SUCCESS`;
export const GET_TOP_STATS = `${PREFIX}GET_TOP_STATS`;
export const GET_TOP_STATS_SUCCESS = `${PREFIX}GET_TOP_STATS_SUCCESS`;
export const GET_INFOCHEF_ORDERS = `${PREFIX}GET_INFOCHEF_ORDERS`;
export const GET_INFOCHEF_ORDERS_SUCCESS = `${PREFIX}GET_INFOCHEF_ORDERS_SUCCESS`;
export const GET_LEADERBOARD = `${PREFIX}GET_LEADERBOARD`;
export const GET_LEADERBOARD_SUCCESS = `${PREFIX}GET_LEADERBOARD_SUCCESS`;
export const SET_MANUAL_CORRECTION = `${PREFIX}SET_MANUAL_CORRECTION`;
export const GET_MANUAL_CORRECTION_BY_ID = `${PREFIX}GET_MANUAL_CORRECTION_BY_ID`;
export const GET_MANUAL_CORRECTION_BY_ID_SUCCESS = `${PREFIX}GET_MANUAL_CORRECTION_BY_ID_SUCCESS`;
export const SET_CALCULATION_STATUS = `${PREFIX}SET_CALCULATION_STATUS`;
export const GET_UPDATED_STATUSES = `${PREFIX}GET_UPDATED_STATUSES`;
export const GET_UPDATED_STATUSES_SUCCESS = `${PREFIX}GET_UPDATED_STATUSES_SUCCESS`;
export const GET_UPDATED_STATUSES_BY_ID = `${PREFIX}GET_UPDATED_STATUSES_BY_ID`;
export const GET_UPDATED_STATUSES_BY_ID_SUCCESS = `${PREFIX}GET_UPDATED_STATUSES_BY_ID_SUCCESS`;
export const SET_CURRENT_TAB = `${PREFIX}SET_CURRENT_TAB`;
export const EDIT_TOTAL_PAGES = `${PREFIX}EDIT_TOTAL_PAGES`;
export const UPDATE_STATUSES_ON_STATE = `${PREFIX}UPDATE_STATUSES_ON_STATE`;
export const UPDATE_STATUSES_ON_STATE_FINISHED = `${PREFIX}UPDATE_STATUSES_ON_STATE_FINISHED`;
export const DELETE_MANUAL_CORRECTION = `${PREFIX}DELETE_MANUAL_CORRECTION`;
export const EDIT_MANUAL_CORRECTION = `${PREFIX}EDIT_MANUAL_CORRECTION`;
export const EXPORT_CALCULATION = `${PREFIX}EXPORT_CALCULATION`;
export const GET_CLIENT_STATISTICS = `${PREFIX}GET_CLIENT_STATISTICS`;
export const GET_CLIENT_STATISTICS_SUCCESS = `${PREFIX}GET_CLIENT_STATISTICS_SUCCESS`;
export const GET_EMPLOYEE_ROLE = `${PREFIX}GET_EMPLOYEE_ROLE`;
export const GET_EMPLOYEE_ROLE_SUCCESS = `${PREFIX}GET_EMPLOYEE_ROLE_SUCCESS`;
export const GET_LEADERBOARD_EMPLOYEES = `${PREFIX}GET_LEADERBOARD_EMPLOYEES`;
export const GET_LEADERBOARD_EMPLOYEES_SUCCESS = `${PREFIX}GET_LEADERBOARD_EMPLOYEES_SUCCESS`;
export const GET_CHART_BY_CLIENT = `${PREFIX}GET_CHART_BY_CLIENT`;
export const GET_CHART_BY_CLIENT_SUCCESS = `${PREFIX}GET_CHART_BY_CLIENT_SUCCESS`;
export const SELECT_CLIENT = `${PREFIX}SELECT_CLIENT`;
export const SET_STATUSES_LOADER = `${PREFIX}SET_STATUSES_LOADER`;
export const SET_ORDERS_STATUS_IN_PROGRESS = `${PREFIX}SET_ORDERS_STATUS_IN_PROGRESS`;
export const GET_ORDERS_COUNT = `${PREFIX}GET_ORDERS_COUNT`;
export const SET_LEADERBOARD_LOADING = `${PREFIX}SET_LEADERBOARD_LOADING`;
export const SET_EXPORT_STATUS = `${PREFIX}SET_EXPORT_STATUS`;
export const SET_REVENUE_LOADING = `${PREFIX}SET_REVENUE_LOADING`;
export const SET_GRAPH_LOADING = `${PREFIX}SET_GRAPH_LOADING`;
export const SET_STATS_LOADING = `${PREFIX}SET_STATS_LOADING`;

const _state = {
	dates: {
		toDate: moment().valueOf(),
		fromDate: moment().add(-30, "days").valueOf(),
	},
	manualCorrection: {
		price: "",
		description: "",
	},
	orders: [],
	orderGraphs: {},
	loading: false,
	loadingChart: false,
	singleOrder: {},
	editOrder: {},
	page: 1,
	size: 30,
	totalSize: 5,
	totalPages: 1,
	filters: {
		fromDate: null,
		toDate: null,
		search: "",
		clientId: null,
		status: null,
		teamId: null,
		agencyId: null,
		locationId: null,
		clientPayoutModel: null,
	},
	query: {
		search: "",
		fromDate: null,
		toDate: null,
		clientId: null,
		teamId: null,
		agencyId: null,
		locationId: null,
		isExactSearch: false,
		projectCategoryId: null,
		userIds: [],
		salesOrganizationId: null,
		clientPayoutModel: null,
	},
	revenues: [],
	userStats: {},
	pdfsZip: [],
	topStats: {},
	infoChefOrders: [],
	leaderboard: [],
	updatedStatuses: [],
	updatedStatus: {},
	currentTab: i18n.t("orders"),
	downloadLoading: false,
	clientStatistics: [],
	employeeRole: {},
	leaderboardEmployees: [],
	chartByClient: {},
	selectedClient: {
		id: null,
		clientId: null,
	},
	statusesLoader: false,
	leaderboardLoading: false,
	exportStatus: "",
	revenueLoading: false,
	graphLoading: false,
	statsLoading: false,
};
const timezone = new Date().getTimezoneOffset();

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case EDIT_PAGE:
				draft.page = action.payload;
				break;
			case EDIT_TOTAL_PAGES:
				draft.totalPages = action.payload;
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = action.payload;
				break;
			case SIZE_EDIT:
				draft.size = action.payload;

				break;
			case FETCH_ORDERS_SUCCESS:
				if (state.page !== 1) {
					draft.orders = [...state?.orders, ...action?.payload];
				} else {
					draft.orders = action.payload;
				}
				break;
			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.filters[action.payload?.name] = action.payload.value;
				break;

			case ADD_ORDER_ON_STATE:
				draft.orders = [action.payload].concat(state.orders);
				break;

			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case TOTAL_SIZE_INCREASE:
				draft.totalSize = state.totalSize + 1;
				break;
			case GET_ORDER_PREVIEW_SUCCESS:
				draft.singleOrder = action.payload.singleOrder;
				break;
			case GET_SINGLE_ORDER__SUCCESS:
				draft.singleOrder = action.payload.pdf;
				downloadPDF(action.payload.pdfFile);
				draft.pdfFile = `data:application/pdf;base64,${action.payload.pdfFile}`;
				break;
			case DOWNLOAD_ORDER_LOADING:
				draft.downloadLoading = action.payload;
				break;
			case CLEAR_SINGLE_ORDER:
				draft.singleOrder = {};
				break;
			case SET_QUERY:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.query = action.payload;
				break;
			case CLEAN_QUERY:
				draft.query = {
					search: "",
					fromDate: null,
					toDate: null,
					clientId: null,
					status: action?.payload === "all" ? null : action.payload,
					teamId: null,
					agencyId: null,
					locationId: null,
					isExactSearch: false,
					salesOrganizationId: null,
					clientPayoutModel: null,
				};
				break;
			case CHANGE_ORDER_STATUS_SUCCESS:
				const arr = [];

				// eslint-disable-next-line no-unused-expressions
				state?.orders?.map((e) => {
					const tableOrderID = action?.payload?.orders?.find(
						(item) => e?.orderId === item?.orderId,
					);
					if (tableOrderID)
						arr.push({
							...tableOrderID,
							tableData: {id: tableOrderID?.tableData?.id, checked: false},
							orderStatus: action?.payload?.status,
						});
					else arr.push(e);
					return arr;
				});
				draft.orders = arr;

				break;
			case GET_SINGLE_ORDER_GRAPHS_SUCCESS:
				draft.orderGraphs = action.payload;
				break;
			case GET_REVENUES_SUCCESS:
				draft.revenues = action.payload;
				break;
			case GET_USER_STATS_SUCCESS:
				draft.userStats = action.payload;
				break;
			case GET_TOP_STATS_SUCCESS:
				draft.topStats = action.payload;
				break;
			case GET_INFOCHEF_ORDERS_SUCCESS:
				draft.infoChefOrders = action.payload;
				break;
			case GET_LEADERBOARD_SUCCESS:
				const sortedByContracts = action?.payload?.payload.sort(
					(a, b) => b.points - a.points,
				);
				const array = sortedByContracts.map((item, index) => ({
					...item,
					nr: index + 1,
					active: item?.userId === action?.payload?.userInfo?.id,
				}));
				draft.leaderboard = array;
				break;
			case GET_MANUAL_CORRECTION_BY_ID_SUCCESS:
				if (action.payload !== null) {
					draft.manualCorrection = action.payload;
				} else {
					draft.manualCorrection = {
						price: "",
						description: "",
					};
				}
				break;
			case GET_UPDATED_STATUSES_SUCCESS:
				draft.updatedStatuses = action.payload;
				break;
			case UPDATE_STATUSES_ON_STATE:
				draft.updatedStatuses[0] = {
					...state?.updatedStatuses?.[0],
					numOfOrdersProcessing: action?.payload?.numOfOrdersProcessing,
				};
				break;
			case UPDATE_STATUSES_ON_STATE_FINISHED:
				draft.updatedStatuses[0] = {
					...state?.updatedStatuses?.[0],
					progressStatus: action?.payload?.progressStatus,
					numOfOrdersProcessing: action?.payload?.numOfOrdersProcessing,
					updatedAt: action?.payload?.updatedAt,
				};
				break;
			case GET_UPDATED_STATUSES_BY_ID_SUCCESS:
				draft.updatedStatus = action.payload;
				break;
			case SET_CURRENT_TAB:
				draft.currentTab = action.payload;
				break;
			case GET_CLIENT_STATISTICS_SUCCESS:
				draft.clientStatistics = action.payload;
				break;
			case GET_EMPLOYEE_ROLE_SUCCESS:
				draft.employeeRole = action.payload;
				break;
			case GET_LEADERBOARD_EMPLOYEES_SUCCESS:
				draft.leaderboardEmployees = action.payload.payload;
				break;
			case GET_CHART_BY_CLIENT_SUCCESS:
				draft.chartByClient = action.payload;
				break;
			case SELECT_CLIENT:
				draft.selectedClient = action.payload;
				break;
			case SET_LOADING_CHART:
				draft.loadingChart = action.payload;
				break;
			case SET_STATUSES_LOADER:
				draft.statusesLoader = action.payload;
				break;
			case SET_ORDERS_STATUS_IN_PROGRESS:
				const payloadIds = action.payload.map((it) => it.id);
				const newOrdersArray = state.orders.map((order) => {
					if (payloadIds?.includes(order?.orderId)) {
						return {
							...order,
							orderStatus: "IN_PROGRESS",
							statusName: i18n.t("inProgress"),
						};
					}
					return order;
				});
				draft.orders = newOrdersArray;
				break;
			case SET_LEADERBOARD_LOADING:
				draft.leaderboardLoading = action.payload;
				break;
			case EDIT_ORDER_ON_STATE:
				const {orderId, tariffName} = action.payload;
				const newOrders = [...state.orders]?.map((item) => {
					if (item.orderId === orderId) {
						return {
							...item,
							tariffName,
						};
					}
					return item;
				});
				draft.orders = newOrders;
				break;
			case SET_EXPORT_STATUS:
				draft.exportStatus = action.payload;
				break;
			case SET_REVENUE_LOADING:
				draft.revenueLoading = action.payload;
				break;
			case SET_GRAPH_LOADING:
				draft.graphLoading = action.payload;
				break;
			case SET_STATS_LOADING:
				draft.statsLoading = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export function downloadPDF(pdf, data) {
	// OLD DOWNLOAD SINGLE PDF FUNCTION
	// const fileName = `${pdf?.fileName}.pdf`;
	// const linkSource = pdf.pdfUrl;
	// const downloadLink = document.createElement("a");
	// downloadLink.href = linkSource;
	// downloadLink.download = fileName;
	// downloadLink.click();
	const mergedName = `${data?.externalId}_${pdf.fileName}`;
	axios
		.get(pdf.pdfUrl, {
			responseType: "blob",
		})
		.then((response) => {
			const a = document.createElement("a");
			const url = window.URL.createObjectURL(response.data);
			a.href = url;
			a.download = data?.externalId !== null ? mergedName : pdf.fileName;
			a.click();
		})
		.catch((err) => {
			// console.log("error", err);
		});
}

export const actions = {
	getOrderById: (payload) => createAction(GET_SINGLE_ORDER, {payload}),
	getOrderPreview: (payload) => createAction(GET_ORDER_PREVIEW, {payload}),
	getOrderPreviewSuccess: (payload) => createAction(GET_ORDER_PREVIEW_SUCCESS, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	getOrders: (payload) => createAction(GET_ORDERS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setLoadingChart: (payload) => createAction(SET_LOADING_CHART, {payload}),
	fetchOrdersSuccess: (payload) => createAction(FETCH_ORDERS_SUCCESS, {payload}),
	addOrderOnState: (payload) => createAction(ADD_ORDER_ON_STATE, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	filterOrders: (payload) => createAction(FILTERS, {payload}),
	setQuery: (payload) => createAction(SET_QUERY, {payload}),
	cleanQuery: (payload) => createAction(CLEAN_QUERY, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	editTotalPage: (payload) => createAction(EDIT_TOTAL_PAGES, {payload}),
	totalSizeIncrease: (payload) => createAction(TOTAL_SIZE_INCREASE, {payload}),
	getSingleOrderSuccess: (payload) => createAction(GET_SINGLE_ORDER__SUCCESS, {payload}),
	clearSingleOrder: (payload) => createAction(CLEAR_SINGLE_ORDER, {payload}),
	getOrdersGraphs: (payload) => createAction(GET_ORDER_GRAPHS, {payload}),
	getOrdersGraphsSuccess: (payload) => createAction(GET_SINGLE_ORDER_GRAPHS_SUCCESS, {payload}),
	getTopStats: (payload) => createAction(GET_TOP_STATS, {payload}),
	getTopStatsSuccess: (payload) => createAction(GET_TOP_STATS_SUCCESS, {payload}),
	getRevenues: (payload) => createAction(GET_REVENUES, {payload}),
	getRevenuesSuccess: (payload) => createAction(GET_REVENUES_SUCCESS, {payload}),
	getUserStats: (payload) => createAction(GET_USER_STATS, {payload}),
	getUserStatsSuccess: (payload) => createAction(GET_USER_STATS_SUCCESS, {payload}),
	changeOrderStatusSuccess: (payload) => createAction(CHANGE_ORDER_STATUS_SUCCESS, {payload}),
	downloadOrderById: (payload) => createAction(DOWNLOAD_ORDER, {payload}),
	downloadOrderByIdLoading: (payload) => createAction(DOWNLOAD_ORDER_LOADING, {payload}),
	setOrderPaidDate: (payload) => createAction(SET_ORDER_PAID_DATE, {payload}),
	getInfoChefOrders: (payload) => createAction(GET_INFOCHEF_ORDERS, {payload}),
	getInfoChefOrderSuccess: (payload) => createAction(GET_INFOCHEF_ORDERS_SUCCESS, {payload}),
	getLeaderboard: (payload) => createAction(GET_LEADERBOARD, {payload}),
	getLeaderboardSuccess: (payload) => createAction(GET_LEADERBOARD_SUCCESS, {payload}),
	setManualCorrection: (payload) => createAction(SET_MANUAL_CORRECTION, {payload}),
	getManualCorrectionById: (payload) => createAction(GET_MANUAL_CORRECTION_BY_ID, {payload}),
	getManualCorrectionByIdSuccess: (payload) =>
		createAction(GET_MANUAL_CORRECTION_BY_ID_SUCCESS, {payload}),

	setCalculationStatus: (payload) => createAction(SET_CALCULATION_STATUS, {payload}),
	getUpdatedStatuses: (payload) => createAction(GET_UPDATED_STATUSES, {payload}),
	getUpdatedStatusesSuccess: (payload) => createAction(GET_UPDATED_STATUSES_SUCCESS, {payload}),
	getUpdatedStatusesById: (payload) => createAction(GET_UPDATED_STATUSES_BY_ID, {payload}),
	getUpdatedStatusesByIdSuccess: (payload) =>
		createAction(GET_UPDATED_STATUSES_BY_ID_SUCCESS, {payload}),
	setCurrentTab: (payload) => createAction(SET_CURRENT_TAB, {payload}),
	updateStatusesOnState: (payload) => createAction(UPDATE_STATUSES_ON_STATE, {payload}),
	updateStatusesOnStateFINISHED: (payload) =>
		createAction(UPDATE_STATUSES_ON_STATE_FINISHED, {payload}),
	deleteManualCorrection: (payload) => createAction(DELETE_MANUAL_CORRECTION, {payload}),
	editManualCorrection: (payload) => createAction(EDIT_MANUAL_CORRECTION, {payload}),
	exportCalculations: (payload) => createAction(EXPORT_CALCULATION, {payload}),
	getClientStatistics: (payload) => createAction(GET_CLIENT_STATISTICS, {payload}),
	getClientStatisticsSuccess: (payload) => createAction(GET_CLIENT_STATISTICS_SUCCESS, {payload}),
	getEmpolyeeRole: (payload) => createAction(GET_EMPLOYEE_ROLE, {payload}),
	getEmpolyeeRoleSuccess: (payload) => createAction(GET_EMPLOYEE_ROLE_SUCCESS, {payload}),
	getLeaderboardEmployees: (payload) => createAction(GET_LEADERBOARD_EMPLOYEES, {payload}),
	getLeaderboardEmployeesSuccess: (payload) =>
		createAction(GET_LEADERBOARD_EMPLOYEES_SUCCESS, {payload}),
	getChartByClient: (payload) => createAction(GET_CHART_BY_CLIENT, {payload}),
	getChartByClientSuccess: (payload) => createAction(GET_CHART_BY_CLIENT_SUCCESS, {payload}),
	setSelectedClient: (payload) => createAction(SELECT_CLIENT, {payload}),
	setStatusesLoader: (payload) => createAction(SET_STATUSES_LOADER, {payload}),
	setOrdersStatusInProgress: (payload) => createAction(SET_ORDERS_STATUS_IN_PROGRESS, {payload}),
	getOrdersCount: (payload) => createAction(GET_ORDERS_COUNT, {payload}),
	setLeaderBoardLoading: (payload) => createAction(SET_LEADERBOARD_LOADING, {payload}),
	editOrderOnState: (payload) => createAction(EDIT_ORDER_ON_STATE, {payload}),
	setExportStatus: (payload) => createAction(SET_EXPORT_STATUS, {payload}),
	setRevenueLoading: (payload) => createAction(SET_REVENUE_LOADING, {payload}),
	setGraphLoading: (payload) => createAction(SET_GRAPH_LOADING, {payload}),
	setStatsLoading: (payload) => createAction(SET_STATS_LOADING, {payload}),
};
const downloadAsZip = (pdfsZip, filteredData, id = null) => {
	const obj = {
		files: [],
		names: [],
	};
	obj.files = pdfsZip.map((item) => item.pdfUrl);
	obj.names = pdfsZip.map((item, index) => `${item.fileName} ${index}`);
	const folder = zip.folder("Order PDFs");
	obj.files.forEach((url, index) => {
		const blobPromise = fetch(url).then((r) => {
			if (r.status === 200) return r.blob();
			return Promise.reject(new Error(r.statusText));
		});
		const name = `${obj.names[index]}.pdf`;
		folder.file(name, blobPromise);
	});
	zip.generateAsync({type: "blob"}).then((blob) => saveAs(blob, "Order PDFs"));
	zip.files = {};
};

export const sagas = {
	*getOrders({payload}) {
		yield put(actions.setLoading(true));
		const {size, page, query} = yield select((state) => state.app.orders.index);
		try {
			const response = yield axios.post(
				`/order/all?page=${payload?.dashboardPage ? 1 : page}&size=${
					payload?.dashboardPage ? 30 : size
				}`,
				buildPayload(payload, query),
			);
			yield put(actions.fetchOrdersSuccess(response?.data?.data?.data));
			yield put(actions.getOrdersCount(payload));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getOrdersCount({payload}) {
		yield put(actions.setLoading(true));
		const {size, page, query} = yield select((state) => state.app.orders.index);
		try {
			const {data} = yield axios.post(
				`/order/all-count?page=${payload?.dashboardPage ? 1 : page}&size=${
					payload?.dashboardPage ? 30 : size
				}`,
				buildPayload(payload, query),
			);
			yield put(actions.editTotalPage(data?.data?.totalPages));
			yield put(actions.editTotalSize(data?.data?.totalSize));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getInfoChefOrders() {
		try {
			yield put(actions.setLoading(true));
			const response = yield axios.post("/order/info/chef");
			yield put(actions.getInfoChefOrderSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getOrderPreview(id) {
		try {
			yield put(actions.setLoading(true));
			const response = yield axios.post(`/order/${id.payload}`);
			yield put(
				actions.getOrderPreviewSuccess({
					singleOrder: response.data.data,
				}),
			);
			const {data} = response.data;
			yield put(editActions.getOrder(data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getOrderById(id) {
		try {
			const response = yield axios.post(`/order/${id.payload}`);
			const responsePdf = yield axios.get(`/order/download/${id.payload}`);
			yield put(
				actions.getSingleOrderSuccess({
					pdf: response.data.data,
					pdfFile: responsePdf.data.data,
				}),
			);
		} catch (error) {
			logger.error(error);
		}
	},
	*setOrderPaidDate({payload}) {
		try {
			yield axios.put("/order/paid-date", {
				date: payload.date,
				uuid: payload.uuid,
			});
			ToastSuccesComponent(i18n.t("SetdateorderSuccess"));
		} catch (error) {
			logger.error(error);
			ToastErrorComponent(i18n.t("Setdateordererror"));
		}
	},
	*setCalculationStatus({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {uuids, publishTime, onSuccess, change, getAll, billingMonthId} = payload;
			const response = yield axios.put(`/manual-correction/publish`, {
				uuids,
				publishTime,
				change,
			});
			if (response?.data?.status === 200) {
				if (getAll) {
					yield put(calculationActions.getCalculations({billingMonthId}));
				} else {
					yield put(calculationActions.getCalculationById(uuids[0]));
				}
				onSuccess();
			}
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*setManualCorrection({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.post("/manual-correction/create", {
				orderId: payload.orderId && payload.orderId,
				price: payload.price,
				description: payload.description,
				settledUuid: payload.settledUuid && payload.settledUuid,
				fileId: payload?.fileId,
			});
			if (response?.status === 200 && payload.settledUuid) {
				yield put(calculationActions.getCalculationById({uuid: payload?.settledUuid}));
			}
			if (payload.setModal) {
				payload.setModal(false);
			}
			ToastSuccesComponent(i18n.t("SetPriceSuccess"));
		} catch (error) {
			logger.error(error);
			ToastErrorComponent(i18n.t("SetPriceError"));
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*deleteManualCorrection({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {id} = payload;
			yield axios.delete(`/manual-correction/${id}`);
			yield put(calculationActions.deleteManualCorrectionOnState(id));
			ToastSuccesComponent(i18n.t("PriceDeleted"));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*editManualCorrection({payload}) {
		yield put(actions.setLoading(true));
		try {
			yield axios.put(`/manual-correction/${payload?.manualCorrectionId}`, payload?.values);
			yield put(calculationActions.getCalculationById(payload?.uuid));
			if (payload.setModal) {
				payload.setModal(false);
			}
			ToastSuccesComponent(i18n.t("PriceEdited"));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getManualCorrectionById({payload}) {
		try {
			const response = yield axios.post("/manual-correction/get", {
				orderId: payload.orderId && payload.orderId,
				settledUuid: payload.settledUuid && payload.settledUuid,
			});
			yield put(actions.getManualCorrectionByIdSuccess(response.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*downloadOrderById({payload}) {
		const {data, filteredData, type, pdfs} = payload;
		try {
			if (type === "single") {
				yield put(actions.downloadOrderByIdLoading(true));
				const responsePdf = yield axios.post(`/order/download`, {
					orderId: data?.orderId,
					orderIds: null,
					pdfs: null,
				});
				// downloadPDF(responsePdf?.data?.data?.[0], data);
				const pdfsZip = responsePdf?.data?.data;
				pdfsZip.forEach((element, idx) => {
					downloadPDF(element, data);
				});
			} else if (type === "multiple") {
				yield put(actions.downloadOrderByIdLoading(true));
				const ids = [];
				filteredData.map((e) => ids.push(e.orderId));
				const responsePdf = yield axios.post(`/order/download/ids`, {
					orderId: null,
					orderIds: ids,
					pdfs: null,
				});
				const pdfsZip = responsePdf?.data?.data;
				downloadAsZip(pdfsZip, filteredData);
			} else if (type === "homeNetMultiplePdf") {
				yield put(actions.downloadOrderByIdLoading(true));
				const responsePdf = yield axios.post(`/order/download`, {
					orderId: data?.orderId,
					orderIds: null,
					pdfs,
				});
				const pdfsZip = responsePdf?.data?.data;
				pdfsZip.forEach((element, idx) => {
					downloadPDF(element, data);
				});
				// downloadAsZip(pdfsZip, null, data?.orderId);
			}
		} catch (error) {
			ToastErrorComponent(i18n.t("FaildToDownload"));
			logger.error(error);
		} finally {
			yield put(actions.downloadOrderByIdLoading(false));
		}
	},
	*getRevenues() {
		yield put(actions.setRevenueLoading(true));
		const {dates} = yield select((state) => state.app.orders.index);
		const {query} = yield select((state) => state.app.orders.index);
		try {
			const response = yield axios.post(`/order/revenue_sales`, {
				search: query?.search,
				fromDate: query?.fromDate
					? moment(query.fromDate).startOf("day").subtract(timezone, "minutes").valueOf()
					: dates.fromDate,
				toDate: query?.toDate
					? moment(query.toDate).endOf("day").subtract(timezone, "minutes").valueOf()
					: dates.toDate,
			});
			yield put(actions.getRevenuesSuccess(response.data.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setRevenueLoading(false));
		}
	},
	*getOrdersGraphs({payload}) {
		yield put(actions.setGraphLoading(true));
		const {dates} = yield select((state) => state.app.orders.index);
		const {query} = yield select((state) => state.app.orders.index);
		try {
			const response = yield axios.post(`/order/graph/all`, {
				search: query?.search,
				interval: payload.type.toUpperCase(),
				fromDate: payload?.dates?.from
					? moment(payload?.dates?.from)
							.startOf(payload.active === "custom" ? "day" : payload.type)
							.subtract(timezone, "minutes")
							.valueOf()
					: dates.fromDate,
				toDate: payload?.dates?.to
					? moment(payload?.dates?.to)
							.endOf(payload.active === "custom" ? "day" : payload.type)
							.subtract(timezone, "minutes")
							.valueOf()
					: dates.toDate,
			});
			yield put(actions.getOrdersGraphsSuccess(response.data.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setGraphLoading(false));
		}
	},
	*getUserStats() {
		yield put(actions.setLoading(true));
		const {dates} = yield select((state) => state.app.orders.index);
		const {query} = yield select((state) => state.app.orders.index);
		try {
			const response = yield axios.post(`/order/all/client/role`, {
				fromDate: query?.fromDate
					? moment(query.fromDate).startOf("day").subtract(timezone, "minutes").valueOf()
					: dates.fromDate,
				toDate: query?.toDate
					? moment(query.toDate).endOf("day").subtract(timezone, "minutes").valueOf()
					: dates.toDate,
			});
			yield put(actions.getUserStatsSuccess(response.data.data));
			yield put(actions.getOrdersGraphsSuccess(response.data.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getTopStats({payload}) {
		yield put(actions.setStatsLoading(true));
		const {dates} = yield select((state) => state.app.orders.index);
		const {query} = yield select((state) => state.app.orders.index);
		try {
			const response = yield axios.post(`/order/top_statistics`, {
				search: query?.search,
				fromDate: query?.fromDate
					? moment(query.fromDate).startOf("day").subtract(timezone, "minutes").valueOf()
					: dates.fromDate,
				toDate: query?.toDate
					? moment(query.toDate).endOf("day").subtract(timezone, "minutes").valueOf()
					: dates.toDate,
			});
			yield put(actions.getTopStatsSuccess(response.data.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setStatsLoading(false));
		}
	},
	*getLeaderboard() {
		yield put(actions.setLeaderBoardLoading(true));
		const {dates} = yield select((state) => state.app.orders.index);
		const {query} = yield select((state) => state.app.orders.index);
		const user = getCurrentUser();
		try {
			const response = yield axios.post(`/dashboard/leaderboard`, {
				// search: query?.search,
				from: query?.fromDate
					? moment(query.fromDate).startOf("day").subtract(timezone, "minutes").valueOf()
					: dates.fromDate,
				to: query?.toDate
					? moment(query.toDate).endOf("day").subtract(timezone, "minutes").valueOf()
					: dates.toDate,
			});
			yield put(
				actions.getLeaderboardSuccess({payload: response?.data?.data, userInfo: user}),
			);
			yield put(actions.setLeaderBoardLoading(false));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLeaderBoardLoading(false));
		}
	},
	*getUpdatedStatuses({payload}) {
		yield put(actions.setStatusesLoader(true));
		try {
			const response = yield axios.post(`/order/all/updatedStatus`, {
				changeStatusType: payload?.changeStatusType ? payload?.changeStatusType : "NORMAL",
			});
			yield put(actions.getUpdatedStatusesSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setStatusesLoader(false));
		}
	},
	*getUpdatedStatusesById({payload}) {
		try {
			const response = yield axios.get(`/order/all/updatedStatus/${payload}`);
			yield put(actions.getUpdatedStatusesByIdSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*exportCalculations({payload}) {
		yield put(actions.setLoading(true));
		ToastInfoComponent(i18n.t("DownloadInProcess"));
		try {
			const fromDate = payload?.billingMonth?.id
				? null
				: moment(payload.date).startOf("month").utcOffset(0, true).valueOf();
			const toDate = payload?.billingMonth?.id
				? null
				: moment(payload.date).endOf("month").valueOf();

			const obj = {
				fromDate,
				toDate,
				billingMonthId: payload?.billingMonth?.id || null,
			};
			const response = yield axios.post(ENDPOINT.EXPORT_SETTLEMENTS, obj);
			const {data} = response?.data;
			if (data?.[0]) {
				generateAndDownloadExcel(obj, data, payload?.billingMonth);
				ToastSuccesComponent(i18n.t("DownloadDone"));
			} else {
				ToastInfoComponent(i18n.t("NoDateForThisPeriod"));
			}
		} catch (error) {
			logger.error(error);
			ToastErrorComponent(i18n.t("DownloadFailed"));
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getClientStatistics({payload}) {
		try {
			yield put(actions.setLoadingChart(true));
			const response = yield axios.post(ENDPOINT.GET_CLIENT_STATISTICS, payload);
			yield put(actions.getClientStatisticsSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoadingChart(false));
		}
	},
	*getEmpolyeeRole({payload}) {
		try {
			const response = yield axios.post(ENDPOINT.GET_EMPLOYEE_ROLE, payload);
			yield put(actions.getEmpolyeeRoleSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*getLeaderboardEmployees() {
		yield put(actions.setLoading(true));
		const {dates} = yield select((state) => state.app.orders.index);
		const {query} = yield select((state) => state.app.orders.index);
		const user = getCurrentUser();
		try {
			const response = yield axios.post(`/dashboard/leaderboard/employees`, {
				// search: query?.search,
				from: query?.fromDate
					? moment(query.fromDate).startOf("day").subtract(timezone, "minutes").valueOf()
					: dates.fromDate,
				to: query?.toDate
					? moment(query.toDate).endOf("day").subtract(timezone, "minutes").valueOf()
					: dates.toDate,
			});
			yield put(
				actions.getLeaderboardEmployeesSuccess({
					payload: response?.data?.data,
					userInfo: user,
				}),
			);
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getChartByClient({payload}) {
		yield put(actions.setLoading(true));
		const {dates} = yield select((state) => state.app.orders.index);
		const {query} = yield select((state) => state.app.orders.index);
		try {
			const response = yield axios.post(`/order/graph/clients`, {
				search: query?.search,
				interval: payload.type.toUpperCase(),
				fromDate: payload?.dates?.from
					? moment(payload?.dates?.from)
							.startOf(payload.active === "custom" ? "day" : payload.type)
							.subtract(timezone, "minutes")
							.valueOf()
					: dates.fromDate,
				toDate: payload?.dates?.to
					? moment(payload?.dates?.to)
							.endOf(payload.active === "custom" ? "day" : payload.type)
							.subtract(timezone, "minutes")
							.valueOf()
					: dates.toDate,
				clientId: payload?.id,
			});
			yield put(actions.getOrdersGraphsSuccess(response.data.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_ORDERS, sagas.getOrders);
	yield takeLatest(GET_SINGLE_ORDER, sagas.getOrderById);
	yield takeLatest(DOWNLOAD_ORDER, sagas.downloadOrderById);
	yield takeLatest(GET_ORDER_GRAPHS, sagas.getOrdersGraphs);
	yield takeLatest(GET_USER_STATS, sagas.getUserStats);
	yield takeLatest(GET_TOP_STATS, sagas.getTopStats);
	yield takeLatest(GET_REVENUES, sagas.getRevenues);
	yield takeLatest(GET_ORDER_PREVIEW, sagas.getOrderPreview);
	yield takeLatest(SET_ORDER_PAID_DATE, sagas.setOrderPaidDate);
	yield takeLatest(GET_INFOCHEF_ORDERS, sagas.getInfoChefOrders);
	yield takeLatest(GET_LEADERBOARD, sagas.getLeaderboard);
	yield takeLatest(SET_MANUAL_CORRECTION, sagas.setManualCorrection);
	yield takeLatest(GET_MANUAL_CORRECTION_BY_ID, sagas.getManualCorrectionById);
	yield takeLatest(SET_CALCULATION_STATUS, sagas.setCalculationStatus);
	yield takeLatest(GET_UPDATED_STATUSES, sagas.getUpdatedStatuses);
	yield takeLatest(GET_UPDATED_STATUSES_BY_ID, sagas.getUpdatedStatusesById);
	yield takeLatest(DELETE_MANUAL_CORRECTION, sagas.deleteManualCorrection);
	yield takeLatest(EDIT_MANUAL_CORRECTION, sagas.editManualCorrection);
	yield takeLatest(EXPORT_CALCULATION, sagas.exportCalculations);
	yield takeLatest(GET_CLIENT_STATISTICS, sagas.getClientStatistics);
	yield takeLatest(GET_EMPLOYEE_ROLE, sagas.getEmpolyeeRole);
	yield takeLatest(GET_LEADERBOARD_EMPLOYEES, sagas.getLeaderboardEmployees);
	yield takeLatest(GET_CHART_BY_CLIENT, sagas.getChartByClient);
	yield takeLatest(GET_ORDERS_COUNT, sagas.getOrdersCount);
};
