/* eslint-disable max-len */
import CreateEditOrder from "@components/dashboard/orders/createEditOrder";
import React, {useEffect, useMemo, useRef, useState} from "react";
import _ from "lodash";
import BigSearchBar from "@common/bigSearchBar/BigSearchBar";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as orderActions} from "@sagas/orders";
import {actions as editOrderActions} from "@sagas/orders/editOrder";
import {actions as createOrderActions} from "@sagas/orders/create";
import {actions as teamActions} from "@sagas/teams";
import {useTranslation} from "react-i18next";
import {actions as tariffActios} from "@sagas/tariff";
import {actions as clientActions} from "@sagas/client";
import {actions as projectCategoriesActions} from "@sagas/projectCategories/clientCategory";
import {actions as categoryBonusesActions} from "@sagas/projectCategories/bonuses";
import {actions as locationActions} from "@sagas/locations";
import {actions as billingMonthsActions} from "@sagas/calculations/billingMonths";
import {actions as usersActions} from "@sagas/users/users/index";
import moment from "moment";
import Modal from "@common/modal";
import {ToastErrorComponent, ToastInfoComponent} from "@common/ToastComponent/ToastComponent";
import {actions as navigation} from "@sagas/navigation/";
import calculationsLogo from "@assets/img/calculations-logo.svg";
import notCalculationsLogo from "@assets/img/notCalculations-logo.svg";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import HtmlTooltip from "@common/htmlTooltip";
import {checkRoles} from "@utils/checkRoles";
import {getSelectOptionsFromDataTeams} from "@utils/select";
import {
	CancelledIcon,
	CheckLogo,
	DownloadRowIcon,
	EditRowIcon,
	EuroSign,
	OrdersIcon,
	PreviewRowIcon,
	ThreeDotsIcon,
} from "@assets/SvgComponents";
import Tabs from "@common/components/tabs";
import Button from "@common/components/button";
import {Dropdown, Popover} from "antd";
import DataGridTable from "@src/common/table/DataGridTable";
import {useUrlParams} from "@src/utils/useUrlParams";
import {getEndpointBasedOnVersion} from "@src/utils/getEndpointBasedOnVersion";
import {actions as salesOrgActions} from "@sagas/salesOrganizations/index";
import Form from "@src/common/Form";
import {actions as orderStatusAction} from "@sagas/orders/statuses";
import {Autocomplete} from "@material-ui/lab";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import TextField from "@material-ui/core/TextField";
import {ENDPOINT} from "@src/utils/endpoint";
import {useFeatures} from "@src/utils/useFeatures";
import {buildPayload} from "@src/store/sagas/app/orders/utils";
import axios from "axios";
import TableSkeleton from "@src/common/table/TableSkeleton";
import downloadIcon from "../../../assets/img/downloadicon.svg";
import {
	filteringStatusOptions,
	infoChefColumns,
	columns,
	updatedStatusesColumns,
	tableTabs,
	userRolesDropdown,
	statusOptions,
} from "./staticData";
import EditAndCreateOrders from "./EditAndCreateOrders";
import loadinLottie from "../../../assets/loadingLottie2.json";
import SettleOrders from "./SettleOrders";
import InformationTooltip from "./components/InformationTooltip";
import Table from "./Table";
import CreateStatusActions from "../sales-tracker/components/leadStatuses/CreateStatusActions";
import PriceForm from "./components/PriceForm";
import {PAYOUT_MODELS} from "../clients/clientElements";

export function getNestedValue(obj, key, splitBy = ".") {
	return key?.split(splitBy)?.reduce((result, key) => result?.[key], obj);
}

const Orders = (props) => {
	const {
		getOrders,
		orders,
		setShowModal,
		showModal,
		fetchTariffsByDropdown,
		fetchProjectCategoriesByDropdown,
		size,
		editSize,
		page,
		editPage,
		totalSize,
		fetchClientsByDrowdown,
		changeOrdersStatus,
		navigate,
		optionsClients,
		downloadLoading,
		downloadOrderById,
		getOrderPreview,
		toggleEditOrderModal,
		showEditModal,
		getTeams,
		agencies,
		teams,
		sagaQuery,
		getAgencies,
		getInfoChefOrders,
		infoChefOrders,
		locations,
		fetchLocations,
		editLocationSize,
		updatedStatuses,
		getUpdatedStatuses,
		editTeamSize,
		statusesLoading,
		optionsProjectCategories,
		getBillingMonths,
		getUserDropdown,
		usersOptions,
		showTimeline,
		getBonuses,
		allSalesOrganizations,
		getAllSalesOrganizations,
		createStatus,
		getOrderStatuses,
		editStatus,
		orderStatusLoading,
		loggedUser,
		setExportStatus,
	} = props;

	const {t} = useTranslation();
	const [selectedPds, setSelectedPds] = useState([]);
	const [csvLoading, setCsvLoading] = useState(false);
	const [previewRow, setPreviewRow] = useState(false);
	const [showPopover, setShowPopover] = useState(false);
	const [autocompleteOpen, setAutocompleteOpen] = useState(false);
	const [selectedRows, setSelectedRows] = useState(() => new Set());
	const [selectedRowObjects, setSelectedRowObjects] = useState([]);
	const {features: tenantFeatures} = useFeatures();

	const handleVisibility = (visibility) => setShowPopover(visibility);
	const tableRef = useRef();
	const resetPage = () => {
		if (tableRef.current) {
			tableRef.current.scrollToRow(0);
		}
		editPage(1);
	};
	const items = [
		{
			key: "menu",
			label: <CreateStatusActions type="orderStatuses" handleVisibility={handleVisibility} />,
		},
	];
	const {setUrlParams, getUrlParams, setQuery, clearUrlQuery, setModal} = useUrlParams(resetPage);
	const {tab, tableTab, query, modal, id} = getUrlParams(
		"tab",
		"table-tab",
		"query",
		"modal",
		"id",
	);
	const initializeQueryParams = (item, customTableTab) => {
		const tableTabToInsert = customTableTab || tableTab || "all";
		setUrlParams(
			item || "orders",
			"tab",
			{paramValue: tableTabToInsert, paramName: "table-tab"},
			{
				paramName: "query",
				paramValue: {...sagaQuery, fromDate: null, toDate: null},
			},
			false,
		);
	};
	const {isV2CurrentVersion} = getEndpointBasedOnVersion(
		"Change_order_status_v2",
		"/order/status",
	);
	const [settleModal, setSettleModal] = useState({
		open: false,
		initialValues: {
			year: null,
			month: null,
			description: "",
			billingMonthId: null,
			baugoId: null,
		},
		initialValuesForSalesOrg: {
			year: null,
			month: null,
			description: "",
			billingMonthId: null,
		},
	});
	const [reEditPage, setReEditPage] = useState(true);
	const [changeStatusModal, setChangeStatusModal] = useState(false);
	const [reactivateModal, setReactivateModal] = useState({
		open: false,
		initialValues: {
			year: null,
			month: null,
			description: "",
			billingMonthId: null,
		},
	});
	const setActiveTab = (paramValue) => {
		setUrlParams(paramValue, "table-tab");
		cleanQuery(paramValue);
	};
	const cleanQuery = (paramValue) => {
		setQuery({
			search: "",
			fromDate: null,
			toDate: null,
			clientId: null,
			status: paramValue === "all" ? null : paramValue,
			teamId: null,
			agencyId: null,
			locationId: null,
			isExactSearch: false,
			userIds: [],
			salesOrganizationId: null,
			clientPayoutModel: null,
		});
	};
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: loadinLottie,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};
	const [status, setStatus] = useState(null);
	useEffect(() => {
		if (tab === "orders" || !tab) initializeQueryParams();
		if (tab === "orders") {
			getBillingMonths({forDropdown: true});
			if (isV2CurrentVersion) {
				getAllSalesOrganizations("all");
			}
		}
		if (tab === "energyOrders") {
			getOrderStatuses();
		}
		getUpdatedStatuses();
		fetchTariffsByDropdown();
		fetchClientsByDrowdown();
		getTeams({isTeam: false, all: true});
		getAgencies({isTeam: true, all: true});
		setReEditPage(false);
		editPage(1);
		editSize(30);
		setSelectedRows(new Set());
		setSelectedRowObjects([]);
	}, [tab]);
	useEffect(() => {
		editLocationSize(1000);
		if (query?.clientId) {
			fetchLocations(query?.clientId);
			fetchProjectCategoriesByDropdown(query?.clientId);
		}
		return () => {
			editSize(30);
			editPage(1);
			setReEditPage(false);
			editTeamSize(30);
		};
	}, [query?.clientId]);
	useEffect(() => {
		if (query?.projectCategoryId) {
			getBonuses(query?.projectCategoryId);
		}
	}, [query?.projectCategoryId]);
	useEffect(() => {
		if (loggedUser?.role) {
			if (checkRoles("getUsersByHeadOfSalesId")) {
				getUserDropdown("HEAD_OF_SALES");
			} else {
				getUserDropdown(
					tableTab === "BONUS"
						? [...userRolesDropdown, "AGENCY", "AGENCY_70"]
						: userRolesDropdown,
				);
			}
		}
		resetPage();
		editSize(30);
	}, [tableTab, loggedUser]);
	const checkUserIds = () => {
		if (Array.isArray(query?.userIds)) {
			return query?.userIds?.length;
		}
		return query?.userIds;
	};
	useEffect(() => {
		setSelectedRows(new Set());
		setSelectedRowObjects([]);
		if (tab === "infoChef") {
			getInfoChefOrders();
		} else if (tab === "orders") {
			getOrders({
				...query,
				status: tableTab === "all" ? query?.status : tableTab,
			});
		}
	}, [
		checkUserIds(),
		query?.fromDate,
		query?.toDate,
		query?.clientId,
		query?.status,
		query?.teamId,
		query?.agencyId,
		query?.locationId,
		query?.isExactSearch,
		query?.projectCategoryId,
		query?.salesOrganizationId,
		query?.clientPayoutModel,
		page,
		size,
		tableTab,
		tab,
	]);
	const selectHandler = () => {
		if (Array.from(selectedRows)?.[0]) {
			changeOrdersStatus({
				orders: selectedRowObjects.map((i) => ({
					id: i.orderId,
					clientPayoutModel: i.clientPayoutModel,
				})),
				status: status.value,
				statusName: status.statusValue,
				statusValue: status.statusValue,
			});
			setSelectedRows(new Set());
			setSelectedRowObjects([]);
		} else {
			ToastErrorComponent(t("PleaseSelectAction"));
		}
		setAutocompleteOpen(false);
	};
	const handleSearch = (value) => {
		setQuery({...query, search: value.target.value});
		const debounced = _.debounce(() => {
			getOrders({
				...query,
				search: value.target.value,
				status: tableTab === "all" ? query?.status : tableTab,
			});
		}, 1000);
		debounced();
	};
	// const filteredTableTabs = useMemo(() => {
	// 	if (!isV2CurrentVersion || tab === "energyOrders") {
	// 		return tableTabs.filter((tab) => tab.type !== "BONUS");
	// 	}
	// 	return tableTabs;
	// }, [isV2CurrentVersion, tab]);
	const handleSubmitChangeStatus = () => {
		if (status.statusValue === "CONTRACT_REACTIVATED") {
			handleReactivateModal(true);
		} else {
			changeOrdersStatus({
				orders: selectedRowObjects.map((i) => ({
					id: i.orderId,
					clientPayoutModel: i.clientPayoutModel,
				})),
				status: status.value,
				statusName: status.statusValue,
				statusValue: status.statusValue,
			});
		}
		setSelectedRows(new Set());
		setSelectedRowObjects([]);
	};

	const downloadCsv = async (data, type) => {
		if (type === "single") {
			if (data.exportPdf && data.fileNames?.[0]) {
				downloadOrderById({data, type, pdfs: selectedPds});
			} else {
				ToastInfoComponent(t("NoPdfsAvailable"));
			}
		} else if (type === "multiple") {
			const filteredData = orders?.filter(
				(item) => item?.exportPdf && data?.includes(item.orderId) && item?.fileNames?.[0],
			);
			if (filteredData.length > 0) {
				downloadOrderById({filteredData, type, pdfs: selectedPds});
			} else {
				ToastInfoComponent(t("NoPdfsAvailable"));
			}
		} else if (type === "homeNetMultiplePdf") {
			if (data.exportPdf && data.fileNames && data.fileNames.length > 0) {
				downloadOrderById({data, type, pdfs: selectedPds});
			} else {
				ToastInfoComponent(t("NoPdfsAvailable"));
			}

			setSelectedPds([]);
		}
	};

	const handlePreviewEdit = (id, type) => {
		if (type === "preview") {
			setPreviewRow(true);
		} else {
			setPreviewRow(false);
		}
		toggleEditOrderModal(true);
		getOrderPreview(id);
	};
	const handlePrice = (id) => setModal("price", id);
	const getByIdFormatter = ({row}) => (
		<div
			style={{
				cursor: "pointer",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: "100%",
			}}
			onClick={() => navigate(`/admin/orders/${row?.id}`)}
		>
			<PreviewRowIcon />
		</div>
	);
	const actionsFormatter = ({row}) => {
		const items = [
			{
				key: "preview",
				label: t("PreviewOrder"),
				icon: (
					<div
						style={{
							width: "20px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<PreviewRowIcon color="#252525" />
					</div>
				),
				onClick: () => {
					handlePreviewEdit(row.orderId, "preview");
				},
			},
			row?.orderStatus === t("PAIDUPPERCASE") &&
				tableTab === "PAID" &&
				checkRoles("editManualCorr") && {
					key: "setPrice",
					label: t("SetPrice"),
					icon: (
						<div
							style={{
								width: "20px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<EuroSign />
						</div>
					),
					onClick: () => {
						handlePrice(row.orderId, "setPrice");
					},
				},
			checkRoles("editOrder")
				? {
						key: "edit",
						label: t("EditOrder"),
						icon: (
							<div
								style={{
									width: "20px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<EditRowIcon strokeWidth={1.7} stroke="#252525" />
							</div>
						),
						onClick: () => {
							handlePreviewEdit(row.orderId, "edit");
						},
				  }
				: "",
			checkRoles("downloadOrder") &&
				row?.exportPdf && {
					key: "download",
					label: t("Download"),
					icon: (
						<div
							style={{
								width: "20px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<DownloadRowIcon strokeWidth={1.7} />
						</div>
					),
					onClick: () => {
						if (row?.clientName !== "HomeNet") {
							downloadCsv(row, "single");
						} else {
							setSelectedPds(row.fileNames?.map((file) => file));
							downloadCsv(row, "homeNetMultiplePdf");
						}
					},
				},
		];
		return (
			<Dropdown
				menu={{items}}
				overlayClassName="popover__actions-wrapper"
				placement="bottomLeft"
				trigger={["click"]}
			>
				<div className="three-dots-icon">
					<ThreeDotsIcon width="12.7px" height="2.7px" fill="#252525" />
				</div>
			</Dropdown>
		);
	};
	const actions = {
		key: "actions",
		name: t("Actions"),
		formatter: tab !== "statusHistory" ? actionsFormatter : getByIdFormatter,
		sortable: false,
		resizable: false,
		width: 50,
	};
	const columnsWithActions = useMemo(() => {
		const newCols = columns?.filter((col) => (col?.type ? col.type === tab : true));
		return [...newCols, actions];
	}, [tab, loggedUser, tableTab]);

	const onRowClick = (row) => {
		navigate(`/admin/orders/${row?.id}`);
	};
	const baseTabsArray = ["orders", "infoChef", "statusHistory"];
	const filterItems = [
		{
			inputType: "rangePicker",
			placeholder: [t("from"), t("to")],
			field: ["fromDate", "toDate"],
			show: true,
			maxDate: moment().endOf("day").valueOf(),
		},
		{
			inputType: "antd-dropdown",
			label: t("chooseClientType"),
			name: "label",
			value: "value",
			field: "clientPayoutModel",
			isMultiple: false,
			options: PAYOUT_MODELS,
			show: true,
			key: "label",
			optionVal: "value",
			optionLabel: "label",
		},
		{
			inputType: "antd-dropdown",
			dependentFields: ["locationId", "projectCategoryId"],
			label: t("Clients"),
			name: "name",
			value: "value",
			field: "clientId",
			isMultiple: false,
			options: optionsClients || [],
			show: checkRoles("changeClient"),
			key: "name",
			optionVal: "value",
			optionLabel: "name",
		},
		{
			inputType: "antd-dropdown",
			value: "value",
			name: "label",
			dependendFrom: "clientId",
			field: "locationId",
			label: t("Locations"),
			options: getSelectOptionsFromDataTeams(locations, "locationName", "id"),
			isMultiple: false,
			show: checkRoles("changeClient"),
			key: "label",
			optionVal: "value",
			optionLabel: "label",
		},
		{
			inputType: "antd-dropdown",
			value: "value",
			name: "label",
			dependendFrom: "clientId",
			field: "projectCategoryId",
			label: t("projectCategories"),
			options: getSelectOptionsFromDataTeams(optionsProjectCategories, "name", "id"),
			isMultiple: false,
			show: checkRoles("changeClient") && tableTab === "BONUS",
			key: "label",
			optionVal: "value",
			optionLabel: "label",
		},
		{
			inputType: "antd-dropdown",
			name: "name",
			value: "statusValue",
			options: [
				...filteringStatusOptions,
				{
					name: t("CancelledAll"),
					value: "CANCELLED_ALL",
					statusValue: "CANCELLED_ALL",
					icon: <CancelledIcon />,
				},
			],
			show: checkRoles("filterStatus") && tableTab === "all",
			isMultiple: false,
			label: t("Statuses"),
			field: "status",
			key: "statusName",
			optionVal: "value",
			optionLabel: "name",
		},
		{
			inputType: "antd-dropdown",
			name: "label",
			value: "value",
			options: getSelectOptionsFromDataTeams(teams, "name", "id"),
			isMultiple: false,
			label: t("AllTeams"),
			field: "teamId",
			show: checkRoles("filterTeams"),
			key: "label",
			optionVal: "value",
			optionLabel: "label",
		},
		{
			inputType: "antd-dropdown",
			name: "label",
			value:
				checkRoles("filterAgencies") &&
				// (Array.isArray(query?.userIds) ? !query?.userIds?.[0] : !query?.userIds) &&
				"value",
			field: "agencyId",
			options: getSelectOptionsFromDataTeams(agencies, "name", "id"),
			isMultiple: false,
			label: t("AllAgencies"),
			show: checkRoles("filterAgencies"),
			key: "label",
			optionVal: "value",
			optionLabel: "label",
			// (Array.isArray(query?.userIds) ? !query?.userIds?.[0] : !query?.userIds),
		},
		{
			inputType: "antd-dropdown",
			name: "label",
			value: "value",
			field: "salesOrganizationId",
			options: allSalesOrganizations,
			isMultiple: false,
			label: t("SalesOrganizations"),
			show: tenantFeatures?.Sales_Organization,
			key: "name",
			optionVal: "id",
			optionLabel: "name",
		},
		{
			inputType: "antd-dropdown",
			name: "name",
			value: "id",
			options: usersOptions,
			isMultiple: tableTab !== "BONUS",
			label: checkRoles("getUsersByHeadOfSalesId") ? t("Members") : t("Users"),
			field: "userIds",
			show: true,
			key: "name",
			optionVal: "id",
			optionLabel: "name",
			secondLabel: "company",
		},
	];
	const handleCloseSettleModal = () =>
		setSettleModal({
			open: false,
			initialValues: {
				year: null,
				month: null,
				description: "",
				billingMonthId: null,
				baugoId: null,
			},
			initialValuesForSalesOrg: {
				year: null,
				month: null,
				description: "",
				billingMonthId: null,
			},
		});
	const handleCloseReactivateModal = () =>
		setReactivateModal({
			open: false,
			initialValues: {
				year: null,
				month: null,
				description: "",
				billingMonthId: null,
				baugoId: null,
			},
		});
	const handleSettleModal = (visible) => {
		if (Array.from(selectedRows)?.[0] && !isSettleBonusDisabled) {
			if (isV2CurrentVersion || tableTab === "BONUS") {
				setSettleModal({
					open: visible,
					initialValues: {
						year: null,
						month: null,
						description: "",
						billingMonthId: null,
						baugoId: null,
					},
				});
			} else {
				changeOrdersStatus({
					orders: selectedRowObjects.map((i) => ({
						id: i.orderId,
						clientPayoutModel: i.clientPayoutModel,
					})),
					status: "PAID",
					statusValue: "SETTLED",
					statusName: "SETTLED",
				});
				setSelectedRows(new Set());
				setSelectedRowObjects([]);
			}
		}
	};
	const handleReactivateModal = (visible) => {
		if (Array.from(selectedRows)?.[0]) {
			setReactivateModal({
				open: visible,
				initialValues: {
					year: null,
					month: null,
					description: "",
					billingMonthId: null,
				},
			});
		}
	};
	const handleTabClick = (item) => {
		if (item === tab) return;
		if (item === "orders") {
			initializeQueryParams(item, "all");
			setQuery({...sagaQuery, status: null});
		} else {
			setUrlParams(item, "tab");
			clearUrlQuery();
		}
	};
	const handleChangeStatusModal = (visible) => {
		setChangeStatusModal(visible);
	};
	const exportCsvRequest = async (payload) => {
		ToastInfoComponent(t("DownloadInProcess"));
		setExportStatus("PROGRESS");
		try {
			await axios.post(`/order/export?page=${1}&size=${totalSize}`, {
				...buildPayload(null, payload),
				export: true,
			});
		} catch (error) {
			ToastErrorComponent(t("DownloadFailed"));
		}
	};
	const isSettleBonusDisabled =
		selectedRows?.size === 0 ||
		(tableTab === "BONUS" &&
			(Array.isArray(query?.userIds) ? !query?.userIds?.[0] : !query?.userIds));
	const renderStatusOptions = () => {
		// if (tab === "energyOrders") {
		// 	if (selectedRows?.size > 0) {
		// 		return (
		// 			<Popover
		// 				trigger={["click"]}
		// 				open={changeStatusModal}
		// 				overlayClassName="popup_reactivated_user paddingLess"
		// 				onOpenChange={handleChangeStatusModal}
		// 				placement="bottomRight"
		// 				content={
		// 					<OrderStatusComponent
		// 						handleSubmit={handleSubmitChangeStatus}
		// 						setStatus={setStatus}
		// 						status={status}
		// 						setChangeStatusModal={setChangeStatusModal}
		// 					/>
		// 				}
		// 			>
		// 				<HtmlTooltip title={t("ChangeStatus")}>
		// 					<div className="wrapper__change-status">
		// 						<Button
		// 							border={selectedRows?.size > 0 ? "none" : "1px solid #C1C1C1"}
		// 							handleChange={() => {}}
		// 							hideTooltip
		// 							icon={checkedLogo}
		// 							smallButtonWithTooltip
		// 						/>
		// 					</div>
		// 				</HtmlTooltip>
		// 			</Popover>
		// 		);
		// 	}
		// 	return (
		// 		<CustomPopover
		// 			customClass="custom-popover-leadstatuses"
		// 			menu={{items}}
		// 			open={showPopover}
		// 			handleVisibility={handleVisibility}
		// 			placement="bottomRight"
		// 		>
		// 			<div>
		// 				<Button
		// 					{...salesTrackerButtonProps}
		// 					icon={createOrderStatusLogo}
		// 					leftRounded={false}
		// 					rightRounded={false}
		// 					hideTooltip={true}
		// 					smallButtonWithTooltip
		// 				/>
		// 			</div>
		// 		</CustomPopover>
		// 	);
		// }
		if (tab === "orders") {
			return (
				<ClickAwayListener onClickAway={() => setAutocompleteOpen(false)}>
					<div className="wrapper__change-status">
						{/* <ChangeStatusSelect
							setOpen={setAutocompleteOpen}
							open={autocompleteOpen}
							setStatus={setStatus}
							onSubmit={(event) => {
								if (Array.from(selectedRows)?.[0] && status !== null) {
									if (status.statusValue === "CONTRACT_REACTIVATED") {
										event.preventDefault();
										event.stopPropagation();
										handleReactivateModal(true);
									} else {
										event.preventDefault();
										event.stopPropagation();
										setAutocompleteOpen(true);
										selectHandler();
									}
								} else {
									ToastErrorComponent(t("PleaseSelectAction"));
								}
							}}
						/> */}
						<Autocomplete
							open={autocompleteOpen}
							disableClearable={false}
							disableCloseOnSelect={false}
							className="test-autocompleteee"
							filterSelectedOptions={false}
							getOptionLabel={(option) => option.name}
							getOptionSelected={(option, value) => option?.value === value?.value}
							onOpen={() => setAutocompleteOpen(true)}
							onClose={(event, reason) => {
								if (reason === "select-option" || reason === "blur")
									setAutocompleteOpen(true);
								else setAutocompleteOpen(false);
							}}
							limitTags={20}
							multiple={false}
							onChange={(event, value) => setStatus(value)}
							options={statusOptions}
							renderInput={(params) => (
								<TextField
									{...params}
									className="text-field"
									id="name"
									name="name"
									placeholder={t("SelectOrderStatus")}
									variant="outlined"
								/>
							)}
							value={status}
							renderOption={(item, option) => (
								<li
									className={`custom__option ${
										option?.selected ? "selected" : ""
									}`}
									key={item?.name}
								>
									<span>{item?.icon ? item?.icon : ""} </span>
									<span>{item?.name}</span>
								</li>
							)}
							PopperComponent={(props) => (
								<Popper {...props} className="autoCompleteStyle">
									<div className="popperComponent">
										{props.children}
										<div
											className={`popperComponentStyle ${
												status ? "popperComponentStyle-second" : ""
											}`}
											onMouseDown={(event) => {
												if (
													Array.from(selectedRows)?.[0] &&
													status !== null
												) {
													if (
														status.statusValue ===
														"CONTRACT_REACTIVATED"
													) {
														event.stopPropagation();
														handleReactivateModal(true);
													} else {
														event.stopPropagation();
														setAutocompleteOpen(true);
														selectHandler();
													}
												} else {
													ToastErrorComponent(t("PleaseSelectAction"));
												}
											}}
										>
											<CheckLogo fill={status ? "#400077" : "#cacaca"} />
										</div>
									</div>
								</Popper>
							)}
						/>
					</div>
				</ClickAwayListener>
			);
		}
		return null;
	};
	const inputs = [
		{
			title: t("FieldNameEn"),
			field: "name",
			inputType: "text",
			required: true,
		},
		{
			title: t("FieldNameDe"),
			field: "nameDe",
			inputType: "text",
			required: true,
		},
		{
			inputType: "dropdown",
			field: "status",
			options: statusOptions.map((item) => ({
				value: item?.statusValue,
				label: item?.name,
			})),
			title: t("ChooseStatus"),
			required: true,
		},
		{
			title: t("PickAColor"),
			inputType: "colorPicker",
			field: "color",
			required: true,
		},
	];
	const formData = {
		inputs,
		id,
		title: id ? t("EditStatus") : t("CreateStatus"),
		submitFn: (values) =>
			id ? editStatus({values, setModal}) : createStatus({values, setModal}),
		getUrl: ENDPOINT.GET_BY_ID_STATUS.replace(":id", id),
	};
	return (
		<>
			<Form
				open={modal === "orderStatuses"}
				handleClose={() => setModal(false)}
				formData={formData}
				loading={orderStatusLoading}
			/>
			<PriceForm />
			<Modal
				className="add_edit_users"
				open={showModal}
				handleClose={() => setShowModal(false)}
			>
				<EditAndCreateOrders setShowModal={setShowModal} />
			</Modal>
			<Modal
				open={showEditModal}
				handleClose={() => toggleEditOrderModal(false)}
				disableEnforceFocus
				className="add_edit_user preview"
				width={showTimeline ? 1000 : 600}
				positionModal="right"
				overflow="hidden"
				border={previewRow && "none"}
				padding={previewRow && 0}
				display={previewRow && "flex"}
			>
				<CreateEditOrder
					setShowEditModal={toggleEditOrderModal}
					previewMode={previewRow}
					query={query}
					setReEditPage={setReEditPage}
				/>
			</Modal>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={downloadLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<div className="dashboard__container orders">
				<div className="header-component" style={{height: "auto"}}>
					<div className="dashboard__left__text-header">
						<div className="header-component-wrapper">
							<OrdersIcon />
							<span className="header-component-text">{t("Orders")}</span>
						</div>

						<Tabs
							items={checkRoles("seeInfoChef") ? baseTabsArray : ["orders"]}
							onClick={(_, item) => handleTabClick(item)}
							value={t(tab)}
						/>
					</div>

					{tab !== "infoChef" && tab !== "statusHistory" && (
						<div className="dashboard__left__text-ordersactions">
							{checkRoles("settleContracts") && tableTab !== "all" && (
								<Popover
									trigger={["click"]}
									open={settleModal.open}
									overlayClassName="popup_add_edit_user paddingLess"
									onOpenChange={handleSettleModal}
									placement="bottomRight"
									content={
										<SettleOrders
											handleClose={handleCloseSettleModal}
											selectedRows={selectedRows}
											setSelectedRows={setSelectedRows}
											setSelectedRowObjects={setSelectedRowObjects}
											selectedRowObjects={selectedRowObjects}
											initialValues={settleModal.initialValues}
											title={t("settleOrders")}
											button={t("settleOrders")}
											tableTab={tableTab}
											isV2CurrentVersion={isV2CurrentVersion}
											open={settleModal.open}
										/>
									}
								>
									<HtmlTooltip
										title={<InformationTooltip data={t("bonusSettleRules")} />}
										disableHoverListener={
											tableTab !== "BONUS" || !isSettleBonusDisabled
										}
									>
										<div className="wrapper__change-status">
											<Button
												border={
													selectedRows?.size > 0
														? "none"
														: "1px solid #C1C1C1"
												}
												handleChange={() => {}}
												hideTooltip
												disabled={isSettleBonusDisabled}
												icon={
													selectedRows?.size
														? calculationsLogo
														: notCalculationsLogo
												}
												smallButtonWithTooltip
											/>
										</div>
									</HtmlTooltip>
								</Popover>
							)}
							<BigSearchBar
								withFilters
								headerSearch
								filterItems={filterItems}
								setFilterValues={setQuery}
								clearFilterValues={() => cleanQuery(tableTab)}
								hasStrictSearch
								filterValues={query}
								onStrictIconClick={(value) => {
									setQuery({
										...query,
										isExactSearch: value,
									});
								}}
								value={query?.search}
								handleSearch={handleSearch}
							/>

							{tableTab === "all" && tenantFeatures?.Sales_Organization && (
								<Popover
									trigger={["click"]}
									open={settleModal.open}
									overlayClassName="popup_add_edit_user paddingLess"
									onOpenChange={
										query?.salesOrganizationId !== null &&
										query?.status === "SETTLED" &&
										Array.from(selectedRows)?.[0]
											? handleSettleModal
											: null
									}
									placement="bottomRight"
									content={
										<SettleOrders
											handleClose={handleCloseSettleModal}
											selectedRows={selectedRows}
											setSelectedRows={setSelectedRows}
											initialValues={settleModal.initialValuesForSalesOrg}
											title={t("settleOrders")}
											button={t("settleOrders")}
											tableTab={tableTab}
											isV2CurrentVersion={isV2CurrentVersion}
											open={settleModal.open}
											salesOrganizationId={query?.salesOrganizationId}
											forSalesOrganization
										/>
									}
								>
									<HtmlTooltip
										title={
											<InformationTooltip data={t("settleSalesOrgRules")} />
										}
									>
										<div className="wrapper__change-status">
											<Button
												border={
													selectedRows?.size > 0
														? "none"
														: "1px solid #C1C1C1"
												}
												handleChange={() => {}}
												hideTooltip
												disabled={
													query?.salesOrganizationId === null ||
													query?.status !== "SETTLED" ||
													!Array.from(selectedRows)?.[0]
												}
												icon={
													query?.salesOrganizationId === null ||
													query?.status !== "SETTLED" ||
													!Array.from(selectedRows)?.[0]
														? notCalculationsLogo
														: calculationsLogo
												}
												smallButtonWithTooltip
											/>
										</div>
									</HtmlTooltip>
								</Popover>
							)}
							<div className="wrapper__buttons">
								{checkRoles("exportOrders") && (
									<div
										className={
											totalSize > 0
												? "createuserExport"
												: "createuserExportDisabled"
										}
									>
										{Array.from(selectedRows)?.length > 0 ? (
											<>
												<HtmlTooltip
													placement="top"
													title={<div>{t("Download")} PDF</div>}
												>
													<img
														className="download_button"
														onClick={() =>
															downloadCsv(
																Array.from(selectedRows),
																"multiple",
															)
														}
														alt="download"
														src={downloadIcon}
													/>
												</HtmlTooltip>
											</>
										) : (
											<div
												className={
													totalSize > 0
														? "createuserExport"
														: "createuserExportDisabled"
												}
											>
												<HtmlTooltip
													placement="top"
													title={
														<div>
															{totalSize > 0
																? `${t("Download")} ${t(
																		"OrderList",
																  )}`
																: t("NoDataDownload")}
														</div>
													}
												>
													<img
														className="download_button"
														onClick={
															totalSize > 0
																? () =>
																		exportCsvRequest({
																			...query,
																			status:
																				tableTab === "all"
																					? query?.status
																					: tableTab,
																		})
																: null
														}
														alt="download"
														style={{
															pointerEvents: csvLoading
																? "none"
																: "auto",
														}}
														src={downloadIcon}
													/>
												</HtmlTooltip>
											</div>
										)}
									</div>
								)}
							</div>
							{checkRoles("changeStatusOrder") &&
								tableTab === "all" &&
								renderStatusOptions()}
							{tableTab === "all" && (
								<Popover
									trigger={["click"]}
									open={reactivateModal.open}
									overlayClassName="popup_reactivated_user paddingLess"
									onOpenChange={handleReactivateModal}
									placement="bottomRight"
									content={
										<SettleOrders
											handleClose={handleCloseReactivateModal}
											selectedRows={selectedRows}
											setSelectedRows={setSelectedRows}
											initialValues={reactivateModal.initialValues}
											title={t("reactivateContract")}
											button={t("reactivate")}
											statusValue="CONTRACT_REACTIVATED"
											status="CONTRACT_REACTIVATED"
											tableTab={tableTab}
											open={reactivateModal.open}
											isV2CurrentVersion={isV2CurrentVersion}
										/>
									}
								></Popover>
							)}
						</div>
					)}
				</div>
				<div className="orders-page">
					{tab === "orders" ? (
						<Table
							columnsWithActions={columnsWithActions}
							selectedRows={selectedRows}
							setSelectedRows={setSelectedRows}
							filteredTableTabs={tableTabs}
							tableTab={tableTab}
							tableRef={tableRef}
							setActiveTab={setActiveTab}
							setReEditPage={setReEditPage}
							reEditPage={reEditPage}
							handlePreviewEdit={handlePreviewEdit}
							previewRow={previewRow}
							setSelectedRowObjects={setSelectedRowObjects}
							selectedRowObjects={selectedRowObjects}
							selectObject
							key="orderId"
						/>
					) : (
						<div className="loader__and__table-orderspage">
							<DataGridTable
								columns={
									tab === "infoChef"
										? infoChefColumns.map((col) => ({
												...col,
												headerCellClass: "headerStyles",
										  }))
										: updatedStatusesColumns.map((col) => ({
												...col,
												headerCellClass: "headerStyles",
										  }))
								}
								enableCellSelect={false}
								data={tab === "infoChef" ? infoChefOrders : updatedStatuses}
								loading={statusesLoading}
								pagination={false}
								onRowClick={(rowIndex, row) =>
									tab === "statusHistory" && onRowClick(row)
								}
							/>
							{!previewRow && !showEditModal && statusesLoading && (
								<div className="infinitscroll_loader center">
									<TableSkeleton />
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	sagaQuery: state.app.orders.index.query,
	page: state.app.orders.index.page,
	loading: state.app.orders.index.loading,
	totalSize: state.app.orders.index.totalSize,
	totalPages: state.app.orders.index.totalPages,
	size: state.app.orders.index.size,
	orders: state.app.orders.index.orders,
	downloadLoading: state.app.orders.index.downloadLoading,
	showModal: state.app.orders.create.showModal,
	showEditModal: state.app.orders.edit.showModal,
	optionsClients: state.app.client.index.optionsClients,
	teams: state.app.teams.index.teams,
	agencies: state.app.teams.index.agencies,
	infoChefOrders: state.app.orders.index.infoChefOrders,
	locations: state.app.locations.index.locations,
	updatedStatuses: state.app.orders.index.updatedStatuses,
	statusesLoading: state.app.orders.index.statusesLoader,
	optionsProjectCategories: state.app.projectCategories.clientCategory.categoriesOptions,
	usersOptions: state.app.users.index.usersOptions,
	showTimeline: state.app.orders.edit.showTimeline,
	allSalesOrganizations: state.app.salesOrganizations.index.allSalesOrganizations,
	orderStatusLoading: state.app.orders.statuses.loading,
	loggedUser: state.app.users.index.loggedUser,
});

const mapDispatchToProps = {
	editPage: orderActions.editPage,
	editSize: orderActions.editSize,
	editTeamSize: teamActions.editSize,
	getOrders: orderActions.getOrders,
	getOrderPreview: orderActions.getOrderPreview,
	downloadOrderById: orderActions.downloadOrderById,
	setShowModal: createOrderActions.setShowModal,
	changeOrdersStatus: createOrderActions.changeOrdersStatus,
	fetchTariffsByDropdown: tariffActios.fetchTariffsByDropdown,
	fetchProjectCategoriesByDropdown: projectCategoriesActions.getClientCategoriesDropdown,
	fetchClientsByDrowdown: clientActions.fetchClientsByDropdown,
	toggleEditOrderModal: editOrderActions.toggleModal,
	navigate: navigation.navigate,
	getTeams: teamActions.fetchTeams,
	getAgencies: teamActions.fetchAgencies,
	getInfoChefOrders: orderActions.getInfoChefOrders,
	fetchLocations: locationActions.fetchLocations,
	editLocationSize: locationActions.editSize,
	getUpdatedStatuses: orderActions.getUpdatedStatuses,
	getBillingMonths: billingMonthsActions.getBillingMonths,
	getUserDropdown: usersActions.getUserDropdown,
	getBonuses: categoryBonusesActions.fetchBonuses,
	getAllSalesOrganizations: salesOrgActions.getAllSalesOrganizations,
	createStatus: orderStatusAction.createStatus,
	getOrderStatuses: orderStatusAction.getOrderStatuses,
	editStatus: orderStatusAction.editStatus,
	setExportStatus: orderActions.setExportStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Orders));
