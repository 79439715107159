/* eslint-disable */
import produce from 'immer'
import { put, select, takeLatest } from 'redux-saga/effects'
import createAction from '@utils/action-creator'
import {
  ToastErrorComponent,
  ToastInfoComponent,
  ToastSuccesComponent,
} from '@common/ToastComponent/ToastComponent'
import axios from '@utils/axios'
import { uniqBy } from 'lodash'
import { actions as navigateActions } from '@sagas/navigation'
import { actions as tariffActions } from '@sagas/tariff/tariffByLocations/index'
import { actions as tariffListActions } from '@sagas/tariff/clientTariff'
import i18n from '../../../../../i18n'
import Logger from "@utils/logger";

const logger = new Logger("Create Tariff!");
const PREFIX = '@app/tariffa/create'
export const CREATE_TARIFF = `${PREFIX}CREATE_TARIFF`
export const SET_LOADING = `${PREFIX}SET_LOADING`
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`
export const FILL_TARIFF = `${PREFIX}FILL_TARIFF`
export const FILL_LOCATION_TARIFF_ARRAY = `${PREFIX}FILL_LOCATION_TARIFF_ARRAY`
export const DELETE_LOCATION_TARIFF_ARRAY = `${PREFIX}DELETE_LOCATION_TARIFF_ARRAY`
export const CLEAN_LOCATION_TARIFF_ARRAY = `${PREFIX}CLEAN_LOCATION_TARIFF_ARRAY`
export const FILL_INITIAL_VALUES = `${PREFIX}FILL_INITIAL_VALUES`
export const FILL_INITIAL_VALUES_TABLE = `${PREFIX}FILL_INITIAL_VALUES_TABLE`
export const CLEAR_INITIAL_VALUES = `${PREFIX}CLEAR_INITIAL_VALUES`
export const FILL_COMMISSION_ARRAY = `${PREFIX}FILL_COMMISSION_ARRAY`
export const FILL_COMMISSION_OBJ = `${PREFIX}FILL_COMMISSION_OBJ`
export const CREATE_TARIFF_TABLE = `${PREFIX}CREATE_TARIFF_TABLE`
export const FETCH_TARIFF_TABLE = `${PREFIX}FETCH_TARIFF_TABLE`
export const DELETE_TARIFF_TABLE_ON_STATE = `${PREFIX}DELETE_TARIFF_TABLE_ON_STATE`
export const DELETE_TARIFF_TABLE = `${PREFIX}DELETE_TARIFF_TABLE`
export const EDIT_TARIFF_TABLE = `${PREFIX}EDIT_TARIFF_TABLE`
export const CLEAR_INITIAL_VALUES_TABLE = `${PREFIX}CLEAR_INITIAL_VALUES_TABLE`
export const CLEAN_STATE = `${PREFIX}CLEAN_STATE`

const _state = {
  loading: false,
  showModal: false,
  locationTariffsArray: [],
  commissionsArray: [],
  commissionObj: { data: [] },
  singleTariff: {},
  tarrifTable: [],
  tariffIntialValues: {
    name: '',
    tariffPrice: 0,
    // cancellationReserve: 0,
    cancellationReserve: {
      name: i18n.t('0%'),
      value: '0',
    },
    cancellationReserveType: {
      name: i18n.t('percentage'),
      value: 'PERCENTAGE',
    },
  },
  tarrifTableInitialValues: {
    tableName: '',
    from: '',
    to: '',
    locationsOptions: [],
    receivedCommission: 0,
  },
  filterTables: {},
}

const reducer = (state = _state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CLEAN_STATE:
        draft.tarrifTable = [];
        draft.filterTables = {};
        draft.tariffIntialValues = {
          name: '',
          tariffPrice: 0,
          // cancellationReserve: 0,
          cancellationReserve: {
            name: i18n.t('0%'),
            value: '0',
          },
          cancellationReserveType: {
            name: i18n.t('percentage'),
            value: 'PERCENTAGE',
          },
        };
        // draft.tariffIntialValues = {
        //   name: '',
        //   tariffPrice: 0,
        //   // cancellationReserve: 0,
        //   cancellationReserve: {
        //     name: i18n.t('0%'),
        //     value: '0',
        //   },
        //   cancellationReserveType: {
        //     name: i18n.t('percentage'),
        //     value: 'PERCENTAGE',
        //   },
        // }
        break
      case CREATE_TARIFF_TABLE:
        draft.tarrifTable = [...state.tarrifTable, action.payload];
        break
      case FETCH_TARIFF_TABLE:
        draft.tarrifTable = action.payload ? action.payload : [];
        break
      case EDIT_TARIFF_TABLE:
        const index = state.tarrifTable.findIndex(
          (c, index) => index === action.payload.index,
        )
        draft.tarrifTable[index] = action.payload;
        draft.filterTables[action.payload.index][0] = { ...state.filterTables[action.payload.index]?.[0], tableName: action.payload?.tableName, from: action.payload?.from, to: action.payload?.to, locationIds: action.payload.locations?.map((elem) => elem.value), receivedCommission: action?.payload?.receivedCommission };
        break
      case DELETE_TARIFF_TABLE_ON_STATE:
        let deleteVar = state.tarrifTable.filter(
          (item, index) => index !== action.payload,
        )
        draft.tarrifTable = deleteVar
        const newFilterTable = { ...state.filterTables }
        delete newFilterTable[action.payload]
        draft.filterTables = newFilterTable
        break
      case SET_LOADING:
        draft.loading = action.payload
        break
      case FILL_LOCATION_TARIFF_ARRAY:
        draft.filterTables[action.payload[0].index] = action.payload;
        break
      case DELETE_LOCATION_TARIFF_ARRAY:
        const cloneState = _.cloneDeep(state.filterTables);
        delete cloneState?.[action.payload];
        draft.filterTables = cloneState;
        break
      case FILL_COMMISSION_ARRAY:
        draft.commissionsArray = action.payload.concat(state.commissionsArray)
        break
      case FILL_COMMISSION_OBJ:
        draft.commissionObj = action.payload
        break
      case CLEAN_LOCATION_TARIFF_ARRAY:
        draft.locationTariffsArray = []
        break
      case FILL_INITIAL_VALUES:
        draft.tariffIntialValues = {
          name: action.payload.name,

          // cancellationReserve: action.payload.cancellationReserve,
          cancellationReserve: {
            name: action.payload.cancellationReserve,
            value: action.payload.cancellationReserve,
          },
          cancellationReserveType: {
            name: action.payload.cancellationReserveType || "",
            value: action.payload.cancellationReserveType || null,
          },
          isEditing: true,
          clientType: {
            name: action.payload.clientType,
            value: action.payload.clientType,
          },
          energyType: {
            name: action.payload.energyType,
            value: action.payload.energyType,
          },
        }
        break
      case FILL_INITIAL_VALUES_TABLE:
        draft.tarrifTableInitialValues = {
          tableName: action.payload.tableName,
          from: action.payload.from,
          to: action.payload?.to,
          locationsOptions: action.payload.locations,
          isEditing: true,
          index: action.payload?.index,
          tableUUID: action.payload?.tableUUID,
          receivedCommission: action.payload?.receivedCommission || 0,
        }
        break
      case CLEAR_INITIAL_VALUES_TABLE:
        draft.tarrifTableInitialValues = {
          tableName: '',
          from: '',
          to: '',
          locationsOptions: [],
          receivedCommission: 0,
        }
        break
      case SHOW_MODAL:
        draft.showModal = action.payload

        break
      case CLEAR_INITIAL_VALUES:
        draft.tariffIntialValues = {
          name: '',
          tariffPrice: 0,
          // cancellationReserve: 0,
          cancellationReserve: {
            name: i18n.t('0%'),
            value: '0',
          },
          cancellationReserveType: {
            name: i18n.t('percentage'),
            value: 'PERCENTAGE',
          },
        }
        break
      default:
        return state
    }
  })
export default reducer

export const actions = {
  createTariff: (payload) => createAction(CREATE_TARIFF, { payload }),
  setLoading: (payload) => createAction(SET_LOADING, { payload }),
  setShowModal: (payload) => createAction(SHOW_MODAL, { payload }),
  fillLocationTariff: (payload) =>
    createAction(FILL_LOCATION_TARIFF_ARRAY, { payload }),
  fillComissionsArray: (payload) =>
    createAction(FILL_COMMISSION_ARRAY, { payload }),
  fillComissionsObj: (payload) =>
    createAction(FILL_COMMISSION_OBJ, { payload }),
  cleanLocationTariff: (payload) =>
    createAction(CLEAN_LOCATION_TARIFF_ARRAY, { payload }),
  fillInitialValues: (payload) =>
    createAction(FILL_INITIAL_VALUES, { payload }),
  cleanTariffsInitialValues: (payload) =>
    createAction(CLEAR_INITIAL_VALUES, { payload }),
  createTariffTable: (payload) =>
    createAction(CREATE_TARIFF_TABLE, { payload }),
  fetchTariffTable: (payload) => createAction(FETCH_TARIFF_TABLE, { payload }),
  deleteTarrifTableOnState: (payload) =>
    createAction(DELETE_TARIFF_TABLE_ON_STATE, { payload }),
  deleteTarrifTable: (payload) =>
    createAction(DELETE_TARIFF_TABLE, { payload }),
  editTarriffTable: (payload) => createAction(EDIT_TARIFF_TABLE, { payload }),
  fillTarriffTable: (payload) =>
    createAction(FILL_INITIAL_VALUES_TABLE, { payload }),
  clearTarriffTable: (payload) =>
    createAction(CLEAR_INITIAL_VALUES_TABLE, { payload }),
  cleanTarrifState: (payload) =>
    createAction(CLEAN_STATE, { payload }),
  deleteLocationFromArray: (payload) =>
    createAction(DELETE_LOCATION_TARIFF_ARRAY, { payload }),
}

export const filterData = (tablesData) => {
  const removeDuplicates = uniqBy(tablesData, 'tableName');
  const array = []
  removeDuplicates.map((item) => {
    item?.data?.map((iData) => {
      const arrayKeys = Object.keys(iData)
      arrayKeys.map((iKeys) => {
        let obj = {}
        if (tablesData?.[0]?.comissionsByProjectCategories) {
          obj = {
            projectCategoryId: parseInt(iKeys),
            // set commission value to 0 since it wont be used
            // commission: 0,
            // commission: parseFloat(iData[iKeys]),
          }
        } else {
          obj = {
            projectCategoryId: parseInt(iKeys),
            provision: parseFloat(iData[iKeys]) || 0,
            roleId: iData?.id,
            team: !!iData?.team,
            leader: !!iData?.leader,
          }
        }
        if (obj?.projectCategoryId) {
          array.push(obj)
        }
      })
    })
  })

  return array
}

const filterCommision = (tablesData) => {
  const removeDuplicates = uniqBy(tablesData, 'locationId')
  const array = []
  removeDuplicates.map((item) => {
    item.data.map((iData) => {
      const arrayKeys = Object.keys(iData)
      arrayKeys.map((iKeys) => {
        let obj = {}
        if (iData.roles !== i18n.t('TariffComission')) return
        obj = {
          projectCategoryId: parseInt(iKeys),
          // commission: parseFloat(iData[iKeys]),
          // commission: 0,
          locationId: item.locationId,
        }
        if (obj?.projectCategoryId) {
          array.push(obj)
        }
      })
    })
  })
  return array
}

export const sagas = {
  *createTariff({ payload }) {
    yield put(actions.setLoading(true))
    try {
      const { filterTables, tarrifTable } = yield select(
        (state) => state.app.tariffByLocations.create,
      )
      const tables = []
      for (let obj in filterTables) {
        tables.push(filterTables?.[obj]?.[0])
      };
      const isAnyTableWithoutLocations = tables.some((item) => item?.locationIds?.length === 0);
      const updatedTables = tables?.map((table, index) => {
        return {
          ...table,
          receivedCommission: tarrifTable[index]?.receivedCommission || 0
        }
      })
      if (!isAnyTableWithoutLocations) {
        if (payload.tariffId === undefined) {
          const data = {
            ...payload?.values,
            tables: [],
            cancellationReserveType: 'PERCENTAGE',
            cancellationReserve: payload?.values?.cancellationReserve || 0,
          }
          const response  = yield axios.post(`/tariff_commission/create`, data)
          ToastSuccesComponent(i18n.t('TariffCreateSuccess'))
         yield put(tariffListActions.createTariffSuccess({...data, id: response.data?.data.id}))
          payload.setModal(false)
        } else {
          const data = {
            ...payload,
            tables: updatedTables,
            tariffId: payload.tariffId || undefined,
            cancellationReserve: parseInt(payload?.cancellationReserve?.value),
            cancellationReserveType: 'PERCENTAGE',
            clientType: payload.clientType?.value,
            energyType: payload.energyType?.value,
            // cancellationReserveType: payload.cancellationReserveType.value,
            // projectCategoryCommission: filterCommision(locationTariffsArray),
          }
          delete data?.isEditing
          yield axios.put(`/tariff_commission/update`, data)
          ToastSuccesComponent(i18n.t('TariffUpdateSuccess'))
          yield put(navigateActions.navigate(`/admin/clients/${data.clientId}/${data.client}?tab=tariff&table-tab=active&query=%7B"search"%3A""%7D`))
        }
      } else {
        ToastInfoComponent(i18n.t('CannotUpdateTables'))
      }
    } catch (error) {
      logger.error("Create Tariff Error", error);
      const message = error?.response?.data?.message?.split('|');
      if (message?.[0].includes('PROVISION_BIGGER')) {
        ToastErrorComponent(i18n.t("TariffUpdateErrorProvision"));
      }
      ToastErrorComponent(message?.[1]);
    } finally {
      yield put(actions.setLoading(false))
    }
  },
  *deleteTarrifTable({ payload }) {
    const { id, tariffId , index } = payload;
    try {
      const { tarrifTable } = yield select(
        (state) => state.app.tariffByLocations.create,
      )
      yield axios.put(`/tariff_commission/deactivate/${tariffId}/${id}`)
      const foundedTable = tarrifTable?.find((it) => it.tableUUID === id);
      ToastSuccesComponent(i18n.t('TariffTableDeactivatedSuccesss'))
      yield put(actions.deleteTarrifTableOnState(index));
      yield put(tariffActions.addDeletedtableOnState(foundedTable));
    } catch (error) {
      ToastErrorComponent(error?.response?.data?.message)
    }
  }
}

export const watcher = function* w() {
  yield takeLatest(CREATE_TARIFF, sagas.createTariff);
  yield takeLatest(DELETE_TARIFF_TABLE, sagas.deleteTarrifTable);

}
