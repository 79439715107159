import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {axiosRequests} from "@src/utils/getAgAxios";
import ButtonComponent from "@common/button";
import {ErrorResponseIcon, SuccessResponseIcon} from "@src/assets/SvgComponents";

const clearDependentFields = (dependencyFields, fibreOrder, setFieldValue) => {
	if (dependencyFields) {
		dependencyFields.forEach((field) =>
			setFieldValue(fibreOrder ? `customFieldsValue.${field}.value` : field, null),
		);
	}
};

const validateIban = async (item, value, setFieldValue, setState, fibreOrder, fields) => {
	setState("started");
	try {
		const {data} = await axiosRequests.getAgRequest({
			url: `/checkIBAN?iban=${value}`,
			baseURL: process.env.REACT_APP_GET_AG_BANKSERVICE,
			method: "get",
		});
		const {isValidIBAN} = data?.data;
		if (isValidIBAN) {
			item.dependencyFields.forEach((field) => {
				const [name] = field.split("_");
				if (data?.data?.[name]) {
					setFieldValue(
						fibreOrder ? `customFieldsValue.${field}.value` : field,
						data?.data?.[name],
					);
				} else {
					const foundedField = fields?.find((f) => f.name === field);
					setFieldValue(
						fibreOrder ? `customFieldsValue.${field}.value` : field,
						data?.data?.[foundedField?.fixedNoteEn],
					);
				}
			});
			setState("success");
		} else {
			clearDependentFields(item.dependencyFields, fibreOrder, setFieldValue);
			setState("error");
		}
	} catch (error) {
		setState("error");
		return error;
	}
};
const IbanValidator = ({item, value, setFieldValue, setState, state, fibreOrder, fields}) => {
	const {t} = useTranslation();
	return (
		<div className="iban-validator-wrapper">
			<ButtonComponent
				buttonText={t("Check")}
				disabled={state === "started"}
				loading={state === "started"}
				onClick={(e) => {
					e.preventDefault();
					validateIban(item, value, setFieldValue, setState, fibreOrder, fields);
				}}
			/>
		</div>
	);
};

const IbanInput = ({element, value, setFieldValue, fibreOrder = false, fields, canValidate}) => {
	const [state, setState] = useState(null);
	const handleInput = (e) => {
		const {value} = e.target;
		const {dependencyFields} = element?.item;
		setFieldValue(element?.name, value);
		clearDependentFields(dependencyFields, fibreOrder, setFieldValue);
		setState(null);
	};
	return (
		<div className="iban-input__wrapper">
			<label>IBAN</label>
			<div className="iban-input__wrapper-input">
				<div className="inputWrapper">
					<input onChange={handleInput} value={value} name={element?.name} />
					{state && (
						<div className="inputWrapper-icons">
							{state === "success" && <SuccessResponseIcon />}
							{state === "error" && <ErrorResponseIcon />}
						</div>
					)}
				</div>
				{canValidate && (
					<IbanValidator
						item={element?.item}
						value={value}
						setFieldValue={setFieldValue}
						setState={setState}
						state={state}
						fibreOrder={fibreOrder}
						fields={fields}
					/>
				)}
			</div>
		</div>
	);
};

export default IbanInput;
