import DataGridTable from "@src/common/table/DataGridTable";
import React from "react";
import {connect} from "react-redux";
import {actions as orderActions} from "@sagas/orders";
import {actions as editOrderActions} from "@sagas/orders/editOrder";
import {actions as createOrderActions} from "@sagas/orders/create";
import {actions as teamActions} from "@sagas/teams";
import {useTranslation} from "react-i18next";
import {actions as tariffActios} from "@sagas/tariff";
import {actions as clientActions} from "@sagas/client";
import {actions as projectCategoriesActions} from "@sagas/projectCategories/clientCategory";
import {actions as categoryBonusesActions} from "@sagas/projectCategories/bonuses";
import {actions as locationActions} from "@sagas/locations";
import {actions as billingMonthsActions} from "@sagas/calculations/billingMonths";
import {actions as usersActions} from "@sagas/users/users/index";
import {withRouter} from "react-router";
import {actions as navigation} from "@sagas/navigation/";

const Table = ({
	columnsWithActions,
	selectedRows,
	setSelectedRows,
	loading,
	filteredTableTabs,
	tableTab,
	tableRef,
	setActiveTab,
	setReEditPage,
	reEditPage,
	handlePreviewEdit,
	previewRow,
	size,
	page,
	totalPages,
	totalSize,
	showEditModal,
	editPage,
	editSize,
	orders,
	setSelectedRowObjects,
	selectObject = false,
	key,
}) => {
	const {t} = useTranslation();
	return (
		<div className="loader__and__table-orderspage">
			<DataGridTable
				columns={columnsWithActions.map((column) => ({
					...column,
					resizable: true,
					headerCellClass: "headerStyles",
				}))}
				data={orders}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
				onSelectedRowsChange={setSelectedRows}
				editSize={editSize}
				editPage={editPage}
				loading={loading}
				withTabs
				customTabs={filteredTableTabs}
				currentTab={tableTab}
				tableRef={tableRef}
				onTabClick={setActiveTab}
				size={size}
				page={page}
				totalPages={totalPages}
				totalSize={totalSize}
				pagination={true}
				setReEditPage={setReEditPage}
				reEditPage={reEditPage}
				previewRow={previewRow}
				showEditModal={showEditModal}
				onRowClick={(rowIndex, row, column) => {
					if (column?.key !== "actions" && column?.key !== "select-row") {
						handlePreviewEdit(row.orderId, "preview");
					} else if (column?.key === "select-row" && row.statusName === t("inProgress")) {
						setSelectedRows(new Set());
					}
				}}
				setSelectedRowObjects={setSelectedRowObjects}
				selectObject={selectObject}
				key={key}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	sagaQuery: state.app.orders.index.query,
	page: state.app.orders.index.page,
	loading: state.app.orders.index.loading,
	totalSize: state.app.orders.index.totalSize,
	totalPages: state.app.orders.index.totalPages,
	size: state.app.orders.index.size,
	orders: state.app.orders.index.orders,
	downloadLoading: state.app.orders.index.downloadLoading,
	showModal: state.app.orders.create.showModal,
	showEditModal: state.app.orders.edit.showModal,
	optionsClients: state.app.client.index.optionsClients,
	teams: state.app.teams.index.teams,
	agencies: state.app.teams.index.agencies,
	infoChefOrders: state.app.orders.index.infoChefOrders,
	locations: state.app.locations.index.locations,
	updatedStatuses: state.app.orders.index.updatedStatuses,
	statusesLoading: state.app.orders.index.statusesLoader,
	optionsProjectCategories: state.app.projectCategories.clientCategory.categoriesOptions,
	usersOptions: state.app.users.index.usersOptions,
	showTimeline: state.app.orders.edit.showTimeline,
});

const mapDispatchToProps = {
	editPage: orderActions.editPage,
	editSize: orderActions.editSize,
	editTeamSize: teamActions.editSize,
	getOrders: orderActions.getOrders,
	getOrderPreview: orderActions.getOrderPreview,
	downloadOrderById: orderActions.downloadOrderById,
	setShowModal: createOrderActions.setShowModal,
	fetchTariffsByDropdown: tariffActios.fetchTariffsByDropdown,
	fetchProjectCategoriesByDropdown: projectCategoriesActions.getClientCategoriesDropdown,
	fetchClientsByDrowdown: clientActions.fetchClientsByDropdown,
	toggleEditOrderModal: editOrderActions.toggleModal,
	navigate: navigation.navigate,
	getTeams: teamActions.fetchTeams,
	getAgencies: teamActions.fetchAgencies,
	getInfoChefOrders: orderActions.getInfoChefOrders,
	fetchLocations: locationActions.fetchLocations,
	editLocationSize: locationActions.editSize,
	getUpdatedStatuses: orderActions.getUpdatedStatuses,
	getBillingMonths: billingMonthsActions.getBillingMonths,
	getUserDropdown: usersActions.getUserDropdown,
	getBonuses: categoryBonusesActions.fetchBonuses,
	getOrdersCount: orderActions.getOrdersCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Table));
