import moment from "moment";

export const goalValues = {
	step: 0,
	name: "",
	sizeType: "MEDIUM",
	dateType: "week",
	startDate: null,
	endDate: null,
	repeatGoal: false,
	goalType: "",
	amount: null,
	priority: "",
	description: "",
	textColor: "#000000",
	bgColor: "#6C1EB0",
	typeList: "USERS",
	// usersIdList: [],
	userList: [],
	clientList: [],
	teamList: [],
	agencyList: [],
	targetClientsList: [],
	targetTariffsList: [],
	targetProductsList: [],
	targetLocationsList: [],
	orderStatusList: [],
};

export const queryInitialValues = {
	dateType: "CUSTOM",
	from: null,
	to: null,
	search: "",
	clientId: null,
	locationId: null,
	priority: "NONE",
	status: null,
	teamId: null,
	agencyId: null,
};
