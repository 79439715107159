/* eslint-disable func-names */
/* eslint-disable max-len */
import {DesktopViewIcon, MobileViewIcon, TabletViewIcon} from "@src/assets/SvgComponents";
import i18n from "@src/i18n";
import moment from "moment";
import * as Yup from "yup";

export const DATE_INPUTS = ["DATE", "BIRTHDAY"];
export const TEXT_INPUTS = ["TEXT", "NUMBER", "BIC", "BANK_NAME"];

export const convertDateType = (type) => {
	switch (type) {
		case "DAILY":
			return "days";
		case "WEEKLY":
			return "week";
		case "MONTHLY":
			return "months";
		case "YEARLY":
			return "years";
		default:
			return "";
	}
};

export const validateDates = (date, minDate, maxDate, dateType, isBirthdayInput) => {
	const convertedType = convertDateType(dateType);
	let isValid = true,
		error = null;
	const givenDate = moment(date);
	if (minDate) {
		const comparedDateWithMinDate = moment().subtract(minDate, convertedType);
		if (isBirthdayInput) {
			const isOlderThanMinDate = givenDate.isBefore(comparedDateWithMinDate);
			if (!isOlderThanMinDate) {
				isValid = false;
				error = `${i18n.t("dateErrorAtLeast")} ${minDate} ${i18n.t(`${convertedType}Ago`)}`;
			}
		} else {
			const isYoungerThanMinDate = givenDate.isAfter(comparedDateWithMinDate);
			if (!isYoungerThanMinDate) {
				isValid = false;
				error = `${i18n.t("dateErrorAtLeast")} ${minDate} ${i18n.t(`${convertedType}Ago`)}`;
			}
		}
	}
	if (maxDate) {
		const comparedDateWithMaxDate = moment().add(maxDate, convertedType);
		const isOlderThanMaxDate = givenDate.isBefore(comparedDateWithMaxDate);
		if (!isOlderThanMaxDate) {
			isValid = false; // Not valid if date is older than maxAge
			error = `${i18n.t("dateErrorAtMost")} ${maxDate} ${i18n.t(`${convertedType}Ago`)}`;
		}
	}
	// Handle the case when both minAge and maxAge are provided
	if (minDate && maxDate) {
		const comparedDateWithMinDate = moment().subtract(minDate, convertedType);
		const comparedDateWithMaxDate = moment().add(maxDate, convertedType);

		// Ensure the date is within the range (older than minAge and younger than maxAge)
		const isWithinAgeRange =
			givenDate.isAfter(comparedDateWithMinDate) &&
			givenDate.isBefore(comparedDateWithMaxDate);

		if (!isWithinAgeRange) {
			isValid = false; // Not valid if date is outside of the range
			error = i18n.t("dateRangeError");
		}
	}
	if (minDate === 0) {
		const addedOne = moment().add(1, convertedType);
		const isAfterOne = givenDate.isAfter(addedOne);
		if (!isAfterOne) {
			isValid = false;
			error = i18n.t("dateRangeError");
		}
	}
	if (maxDate === 0) {
		const subtractedOne = moment().subtract(1, convertedType);
		const isBeforeOne = givenDate.isBefore(subtractedOne);
		if (!isBeforeOne) {
			isValid = false;
			error = i18n.t("dateRangeError");
		}
	}
	return {isValid, error};
};

export const dateSchema = (item, fieldSchema, fieldLabel) => {
	const {minDate, maxDate, inputType, dateType, isRequired} = item;
	const isBirthdayInput = inputType === "BIRTHDAY";
	return fieldSchema.test("validate-dates", `${i18n.t("dateRangeError")}`, function (value) {
		const {path, createError} = this;
		if (isRequired && !value) {
			return createError({path, message: `${fieldLabel} ${i18n.t("isRequired")}`});
		}
		if (!value) {
			return true;
		}
		const {error} = validateDates(value, minDate, maxDate, dateType, isBirthdayInput);
		if (error) {
			return createError({path, message: error});
		}
		return true;
	});
};

const currentLanguage = i18n.language;

export const generateYupSchema = (jsonArray, emailErrors) => {
	const schemaFields = {};
	jsonArray.forEach((field) => {
		// this dependentFrom field will serve to make validations for this field
		// 'when a field is dependent from another field 'dependentFrom' will determine if that field is required or not
		// also the same field will be used to hide/show field
		const {
			name,
			inputType,
			isRequired,
			labelEn,
			labelDe,
			maxCharacters,
			minCharacters,
			dependentFrom,
			hideMobile,
		} = field;
		if (hideMobile) return;

		let fieldSchema;

		const fieldLabel = currentLanguage === "en" ? labelEn : labelDe;

		switch (inputType) {
			case "TEXT":
				fieldSchema = Yup.string().nullable();
				break;

			case "DATE":
			case "BIRTHDAY":
				fieldSchema = Yup.mixed().nullable();
				break;

			case "DROPDOWN":
				fieldSchema = field.isMultiple ? Yup.array().nullable() : Yup.string().nullable();
				break;

			case "CHECKBOX":
				fieldSchema = Yup.boolean().nullable();
				break;

			case "NUMBER":
				fieldSchema = Yup.number().nullable();
				break;
			case "EMAIL":
				fieldSchema = Yup.string().email(i18n.t("CheckEmail")).nullable();
				break;

			default:
				fieldSchema = Yup.mixed().nullable();
		}
		// this check will serve to make a field required if it isn't depenedent from another field,
		if (isRequired && !dependentFrom) {
			fieldSchema = fieldSchema.required(`${fieldLabel} ${i18n.t("isRequired")}`);
		}

		if (maxCharacters) {
			fieldSchema = fieldSchema?.max(
				maxCharacters,
				`${fieldLabel} ${i18n.t("atMost")} ${maxCharacters} ${i18n.t("chars")}`,
			);
		}

		if (minCharacters && inputType !== "DATE") {
			fieldSchema = fieldSchema?.min(
				minCharacters,
				`${fieldLabel} ${i18n.t("atLeast")} ${minCharacters} ${i18n.t("chars")}`,
			);
		}
		if (DATE_INPUTS.includes(inputType)) {
			fieldSchema = dateSchema(field, fieldSchema, fieldLabel);
		}

		if (dependentFrom) {
			const depFields = dependentFrom.includes(",")
				? dependentFrom.split(",")
				: [dependentFrom];

			fieldSchema = fieldSchema.test(
				"is-required",
				`${fieldLabel} ${i18n.t("isRequired")}`,
				function (val) {
					const values = this.parent;
					if (val) return true; // If the current value is present, no need to check further

					// Check if at least one parent field is non-empty
					const isOneOfParentsNonEmpty = depFields.some((f) => !!values?.[f.trim()]);
					return !isOneOfParentsNonEmpty; // Current field is required if at least one parent is non-empty
				},
			);
		}
		if (inputType === "EMAIL") {
			fieldSchema = fieldSchema.test("is-valid", i18n.t("falseEmail"), function () {
				return !emailErrors?.[name];
			});
		}

		schemaFields[name] = fieldSchema.label(labelEn);
	});

	return Yup.object().shape(schemaFields);
};
export const generateInitialValues = (inputs) => {
	const obj = {};
	for (let i = 0; i < inputs.length; i++) {
		const {defaultValue, isMultiple, name} = inputs[i];
		if (defaultValue) {
			obj[name] = defaultValue;
		} else {
			obj[name] = isMultiple ? [] : null;
		}
	}
	return obj;
};

const NON_RENDERED_INPUTS = ["TARIFF", "PRODUCT", "TITLE"];

export const shouldHideField = ({item, values}) => {
	const {dependentFrom, hideMobile, dropDownParentId, inputType} = item;
	if (dependentFrom) {
		if (!dependentFrom?.split(",").some((dependentField) => values?.[dependentField.trim()])) {
			return true;
		}
	}
	if (hideMobile || dropDownParentId || NON_RENDERED_INPUTS.includes(inputType)) return true;
};

export const checkErrors = (fields, errors) => {
	const fieldNames = fields.map((f) => f.name);
	return fieldNames.some((name) => !!errors[name]);
};

export const tabs = [
	{
		type: "mobile",
		label: "Mobile",
		Icon: MobileViewIcon,
	},
	{
		type: "tablet",
		label: "Tablet",
		Icon: TabletViewIcon,
	},
	{
		type: "desktop",
		label: "Desktop",
		Icon: DesktopViewIcon,
	},
];

export const preparePayload = (fields, values, clientId) => {
	const {
		tariffId,
		projectCategoryId,
		productIds,
		locationId,
		contractId,
		userEmail,
		firstName,
		lastName,
	} = values;
	const obj = {
		clientId,
		tariffId,
		projectCategoryId,
		products: productIds || [],
		locationId,
		contractId,
		userEmail: userEmail || "",
		emailLogsIds: [],
		customFieldValue: {},
		createOrderDetails: {
			firstName: firstName || "",
			lastName: lastName || "",
			userEmail: userEmail || "",
		},
	};
	for (let i = 0; i < fields.length; i++) {
		const {objectType, name, inputType, isMultiple} = fields[i];
		if (objectType === "CUSTOM_FIELD_VALUE") {
			let value;
			if (inputType === "CHECKBOX") {
				value = values[name] || false;
			} else if (inputType === "DROPDOWN" && isMultiple) {
				value = values[name].length > 0 ? JSON.stringify(values[name]) : "";
			} else {
				value = values[name];
			}
			obj.customFieldValue[name] = value;
		}
	}
	return obj;
};
export const VIEWS = {
	tablet: "tablet",
	mobile: "mobile",
	desktop: "desktop",
};
