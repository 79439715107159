/* eslint-disable max-len */
import {ShowInfoIcon} from "@src/assets/SvgComponents";
import ButtonComponent from "@src/common/button";
import CustomAccordion from "@src/common/CustomAccordion";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as clientActions} from "@sagas/client";
import SimpleDropDownInput from "@src/common/dropDown/simpleDropDown";
import {filteringStatusOptions} from "@src/components/dashboard/orders/staticData";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";

const ContractPdfSettings = ({loading, updateSettings, deliverPdfWithStatusName}) => {
	const {t} = useTranslation();
	const [status, setStatus] = useState(deliverPdfWithStatusName);
	const handleClick = () => {
		if (status) {
			updateSettings({
				deliverPdfWithStatus: status?.includes("CANCELLED") ? "CANCELLED" : status,
				deliverPdfWithStatusName: status,
			});
		} else {
			ToastErrorComponent(t("PleaseSelectStatus"));
		}
	};

	return (
		<CustomAccordion title={t("ContractPdfSettings")} className="contracts__accordion">
			<div className="generated__pdf__container">
				<div className="generated__pdf__container-title">
					<ShowInfoIcon fill="#C1C1C1" />
					<span>{t("ContractPdfInfo")}</span>
				</div>

				<div className="generated__pdf__container-content">
					<div className="dropdown--container">
						<p className="dropdown--container-label">{t("ChooseStatus")}</p>
						<SimpleDropDownInput
							options={filteringStatusOptions}
							value={{
								value: filteringStatusOptions.find(
									(item) => item.statusValue === status,
								)?.statusValue,
								name: filteringStatusOptions.find(
									(item) => item.statusValue === status,
								)?.name,
							}}
							title="name"
							onChange={(_, value) => {
								setStatus(value.statusValue);
							}}
						/>
					</div>
				</div>
				<div className="generated__pdf__container-actions">
					<ButtonComponent
						buttonText={t("Save")}
						disabled={loading}
						loading={loading}
						onClick={handleClick}
					/>
				</div>
			</div>
		</CustomAccordion>
	);
};

const mapStateToProps = (state) => ({
	loading: state.app.client.index.isLoading,
	deliverPdfWithStatusName: state.app.client.index.client.deliverPdfWithStatusName,
});
const mapDispatchToProps = {
	updateSettings: clientActions.updateSettings,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContractPdfSettings));
