import React from "react";
import TableCustomColumn from "@common/tableCustomColumn/TableCustomColumn";
import OrderIdCustom from "@common/orderId/OrderIdCustom";
import {DATE_FORMAT, FORMAT_DATE} from "@common/constants";
import EyeIcon from "@common/EyeIcon";
import moment from "moment";
import StatusBox from "@src/common/components/status-box";
import i18n from "../../i18n";
import {statusData} from "./orders/staticData";

export const usersColumnsCSV = [
	{title: i18n.t("FirstName"), field: "firstName"},
	{title: i18n.t("LastName"), field: "lastName"},
	{title: i18n.t("Email"), field: "email"},
	{title: i18n.t("Role"), field: "role.name"},
	{title: i18n.t("VPNumber"), field: "extVpNr"},
	{title: i18n.t("Gender"), field: "gender"},
	{title: i18n.t("HouseNumber"), field: "houseNumber"},
	{title: i18n.t("Street"), field: "street"},
	{title: i18n.t("City"), field: "city"},
	{title: i18n.t("PostCode"), field: "postCode"},
	{title: i18n.t("IDNumber"), field: "idNumber"},
	{title: i18n.t("HRNumber"), field: "hrNumber"},
	{title: i18n.t("DateOfBirth"), field: "dateOfBirth"},
	{title: i18n.t("MobilePhone"), field: "mobilePhone"},
	{title: i18n.t("TaxNumber"), field: "taxNumber"},
	{title: i18n.t("BankName"), field: "bankName"},
	{title: i18n.t("TaxOffice"), field: "taxOffice"},
	{title: i18n.t("Iban"), field: "iban"},
	{title: `${i18n.t("VATEntitlement")} URL`, field: "vatEntitlementUrl"},
	{title: `${i18n.t("TaxNumber")} URL`, field: "taxNumberUrl"},
	{title: `${i18n.t("BusinessLicense")} URL`, field: "businessLicenseUrl"},
	{title: `${i18n.t("HrNumber")} URL`, field: "hrNumberUrl"},
	{title: `${i18n.t("IDNumber")} Back URL`, field: "idNumberUrlBack"},
	{title: `${i18n.t("IDNumber")} Front URL`, field: "idNumberUrlFront"},
	{title: `${i18n.t("VATNumber")} URL`, field: "vatNumberUrl"},
];

export const usersColumns = [
	{
		key: "name",
		cellClass: "custom-cell-class",
		name: i18n.t("Username"),
		resizable: true,
		sortable: true,
		headerCellClass: "headerStyles",
		formatter: ({row}) => (
			<TableCustomColumn
				row={row}
				link="users"
				navLink={`${row?.firstName} ${row?.lastName}`}
			/>
		),
	},
	{
		name: i18n.t("Role"),
		key: "role.name",
		sortable: true,
		headerCellClass: "headerStyles",
		resizable: true,
		formatter: ({row}) => <span>{i18n.t(`${row?.role?.name}`).toUpperCase()}</span>,
	},
	{
		name: i18n.t("AgencyName"),
		key: "company",
		headerCellClass: "headerStyles",
		sortable: true,
		resizable: true,
		formatter: ({row}) => <span>{row?.company}</span>,
	},
	{
		name: i18n.t("VPNumber"),
		key: "extVpNr",
		headerCellClass: "headerStyles",
		resizable: true,
		sortable: true,
		formatter: ({row}) => <span>{row?.extVpNr ? row?.extVpNr : ""}</span>,
	},
	{
		name: i18n.t("Email"),
		key: "email",
		resizable: true,
		headerCellClass: "headerStyles",
		sortable: true,
		formatter: ({row}) => <span>{row?.email ? row?.email : ""}</span>,
		export: false,
	},
];
export const ordersForSpColumns = [
	{
		title: i18n.t("ID"),
		field: "orderId",
	},
	{title: i18n.t("TariffName"), field: "tariffName"},
	{title: i18n.t("SalesPartner"), field: "salesPartnerName"},
	{
		title: i18n.t("Status"),
		field: "orderStatus",
		render: (rowData) => (
			<span style={{color: statusColors[rowData.orderStatus]}}>{rowData.orderStatus}</span>
		),
	},
	{
		title: i18n.t("DateCreated"),
		field: "createdAt",
		render: (row) => FORMAT_DATE(row.createdAt),
	},
];

export const productsColumns = [
	{
		title: "Product Name",
		field: "name",
		image: "imageField",
		headerStyle: {
			backgroundColor: "#fbfbfb",
			fontFamily: "Montserrat",
			fontSize: "1.3rem",
		},
		cellStyle: {
			backgroundColor: "#fbfbfb",
			marginRight: "190px",
			borderRightStyle: "solid",
			borderRightColor: "#f5f5f5",
			borderRightWidth: "1px",
		},
	},
	{title: "Product ID", field: "id"},

	{
		title: "Last Updated",
		field: "updatedAt",
		// render: (row) => FORMAT_DATE(row.updatedAt),
	},
];

export const deletedColumns = [
	...usersColumns,
	{name: i18n.t("deactivatedBy"), key: "deactivatedBy", resizable: true, sortable: true},
	{
		name: i18n.t("deactivatedAt"),
		key: "deactivatedAt",
		resizable: true,
		sortable: true,
		formatter: ({row}) => (
			<span>{row?.deactivatedAt ? moment(row?.deactivatedAt).format(DATE_FORMAT) : ""}</span>
		),
	},
];

export const tariffColumns = [
	{title: i18n.t("tariffId"), field: "id"},
	{
		title: i18n.t("TariffName"),
		field: "name",
		image: "imageField",
		headerStyle: {
			backgroundColor: "#fbfbfb",
			fontFamily: "Montserrat",
			fontSize: "1.3rem",
		},
		cellStyle: {
			backgroundColor: "#fbfbfb",
			marginRight: "190px",
			borderRightStyle: "solid",
			borderRightColor: "#f5f5f5",
			borderRightWidth: "1px",
		},
	},
];

const statusColors = {
	APPROVED: "#56A8CA",
	PENDING: "#F5BB6F",
	PAID: "#60C7AB",
	ZAHLUNGSFREIGABE: "#60C7AB",
	UNVOLLSTÄNDIG: "#F5BB6F",
	ANGENOMMEN: "#56A8CA",
	SETTLED: "#1e8659",
	ABGERECHNET: "#1e8659",
	CANCELLED: "#ff5658",
	STORNO: "#ff5658",
	Storno: "#ff5658",
	Delivered: "#1e8659",
	DELIVERED: "#1e8659",
	Finished: "#1e8659",
	FINISHED: "#1e8659",
	Abgeschlossen: "#1e8659",
	ABGESCHLOSSEN: "#1e8659",
	EXPORTIERT: "#1e8659",
	BONUS: "#002D62",
	Bonus: "#002D62",
	Success: "#1D875A",
	Warning: "#E3254D",
};

export const ordersColumnsAll = [
	{title: i18n.t("VPNumber"), field: "vpNumber"},
	{title: i18n.t("VPName"), field: "salesPartnerName"},

	{
		title: i18n.t("ID"),
		field: "orderId",
		render: (row) => <OrderIdCustom row={row} />,
		export: false,
	},

	{
		title: i18n.t("Client"),
		field: "clientName",
		// render: (row) => <TableClientColumn row={row} />,
	},

	{title: i18n.t("contractId"), field: "clientOrderId"},

	{title: i18n.t("TariffName"), field: "tariffName"},

	{
		title: i18n.t("ProjectCategory"),
		field: "projectCategoryName",
	},

	{title: i18n.t("ProjectLocation"), field: "locationName"},

	{
		title: i18n.t("ContractDate"),
		field: "createdAt1",
		render: (row) => FORMAT_DATE(row.createdAt),
	},
	{
		title: i18n.t("Status"),
		field: "orderStatus",
		// export: false,
		render: (rowData) => (
			<span style={{color: statusColors[rowData.orderStatus]}}>{rowData.orderStatus}</span>
		),
	},
	{
		title: i18n.t("OrderProductNumber"),
		field: "orderProductNumber",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("DateOfSignature"),
		field: "viewOrderDetails.signatureDate1",
		hidden: true,
		export: true,
		render: (row) =>
			row.viewOrderDetails.signatureDate !== null
				? FORMAT_DATE(row.viewOrderDetails.signatureDate)
				: "",
	},

	//   { title: i18n.t('ProductName'), field: 'viewOrderDetails.productName' },

	// order details

	{
		title: i18n.t("customerType"),
		field: "customerType",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("Salutation"),
		field: "viewOrderDetails.salutation",
		hidden: true,
		export: true,
	},

	{
		title: i18n.t("UserTitle"),
		field: "userTittle",
		hidden: true,
		export: true,
	},

	{
		title: i18n.t("FirstName"),
		field: "viewOrderDetails.firstName",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("LastName"),
		field: "viewOrderDetails.lastName",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("CompanyName"),
		field: "company",
		hidden: true,
		export: true,
	},

	{
		title: i18n.t("Street"),
		field: "viewOrderDetails.street",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("HouseNumber"),
		field: "viewOrderDetails.houseNumber",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("Addition"),
		field: "viewOrderDetails.addition",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("Place"),
		field: "viewOrderDetails.place",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("subLocation"),
		field: "viewOrderDetails.subLocation",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("ZipCode"),
		field: "viewOrderDetails.zip",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("BirthDay"),
		field: "viewOrderDetails.dayOfBirth1",
		hidden: true,
		export: true,
		// render: (row) =>
		// 	row.viewOrderDetails.dayofBirth !== null
		// 		? FORMAT_DATE(row.viewOrderDetails.dayofBirth)
		// 		: "",
	},
	{title: i18n.t("Email"), field: "email", hidden: true, export: true},
	{
		title: i18n.t("Telephone"),
		field: "viewOrderDetails.phone",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("MobilePhone"),
		field: "viewOrderDetails.mobileNumber",
		hidden: true,
		export: true,
	},
	// {title: i18n.t("Fax"), field: "viewOrderDetails.fax"},

	{
		title: i18n.t("OneFamilyHouse"),
		field: "oneFamilyHouse1",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("MoreFamilyHouse"),
		field: "apartmentBuilding1",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("HomeOwner"),
		field: "underground1",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("HomeCoOwner"),
		field: "upstairs1",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("Renter"),
		field: "groundFloor1",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("NamePropertyOwner"),
		field: "floor",
		hidden: true,
		export: true,
	},

	// bank info
	{
		title: i18n.t("BankSalutation"),
		field: "viewBankAccount.salutation",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("FirstName"),
		field: "viewBankAccount.firstName",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("LastNameCompany"),
		field: "viewBankAccount.lastName",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("BankStreet"),
		field: "viewBankAccount.street",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("BankHouseNumber"),
		field: "viewBankAccount.houseNumber",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("Addition"),
		field: "viewBankAccount.addition",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("BankLocation"),
		field: "viewBankAccount.location",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("BankZipCode"),
		field: "viewBankAccount.zip",
		hidden: true,
		export: true,
	},

	{
		title: i18n.t("BankName"),
		field: "viewBankAccount.bankName",
		hidden: true,
		export: true,
	},

	// {title: i18n.t("BankCode"), field: "viewBankAccount.bankCode"},
	{
		title: i18n.t("BankIban"),
		field: "viewBankAccount.bankIban",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("BankBic"),
		field: "viewBankAccount.bankBic",
		hidden: true,
		export: true,
	},

	// {
	// 	title: i18n.t("BankCreditCardNumber"),
	// 	field: "viewBankAccount.bankCreditCardNr",
	// },
	{
		title: i18n.t("BankSignatureDate"),
		field: "viewBankAccount.signatureDate1",
		hidden: true,
		export: true,
		// render: (row) => FORMAT_DATE(row.viewOrderDetails.signatureDate),
		render: (row) =>
			row.viewBankAccount.signatureDate !== null
				? FORMAT_DATE(row.viewBankAccount.signatureDate)
				: "",
	},

	// different ivoiceing adress
	{
		title: i18n.t("AddressSalutation"),
		field: "viewAddressForInvoice.salutation",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("AdrressFullName"),
		field: "viewAddressForInvoice.fullName",
		hidden: true,
		export: true,
		// render: (row) => <TableClientColumn row={row?.viewAddressForInvoice} />,
	},
	{
		title: i18n.t("ContactPersonName"),
		field: "viewAddressForInvoice.contactPersonFirma",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("AddressEmail"),
		field: "viewAddressForInvoice.email",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("AddressBirthdate"),
		field: "viewAddressForInvoice.dayOfBirth1",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("AddressStreet"),
		field: "viewAddressForInvoice.street",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("AddressHousrNr"),
		field: "viewAddressForInvoice.houseNumber",
		hidden: true,
		export: true,
	},

	{
		title: i18n.t("AddressAddition"),
		field: "viewAddressForInvoice.addition",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("AddressCity"),
		field: "viewAddressForInvoice.abwRACity",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("AddressZipCode"),
		field: "viewAddressForInvoice.zip",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("Telephone"),
		field: "viewAddressForInvoice.phone",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("MobilePhone"),
		field: "viewAddressForInvoice.mobileNumber",
		hidden: true,
		export: true,
	},

	// former provider

	// {title: i18n.t("FormerProviderPorting"), field: "viewFormerProvider.formerProviderName"},

	{
		title: i18n.t("TransferNumber"),
		field: "checkFormerProvider1",
		hidden: true,
		export: true,
	},

	{
		title: i18n.t("FormerProviderName"),
		field: "viewFormerProvider.formerProviderName",
		hidden: true,
		export: true,
	},

	{
		title: i18n.t("ProviderSalutation"),
		field: "viewFormerProvider.salutation",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("ProviderFirstName"),
		field: "viewFormerProvider.firstName",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("ProviderLastName"),
		field: "viewFormerProvider.lastName",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("ProviderStreet"),
		field: "viewFormerProvider.street",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("ProviderHouseNumber"),
		field: "viewFormerProvider.houseNumber",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("ProviderAddition"),
		field: "viewFormerProvider.addition",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("ProviderZipCode"),
		field: "viewFormerProvider.zip",
		hidden: true,
		export: true,
	},

	{
		title: i18n.t("ProviderLocation"),
		field: "viewFormerProvider.location",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("Prefix"),
		field: "viewFormerProvider.locationPrefix",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("Extension"),
		field: "viewFormerProvider.providerExtension",
		hidden: true,
		export: true,
	},
	{
		title: `${i18n.t("ActivePhoneNumber")} 1`,
		field: "viewFormerProvider.activePhoneNumber1",
		hidden: true,
		export: true,
	},
	{
		title: `${i18n.t("ActivePhoneNumber")} 2`,
		field: "viewFormerProvider.activePhoneNumber2",
		hidden: true,
		export: true,
	},
	{
		title: `${i18n.t("ActivePhoneNumber")} 3`,
		field: "viewFormerProvider.activePhoneNumber3",
		hidden: true,
		export: true,
	},
	{
		title: `${i18n.t("ActivePhoneNumber")} 4`,
		field: "viewFormerProvider.activePhoneNumber4",
		hidden: true,
		export: true,
	},
	{
		title: `${i18n.t("ActivePhoneNumber")} 5`,
		field: "viewFormerProvider.activePhoneNumber5",
		hidden: true,
		export: true,
	},
	{
		title: `${i18n.t("ActivePhoneNumber")} 6`,
		field: "viewFormerProvider.activePhoneNumber6",
		hidden: true,
		export: true,
	},
	// {
	// 	title: "Router Classic",
	// 	field: "addonsViews.routerClassic",
	// },
	// {
	// 	title: "Router Premium",
	// 	field: "addonsViews.routerPremium",
	// },
	// {
	// 	title: "Festnetz Flatrate ",
	// 	field: "addonsViews.festnetzflat",
	// },
	// {
	// 	title: "Festnetz und Mobilfunk Flatrate",
	// 	field: "addonsViews.mobFlat",
	// },
	// {
	// 	title: "TV IPTV SD-Qualität ",
	// 	field: "addonsViews.tvSdQualität",
	// },
	// {
	// 	title: "TV IPTV HD-Qualität ",
	// 	field: "addonsViews.tvHdQualität",
	// },
	// {
	// 	title: "Upload Booster",
	// 	field: "addonsViews.uploadLeistung",
	// },

	{
		title: i18n.t("AdditionalSalutation"),
		field: "additionalContractor.salutation",
		hidden: true,
		export: true,
	},

	{
		title: i18n.t("AditionalFirstName"),
		field: "additionalContractor.firstName",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("AditionalLastName"),
		field: "additionalContractor.lastName",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("AdditionalEmail"),
		field: "additionalContractor.userEmail",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("AdditionalStreet"),
		field: "additionalContractor.street",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("AdditionalHousrNr"),
		field: "additionalContractor.houseNumber",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("AdditionalAddition"),
		field: "additionalContractor.addition",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("AdditionalPlace"),
		field: "additionalContractor.place",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("AdditionalZipCode"),
		field: "additionalContractor.zip",
		hidden: true,
		export: true,
	},

	{
		title: i18n.t("AdditionalTelephone"),
		field: "additionalContractor.telephone",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("AdditionalMobile"),
		field: "additionalContractor.mobileNumber",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("AdditionalBirthdate"),
		field: "additionalContractor.dayOfBirth",
		hidden: true,
		export: true,
		render: (row) =>
			// eslint-disable-next-line no-unused-expressions
			row.additionalContractor.dayOfBirth !== null
				? FORMAT_DATE(row.additionalContractor.dayOfBirth)
				: "",
	},
	{
		title: i18n.t("TermsAndConditions"),
		field: "termsAndConditions1",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("SendNewsLetter"),
		field: "sendNewsLetter1",
		hidden: true,
		export: true,
	},
	{
		title: i18n.t("EmailNewsLetter"),
		field: "emailNewsLetter1",
		hidden: true,
		export: true,
	},
];
export const ordersColumns = [
	{name: i18n.t("contractId"), key: "clientOrderId"},

	{
		name: i18n.t("Client"),
		key: "clientName",
	},
	{
		name: i18n.t("Status"),
		key: "orderStatus",
		export: true,
		formatter: ({row}) => (
			<StatusBox row={row?.statusName || row.orderStatus} statusData={statusData} />
		),
	},
	{name: i18n.t("ProjectLocation"), key: "locationName"},
	{name: i18n.t("SalesPerson"), key: "salesPartnerName"},

	{
		name: i18n.t("ContractDate"),
		key: "createdAt1",
		formatter: ({row}) => <span>{moment(row.createdAt).format("DD.MM.YYYY")}</span>,
	},
	{
		name: i18n.t("projectCatergory"),
		key: "projectCategoryName",
	},

	{
		name: i18n.t("FirstName"),
		key: "firstName",
	},
	{
		name: i18n.t("LastName"),
		key: "lastName",
	},
];

export const importedColumns = [
	{
		name: i18n.t("Name"),
		key: "name",
		sortable: true,
	},

	{
		name: i18n.t("DateCreated"),
		key: "createdAt",
		sortable: true,
		formatter: ({row}) => FORMAT_DATE(row.createdAt),
	},
	{
		name: i18n.t("ImportedFrom"),
		key: "uploadedBy",
		sortable: true,
	},
	{
		name: i18n.t("Status"),
		key: "status",
		sortable: true,
		formatter: ({row}) => <span style={{color: statusColors[row.status]}}>{row.status}</span>,
	},
	// {
	// 	render: (rowData) => <EyeIcon id={rowData.id} type="salestracker" />,
	// },
];
export const exportColumns = [
	{
		title: i18n.t("Name"),
		field: "name",
	},

	{
		title: i18n.t("DateCreated"),
		field: "createdAt",
		render: (row) => FORMAT_DATE(row.createdAt),
	},
	{
		title: i18n.t("ImportedFrom"),
		field: "uploadedBy",
	},
	{
		title: i18n.t("Status"),
		field: "status",
		render: (row) => <span style={{color: statusColors[row.status]}}>{row.status}</span>,
	},
];
export const leaderboardColumns = [
	{
		name: i18n.t("Nr"),
		key: "nr",
		width: 0,
		formatter: ({row, rowIdx}) => (
			<span
				style={{
					color: row.active && "#6c1eb0",
					fontWeight: row.active && 700,
				}}
			>
				{rowIdx + 1}
			</span>
		),
	},
	{
		name: i18n.t("name"),
		key: "fullName",
		formatter: ({row}) => (
			<span style={{color: row.active && "#6c1eb0", fontWeight: row.active && 700}}>
				{row.fullName}
			</span>
		),
	},
	{
		name: i18n.t("Orders"),
		key: "contracts",
		sortable: true,
		width: 0,
		formatter: ({row}) => (
			<span
				style={{
					color: row.active && "#6c1eb0",
					fontWeight: row.active && 700,
				}}
			>
				{row.contracts}
			</span>
		),
	},
	{
		name: i18n.t("Points"),
		key: "points",
		sortable: true,
		width: 0,
		formatter: ({row}) => (
			<span
				style={{
					color: row.active && "#6c1eb0",
					fontWeight: row.active && 700,
				}}
			>
				{(row.points || 0).toFixed(2)}
			</span>
		),
	},
];

export const leaderboardEmployeeColumns = [
	{
		name: i18n.t("Nr"),
		key: "nr",
		width: 0,
		formatter: ({row, rowIdx}) => (
			<span
				style={{
					color: row.active && "#6c1eb0",
					fontWeight: row.active && 700,
				}}
			>
				{rowIdx + 1}
			</span>
		),
	},
	{
		name: i18n.t("name"),
		key: "fullName",
		formatter: ({row}) => (
			<span style={{color: row.active && "#6c1eb0", fontWeight: row.active && 700}}>
				{row.fullName}
			</span>
		),
	},
	{
		name: i18n.t("Orders"),
		key: "contracts",
		sortable: true,
		width: 0,
		formatter: ({row}) => (
			<span
				style={{
					color: row.active && "#6c1eb0",
					fontWeight: row.active && 700,
				}}
			>
				{row.contracts}
			</span>
		),
	},
];
