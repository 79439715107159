/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import produce from "immer";
import _ from "lodash";
import {put, select, takeLatest} from "redux-saga/effects";
import {ToastErrorComponent} from "@src/common/ToastComponent/ToastComponent";
import {actions as createGoalActions} from "@sagas/analytics/goals/create";
import axios from "axios";
import {ENDPOINT} from "@src/utils/endpoint";
import moment from "moment";
import createAction from "../../../../utils/action-creator";
import {queryInitialValues} from "./staticData";

// const logger = new Logger("Sagas>Goals>Index");
const PREFIX = "@app/goals/";

export const SET_MODAL = `${PREFIX}SET_MODAL`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const FETCH_GOALS = `${PREFIX}FETCH_GOALS`;
export const FETCH_GOALS_SUCCESS = `${PREFIX}FETCH_GOALS_SUCCESS`;
export const ADD_GOAL_SUCCESS = `${PREFIX}ADD_GOAL_SUCCESS`;
export const EDIT_GOAL_SUCCESS = `${PREFIX}EDIT_GOAL_SUCCESS`;
export const DELETE_GOAL_SUCCESS = `${PREFIX}DELETE_GOAL_SUCCESS`;
export const TOGGLE_GOAL_ON_DASHBOARD = `${PREFIX}TOGGLE_GOAL_ON_DASHBOARD`;
export const TOGGLE_GOAL_ON_DASHBOARD_SUCCESS = `${PREFIX}TOGGLE_GOAL_ON_DASHBOARD_SUCCESS`;
export const SET_PAGE = `${PREFIX}SET_PAGE`;
export const SET_SCROLL_LOADING = `${PREFIX}SET_SCROLL_LOADING`;
export const SET_QUERY = `${PREFIX}SET_QUERY`;
export const CLEAN_QUERY = `${PREFIX}CLEAN_QUERY`;
export const GET_GOAL_BY_ID = `${PREFIX}GET_GOAL_BY_ID`;
export const RESET_PAGINATION = `${PREFIX}RESET_PAGINATION`;

const _state = {
	modal: {open: false, type: ""},
	loading: false,
	goals: [],
	page: 1,
	size: 15,
	totalPages: 1,
	totalSize: 0,
	scrollLoading: false,
	searchTerm: "",
	query: {...queryInitialValues},
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case SET_MODAL:
				draft.modal = payload;
				break;
			case SET_LOADING:
				draft.loading = payload;
				break;
			case FETCH_GOALS_SUCCESS:
				const updatedPayload = payload?.data?.map((i) => {
					if (i?.endDate < moment().valueOf()) {
						return {
							...i,
							hasExpired: true,
						};
					}
					return i;
				});
				draft.goals = state.page > 1 ? [...state.goals, ...updatedPayload] : updatedPayload;
				draft.totalPages = payload.totalPages;
				draft.totalSize = payload.totalSize;
				break;
			case ADD_GOAL_SUCCESS:
				const updatedGoal = {
					...payload,
					hasExpired: payload?.endDate < moment().valueOf(),
				};
				draft.goals = [updatedGoal, ...state.goals];
				break;
			case EDIT_GOAL_SUCCESS:
				const index = state.goals.findIndex((g) => g.id === payload.id);
				const updatedIndex = {
					...payload,
					hasExpired: payload?.endDate < moment().valueOf(),
				};
				draft.goals[index] = updatedIndex;
				break;
			case DELETE_GOAL_SUCCESS:
				draft.goals = state.goals.filter((item) => !payload.includes(item.id));
				break;
			case TOGGLE_GOAL_ON_DASHBOARD_SUCCESS:
				const {ids, showDashboard, showMobile, mobile} = payload;
				draft.goals = state.goals.map((goal) => {
					if (ids.includes(goal.id)) {
						return {
							...goal,
							showDashboard: mobile ? goal?.showDashboard : showDashboard,
							showMobile: mobile ? showMobile : goal?.showMobile,
						};
					}
					return goal;
				});
				break;
			case SET_PAGE:
				draft.page = state.page + 1;
				break;
			case SET_SCROLL_LOADING:
				draft.scrollLoading = payload;
				break;
			case SET_QUERY:
				draft.query = payload;
				break;
			case CLEAN_QUERY:
				draft.query = {...queryInitialValues};
				break;
			case RESET_PAGINATION:
				draft.page = 1;
				draft.size = 15;
				draft.totalPages = 1;
				draft.totalSize = 0;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	setShowModal: (payload) => createAction(SET_MODAL, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	fetchGoals: (payload) => createAction(FETCH_GOALS, {payload}),
	fetchGoalsSuccess: (payload) => createAction(FETCH_GOALS_SUCCESS, {payload}),
	addGoalSuccess: (payload) => createAction(ADD_GOAL_SUCCESS, {payload}),
	editGoalSuccess: (payload) => createAction(EDIT_GOAL_SUCCESS, {payload}),
	deleteGoalsSuccess: (payload) => createAction(DELETE_GOAL_SUCCESS, {payload}),
	toggleGoalOnDashboard: (payload) => createAction(TOGGLE_GOAL_ON_DASHBOARD, {payload}),
	toggleGoalOnDashboardSuccess: (payload) =>
		createAction(TOGGLE_GOAL_ON_DASHBOARD_SUCCESS, {payload}),
	setScrollLoading: (payload) => createAction(SET_SCROLL_LOADING, {payload}),
	setPage: (payload) => createAction(SET_PAGE, {payload}),
	setQuery: (payload) => createAction(SET_QUERY, {payload}),
	cleanQuery: (payload) => createAction(CLEAN_QUERY, {payload}),
	getGoalById: (payload) => createAction(GET_GOAL_BY_ID, {payload}),
	resetPagination: (payload) => createAction(RESET_PAGINATION, {payload}),
};

export const sagas = {
	// *fetchGoals({payload}) {
	// 	const {page, size} = yield select((state) => state.app.analytics.index);
	// 	yield put(actions.setLoading(true));
	// 	if (page > 1) {
	// 		yield put(actions.setScrollLoading(true));
	// 	} else {
	// 		yield put(actions.setLoading(true));
	// 	}
	// 	try {
	// 		const response = yield axios.post(
	// 			ENDPOINT.GET_GOALS.replace(":size", size).replace(":page", page),
	// 			payload,
	// 		);
	// 		yield put(actions.fetchGoalsSuccess(response.data.data));
	// 	} catch (error) {
	// 		ToastErrorComponent(error?.response?.data?.message);
	// 	} finally {
	// 		yield put(actions.setLoading(false));
	// 		yield put(actions.setScrollLoading(false));
	// 	}
	// },
	*fetchGoals({payload}) {
		const {page, size} = yield select((state) => state.app.analytics.index);
		if (page > 1) {
			yield put(actions.setScrollLoading(true));
		} else {
			yield put(actions.setLoading(true));
		}
		try {
			const response = yield axios.post(
				ENDPOINT.GET_GOALS.replace(":size", size).replace(":page", page),
				{...payload},
			);

			yield put(actions.fetchGoalsSuccess(response.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
		} finally {
			yield put(actions.setLoading(false));
			yield put(actions.setScrollLoading(false));
		}
	},
	// eslint-disable-next-line require-yield
	*toggleGoalOnDashboard({payload}) {
		try {
			const {ids, showDashboard, mobile, showMobile} = payload;
			yield axios.put(!mobile ? ENDPOINT.TOGGLE_GOALS : ENDPOINT.TOGGLE_GOALS_MOBILE, {
				showDashboard: showDashboard && showDashboard,
				showMobile: showMobile && showMobile,
				goalIds: ids,
			});
			yield put(actions.toggleGoalOnDashboardSuccess(payload));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
		}
	},
	*getGoalById({payload}) {
		const {usersOptions} = yield select((state) => state.app.users.index);
		try {
			const response = yield axios.get(`/goals/${payload}`);
			yield put(createGoalActions.setInitialValues({data: response?.data, usersOptions}));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(FETCH_GOALS, sagas.fetchGoals);
	yield takeLatest(TOGGLE_GOAL_ON_DASHBOARD, sagas.toggleGoalOnDashboard);
	yield takeLatest(GET_GOAL_BY_ID, sagas.getGoalById);
};
