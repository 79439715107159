import i18n from "@src/i18n";

export const userRoles = [
	{name: i18n.t("SP"), value: 3},
	{name: i18n.t("Employee"), value: 5},
	{name: i18n.t("Agency"), value: 6},
	{name: i18n.t("AgencySP"), value: 7},
	{name: i18n.t("BackOffice"), value: 8},
	{name: i18n.t("ManagementInfo"), value: 9},
	{name: i18n.t("EMPLOYEE_SHOP"), value: 11},
	{name: i18n.t("EmployeeTL"), value: 12},
	{name: i18n.t("EmployeeAB"), value: 13},
	{name: i18n.t("Agency70"), value: 14},
];

export const companyType = [
	{value: 1, name: i18n.t("EINZELUNTERNEHMEN")},
	{value: 2, name: i18n.t("GMBH")},
	{value: 3, name: i18n.t("UG")},
	{value: 4, name: i18n.t("AG")},
	{value: 5, name: i18n.t("GBR")},
	{value: 6, name: i18n.t("OHG")},
	{value: 7, name: i18n.t("LIMITED")},
];

export const gender = [
	{
		name: i18n.t("Male"),
		value: "MALE",
	},
	{
		name: i18n.t("Female"),
		value: "FEMALE",
	},
];

export const STRUCTURE_ROLES = [3, 5, 11, 12, 13, 14];
