import React from "react";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextAreaComponent from "@src/common/TextArea/TextAreaComponent";
import ColorPickerPopOver from "@src/common/ColorPickerComponent";
import {ToggleAccordionIcon2} from "@src/assets/SvgComponents";
import {statusData} from "@src/components/dashboard/orders/staticData";
import StatusBox from "@src/common/components/status-box";
import i18n from "@src/i18n";
import {analyticsStatusOptions, pritorities} from "../../staticData";
import AlertsCards from "../../components/AlertsCardComponent.js";

const ActivityThirdStep = ({formik}) => {
	const {setFieldValue, values, touched, errors} = formik;
	return (
		<div className="firstStep__container--third_container">
			<div className="activity__modal__dropdown__menu">
				<div className="activity__modal__dropdown__menu--items">
					<div className="activity__modal__dropdown__menu--items-each">
						<label htmlFor="selectStatus">{i18n.t("priority")}</label>
						<Autocomplete
							popupIcon={<ToggleAccordionIcon2 />}
							disableClearable={false}
							className="autocomplete"
							disableCloseOnSelect={false}
							filterSelectedOptions={true}
							getOptionLabel={(option) => option.label}
							getOptionSelected={(option, value) => option?.value === value?.value}
							multiple={false}
							onChange={(event, value) => {
								setFieldValue("priority", value?.value);
							}}
							options={pritorities}
							// classes={{popper: classes.popper}}
							defaultValue={pritorities.find(
								(element) => element.value === values.priority,
							)}
							renderOption={(option) => (
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										gap: "5px",
									}}
								>
									{option.icon}
									<span style={{color: option.color}}>{option.label}</span>
								</div>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									className="text-field"
									id="name"
									name="name"
									placeholder="Select"
									variant="outlined"
								/>
							)}
						/>
						{errors?.priority && touched?.priority && (
							<span className="errorClass">*{errors?.priority}</span>
						)}
					</div>
				</div>
				<div className="activity__modal__dropdown__menu--items">
					<div
						className="activity__modal__dropdown__menu--items-each
		                  activity__modal__dropdown__select-status"
					>
						<label htmlFor="selectStatus">{i18n.t("selectStatus")}</label>
						<Autocomplete
							popupIcon={<ToggleAccordionIcon2 />}
							disableClearable={false}
							className="autocomplete"
							disableCloseOnSelect={false}
							filterSelectedOptions={true}
							getOptionLabel={(option) => option.name}
							getOptionSelected={(option, value) =>
								option?.statusValue === value?.statusValue
							}
							multiple={true}
							onChange={(event, value) => {
								setFieldValue(
									"orderStatusList",
									value?.map((item) => item.statusValue),
								);
							}}
							value={analyticsStatusOptions.filter((element) =>
								values.orderStatusList.includes(element.statusValue),
							)}
							options={analyticsStatusOptions}
							renderOption={(option) => (
								<StatusBox
									statusData={statusData}
									row={option.statusValue}
									translateText
								/>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									className="text-field"
									id="name"
									name="name"
									placeholder="Select"
									variant="outlined"
								/>
							)}
						/>
						{errors?.orderStatusList && touched?.orderStatusList && (
							<span className="errorClass">*{errors?.orderStatusList}</span>
						)}
					</div>
				</div>
				<div className="activity__modal__dropdown__menu--items">
					<div className="activity__modal__dropdown__menu--items-each inputFocus">
						<label htmlFor="selectStatus">{i18n.t("Description")}</label>
						<TextAreaComponent
							maxLength={200}
							value={values?.description}
							setFieldValue={setFieldValue}
							breakLine={false}
							hasDescription={false}
							classContainer="activity__textarea"
							name="description"
							errors={errors?.description}
							touched={touched?.description}
							errorClass="errorClass"
						/>
					</div>
				</div>
				<div className="activity__modal__dropdown__menu--items">
					<div
						className="activity__modal__dropdown__menu--items-each"
						style={{gap: "5px"}}
					>
						<label htmlFor="selectStatus">{i18n.t("txtColor")}</label>
						<ColorPickerPopOver
							values={values}
							color={values.textColor}
							name="textColor"
							onChange={(value) => {
								setFieldValue("textColor", value);
							}}
							textColor="#000000"
						/>
					</div>
					{errors?.textColor && touched?.textColor && (
						<span className="errorClass">*{errors?.textColor}</span>
					)}
				</div>
				<div className="activity__modal__dropdown__menu--items">
					<div
						className="activity__modal__dropdown__menu--items-each"
						style={{gap: "5px"}}
					>
						<label htmlFor="selectStatus">{i18n.t("bgColor")}</label>
						<div className="accordions-wrapper-second">
							<ColorPickerPopOver
								values={values}
								name="bgColor"
								color={values.backgroundColor}
								onChange={(value) => {
									setFieldValue("bgColor", value);
								}}
								textColor="#6C1EB0"
							/>
						</div>
						{errors?.backgroundColor && touched?.backgroundColor && (
							<span className="errorClass">*{errors?.backgroundColor}</span>
						)}
					</div>
				</div>
			</div>
			<div className="activity__modal__dropdown__menu--items" style={{marginTop: "20px"}}>
				<div className="activity__modal__dropdown__menu--items-each preview__card">
					<label htmlFor="selectStatus">{i18n.t("previewCard")}</label>
					<div className="activity__modal__dropdown__menu--items-previewCard">
						<AlertsCards disabled={true} item={values} />
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	clientList: state.app.client.index.clientsByDropdown,
	usersOptions: state.app.users.index.usersOptions,
	loggedUser: state.app.users.index.loggedUser,
	teams: state.app.teams.index.teams,
	agencies: state.app.teams.index.agencies,
});
export default connect(mapStateToProps, null)(ActivityThirdStep);
