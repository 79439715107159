import React from "react";
import * as yup from "yup";
import {FORMAT_DATE} from "@common/constants";
import HtmlTooltip from "@common/htmlTooltip";
import {SelectColumn} from "react-data-grid";
import moment from "moment";
import {
	BonusIcon,
	DeliveredIcon,
	EuroSign,
	FinishedIcon,
	ProgressIcon,
	SettledIcon,
	SingatureMissIcon,
	SuccessIcon,
	WarningIcon,
	PaidIcon,
	ApprovedIcon,
	PendingIcon,
	CancelledIcon,
	IconDelivered,
	IconFinished,
	SignatureIcon,
	IconBonus,
	ReactivatedIcon,
	ReactivatedStatus,
	AllOrdersIcon,
	InProgressIcon,
	BonusSettledIcon,
} from "@src/assets/SvgComponents";
import StatusBox from "@src/common/components/status-box";
import {formatNumber} from "@src/utils/convertNumber";
import i18n from "../../../i18n";

// export const statusOptions = [

// 	{
// 		name: i18n.t("Paid"),
// 		value: i18n.t("PAID"),
// 		statusValue: "PAID",
// 		icon: <PaidIcon />,
// 	},
// 	{
// 		name: i18n.t("Pending"),
// 		value: i18n.t("PENDING"),
// 		statusValue: "PENDING",
// 		icon: <PendingIcon />,
// 	},
// 	{
// 		name: i18n.t("Cancelled"),
// 		value: i18n.t("CANCELLED"),
// 		statusValue: "CANCELLED",
// 		icon: <CancelledIcon />,
// 	},
// 	{
// 		name: i18n.t("Delivered"),
// 		value: i18n.t("DELIVERED"),
// 		statusValue: "DELIVERED",
// 		icon: <IconDelivered />,
// 	},
// 	{
// 		name: i18n.t("Finished"),
// 		value: i18n.t("FINISHED"),
// 		statusValue: "FINISHED",
// 		icon: <IconFinished />,
// 	},
// 	{
// 		name: i18n.t("Bonus"),
// 		value: i18n.t("BONUS"),
// 		statusValue: "BONUS",
// 		icon: <IconBonus />,
// 	},
// 	{
// 		name: i18n.t("NoSignature"),
// 		value: i18n.t("NOSIGNATURE"),
// 		statusValue: "NOSIGNATURE",
// 		icon: <SignatureIcon />,
// 	},
// ];

export const statusData = {
	// PROGRESS
	IN_PROGRESS: {icon: <InProgressIcon />, color: "#FF8A00"},
	IM_GANGE: {icon: <InProgressIcon />, color: "#FF8A00"},
	// APPROVED
	APPROVED: {icon: <SuccessIcon />, color: "#5673C2", value: "APPROVED"},
	ANGENOMMEN: {icon: <SuccessIcon />, color: "#5673C2", value: "APPROVED"},
	// CLARIFICATION
	CLARIFICATION: {icon: <SuccessIcon />, color: "#5673C2", value: "CLARIFICATION"},
	KLÄRUNG: {icon: <SuccessIcon />, color: "#5673C2", value: "KLÄRUNG"},
	// BILLING_NEXT_MONTH
	BILLING_NEXT_MONTH: {icon: <SuccessIcon />, color: "#5673C2", value: "BILLING_NEXT_MONTH"},
	"Billing next month": {icon: <SuccessIcon />, color: "#5673C2", value: "BILLING_NEXT_MONTH"},
	"Abrechnung Folgemonat": {icon: <SuccessIcon />, color: "#5673C2", value: "BILLING_NEXT_MONTH"},
	ABRECHNUNG_FOLGEMONAT: {icon: <SuccessIcon />, color: "#5673C2", value: "BILLING_NEXT_MONTH"},
	// PENDING
	PENDING: {icon: <ProgressIcon />, color: "#EECB64", value: "PENDING"},
	"PARKENDE-AUFTRÄGE": {icon: <ProgressIcon />, color: "#EECB64", value: "PENDING"},
	// PAID
	PAID: {icon: <EuroSign stroke="#fff" />, color: "#6DB070", value: "PAID"},
	ZAHLUNGSFREIGABE: {icon: <EuroSign stroke="#fff" />, color: "#6DB070", value: "PAID"},
	// SETTLED
	SETTLED: {icon: <SettledIcon />, color: "#5D7E93", value: "SETTLED"},
	ABGERECHNET: {icon: <SettledIcon />, color: "#5D7E93", value: "SETTLED"},
	// CANCELLED
	CANCELLED: {icon: <WarningIcon />, color: "#DA6D6D", value: "CANCELLED"},
	STORNO: {icon: <WarningIcon />, color: "#DA6D6D", value: "CANCELLED"},
	Storno: {icon: <WarningIcon />, color: "#DA6D6D", value: "CANCELLED"},
	// FINISHED
	Finished: {icon: <FinishedIcon />, color: "#9A729E", value: "FINISHED"},
	FINISHED: {icon: <FinishedIcon />, color: "#9A729E", value: "FINISHED"},
	Abgeschlossen: {icon: <FinishedIcon />, color: "#9A729E", value: "FINISHED"},
	ABGESCHLOSSEN: {icon: <FinishedIcon />, color: "#9A729E", value: "FINISHED"},
	// CONTRACT_REACTIVATED
	"Contract Reactivated": {
		icon: <ReactivatedStatus />,
		color: "#2A3CAE",
		value: "CONTRACT_REACTIVATED",
	},
	CONTRACT_REACTIVATED: {
		icon: <ReactivatedStatus />,
		color: "#2A3CAE",
		value: "CONTRACT_REACTIVATED",
	},
	"Vertrag Reaktiviert": {
		icon: <ReactivatedStatus />,
		color: "#2A3CAE",
		value: "CONTRACT_REACTIVATED",
	},
	// NOSIGNATURE
	NoSignature: {icon: <SingatureMissIcon />, color: "#B6B6B6", value: "NOSIGNATURE"},
	"UNTERSCHRIFT-FEHLT": {icon: <SingatureMissIcon />, color: "#B6B6B6", value: "NOSIGNATURE"},
	NOSIGNATURE: {icon: <SingatureMissIcon />, color: "#B6B6B6", value: "NOSIGNATURE"},
	// DELIVERED
	Delivered: {icon: <DeliveredIcon />, color: "#68ACE8", value: "DELIVERED"},
	DELIVERED: {icon: <DeliveredIcon />, color: "#68ACE8", value: "DELIVERED"},
	EXPORTIERT: {icon: <DeliveredIcon />, color: "#68ACE8", value: "DELIVERED"},
	// BONUS
	BONUS: {icon: <BonusIcon />, color: "#C57BC4", value: "BONUS"},
	Bonus: {icon: <BonusIcon />, color: "#C57BC4", value: "BONUS"},
	// BONUS_SETTLED
	BONUS_SETTLED: {icon: <BonusSettledIcon />, color: "#008C8C", value: "BONUS_SETTLED"},
	"BONUS SETTLED": {icon: <BonusSettledIcon />, color: "#008C8C", value: "BONUS_SETTLED"},
	"BONUS ABWICKELT": {icon: <BonusSettledIcon />, color: "#008C8C", value: "BONUS_SETTLED"},
	// CANCELLED_CUSTOMER
	CANCELLED_CUSTOMER: {icon: <WarningIcon />, color: "#DA6D6D", value: "CANCELLED_CUSTOMER"},
	WIDERRUF_KUNDE: {icon: <WarningIcon />, color: "#DA6D6D", value: "CANCELLED_CUSTOMER"},
	// CANCELLED_INCORRECT_ORDER
	CANCELLED_INCORRECT_ORDER: {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		value: "CANCELLED_INCORRECT_ORDER",
	},
	AUFTRAG_FEHLERHAFT: {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		value: "CANCELLED_INCORRECT_ORDER",
	},
	// CANCELLED_PROVIDER
	CANCELLED_PROVIDER: {icon: <WarningIcon />, color: "#DA6D6D", value: "CANCELLED_PROVIDER"},
	AUFTRAG_VOM_ANBIETER_STORNIERT: {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		value: "CANCELLED_PROVIDER",
	},

	"Revert Success": {icon: <SuccessIcon />, color: "#6DB070"},
	"Erfolg zurücksetzen": {icon: <SuccessIcon />, color: "#6DB070"},
	Success: {icon: <SuccessIcon />, color: "#6DB070"},
	Erfolg: {icon: <SuccessIcon />, color: "#6DB070"},
	Warning: {icon: <WarningIcon />, color: "#DA6D6D"},
	Warnung: {icon: <WarningIcon />, color: "#DA6D6D"},
	"In Process": {icon: <ProgressIcon />, color: "#EECB64"},
	"In Bearbeitung": {icon: <ProgressIcon />, color: "#EECB64"},
	Failed: {icon: <WarningIcon />, color: "#DA6D6D"},
	"Revert In Process": {icon: <ProgressIcon />, color: "#EECB64"},
	"In Zurücksetzen in Bearbeitung": {
		icon: <ProgressIcon />,
		color: "#EECB64",
	},
	Fehlgeschlagen: {icon: <WarningIcon />, color: "#DA6D6D"},
	Importing: {icon: <ProgressIcon />, color: "#EECB64"},
	Imported: {icon: <SuccessIcon />, color: "#6DB070"},
	CANCELLED_DOUBLE: {icon: <WarningIcon />, color: "#DA6D6D", value: "CANCELLED_DOUBLE"},
	ABGESAGT_DOUBLE: {icon: <WarningIcon />, color: "#DA6D6D", value: "CANCELLED_DOUBLE"},
	"Cancelled Double": {icon: <WarningIcon />, color: "#DA6D6D", value: "CANCELLED_DOUBLE"},
	"Abgesagt Double": {icon: <WarningIcon />, color: "#DA6D6D", value: "CANCELLED_DOUBLE"},
	// CANCELLED_CREDIT_WORTH
	CANCELLED_CREDIT_WORTH: {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		value: "CANCELLED_CREDIT_WORTH",
	},
	"Cancelled credit worth": {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		value: "CANCELLED_CREDIT_WORTH",
	},
	STORNO_BONITÄT: {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		value: "CANCELLED_CREDIT_WORTH",
	},
	"Storno Bonität": {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		value: "CANCELLED_CREDIT_WORTH",
	},
	EXCLUDE_SETTLEMENT: {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		value: "EXCLUDE_SETTLEMENT",
	},
	"Exclude from Settlement": {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		value: "EXCLUDE_SETTLEMENT",
	},
	"Nicht abrechnen": {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		value: "EXCLUDE_SETTLEMENT",
	},
};

export const tableTabs = [
	{
		icon: <AllOrdersIcon />,
		name: i18n.t("allOrders"),
		type: "all",
	},
	{
		icon: <EuroSign stroke="#979797" width={18} height={18} />,
		name: i18n.t("paidOrders"),
		type: "PAID",
	},
	{
		icon: <BonusIcon stroke="#979797" />,
		name: "Bonus",
		type: "BONUS",
	},
];
export const infoChefColumns = [
	{
		name: i18n.t("month"),
		key: "month",
		formatter: ({row}) => <span>{i18n.t(row?.month)}</span>,
	},
	{
		name: i18n.t("accessCancellationReserve"),
		key: "accessCancellationReserve",
		formatter: ({row}) => <span>{`${formatNumber(row?.accessCancellationReserve)}€`}</span>,
	},
	{
		name: i18n.t("standCancellationReserve"),
		key: "standCancellationReserve",
		formatter: ({row}) => <span>{`${formatNumber(row?.standCancellationReserve)}€`}</span>,
	},
	{
		name: i18n.t("payout"),
		formatter: ({row}) => <span>{`${formatNumber(row?.payout)}€`}</span>,
		key: "payout",
	},
];

export const updatedStatusesColumns = [
	{
		name: i18n.t("Finished"),
		key: "numOfOrdersProcessing",
		formatter: ({row}) => {
			const total = row?.numOfOrders
				? `${
						row?.numOfOrdersProcessing <= row?.numOfOrders
							? row?.numOfOrdersProcessing
							: row?.numOfOrders
				  } ${i18n.t("Of")} ${row?.numOfOrders}`
				: "";
			const reverted =
				row?.numOfOrders &&
				row?.numOfOrdersProcessing > row?.numOfOrders &&
				row?.numOfOrdersProcessing - row?.numOfOrders;
			return (
				<span style={{fontWeight: "500"}}>
					{total} {reverted && `/ (${reverted}) ${i18n.t("Reverted")}`}
				</span>
			);
		},
	},
	{
		name: i18n.t("ProgressStatus"),
		key: "progressStatus",
		formatter: ({row}) => {
			const calcPercentage =
				row?.numOfOrdersProcessing <= row?.numOfOrders
					? (row?.numOfOrdersProcessing / row?.numOfOrders) * 100
					: (row?.numOfOrders / row?.numOfOrders) * 100;
			const percentage = row?.numOfOrders && calcPercentage;
			return (
				<HtmlTooltip
					placement="top"
					title={
						<span style={{fontSize: "14px"}}>
							{percentage && `${percentage?.toFixed(percentage !== 100 ? 2 : 0)}%`}
						</span>
					}
				>
					<span>
						<StatusBox
							row={row.progressStatus}
							statusData={statusData}
							percentage={percentage}
						/>
					</span>
				</HtmlTooltip>
			);
		},
	},
	{
		name: i18n.t("uploadedBy"),
		key: "uploadedBy",
	},
	{
		name: i18n.t("startTime"),
		key: "createdAt",
		formatter: ({row}) => <span>{moment(row?.createdAt).format("DD.MM.YYYY, HH:mm")}</span>,
	},
	{
		name: i18n.t("endTime"),
		key: "updatedAt",
		formatter: ({row}) => (
			<span>
				{row?.updatedAt !== 0 ? moment(row?.updatedAt).format("DD.MM.YYYY, HH:mm") : "-"}
			</span>
		),
	},
	{
		name: i18n.t("UpdatedStatus"),
		key: "status",
		// width: 280,
		formatter: ({row}) => (
			<StatusBox row={row?.statusName || row.status} statusData={statusData} />
		),
	},
];
export const columns = [
	SelectColumn,
	{name: i18n.t("VPNumber"), key: "vpNumber", width: 0, sortable: true},
	{name: i18n.t("VPName"), key: "salesPartnerName", sortable: true},
	{
		name: i18n.t("ID"),
		key: "orderId",
		sortable: true,
	},
	{
		name: i18n.t("Client"),
		key: "clientName",
		sortable: true,
	},
	{name: i18n.t("contractId"), key: "clientOrderId", sortable: true},
	{
		name: i18n.t("TariffName"),
		key: "tariffName",
		sortable: true,
	},

	{
		name: i18n.t("ProjectCategory"),
		key: "projectCategoryName",
		sortable: true,
		type: "orders",
	},

	{name: i18n.t("ProjectLocation"), key: "locationName", sortable: true, type: "orders"},

	{
		name: i18n.t("ContractDate"),
		key: "createdAt1",
		formatter: ({row}) => <span>{moment(row?.createdAt).format("DD.MM.YYYY")}</span>,
		sortable: true,
	},
	{
		name: i18n.t("Status"),
		key: "orderStatus",
		width: 230,
		formatter: ({row}) => {
			const isInProgress = row?.orderStatus === "IN_PROGRESS";
			const tooltipText = isInProgress
				? i18n.t("checkStatusHistory")
				: `${i18n.t("ChangedAt")}: ${moment(row?.statusChangeTime).format("DD.MM.YYYY")}`;

			return (
				<>
					{row?.statusChangeTime !== null ? (
						<HtmlTooltip
							placement="bottom"
							title={
								<span style={{fontSize: "14px"}}>
									{isInProgress ? (
										<span>{tooltipText}</span>
									) : (
										<div style={{display: "flex", flexDirection: "column"}}>
											<div>
												<span style={{fontSize: "14px", fontWeight: 600}}>
													{i18n.t("ChangedAt")}:{" "}
												</span>
												{moment(row?.statusChangeTime).format("DD.MM.YYYY")}
											</div>
											{row?.voSettledUUID && (
												<span className="flagData">
													{i18n.t("HasSettlementCreated")}
												</span>
											)}
										</div>
									)}
								</span>
							}
						>
							<span>
								<StatusBox
									row={row?.statusName || row?.orderStatus}
									statusData={statusData}
									flag={row?.voSettledUUID && true}
								/>
							</span>
						</HtmlTooltip>
					) : (
						<StatusBox
							row={row?.statusName || row?.orderStatus}
							statusData={statusData}
							flag={row?.voSettledUUID && true}
						/>
					)}
				</>
			);
		},
		sortable: true,
	},
	{
		name: i18n.t("OrderProductNumber"),
		key: "orderProductNumber",
		sortable: true,
		type: "orders",
	},
	// {
	// 	name: i18n.t("customerType"),
	// 	key: "customerType",
	// 	sortable: true,
	// },
];

export const statusOptions = [
	{
		name: i18n.t("Approved"),
		value: i18n.t("APPROVED"),
		statusValue: "APPROVED",
		icon: <ApprovedIcon />,
	},
	{
		name: i18n.t("Clarification"),
		value: i18n.t("CLARIFICATION"),
		statusValue: "CLARIFICATION",
		icon: <ApprovedIcon />,
	},
	{
		name: i18n.t("BillingNextMonth"),
		value: i18n.t("BILLING_NEXT_MONTH"),
		statusValue: "BILLING_NEXT_MONTH",
		icon: <ApprovedIcon />,
	},
	{
		name: i18n.t("Paid"),
		value: i18n.t("PAID"),
		statusValue: "PAID",
		icon: <PaidIcon />,
	},
	{
		name: i18n.t("Pending"),
		value: i18n.t("PENDING"),
		statusValue: "PENDING",
		icon: <PendingIcon />,
	},
	{
		name: i18n.t("Cancelled"),
		value: i18n.t("CANCELLED"),
		statusValue: "CANCELLED",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("Delivered"),
		value: i18n.t("DELIVERED"),
		statusValue: "DELIVERED",
		icon: <IconDelivered />,
	},
	{
		name: i18n.t("Finished"),
		value: i18n.t("FINISHED"),
		statusValue: "FINISHED",
		icon: <IconFinished />,
	},
	{
		name: i18n.t("Bonus"),
		value: i18n.t("BONUS"),
		statusValue: "BONUS",
		icon: <IconBonus />,
	},
	{
		name: i18n.t("NoSignature"),
		value: i18n.t("NOSIGNATURE"),
		statusValue: "NOSIGNATURE",
		icon: <SignatureIcon />,
	},
	{
		name: i18n.t("CancellationCustomer"),
		value: i18n.t("CANCELLATIONCUSTOMER"),
		statusValue: "CANCELLED_CUSTOMER",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("CancelledByProvider"),
		value: i18n.t("CANCELLEDBYPROVIDER"),
		statusValue: "CANCELLED_PROVIDER",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("OrderIncorrect"),
		value: i18n.t("ORDERINCORRECT"),
		statusValue: "CANCELLED_INCORRECT_ORDER",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("ContractReactivated"),
		value: i18n.t("CONTRACTREACTIVATED"),
		statusValue: "CONTRACT_REACTIVATED",
		icon: <ReactivatedIcon />,
	},
	{
		name: i18n.t("CancelledDouble"),
		value: i18n.t("CANCELLED_DOUBLE"),
		statusValue: "CANCELLED_DOUBLE",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("CancelledCreditWorth"),
		value: i18n.t("CANCELLED_CREDIT_WORTH"),
		statusValue: "CANCELLED_CREDIT_WORTH",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("excludeFromSettlement"),
		value: i18n.t("EXCLUDE_SETTLEMENT"),
		statusValue: "EXCLUDE_SETTLEMENT",
		icon: <CancelledIcon />,
	},
];
export const filteringStatusOptions = [
	{
		name: i18n.t("Approved"),
		value: "APPROVED",
		statusValue: "APPROVED",
	},
	{
		name: i18n.t("Clarification"),
		value: "CLARIFICATION",
		statusValue: "CLARIFICATION",
	},
	{
		name: i18n.t("BillingNextMonth"),
		value: "BILLING_NEXT_MONTH",
		statusValue: "BILLING_NEXT_MONTH",
	},
	{
		name: i18n.t("Paid"),
		value: "PAID",
		statusValue: "PAID",
	},
	{
		name: i18n.t("Pending"),
		value: "PENDING",
		statusValue: "PENDING",
	},
	{
		name: i18n.t("Cancelled"),
		value: "CANCELLED",
		statusValue: "CANCELLED",
	},
	{
		name: i18n.t("Delivered"),
		value: "DELIVERED",
		statusValue: "DELIVERED",
	},
	{
		name: i18n.t("Finished"),
		value: "FINISHED",
		statusValue: "FINISHED",
	},
	{
		name: i18n.t("Bonus"),
		value: "BONUS",
		statusValue: "BONUS",
	},
	{
		name: i18n.t("Settled"),
		value: "SETTLED",
		statusValue: "SETTLED",
	},
	{
		name: i18n.t("NoSignature"),
		value: "NOSIGNATURE",
		statusValue: "NOSIGNATURE",
	},
	{
		name: i18n.t("CancellationCustomer"),
		value: "CANCELLED_CUSTOMER",
		statusValue: "CANCELLED_CUSTOMER",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("CancelledByProvider"),
		value: "CANCELLED_PROVIDER",
		statusValue: "CANCELLED_PROVIDER",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("OrderIncorrect"),
		value: "CANCELLED_INCORRECT_ORDER",
		statusValue: "CANCELLED_INCORRECT_ORDER",
	},
	{
		name: i18n.t("ContractReactivated"),
		value: "CONTRACT_REACTIVATED",
		statusValue: "CONTRACT_REACTIVATED",
		icon: <ReactivatedIcon />,
	},
	{
		name: i18n.t("bonusSettled"),
		value: "BONUS_SETTLED",
		statusValue: "BONUS_SETTLED",
	},
	{
		name: i18n.t("CancelledDouble"),
		value: "CANCELLED_DOUBLE",
		statusValue: "CANCELLED_DOUBLE",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("CancelledCreditWorth"),
		value: "CANCELLED_CREDIT_WORTH",
		statusValue: "CANCELLED_CREDIT_WORTH",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("excludeFromSettlement"),
		value: "EXCLUDE_SETTLEMENT",
		statusValue: "EXCLUDE_SETTLEMENT",
		icon: <CancelledIcon />,
	},
];

// ADD ARRAY FOR NEW CLIENTS NAME SHOULD BE SAME WITH SELECTEDCLIENT.NAME VALUE

export const columnsObjEXPORT = {
	ALLCLIENTS: [
		{title: i18n.t("VPNumber"), field: "vpNumber"},
		{title: i18n.t("VPName"), field: "salesPartnerName"},

		{
			title: i18n.t("ID"),
			field: "orderId",
		},

		{
			title: i18n.t("Client"),
			field: "clientName",
			// render: (row) => <TableClientColumn row={row} />,
		},

		{title: i18n.t("contractId"), field: "clientOrderId"},

		{title: i18n.t("TariffName"), field: "tariffName"},

		{
			title: i18n.t("ProjectCategory"),
			field: "projectCategoryName",
		},

		{title: i18n.t("ProjectLocation"), field: "locationName"},

		{
			title: i18n.t("ContractDate"),
			field: "createdAt1",
			render: (row) => FORMAT_DATE(row.createdAt),
		},
		{
			title: i18n.t("Status"),
			field: "orderStatus",
			// export: false,
			render: (rowData) => (
				<span
					style={{
						color: statusData[rowData?.statusName || rowData.orderStatus]?.color,
					}}
				>
					{rowData?.statusName || rowData.orderStatus}
				</span>
			),
		},

		//   { title: i18n.t('ProductName'), field: 'viewOrderDetails.productName' },

		// order details

		{title: i18n.t("customerType"), field: "customerType"},
		{
			title: i18n.t("Salutation"),
			field: "viewOrderDetails.salutation",
		},
		{
			title: i18n.t("OrderProductNumber"),
			field: "orderProductNumber",
		},
		{
			title: i18n.t("DateOfSignature"),
			field: "viewOrderDetails.signatureDate1",

			render: (row) =>
				row.viewOrderDetails.signatureDate !== null
					? FORMAT_DATE(row.viewOrderDetails.signatureDate)
					: "",
		},
		{
			title: i18n.t("UserTitle"),
			field: "userTittle",
		},

		{
			title: i18n.t("FirstName"),
			field: "viewOrderDetails.firstName",
		},
		{
			title: i18n.t("LastName"),
			field: "viewOrderDetails.lastName",
		},
		{
			title: i18n.t("CompanyName"),
			field: "company",
		},

		{
			title: i18n.t("Street"),
			field: "viewOrderDetails.street",
		},
		{
			title: i18n.t("HouseNumber"),
			field: "viewOrderDetails.houseNumber",
		},
		{
			title: i18n.t("Addition"),
			field: "viewOrderDetails.addition",
		},
		{
			title: i18n.t("Place"),
			field: "viewOrderDetails.place",
		},
		{
			title: i18n.t("subLocation"),
			field: "viewOrderDetails.subLocation",
		},
		{
			title: i18n.t("ZipCode"),
			field: "viewOrderDetails.zip",
		},
		{
			title: i18n.t("BirthDay"),
			field: "viewOrderDetails.dayOfBirth1",

			// render: (row) =>
			// 	row.viewOrderDetails.dayofBirth !== null
			// 		? FORMAT_DATE(row.viewOrderDetails.dayofBirth)
			// 		: "",
		},
		{title: i18n.t("Email"), field: "email", export: true},
		{
			title: i18n.t("Telephone"),
			field: "viewOrderDetails.phone",
		},
		{
			title: i18n.t("MobilePhone"),
			field: "viewOrderDetails.mobileNumber",
		},
		// {title: i18n.t("Fax"), field: "viewOrderDetails.fax"},

		{
			title: i18n.t("OneFamilyHouse"),
			field: "oneFamilyHouse1",
		},
		{
			title: i18n.t("MoreFamilyHouse"),
			field: "apartmentBuilding1",
		},
		{
			title: i18n.t("HomeOwner"),
			field: "underground1",
		},
		{
			title: i18n.t("HomeCoOwner"),
			field: "upstairs1",
		},
		{
			title: i18n.t("Renter"),
			field: "groundFloor1",
		},
		{
			title: i18n.t("NamePropertyOwner"),
			field: "floor",
		},

		// bank info
		{
			title: i18n.t("BankSalutation"),
			field: "viewBankAccount.salutation",
		},
		{
			title: i18n.t("FirstName"),
			field: "viewBankAccount.firstName",
		},
		{
			title: i18n.t("LastNameCompany"),
			field: "viewBankAccount.lastName",
		},
		{
			title: i18n.t("BankStreet"),
			field: "viewBankAccount.street",
		},
		{
			title: i18n.t("BankHouseNumber"),
			field: "viewBankAccount.houseNumber",
		},
		{
			title: i18n.t("Addition"),
			field: "viewBankAccount.addition",
		},
		{
			title: i18n.t("BankLocation"),
			field: "viewBankAccount.location",
		},
		{
			title: i18n.t("BankZipCode"),
			field: "viewBankAccount.zip",
		},

		{
			title: i18n.t("BankName"),
			field: "viewBankAccount.bankName",
		},

		// {title: i18n.t("BankCode"), field: "viewBankAccount.bankCode"},
		{
			title: i18n.t("BankIban"),
			field: "viewBankAccount.bankIban",
		},
		{
			title: i18n.t("BankBic"),
			field: "viewBankAccount.bankBic",
		},

		{
			title: i18n.t("BankSignatureDate"),
			field: "viewBankAccount.signatureDate1",

			// render: (row) => FORMAT_DATE(row.viewOrderDetails.signatureDate),
			render: (row) =>
				row.viewBankAccount.signatureDate !== null
					? FORMAT_DATE(row.viewBankAccount.signatureDate)
					: "",
		},

		// different ivoiceing adress
		{
			title: i18n.t("AddressSalutation"),
			field: "viewAddressForInvoice.salutation",
		},
		{
			title: i18n.t("AdrressFullName"),
			field: "viewAddressForInvoice.fullName",

			// render: (row) => <TableClientColumn row={row?.viewAddressForInvoice} />,
		},
		{
			title: i18n.t("ContactPersonName"),
			field: "viewAddressForInvoice.contactPersonFirma",
		},
		{
			title: i18n.t("AddressEmail"),
			field: "viewAddressForInvoice.email",
		},
		{
			title: i18n.t("AddressBirthdate"),
			field: "viewAddressForInvoice.dayOfBirth1",
		},
		{
			title: i18n.t("AddressStreet"),
			field: "viewAddressForInvoice.street",
		},
		{
			title: i18n.t("AddressHousrNr"),
			field: "viewAddressForInvoice.houseNumber",
		},

		{
			title: i18n.t("AddressAddition"),
			field: "viewAddressForInvoice.addition",
		},
		{
			title: i18n.t("AddressCity"),
			field: "viewAddressForInvoice.abwRACity",
		},
		{
			title: i18n.t("AddressZipCode"),
			field: "viewAddressForInvoice.zip",
		},
		{
			title: i18n.t("Telephone"),
			field: "viewAddressForInvoice.phone",
		},
		{
			title: i18n.t("MobilePhone"),
			field: "viewAddressForInvoice.mobileNumber",
		},

		// former provider

		{
			title: i18n.t("TransferNumber"),
			field: "checkFormerProvider1",
		},

		{
			title: i18n.t("FormerProviderName"),
			field: "viewFormerProvider.formerProviderName",
		},

		{
			title: i18n.t("ProviderSalutation"),
			field: "viewFormerProvider.salutation",
		},
		{
			title: i18n.t("ProviderFirstName"),
			field: "viewFormerProvider.firstName",
		},
		{
			title: i18n.t("ProviderLastName"),
			field: "viewFormerProvider.lastName",
		},
		{
			title: i18n.t("ProviderStreet"),
			field: "viewFormerProvider.street",
		},
		{
			title: i18n.t("ProviderHouseNumber"),
			field: "viewFormerProvider.houseNumber",
		},
		{
			title: i18n.t("ProviderAddition"),
			field: "viewFormerProvider.addition",
		},
		{
			title: i18n.t("ProviderZipCode"),
			field: "viewFormerProvider.zip",
		},

		{
			title: i18n.t("ProviderLocation"),
			field: "viewFormerProvider.location",
		},
		{
			title: i18n.t("Prefix"),
			field: "viewFormerProvider.locationPrefix",
		},
		{
			title: i18n.t("Extension"),
			field: "viewFormerProvider.providerExtension",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 1`,
			field: "viewFormerProvider.activePhoneNumber1",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 2`,
			field: "viewFormerProvider.activePhoneNumber2",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 3`,
			field: "viewFormerProvider.activePhoneNumber3",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 4`,
			field: "viewFormerProvider.activePhoneNumber4",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 5`,
			field: "viewFormerProvider.activePhoneNumber5",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 6`,
			field: "viewFormerProvider.activePhoneNumber6",
		},

		{
			title: i18n.t("AdditionalSalutation"),
			field: "additionalContractor.salutation",
		},

		{
			title: i18n.t("AditionalFirstName"),
			field: "additionalContractor.firstName",
		},
		{
			title: i18n.t("AditionalLastName"),
			field: "additionalContractor.lastName",
		},
		{
			title: i18n.t("AdditionalEmail"),
			field: "additionalContractor.userEmail",
		},
		{
			title: i18n.t("AdditionalStreet"),
			field: "additionalContractor.street",
		},
		{
			title: i18n.t("AdditionalHousrNr"),
			field: "additionalContractor.houseNumber",
		},
		{
			title: i18n.t("AdditionalAddition"),
			field: "additionalContractor.addition",
		},
		{
			title: i18n.t("AdditionalPlace"),
			field: "additionalContractor.place",
		},
		{
			title: i18n.t("AdditionalZipCode"),
			field: "additionalContractor.zip",
		},
		{
			title: i18n.t("AdditionalTelephone"),
			field: "additionalContractor.telephone",
		},
		{
			title: i18n.t("AdditionalMobile"),
			field: "additionalContractor.mobileNumber",
		},
		{
			title: i18n.t("AdditionalBirthdate"),
			field: "additionalContractor.dayOfBirth",

			render: (row) =>
				// eslint-disable-next-line no-unused-expressions
				row.additionalContractor.dayOfBirth !== null
					? FORMAT_DATE(row.additionalContractor.dayOfBirth)
					: "",
		},
		// {
		// 	title: i18n.t("TermsAndConditions"),
		// 	field: "termsAndConditions1",
		// },
		{
			title: i18n.t("SendNewsLetter"),
			field: "sendNewsLetter1",
		},
		{
			title: i18n.t("EmailNewsLetter"),
			field: "emailNewsLetter1",
		},
	],
	TNG: [
		{title: i18n.t("VPNumber"), field: "vpNumber"},
		{title: i18n.t("VPName"), field: "salesPartnerName"},

		{
			title: i18n.t("ID"),
			field: "orderId",
			// export: false,
		},

		{
			title: i18n.t("Client"),
			field: "clientName",
		},
		{
			title: i18n.t("PointTarifAndProduct"),
			field: "points",
		},

		{title: i18n.t("contractId"), field: "clientOrderId"},

		{title: i18n.t("TariffName"), field: "tariffName"},

		{
			title: i18n.t("ProjectCategory"),
			field: "projectCategoryName",
		},

		{title: i18n.t("ProjectLocation"), field: "locationName"},

		{
			title: i18n.t("ContractDate"),
			field: "createdAt1",
			render: (row) => FORMAT_DATE(row.createdAt),
		},
		{
			title: i18n.t("Status"),
			field: "orderStatus",
			// export: false,
			render: (rowData) => (
				<span
					style={{
						color: statusData[rowData?.statusName || rowData.orderStatus].color,
					}}
				>
					{rowData?.statusName || rowData.orderStatus}
				</span>
			),
		},
		{
			title: i18n.t("OrderProductNumber"),
			field: "orderProductNumber",
		},
		{
			title: i18n.t("FirstName"),
			field: "viewOrderDetails.firstName",
		},
		{
			title: i18n.t("LastName"),
			field: "viewOrderDetails.lastName",
		},
		{
			title: i18n.t("CompanyName"),
			field: "company",
		},
		{
			title: i18n.t("Street"),
			field: "viewOrderDetails.street",
		},
		{
			title: i18n.t("HouseNumber"),
			field: "viewOrderDetails.houseNumber",
		},
		{
			title: i18n.t("Addition"),
			field: "viewOrderDetails.addition",
		},
		{
			title: i18n.t("Place"),
			field: "viewOrderDetails.place",
		},
		{
			title: i18n.t("ZipCode"),
			field: "viewOrderDetails.zip",
		},
		//   { title: i18n.t('ProductName'), field: 'viewOrderDetails.productName' },

		// order details

		{title: i18n.t("customerType"), field: "customerType"},
		{
			title: i18n.t("Salutation"),
			field: "viewOrderDetails.salutation",
		},
		{
			title: i18n.t("UserTitle"),
			field: "userTittle",
		},
		{
			title: i18n.t("subLocation"),
			field: "viewOrderDetails.subLocation",
		},
		{
			title: i18n.t("BirthDay"),
			field: "viewOrderDetails.dayOfBirth1",

			// render: (row) =>
			// 	row.viewOrderDetails.dayofBirth !== null
			// 		? FORMAT_DATE(row.viewOrderDetails.dayofBirth)
			// 		: "",
		},
		{title: i18n.t("Email"), field: "email", export: true},
		{
			title: i18n.t("Telephone"),
			field: "viewOrderDetails.phone",
		},
		{
			title: i18n.t("MobilePhone"),
			field: "viewOrderDetails.mobileNumber",
		},
		// {title: i18n.t("Fax"), field: "viewOrderDetails.fax"},

		{
			title: i18n.t("OneFamilyHouse"),
			field: "oneFamilyHouse1",
		},
		{
			title: i18n.t("MoreFamilyHouse"),
			field: "apartmentBuilding1",
		},
		{
			title: i18n.t("HomeOwner"),
			field: "underground1",
		},
		{
			title: i18n.t("HomeCoOwner"),
			field: "upstairs1",
		},
		{
			title: i18n.t("Renter"),
			field: "groundFloor1",
		},
		{
			title: i18n.t("NamePropertyOwner"),
			field: "floor",
		},

		// bank info
		{
			title: i18n.t("BankSalutation"),
			field: "viewBankAccount.salutation",
		},
		{
			title: i18n.t("FirstName"),
			field: "viewBankAccount.firstName",
		},
		{
			title: i18n.t("LastNameCompany"),
			field: "viewBankAccount.lastName",
		},
		{
			title: i18n.t("BankStreet"),
			field: "viewBankAccount.street",
		},
		{
			title: i18n.t("BankHouseNumber"),
			field: "viewBankAccount.houseNumber",
		},
		{
			title: i18n.t("Addition"),
			field: "viewBankAccount.addition",
		},
		{
			title: i18n.t("BankLocation"),
			field: "viewBankAccount.location",
		},
		{
			title: i18n.t("BankZipCode"),
			field: "viewBankAccount.zip",
		},

		{
			title: i18n.t("BankName"),
			field: "viewBankAccount.bankName",
		},

		// {title: i18n.t("BankCode"), field: "viewBankAccount.bankCode"},
		{
			title: i18n.t("BankIban"),
			field: "viewBankAccount.bankIban",
		},
		{
			title: i18n.t("BankBic"),
			field: "viewBankAccount.bankBic",
		},

		{
			title: i18n.t("BankSignatureDate"),
			field: "viewBankAccount.signatureDate1",

			// render: (row) => FORMAT_DATE(row.viewOrderDetails.signatureDate),
			render: (row) =>
				row.viewOrderDetails.signatureDate !== null
					? FORMAT_DATE(row.viewOrderDetails.signatureDate)
					: "",
		},

		// different ivoiceing adress
		{
			title: i18n.t("AddressSalutation"),
			field: "viewAddressForInvoice.salutation",
		},
		{
			title: i18n.t("AdrressFullName"),
			field: "viewAddressForInvoice.fullName",

			// render: (row) => <TableClientColumn row={row?.viewAddressForInvoice} />,
		},
		{
			title: i18n.t("ContactPersonName"),
			field: "viewAddressForInvoice.contactPersonFirma",
		},
		{
			title: i18n.t("AddressEmail"),
			field: "viewAddressForInvoice.email",
		},
		{
			title: i18n.t("AddressBirthdate"),
			field: "viewAddressForInvoice.dayOfBirth1",
		},
		{
			title: i18n.t("AddressStreet"),
			field: "viewAddressForInvoice.street",
		},
		{
			title: i18n.t("AddressHousrNr"),
			field: "viewAddressForInvoice.houseNumber",
		},

		{
			title: i18n.t("AddressAddition"),
			field: "viewAddressForInvoice.addition",
		},
		{
			title: i18n.t("AddressCity"),
			field: "viewAddressForInvoice.abwRACity",
		},
		{
			title: i18n.t("AddressZipCode"),
			field: "viewAddressForInvoice.zip",
		},
		{
			title: i18n.t("Telephone"),
			field: "viewAddressForInvoice.phone",
		},
		{
			title: i18n.t("MobilePhone"),
			field: "viewAddressForInvoice.mobileNumber",
		},

		// former provider

		{
			title: i18n.t("TransferNumber"),
			field: "checkFormerProvider1",
		},

		{
			title: i18n.t("FormerProviderName"),
			field: "viewFormerProvider.formerProviderName",
		},

		{
			title: i18n.t("ProviderSalutation"),
			field: "viewFormerProvider.salutation",
		},
		{
			title: i18n.t("ProviderFirstName"),
			field: "viewFormerProvider.firstName",
		},
		{
			title: i18n.t("ProviderLastName"),
			field: "viewFormerProvider.lastName",
		},
		{
			title: i18n.t("ProviderStreet"),
			field: "viewFormerProvider.street",
		},
		{
			title: i18n.t("ProviderHouseNumber"),
			field: "viewFormerProvider.houseNumber",
		},
		{
			title: i18n.t("ProviderAddition"),
			field: "viewFormerProvider.addition",
		},
		{
			title: i18n.t("ProviderZipCode"),
			field: "viewFormerProvider.zip",
		},

		{
			title: i18n.t("ProviderLocation"),
			field: "viewFormerProvider.location",
		},
		{
			title: i18n.t("Prefix"),
			field: "viewFormerProvider.locationPrefix",
		},
		{
			title: i18n.t("Extension"),
			field: "viewFormerProvider.providerExtension",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 1`,
			field: "viewFormerProvider.activePhoneNumber1",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 2`,
			field: "viewFormerProvider.activePhoneNumber2",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 3`,
			field: "viewFormerProvider.activePhoneNumber3",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 4`,
			field: "viewFormerProvider.activePhoneNumber4",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 5`,
			field: "viewFormerProvider.activePhoneNumber5",
		},
		{
			title: `${i18n.t("ActivePhoneNumber")} 6`,
			field: "viewFormerProvider.activePhoneNumber6",
		},

		{
			title: i18n.t("AdditionalSalutation"),
			field: "additionalContractor.salutation",
		},

		{
			title: i18n.t("AditionalFirstName"),
			field: "additionalContractor.firstName",
		},
		{
			title: i18n.t("AditionalLastName"),
			field: "additionalContractor.lastName",
		},
		{
			title: i18n.t("AdditionalEmail"),
			field: "additionalContractor.userEmail",
		},
		{
			title: i18n.t("AdditionalStreet"),
			field: "additionalContractor.street",
		},
		{
			title: i18n.t("AdditionalHousrNr"),
			field: "additionalContractor.houseNumber",
		},
		{
			title: i18n.t("AdditionalAddition"),
			field: "additionalContractor.addition",
		},
		{
			title: i18n.t("AdditionalPlace"),
			field: "additionalContractor.place",
		},
		{
			title: i18n.t("AdditionalZipCode"),
			field: "additionalContractor.zip",
		},
		{
			title: i18n.t("AdditionalTelephone"),
			field: "additionalContractor.telephone",
		},
		{
			title: i18n.t("AdditionalMobile"),
			field: "additionalContractor.mobileNumber",
		},
		{
			title: i18n.t("AdditionalBirthdate"),
			field: "additionalContractor.dayOfBirth",

			render: (row) =>
				// eslint-disable-next-line no-unused-expressions
				row.additionalContractor.dayOfBirth !== null
					? FORMAT_DATE(row.additionalContractor.dayOfBirth)
					: "",
		},
		// {
		// 	title: i18n.t("TermsAndConditions"),
		// 	field: "termsAndConditions1",
		// },
		{
			title: i18n.t("SendNewsLetter"),
			field: "sendNewsLetter1",
		},
		{
			title: i18n.t("EmailNewsLetter"),
			field: "emailNewsLetter1",
		},
	],
	GFD: [
		{title: i18n.t("VPNumber"), field: "vpNumber"},
		{title: i18n.t("VPName"), field: "salesPartnerName"},

		{
			title: i18n.t("ID"),
			field: "orderId",
			// export: false,
		},

		{
			title: i18n.t("Client"),
			field: "clientName",
		},

		{title: i18n.t("contractId"), field: "clientOrderId"},

		{title: i18n.t("TariffName"), field: "tariffName"},

		{
			title: i18n.t("ProjectCategory"),
			field: "projectCategoryName",
		},

		{title: i18n.t("ProjectLocation"), field: "locationName"},

		{
			title: i18n.t("ContractDate"),
			field: "createdAt1",
			render: (row) => FORMAT_DATE(row.createdAt),
		},
		{
			title: i18n.t("Status"),
			field: "orderStatus",
			// export: false,
			render: (rowData) => (
				<span
					style={{
						color: statusData[rowData?.statusName || rowData.orderStatus].color,
					}}
				>
					{rowData?.statusName || rowData.orderStatus}
				</span>
			),
		},
		{
			title: i18n.t("OrderProductNumber"),
			field: "orderProductNumber",
		},
		{
			title: i18n.t("DateOfSignature"),
			field: "viewOrderDetails.signatureDate1",

			render: (row) =>
				row.viewOrderDetails.signatureDate !== null
					? FORMAT_DATE(row.viewOrderDetails.signatureDate)
					: "",
		},

		{
			title: i18n.t("FirstName"),
			field: "viewOrderDetails.firstName",
		},
		{
			title: i18n.t("LastName"),
			field: "viewOrderDetails.lastName",
		},
		{
			title: i18n.t("CompanyName"),
			field: "company",
		},
	],
	DGN: [
		{title: i18n.t("VPNumber"), field: "vpNumber"},
		{title: i18n.t("VPName"), field: "salesPartnerName"},
		{
			title: i18n.t("ID"),
			field: "orderId",
			// export: false,
		},
		{
			title: i18n.t("Client"),
			field: "clientName",
		},
		{
			title: i18n.t("PointTarifAndProduct"),
			field: "points",
		},
		{title: i18n.t("contractId"), field: "clientOrderId"},
		{title: i18n.t("TariffName"), field: "tariffName"},
		{
			title: i18n.t("ProjectCategory"),
			field: "projectCategoryName",
		},
		{title: i18n.t("ProjectLocation"), field: "locationName"},
		{
			title: i18n.t("ContractDate"),
			field: "createdAt1",
			render: (row) => FORMAT_DATE(row.createdAt),
		},
		{
			title: i18n.t("Status"),
			field: "orderStatus",
			// export: false,
			render: (rowData) => (
				<span
					style={{
						color: statusData[rowData?.statusName || rowData.orderStatus]?.color,
					}}
				>
					{rowData?.statusName || rowData.orderStatus}
				</span>
			),
		},
		{
			title: i18n.t("OrderProductNumber"),
			field: "orderProductNumber",
		},
		{
			title: i18n.t("FirstName"),
			field: "viewOrderDetails.firstName",
		},
		{
			title: i18n.t("LastName"),
			field: "viewOrderDetails.lastName",
		},
		{
			title: i18n.t("CompanyName"),
			field: "company",
		},
		{
			title: i18n.t("Street"),
			field: "viewOrderDetails.street",
		},
		{
			title: i18n.t("HouseNumber"),
			field: "viewOrderDetails.houseNumber",
		},
		{
			title: i18n.t("Addition"),
			field: "viewOrderDetails.addition",
		},
		{
			title: i18n.t("Place"),
			field: "viewOrderDetails.place",
		},
		{
			title: i18n.t("zip"),
			field: "viewOrderDetails.zip",
		},
		{
			title: i18n.t("DateOfSignature"),
			field: "viewOrderDetails.signatureDate1",

			render: (row) =>
				row.viewOrderDetails.signatureDate !== null
					? FORMAT_DATE(row.viewOrderDetails.signatureDate)
					: "",
		},
	],
	TER: [
		{title: i18n.t("VPNumber"), field: "vpNumber"},
		{title: i18n.t("VPName"), field: "salesPartnerName"},

		{
			title: i18n.t("ID"),
			field: "orderId",
			// export: false,
		},

		{
			title: i18n.t("Client"),
			field: "clientName",
		},
		{
			title: i18n.t("PointTarifAndProduct"),
			field: "points",
		},

		{title: i18n.t("contractId"), field: "clientOrderId"},

		{title: i18n.t("TariffName"), field: "tariffName"},

		{
			title: i18n.t("ProjectCategory"),
			field: "projectCategoryName",
		},

		{title: i18n.t("ProjectLocation"), field: "locationName"},

		{
			title: i18n.t("ContractDate"),
			field: "createdAt1",
			render: (row) => FORMAT_DATE(row.createdAt),
		},
		{
			title: i18n.t("Status"),
			field: "orderStatus",
			// export: false,
			render: (rowData) => (
				<span
					style={{
						color: statusData[rowData?.statusName || rowData.orderStatus].color,
					}}
				>
					{rowData?.statusName || rowData.orderStatus}
				</span>
			),
		},
		{
			title: i18n.t("OrderProductNumber"),
			field: "orderProductNumber",
		},

		{
			title: i18n.t("FirstName"),
			field: "viewOrderDetails.firstName",
		},
		{
			title: i18n.t("LastName"),
			field: "viewOrderDetails.lastName",
		},
		{
			title: i18n.t("CompanyName"),
			field: "company",
		},
		{
			title: i18n.t("Street"),
			field: "viewOrderDetails.street",
		},
		{
			title: i18n.t("HouseNumber"),
			field: "viewOrderDetails.houseNumber",
		},
		{
			title: i18n.t("Addition"),
			field: "viewOrderDetails.addition",
		},
		{
			title: i18n.t("Place"),
			field: "viewOrderDetails.place",
		},
		{
			title: i18n.t("zip"),
			field: "viewOrderDetails.zip",
		},
		{
			title: i18n.t("DateOfSignature"),
			field: "viewOrderDetails.signatureDate1",

			render: (row) =>
				row.viewOrderDetails.signatureDate !== null
					? FORMAT_DATE(row.viewOrderDetails.signatureDate)
					: "",
		},
		{
			title: i18n.t("subLocation"),
			field: "viewOrderDetails.subLocation",
		},
		{
			title: i18n.t("viaET"),
			field: "viaET",
		},
		{
			title: i18n.t("viaMFH"),
			field: "viaMFH",
		},
		{
			title: i18n.t("viaPapier"),
			field: "viaPapier",
		},
	],
	HNT: [
		{
			title: i18n.t("ID"),
			field: "orderId",
		},
		{
			title: "FirstName",
			field: "viewOrderDetails.firstName",
		},
		{
			title: "LastName",
			field: "viewOrderDetails.lastName",
		},
		{
			title: "Prefix",
			field: "viewOrderDetails.salutation",
		},
		{
			title: "Email",
			field: "email",
		},
		{
			title: i18n.t("PointTarifAndProduct"),
			field: "points",
		},
		{title: "ExternalID", field: "vpNumber"},
		{title: "PhoneDay", field: "viewOrderDetails.phone"},
		{title: "PhoneEvening", field: "viewOrderDetails.phone"},
		{title: "PhoneMobil", field: "viewOrderDetails.mobileNumber"},
		{},
		{title: "DeliveryStreetName", field: "viewOrderDetails.street"},
		{title: "DeliveryStreetNumber", field: "viewOrderDetails.houseNumber"},
		{title: "DeliveryStreetEntrance", field: "viewOrderDetails.addition"},
		{},
		{title: "AccessAddressId", field: "viewOrderDetails.addressId"},
		{title: "AdressDetails", field: "viewOrderDetails.floor"},
		{},
		{},
		{title: "DeliveryZip", field: "viewOrderDetails.zip"},
		{title: "DeliveryCity", field: "viewOrderDetails.place"},
		{title: "InvoiceStreetName", field: "additionalContractor.street"},
		{title: "InvoiceStreetNumber", field: "additionalContractor.houseNumber"},
		{title: "InvoiceEntrance", field: "additionalContractor.addition"},
		{title: "InvoiceZIP", field: "additionalContractor.zip"},
		{title: "InvoiceCity", field: "additionalContractor.place"},
		{
			title: "IBAN",
			field: "viewBankAccount.bankIban",
		},
		{
			title: "Birth date",
			field: "viewOrderDetails.dayOfBirth1",
		},
		{title: "Contract Id", field: "clientOrderId"},
		{title: "Order Date", field: "createdAt1"},
	],
	VFK: [
		{title: i18n.t("VPNumber"), field: "vpNumber"},
		{title: i18n.t("VPName"), field: "salesPartnerName"},

		{
			title: i18n.t("ID"),
			field: "orderId",
			// export: false,
		},

		{
			title: i18n.t("Client"),
			field: "clientName",
		},
		{
			title: i18n.t("PointTarifAndProduct"),
			field: "points",
		},

		{title: i18n.t("contractId"), field: "clientOrderId"},

		{title: i18n.t("TariffName"), field: "tariffName"},

		{
			title: i18n.t("ProjectCategory"),
			field: "projectCategoryName",
		},

		{title: i18n.t("ProjectLocation"), field: "locationName"},

		{
			title: i18n.t("ContractDate"),
			field: "createdAt1",
			render: (row) => FORMAT_DATE(row.createdAt),
		},
		{
			title: i18n.t("Status"),
			field: "orderStatus",
			// export: false,
			render: (rowData) => (
				<span
					style={{
						color: statusData[rowData?.statusName || rowData.orderStatus]?.color,
					}}
				>
					{rowData?.statusName || rowData.orderStatus}
				</span>
			),
		},
		{
			title: i18n.t("OrderProductNumber"),
			field: "orderProductNumber",
		},
		{
			title: i18n.t("FirstName"),
			field: "viewOrderDetails.firstName",
		},
		{
			title: i18n.t("LastName"),
			field: "viewOrderDetails.lastName",
		},
		{
			title: i18n.t("CompanyName"),
			field: "company",
		},
		{
			title: i18n.t("Street"),
			field: "viewOrderDetails.street",
		},
		{
			title: i18n.t("HouseNumber"),
			field: "viewOrderDetails.houseNumber",
		},
		{
			title: i18n.t("Addition"),
			field: "viewOrderDetails.addition",
		},
		{
			title: i18n.t("Place"),
			field: "viewOrderDetails.place",
		},
		{
			title: i18n.t("zip"),
			field: "viewOrderDetails.zip",
		},
		{
			title: i18n.t("DateOfSignature"),
			field: "viewOrderDetails.signatureDate1",

			render: (row) =>
				row.viewOrderDetails.signatureDate !== null
					? FORMAT_DATE(row.viewOrderDetails.signatureDate)
					: "",
		},
		{
			title: i18n.t("subLocation"),
			field: "viewOrderDetails.subLocation",
		},
	],
	DEFAULT: [
		{title: i18n.t("VPNumber"), field: "vpNumber"},
		{title: i18n.t("VPName"), field: "salesPartnerName"},

		{
			title: i18n.t("ID"),
			field: "orderId",
			// export: false,
		},

		{
			title: i18n.t("Client"),
			field: "clientName",
		},
		{
			title: i18n.t("PointTarifAndProduct"),
			field: "points",
			hideForClient: ["Stadtwerke Wesel GmbH Digital"],
		},

		{title: i18n.t("contractId"), field: "clientOrderId"},

		{title: i18n.t("TariffName"), field: "tariffName"},

		{
			title: i18n.t("ProjectCategory"),
			field: "projectCategoryName",
		},

		{title: i18n.t("ProjectLocation"), field: "locationName"},

		{
			title: i18n.t("ContractDate"),
			field: "createdAt1",
			render: (row) => FORMAT_DATE(row.createdAt),
		},
		{
			title: i18n.t("Status"),
			field: "orderStatus",
			// export: false,
			render: (rowData) => (
				<span
					style={{
						color: statusData[rowData?.statusName || rowData.orderStatus]?.color,
					}}
				>
					{rowData?.statusName || rowData.orderStatus}
				</span>
			),
		},
		{
			title: i18n.t("ChangedAt"),
			field: "statusChangeTime",
			hideForClient: ["Stadtwerke Wesel GmbH Digital"],
		},
	],
};

export const firstAndLastNameColumns = [
	{
		title: i18n.t("FirstName"),
		field: "viewOrderDetails.firstName",
	},
	{
		title: i18n.t("LastName"),
		field: "viewOrderDetails.lastName",
	},
];

export const settleOrdersValidationSchema = yup.object().shape({
	year: yup.string().when(["isV2CurrentVersion"], {
		is: (val) => val === true,
		then: yup.string().required(i18n.t("yearReq")).nullable(),
		otherwise: yup.string().optional().nullable(),
	}),
	month: yup.string().when(["isV2CurrentVersion"], {
		is: (val) => val === true,
		then: yup.string().required(i18n.t("monthReq")).nullable(),
		otherwise: yup.string().optional().nullable(),
	}),
	baugoId: yup.number().when(["tableTab"], {
		is: (value) => value === "BONUS",
		then: yup.number().required(i18n.t("bonusIsReq")).nullable(),
		otherwise: yup.number().optional().nullable(),
	}),
	description: yup
		.string()
		.test("descriptionLength", i18n.t("descriptionMaxLen"), (val) =>
			val?.length > 0 ? val.length <= 70 : true,
		)
		.optional()
		.nullable(),
	settledUuid: yup.mixed().optional().nullable(),
});

export const userRolesDropdown = [
	"AGENCY-SP",
	"SP",
	"EMPLOYEE",
	"EMPLOYEE_SHOP",
	"EMPLOYEE_TL",
	"EMPLOYEE_SHOP",
	"EMPLOYEE_AB",
];
export const importStatusOptions = [
	{
		name: i18n.t("Approved"),
		value: "APPROVED",
		statusValue: "APPROVED",
	},
	{
		name: i18n.t("Clarification"),
		value: "CLARIFICATION",
		statusValue: "CLARIFICATION",
	},
	{
		name: i18n.t("BillingNextMonth"),
		value: "BILLING_NEXT_MONTH",
		statusValue: "BILLING_NEXT_MONTH",
	},
	{
		name: i18n.t("Paid"),
		value: "PAID",
		statusValue: "PAID",
	},
	{
		name: i18n.t("Pending"),
		value: "PENDING",
		statusValue: "PENDING",
	},
	{
		name: i18n.t("Cancelled"),
		value: "CANCELLED",
		statusValue: "CANCELLED",
	},
	{
		name: i18n.t("Delivered"),
		value: "DELIVERED",
		statusValue: "DELIVERED",
	},
	{
		name: i18n.t("Finished"),
		value: "FINISHED",
		statusValue: "FINISHED",
	},
	{
		name: i18n.t("Bonus"),
		value: "BONUS",
		statusValue: "BONUS",
	},
	{
		name: i18n.t("NoSignature"),
		value: "NOSIGNATURE",
		statusValue: "NOSIGNATURE",
	},
	{
		name: i18n.t("CancellationCustomer"),
		value: "CANCELLED_CUSTOMER",
		statusValue: "CANCELLED_CUSTOMER",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("CancelledByProvider"),
		value: "CANCELLED_PROVIDER",
		statusValue: "CANCELLED_PROVIDER",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("OrderIncorrect"),
		value: "CANCELLED_INCORRECT_ORDER",
		statusValue: "CANCELLED_INCORRECT_ORDER",
	},
	{
		name: i18n.t("CancelledDouble"),
		value: "CANCELLED_DOUBLE",
		statusValue: "CANCELLED_DOUBLE",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("CancelledCreditWorth"),
		value: "CANCELLED_CREDIT_WORTH",
		statusValue: "CANCELLED_CREDIT_WORTH",
		icon: <CancelledIcon />,
	},
	{
		name: i18n.t("excludeFromSettlement"),
		value: i18n.t("excludeFromSettlement"),
		statusValue: "EXCLUDE_SETTLEMENT",
		icon: <CancelledIcon />,
	},
];
