/* eslint-disable max-len */
import React, {useEffect, useRef, useState} from "react";
import {DatePicker} from "antd";
import moment from "moment";
import {generateConfig} from "@utils/datepicker-config";
import locale from "date-fns/locale/en-GB";
import {useTranslation} from "react-i18next";
import "./styles.scss";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as createClientActions} from "@sagas/client/create";
import DataGrid from "react-data-grid";
import {DownloadFile, SaveRowIcon} from "@src/assets/SvgComponents";
import ReactToPrint from "react-to-print";
import NavigationPrompt from "react-router-navigation-prompt";
import LeavePageAlert from "@src/components/dashboard/Trainings/Add/LeavePageAlert";
import Loader from "@src/common/Loader";
import {formatNumber} from "@src/utils/convertNumber";
import PrintTable from "@src/common/components/print-table";
import {checkRoles} from "@src/utils/checkRoles";
import {columns} from "./helper";
import {columnObj} from "../../../../../../Users/TeamConditionsV1/conditionElements";
const SalesHistory = ({fetchData, data, client, match, setData, saveTable, total, loading}) => {
	const {t} = useTranslation();
	const [canNav, setCanNav] = useState(false);
	const componentRef = useRef();
	const [datesGraph, setDatesGraph] = useState({
		from: moment("01.01.2024").startOf("month").utcOffset(0, true).valueOf(),
		to: moment("12.31.2024").endOf("month").valueOf(),
	});
	const handleUpdateRows = (e, row) => {
		const arr = e.map((item) => ({
			...item,
			total: item?.receiveCommisionStatistics + item?.additionalRewards,
		}));
		setData(arr);
		setCanNav(true);
	};
	useEffect(() => {
		const obj = {
			from: datesGraph?.from,
			to: datesGraph?.to,
			clientId: +match?.params.clientId,
		};
		fetchData(obj);
	}, [datesGraph]);
	const {RangePicker} = DatePicker;

	const onChange = (date) => {
		setDatesGraph({
			from: moment(date?.[0]).startOf("month").utcOffset(0, true).valueOf(),
			to: moment(date?.[1]).endOf("month").valueOf(),
		});
	};
	const tableFooter = (type) => (
		<div className={type && "print-footer"}>
			<div className={type ? "print-footer-info" : "info"}>
				<span>{t("totalAmount")}</span>
				<span>{`${formatNumber(total)}`} €</span>
			</div>
			<div className="sales-history-table--body-sum-row">
				<div className="sales-history-table--body-sum-row-col">
					<span>{t("totalAmount")}</span>
				</div>
				<div className="sales-history-table--body-sum-row-total">
					<span>{`${formatNumber(total)}`} €</span>
				</div>
			</div>
		</div>
	);
	return (
		<div className="sales-history-table">
			<NavigationPrompt renderIfNotActive={true} when={canNav}>
				{({isActive, onCancel, onConfirm}) =>
					isActive && (
						<LeavePageAlert
							open={true}
							handleClose={onCancel}
							handleAgree={onConfirm}
						/>
					)
				}
			</NavigationPrompt>
			<div className="sales-history-table--header">
				<p className="sales-history-table--header--title">
					{`${client?.clientName} ${t("SaleStatistics")}`}
				</p>
				<div className="sales-history-table--header--right">
					<RangePicker
						format="DD-MM-YYYY"
						picker="month"
						onChange={(dates) => onChange(dates)}
						defaultValue={[moment(datesGraph.from), moment(datesGraph.to)]}
						locale={locale}
						generateConfig={generateConfig}
					/>
					{checkRoles("seeSaveOnStatisticAndDocument") && (
						<div
							onClick={() => {
								saveTable({
									dataSaleRequests: data,
									clientId: +match?.params.clientId,
								});
								setCanNav(false);
							}}
							className="sales-history-table--header--right-icon"
						>
							<SaveRowIcon fill="none" stroke="#fff" />
						</div>
					)}
					{checkRoles("seePrintOnStatisticAndDocument") && (
						<ReactToPrint
							copyStyles={true}
							trigger={() => (
								<div className="sales-history-table--header--right-icon">
									<DownloadFile stroke="#fff" />
								</div>
							)}
							content={() => componentRef.current}
						/>
					)}
				</div>
			</div>
			<PrintTable
				printTableRef={componentRef}
				data={data}
				columns={columns}
				renderFooter={() => tableFooter("print")}
			/>
			<div>
				{loading ? (
					<div className="loader__contianer">
						<Loader />
					</div>
				) : (
					<div className="sales-history-table--body">
						<div className="info">
							{`${client?.clientName} ${t("SaleStatistics")}`}{" "}
							<span>
								{moment(datesGraph.from).format("DD.MM.YYYY")} -{" "}
								{moment(datesGraph.to).format("DD.MM.YYYY")}
							</span>
						</div>
						<DataGrid
							className="rdg-light"
							columns={columns?.map((item) => ({
								...item,
								...columnObj,
								getRowMetaData: (row) => row,
								editable: item.editable,
							}))}
							rows={data?.[0] ? data : []}
							enableCellSelect={true}
							onRowsChange={handleUpdateRows}
							rowHeight={44}
							headerRowHeight={50}
						/>
						{tableFooter()}
					</div>
				)}
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	data: state.app.client.create.stats,
	client: state.app.client.index.client,
	total: state.app.client.create.total,
	loading: state.app.client.create.loading,
});

const mapDispatchToProps = {
	fetchData: createClientActions.getClientStats,
	setData: createClientActions.getClientStatsSuccess,
	saveTable: createClientActions.saveTable,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SalesHistory));
