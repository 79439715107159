import {ShowInfoIcon} from "@src/assets/SvgComponents";
import i18n from "@src/i18n";
import React from "react";

const TextAreaComponent = (props) => {
	const {
		maxLength,
		setFieldValue,
		value,
		width = "60%",
		breakLine = true,
		errors,
		touched,
		errorClass,
		placeholder,
		inputName = "description",
		hasDescription = true,
		classContainer,
		label = i18n.t("Description"),
		additionalInfo,
		toggleInfoVisibility,
	} = props;
	return (
		<>
			{breakLine && <div className="breakLine"></div>}
			<div className="textarea_container">
				<div className={`textarea__input ${classContainer}`} style={{width}}>
					{hasDescription && (
						<label htmlFor="textarea" className="form_input__label">
							{label}
							{additionalInfo && (
								<span className="info__icon" onClick={toggleInfoVisibility}>
									<ShowInfoIcon fill="#979797" />
								</span>
							)}
						</label>
					)}
					<textarea
						name="textarea"
						id="textarea"
						cols="30"
						rows="3"
						className=" textarea"
						maxLength={maxLength}
						placeholder={placeholder}
						value={value}
						onChange={(e) => {
							setFieldValue(inputName, e.target.value);
						}}
					></textarea>
					<div className="textarea_container-bottom">
						{errors && touched ? (
							<span className={errorClass}>*{errors}</span>
						) : (
							<span></span>
						)}
						{maxLength && (
							<p className="textarea_max_length">
								{value?.length || 0}/{maxLength}
							</p>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default TextAreaComponent;
