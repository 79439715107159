/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-len */
import React, {useState} from "react";
import ButtonComponent from "@common/button";
import DatePicker from "@common/DatePicker";
import AsyncDropDownInput from "@common/dropDown/asyncDropDown";
import SimpleDropDownInput from "@common/dropDown/simpleDropDown";
import InputComponent from "@common/input";
import Signature from "@common/signature";
import {importedFields} from "@components/dashboard/orders/createEditOrder/forms";
import {Title} from "@components/dashboard/orders/createEditOrder/title";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {actions as editOrderActions} from "@sagas/orders/editOrder";
import {Formik} from "formik";
import moment from "moment";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {fieldBasedOnLanguage} from "@utils/searchCustomFields";
import ActivityBox from "@src/common/ActivityBox";
import {ArrowDownIcon, EmailIcon} from "@src/assets/SvgComponents";
import GenericDatePicker from "@src/common/CustomDatePicker";
import {useFeatures} from "@src/utils/useFeatures";
import EmailInput from "@src/common/EmailInput";
import CheckBoxComponentAntd from "@src/common/checkbox/antdCheckbox";
import {DATE_INPUTS} from "@src/components/generic-pdf/createOrder/components/staticData";
import downloadIcon from "../../../../assets/img/downloadILogo.svg";
import {renderUploadComponent} from "./renderUploadComponent";
import {getOptions, isCustomFieldChecked, requestTypeConfig} from "./helper";
import IbanInput from "../../Energy/components/IbanValidator";

const EditOrder = ({
	validationSchema,
	order,
	fieldMapping,
	editOrder,
	previewMode = false,
	editedOrderFields,
	removeFile,
	uploadFile,
	loading,
	language,
	confirmModal,
	setConfirmModal,
	downloadContract,
	sections,
	query,
	setReEditPage,
	tab,
	options,
	retrieveDataFromGetAg,
}) => {
	const {t} = useTranslation();
	const currentLanguage = localStorage.getItem("language");
	const {checkFeature} = useFeatures();
	const [asyncOptions, setAsyncOptions] = useState({});
	const sectionName = currentLanguage === "en" ? "nameEn" : "nameDe";
	const sortedSectionsWithShowField = [...sections]
		.filter((section) => section.showField)
		.sort((a, b) => a.nr - b.nr);
	const filteredFieldMapping = fieldMapping?.filter((item) => !item?.hideWeb);
	const orphanFields = _.filter(filteredFieldMapping, (item) => item?.item?.parentId === "");
	const groupedFieldsBySortedSections = sortedSectionsWithShowField.reduce((acc, section) => {
		acc[section.id] = _.filter(
			filteredFieldMapping,
			(item) => item?.item?.parentId === section.id,
		);
		return acc;
	}, {});
	const groupedFields = {
		orphanFields,
		...groupedFieldsBySortedSections,
	};
	const handleChangeInput = (e, handleChange, element, setFieldValue) => {
		const {dependencyFields, defaultValue, requestType} = element?.item;
		const {value} = e.target;
		if (dependencyFields) {
			dependencyFields.forEach((dep) => {
				const foundedDep = editedOrderFields.find((field) => field?.name === dep);
				if (foundedDep?.inputType === "CHECKBOX") {
					setFieldValue(`customFieldsValue.${foundedDep.name}.value`, !!value);
				}
			});
		}
		if (!defaultValue || value.startsWith(defaultValue)) {
			handleChange(e);
		}
		if (
			requestType &&
			requestTypeConfig?.[requestType] &&
			isGetAgAvailable(requestType, "CHECK_ADDRESS")
		) {
			retrieveDataFromGetAg({...element.item, type: requestTypeConfig?.[requestType]}, value);
			if (dependencyFields) {
				dependencyFields.forEach((f) => {
					const foundedDep = filteredFieldMapping.find((itm) => itm.item.name === f);
					setFieldValue(foundedDep.name, null);
				});
			}
		}
	};
	const isGetAgAvailable = (requestType, targetFeature) => {
		if (!requestType) {
			return true;
		}
		return checkFeature(targetFeature);
	};

	return (
		<>
			<Formik
				validationSchema={validationSchema}
				enableReinitialize={true}
				initialValues={order}
				onSubmit={(values, actions) => {
					const obj = {};
					if (setReEditPage) {
						setReEditPage(false);
					}
					// eslint-disable-next-line no-restricted-syntax
					for (const [key, val] of Object.entries(values?.customFieldsValue)) {
						if (!values?.customFieldsValue[key]?.customFieldId) {
							const foundedField = filteredFieldMapping?.find(
								(it) => it?.item?.name === key,
							);
							obj[foundedField?.item?.name] = {
								customFieldId: foundedField?.item?.fieldId,
								key,
								value: val?.value,
								labelEn: foundedField?.item?.labelEn,
								labelDe: foundedField?.item?.labelDe,
								inputType: foundedField?.item?.inputType,
								dropDownChilds: foundedField?.item?.dropDownChilds,
								customOptionsString: foundedField?.item?.customOptionsString,
								isRequired: foundedField?.item?.isRequired,
							};
						} else {
							obj[key] = {
								customFieldId: val?.fieldId,
								key,
								value: val?.value,
								labelEn: val.labelEn,
								labelDe: val?.labelDe,
								inputType: val?.inputType,
								dropDownChilds: val?.dropDownChilds,
								customOptionsString: val?.customOptionsString,
								isRequired: val?.isRequired,
							};
						}
					}
					const allVals = {
						...values,
						customFieldsValue: {...obj},
					};
					for (let i = 0; i < editedOrderFields.length; i++) {
						const {objectType, name, inputType} = editedOrderFields[i];
						if (objectType === "CREATE_ORDER_DETAILS") {
							const value = values?.createOrderDetails[name];
							allVals.createOrderDetails = {
								...allVals.createOrderDetails,
								[name]: value
									? DATE_INPUTS.includes(inputType)
										? moment.utc(value, "DD/MM/YYYY").valueOf()
										: value
									: null,
							};
						}
					}
					actions.setSubmitting(true);
					editOrder({values: allVals, actions, query, tab});
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					setFieldValue,
					isSubmitting,
				}) => (
					<form autoComplete="off" style={{width: "100%"}}>
						<div className="order_modals">
							<div className="editOrder__container">
								{Object.keys(groupedFields)?.map((sectionId) => (
									<>
										<Title
											title={
												_.find(sections, {
													id: Number(sectionId),
												})?.[sectionName]
											}
										/>
										<div
											key={sectionId}
											className="editOrder__container-sections"
										>
											{groupedFields[sectionId].map((element, index) => (
												<React.Fragment key={index}>
													{
														{
															title: (
																<Title
																	title={
																		_.find(sections, {
																			id: Number(sectionId),
																		})?.[sectionName]
																	}
																	hidden={
																		element?.dependencies &&
																		_.get(
																			values,
																			element.dependencies,
																		) !== element.match
																	}
																/>
															),
															input: (
																<>
																	{element?.item?.requestType ===
																	"IBAN" ? (
																		<IbanInput
																			element={element}
																			setFieldValue={
																				setFieldValue
																			}
																			value={
																				_.get(
																					values,
																					element.name,
																				) ?? ""
																			}
																			fibreOrder
																			fields={
																				editedOrderFields
																			}
																			canValidate={isGetAgAvailable(
																				element.item
																					.requestType,
																				"CHECK_IBAN",
																			)}
																		/>
																	) : (
																		<InputComponent
																			hidden={
																				element?.dependencies &&
																				_.get(
																					values,
																					element.dependencies,
																				) !== element.match
																			}
																			placeholder={t(
																				element?.placeholder,
																			)}
																			handleChange={(e) =>
																				handleChangeInput(
																					e,
																					handleChange,
																					element,
																					setFieldValue,
																				)
																			}
																			handleBlur={handleBlur}
																			key={index}
																			type={element.type}
																			name={element.name}
																			values={
																				_.get(
																					values,
																					element.name,
																				) ?? ""
																			}
																			disabled={
																				previewMode ||
																				element?.disable ||
																				element?.item
																					?.isDisabled ||
																				(element?.item
																					?.isScheduleDeleted &&
																					order.isScheduleDeleted)
																			}
																			errorClass="errorClass"
																			errors={_.get(
																				errors,
																				element.name,
																			)}
																			touched={_.get(
																				touched,
																				element.name,
																			)}
																			newClass="order-edit-input"
																			setFieldValue={
																				setFieldValue
																			}
																		/>
																	)}
																</>
															),
															dropdown: (
																<div className="order-input-dropdown-down">
																	<SimpleDropDownInput
																		disabled={
																			previewMode ||
																			(element?.item
																				?.isScheduleDeleted &&
																				order.isScheduleDeleted)
																		}
																		hidden={
																			element?.dependencies &&
																			_.get(
																				values,
																				element.dependencies,
																			) !== element.match
																		}
																		key={index}
																		options={
																			element?.options ?? []
																		}
																		title="name"
																		placeholder={t(
																			element.placeholder,
																		)}
																		value={
																			_.get(
																				values,
																				element.name,
																			) ?? ""
																		}
																		onChange={(
																			event,
																			value,
																		) => {
																			setFieldValue(
																				element.name,
																				value,
																			);
																		}}
																		name={element.name}
																		errors={_.get(
																			errors,
																			element.name,
																		)}
																		touched={_.get(
																			touched,
																			element.name,
																		)}
																		setFieldValue={
																			setFieldValue
																		}
																	/>
																</div>
															),
															customDropdown: (
																<>
																	{!isGetAgAvailable(
																		element.item.requestType,
																		"CHECK_ADDRESS",
																	) ? (
																		<InputComponent
																			hidden={
																				element?.dependencies &&
																				_.get(
																					values,
																					element.dependencies,
																				) !== element.match
																			}
																			placeholder={t(
																				element?.placeholder,
																			)}
																			handleChange={(e) =>
																				handleChangeInput(
																					e,
																					handleChange,
																					element,
																					setFieldValue,
																				)
																			}
																			handleBlur={handleBlur}
																			key={index}
																			type={element.type}
																			name={element.name}
																			values={
																				_.get(
																					values,
																					element.name,
																				) ?? ""
																			}
																			disabled={
																				previewMode ||
																				element?.disable ||
																				element?.item
																					?.isDisabled ||
																				(element?.item
																					?.isScheduleDeleted &&
																					order.isScheduleDeleted)
																			}
																			errorClass="errorClass"
																			errors={_.get(
																				errors,
																				element.name,
																			)}
																			touched={_.get(
																				touched,
																				element.name,
																			)}
																			newClass="order-edit-input"
																			setFieldValue={
																				setFieldValue
																			}
																		/>
																	) : (
																		<>
																			{renderCustomDropDown(
																				element,
																				values,
																				setFieldValue,
																				previewMode,
																				editedOrderFields,
																				order,
																				language,
																				options,
																				filteredFieldMapping,
																				retrieveDataFromGetAg,
																			)}
																		</>
																	)}
																</>
															),
															asyncDropDown: (
																<>
																	{previewMode &&
																	element.hiddenOnPreview ===
																		true ? null : (
																		<div className="edit-order-input-dropdown">
																			{" "}
																			<AsyncDropDownInput
																				label
																				asyncOptions={
																					asyncOptions
																				}
																				setAsyncOptions={
																					setAsyncOptions
																				}
																				disabled={
																					previewMode ||
																					(element?.item
																						?.isScheduleDeleted &&
																						order.isScheduleDeleted)
																				}
																				renderTags={(e) =>
																					`+${e?.length}`
																				}
																				options={
																					element.fetchOptions
																						? element?.fetchOptions()
																						: []
																				}
																				key={index}
																				element={element}
																				dependencies={
																					values
																				}
																				title={
																					element.placeholder
																				}
																				placeholder={t(
																					element.placeholder,
																				)}
																				value={_.get(
																					values,
																					element.name,
																				)}
																				onChange={(
																					event,
																					value,
																				) => {
																					setFieldValue(
																						element.name,
																						value,
																					);
																					if (
																						element.clearField
																					) {
																						setFieldValue(
																							element.clearField,
																							[],
																						);
																						setAsyncOptions(
																							(
																								prev,
																							) => ({
																								...prev,
																								[element.clearField]:
																									[],
																							}),
																						);
																					}
																				}}
																				disableClearable={
																					element.disableClearable
																				}
																				multiple={
																					element.multiple
																				}
																				name={element.name}
																				errors={_.get(
																					errors,
																					element.name,
																				)}
																				touched={_.get(
																					touched,
																					element.name,
																				)}
																			/>
																		</div>
																	)}
																</>
															),
															checkbox: (
																<div>
																	<CheckBoxComponentAntd
																		disabled={
																			previewMode ||
																			(element?.item
																				?.isScheduleDeleted &&
																				order.isScheduleDeleted)
																		}
																		name={element.name}
																		label={t(
																			element.placeholder,
																		)}
																		checked={isCustomFieldChecked(
																			_.get(
																				values,
																				element.name,
																			),
																		)}
																		placeholder={t(
																			element.placeholder,
																		)}
																		touched={_.get(
																			touched,
																			element.name,
																		)}
																		errors={_.get(
																			errors,
																			element.name,
																		)}
																		handleChange={() => {
																			const currentValue =
																				_.get(
																					values,
																					element.name,
																				) ?? false;
																			setFieldValue(
																				element?.name,
																				!currentValue,
																			);
																		}}
																	/>
																</div>
															),
															radioGroup: (
																<>
																	{!previewMode &&
																	element.hiddenOnEdit ===
																		true ? null : (
																		<div
																			className="order-input-dropdown checkbox"
																			style={{
																				borderTop:
																					element.hiddenOnEdit
																						? "solid 2px #f5f5f5"
																						: "",
																				borderBottom:
																					element.hiddenOnEdit
																						? "none"
																						: "",
																			}}
																		>
																			<FormControl
																				key={index}
																				disabled={
																					previewMode ||
																					(element?.item
																						?.isScheduleDeleted &&
																						order.isScheduleDeleted)
																				}
																			>
																				<RadioGroup
																					onChange={
																						handleChange
																					}
																					value={
																						_.get(
																							values,
																							element.name,
																						) ?? ""
																					}
																					name={
																						element.name
																					}
																				>
																					{element.options?.map(
																						(e, i) => (
																							<FormControlLabel
																								key={
																									i
																								}
																								value={
																									e.value
																								}
																								control={
																									<Radio />
																								}
																								label={t(
																									e.label,
																								)}
																							/>
																						),
																					)}
																				</RadioGroup>
																			</FormControl>
																		</div>
																	)}
																</>
															),
															datePicker: (
																<div
																	className="order-input-dropdown
													datepicker"
																>
																	<DatePicker
																		disabled={
																			previewMode ||
																			(element?.item
																				?.isScheduleDeleted &&
																				order.isScheduleDeleted)
																		}
																		label={t(
																			element.placeholder,
																		)}
																		name={element.name}
																		onChange={(value) => {
																			setFieldValue(
																				element.name,
																				moment(
																					value,
																				).valueOf(),
																			);
																		}}
																		value={
																			_.get(
																				values,
																				element.name,
																			) ?? null
																		}
																		maxDate={element?.maxDate}
																		handleBlur={handleBlur}
																		handleChange={handleChange}
																		errors={_.get(
																			errors,
																			element.name,
																		)}
																		touched={_.get(
																			touched,
																			element.name,
																		)}
																	/>
																</div>
															),
															signature: (
																<>
																	{element.dependencies &&
																	_.get(
																		values,
																		element.dependencies,
																	) !== element.match ? null : (
																		<div
																			key={index}
																			className="signatureOnEdit"
																		>
																			<div className="signatureOnEditDiv">
																				{_.get(
																					order,
																					element.name,
																				) ? (
																					<>
																						<img
																							style={{
																								width: 300,
																								height: 100,
																							}}
																							src={
																								_.get(
																									values,
																									element.name,
																								) ??
																								""
																							}
																							alt=""
																						/>
																					</>
																				) : (
																					<>
																						{!previewMode ? (
																							<Signature
																								setFieldValue={
																									setFieldValue
																								}
																								element={
																									element
																								}
																							/>
																						) : (
																							<div
																								style={{
																									height: "100px",
																								}}
																							></div>
																						)}
																					</>
																				)}
																			</div>
																		</div>
																	)}
																</>
															),
															image: (
																<div
																	className="order-inputImage-wrapper"
																	style={{
																		display:
																			!previewMode && "none",
																	}}
																>
																	{_.get(order, element.name) && (
																		<div
																			onClick={() =>
																				downloadContract(
																					_.get(
																						values,
																						element.name,
																					) ?? "",
																				)
																			}
																			className="order-inputImage"
																		>
																			<span>
																				{t("contractImage")}
																			</span>
																			<img
																				alt=""
																				src={downloadIcon}
																			/>
																		</div>
																	)}
																</div>
															),
															upload: (
																<>
																	{renderUploadComponent(
																		element,
																		values,
																		previewMode,
																		setConfirmModal,
																		confirmModal,
																		loading,
																		removeFile,
																		uploadFile,
																		filteredFieldMapping,
																		index,
																		order?.clientOrderId,
																	)}
																</>
															),
															date: (
																<GenericDatePicker
																	setFieldValue={setFieldValue}
																	element={element}
																	error={_.get(
																		errors,
																		element.name,
																	)}
																	value={_.get(
																		values,
																		element.name,
																	)}
																/>
															),
															email: (
																<EmailInput
																	handleInput={(e) =>
																		handleChangeInput(
																			e,
																			handleChange,
																			element,
																			setFieldValue,
																		)
																	}
																	element={element}
																	value={_.get(
																		values,
																		element.name,
																	)}
																	label={element?.placeholder}
																/>
															),
														}[element.inputType]
													}
												</React.Fragment>
											))}
										</div>
									</>
								))}
							</div>
							{previewMode && order.importData.fileId && (
								<div>
									<Title title={t("ImportOrderData")} />
									<div className="import__order__inputs">
										{importedFields.map((item, index) => (
											<InputComponent
												key={index}
												newClass="order-edit-input"
												disabled
												placeholder={item?.placeholder}
												values={order?.importData[item?.field]}
											/>
										))}
									</div>
								</div>
							)}
							{previewMode && (
								<div>
									<Title title={t("products")} />
									{order?.products?.length > 0 ? (
										<div className="Print__InfoTable">
											{order?.products?.map((item) => (
												<div className="products-section">
													<span>{item.name} </span>
													<span>Ja</span>
												</div>
											))}
										</div>
									) : (
										<div className="noOrderText">{t("clientNoProduct")}</div>
									)}
								</div>
							)}
							{order?.emailLogs?.[0] && (
								<div className="order__activity">
									<p className="order__activity--title">{t("activity")}</p>
									<div className="order__activity--container">
										{order?.emailLogs?.map((item) => (
											<ActivityBox
												email={item?.email}
												sendAt={item?.sendAt}
												sender={order.salesPartnerName}
												key={item.id}
												Icon={EmailIcon}
												title={
													item.type === "OFFER"
														? t("OfferEmailSentTo")
														: t("EmailSentTo")
												}
											/>
										))}
									</div>
								</div>
							)}
						</div>
						<div className="order-edit-button-div">
							<ButtonComponent
								classNames="primaryButton order-edit-button"
								type="submit"
								buttonText={t("Save")}
								loading={isSubmitting || loading}
								disabled={isSubmitting || loading}
								onClick={handleSubmit}
							></ButtonComponent>
						</div>
					</form>
				)}
			</Formik>
		</>
	);
};

const mapStateToProps = (state) => ({
	order: state.app.orders.edit.order,
	editedOrderFields: state.app.client.index.editedOrderFields,
	sections: state.app.client.index.sections,
	loading: state.app.orders.edit.loading,
});

const mapDispatchToProps = {
	editOrder: editOrderActions.editOrder,
	uploadFile: editOrderActions.uploadOrderFile,
	removeFile: editOrderActions.removeOrderFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditOrder));

const renderCustomDropDown = (
	element,
	values,
	setFieldValue,
	previewMode,
	editedOrderFields,
	order,
	language,
	genericOptions,
	fieldMapping,
	retrieveDataFromGetAg,
) => {
	if (element?.inputType !== "customDropdown") return null;
	const dropDownName = _.get(values, element.name);
	const options = getOptions(element, editedOrderFields, genericOptions, language);
	const parsedValue = (() => {
		try {
			const rawValue = _.get(values, element?.name);
			if (element?.item?.isMultiple) {
				return (
					JSON.parse(rawValue)?.map((item) => {
						const foundedItm = editedOrderFields?.find((it) => it.name === item);
						return {
							value: foundedItm ? foundedItm?.[fieldBasedOnLanguage[language]] : item,
							label: item,
						};
					}) || []
				);
			}
			const foundedItm = editedOrderFields.find((it) => it.name === rawValue);
			return {
				value: foundedItm ? foundedItm?.[fieldBasedOnLanguage[language]] : rawValue,
				label: rawValue,
			};
		} catch (error) {
			return element?.item?.isMultiple ? [] : {value: "", label: ""};
		}
	})();
	const handleDropdown = (event, value) => {
		const {isMultiple, requestType, dependentFrom, dependencyFields} = element?.item;
		if (isMultiple) {
			setFieldValue(
				element.name,
				JSON.stringify(value?.map((item) => (item?.label ? item?.label : item))),
			);
		} else {
			setFieldValue(element.name, value.label);
		}
		if (requestType && requestTypeConfig?.[requestType]) {
			if (dependencyFields) {
				dependencyFields.forEach((f) => {
					const foundedDep = fieldMapping?.find((el) => el.item.name === f);
					setFieldValue(foundedDep.name, null);
				});
			}
			let dependentValue;
			if (dependentFrom) {
				const foundedDep = fieldMapping?.find((el) => el.item.name === dependentFrom);
				if (foundedDep) {
					dependentValue = _.get(values, foundedDep.name);
				}
			}
			retrieveDataFromGetAg(
				{...element.item, type: requestTypeConfig?.[requestType]},
				value.label,
				dependentValue,
			);
		}
	};
	return (
		<div className="edit-order-input-dropdown">
			<SimpleDropDownInput
				label
				icon={<ArrowDownIcon />}
				disabled={
					previewMode || (element?.item?.isScheduleDeleted && order.isScheduleDeleted)
				}
				options={options || []}
				name={dropDownName}
				title="value"
				value={parsedValue}
				placeholder={element?.placeholder}
				onChange={handleDropdown}
				setFieldValue={setFieldValue}
				multiple={element?.item?.isMultiple}
			/>
		</div>
	);
};
