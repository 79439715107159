import React, {useRef} from "react";
import SignatureCanvas from "react-signature-canvas";

const Signature = ({setFieldValue, element, width = 300, height = 100, signatureRef, useCustomRef}) => {
	const sig = useRef();
	const onSignatureEnd = (setFieldValue, element) => {
		const ref = useCustomRef ? signatureRef : sig;
		setFieldValue(element.name, ref?.current?.toDataURL());
	};
	return (
		<SignatureCanvas
			ref={useCustomRef ? signatureRef : sig}
			id="signature"
			penColor="black"
			onEnd={() => onSignatureEnd(setFieldValue, element)}
			canvasProps={{
				width,
				height,
				className: "sigCanvas",
			}}
			// clear
		/>
	);
};
export default Signature;
