import React from "react";
import i18n from "@src/i18n";
import {ToastInfoComponent} from "@src/common/ToastComponent/ToastComponent";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import _ from "lodash";
import MediaComponent from "@src/common/messagesComponents/MediaComponent";
import {downloadAllHandler, findFirstUploadIdx} from "./helper";

export const renderUploadComponent = (
	element,
	values,
	previewMode,
	setConfirmModal,
	confirmModal,
	loading,
	removeFile,
	uploadFile,
	fieldMapping,
	index,
	clientOrderId,
) => {
	if (element?.inputType !== "upload") return null;
	const images = _.get(values, element?.name) ? JSON.parse(_.get(values, element?.name)) : [];
	const handleRemove = (file) => {
		setConfirmModal({
			open: true,
			file,
			customFieldId: element?.item?.fieldId,
		});
	};
	const handleClose = () => {
		setConfirmModal({
			open: false,
			file: null,
			customFieldId: null,
		});
	};
	const handleDeleteFile = () => {
		removeFile({
			file: confirmModal?.file?.fileUrl,
			customFieldId: confirmModal?.customFieldId,
			values,
		});
		handleClose();
	};
	const handleUpload = (event) => {
		const fileList = event.target.files;
		if (images?.length + fileList?.length > 10) {
			ToastInfoComponent(i18n.t("UploadLengthInfo10"));
			event.preventDefault();
			return;
		}
		const fileDataArray = Array.from(fileList).map(
			(file) =>
				new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onloadend = () => {
						resolve({
							filename: file.name,
							base64: reader.result,
							type: file.type,
							extension: file.name.split(".").pop()?.toLowerCase(),
							size: file.size,
							customFieldId: element?.item?.fieldId,
							fieldMapping,
							values,
						});
					};
					reader.onerror = () => {
						reader.abort();
						reject(new DOMException("Problem parsing input file."));
					};
				}),
		);
		Promise.all(fileDataArray).then(uploadFile);
	};

	const firstUploadIdx = findFirstUploadIdx(fieldMapping, values);
	return (
		<>
			<DeleteModal
				calculationModal
				newTitle={i18n.t("areYouSureDeleteFile")}
				open={confirmModal?.open}
				setShowDeleteModal={handleClose}
				handleClose={handleClose}
				disabled={loading}
				confirm={handleDeleteFile}
			/>
			<div className="order-input-media-component">
				{firstUploadIdx === index && images?.length > 0 && (
					<div
						className="order-input-media-component-downloadAll"
						onClick={() => downloadAllHandler(fieldMapping, values, clientOrderId)}
					>
						{i18n.t("DownloadAll")}
					</div>
				)}
				<MediaComponent
					totalFiles={{
						files: images.map((image, index) => ({
							fileUrl: image,
							fileType: image?.split(".").pop(),
							fileName: `${element.placeholder}${index + 1}.${image
								?.split(".")
								.pop()}`,
						})),
					}}
					showTitle
					customClassName="edit-order-class"
					title={element?.placeholder}
					canDownload
					canRemove={!previewMode}
					onRemove={handleRemove}
					height="auto"
					downloadWithoutToken
					canUploadMore={!previewMode && !element?.item?.isScheduleDeleted}
					id={element?.item?.fieldId}
					uploadInputHandler={(event) => handleUpload(event, element)}
					titleStyles={{
						justifyContent: "flex-start",
						gap: "15px",
					}}
				/>
			</div>
		</>
	);
};
