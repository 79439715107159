import React from "react";
import ButtonComponent from "@src/common/button";
import {useTranslation} from "react-i18next";
import BaseFields from "./BaseFields";
import {checkErrors} from "./helpers";
import SectionBox from "./components/SectionBox";
import FieldsRenderer from "./components/FieldsRenderer";

const Form = ({
	formik,
	baseFields,
	groupedFields,
	handleCollapse,
	collapsedSections,
	fieldOptions,
	editedOrderFields,
	uploadedFiles,
	setUploadedFiles,
	retrieveDataFromGetAg,
	options,
	loading,
}) => {
	const {t} = useTranslation();
	const {handleSubmit, setFieldValue, values, errors} = formik;
	return (
		<form onSubmit={handleSubmit} className="order__form">
			<div className="inputs__wrapper">
				<BaseFields
					values={values}
					setFieldValue={setFieldValue}
					baseFields={baseFields}
					errors={errors}
				/>
			</div>
			{groupedFields?.map((section) => {
				const {id, showField, fields, nameEn, collapse} = section;
				if (!showField || nameEn === "Section Hide") return null;
				const fieldErrors = checkErrors(fields, errors);
				return (
					<SectionBox
						key={`${id}--${fields.length}`}
						item={section}
						handleClick={handleCollapse}
						collapsed={collapsedSections?.[id]}
						disabled={!collapse}
						error={fieldErrors}
					>
						<FieldsRenderer
							fields={fields}
							values={values}
							errors={errors}
							setFieldValue={setFieldValue}
							fieldOptions={fieldOptions}
							orderFields={editedOrderFields}
							uploadedFiles={uploadedFiles}
							setUploadedFiles={setUploadedFiles}
							retrieveDataFromGetAg={retrieveDataFromGetAg}
							options={options}
						/>
					</SectionBox>
				);
			})}
			<div className="order__form-actions">
				<ButtonComponent
					classNames="order-edit-button"
					type="submit"
					buttonText={t("Save")}
					onClick={handleSubmit}
					disabled={loading}
					loading={loading}
				/>
			</div>
		</form>
	);
};

export default Form;
