import JSZip from "jszip";
import _ from "lodash";
import {saveAs} from "file-saver";
import {fieldBasedOnLanguage} from "@src/utils/searchCustomFields";

export const findFirstUploadIdx = (fieldMapping, values) => {
	const idx = fieldMapping?.findIndex((itm) => {
		if (itm.inputType === "upload") {
			const value = _.get(values, itm?.name);
			let images = [];
			try {
				images = value ? JSON.parse(value) : [];
			} catch (error) {
				return error;
			}
			return images?.length > 0;
		}
		return false; // return false to continue checking other items
	});
	return idx;
};

export const downloadAllHandler = async (fieldMapping, values, clientOrderId) => {
	const uploadInputs = fieldMapping?.filter((item) => item.inputType === "upload");
	const files = [];
	for (let i = 0; i < uploadInputs?.length; i++) {
		const input = uploadInputs[i];
		const images = _.get(values, input?.name) ? JSON.parse(_.get(values, input?.name)) : [];
		if (images?.[0]) {
			const imagesObj = images.map((img, index) => ({
				fileUrl: img,
				fileName: `${input.placeholder}${index + 1}.${img?.split(".").pop()}`,
			}));
			files.push(...imagesObj);
		}
	}
	const zip = new JSZip();
	const folder = zip.folder(clientOrderId || "Order Uploads");
	const fetchPromises = files.map(async (img) => {
		try {
			const response = await fetch(img.fileUrl);
			if (response.status === 200) {
				const blob = await response.blob();
				folder.file(img.fileName, blob);
			} else {
				return Promise.reject(new Error(response.statusText));
			}
		} catch (error) {
			return error;
		}
	});

	await Promise.all(fetchPromises);

	zip.generateAsync({type: "blob"}).then((blob) =>
		saveAs(blob, clientOrderId || "Order Uploads"),
	);
};

export const generateFilesArray = (files) => {
	const array = files?.map((file) => {
		const parts = file.split("/");
		const fileName = parts.pop();
		const fileType = fileName.split(".").pop();
		return {
			fileName,
			fileType,
			fileUrl: file,
		};
	});
	return array;
};

export const clientWithoutGenericArr = ["TNG", "GFD", "EON", "TER", "HNT"];

export const getOptions = (element, editedOrderFields, genericOptions, language) => {
	const {requestType, customOptionsString} = element.item;
	if (requestType) {
		return genericOptions?.[element.item.name]
			? genericOptions?.[element.item.name]?.map((itm) => ({
					value: itm.labelDe,
					label: itm?.labelDe,
			  }))
			: [];
	}
	if (customOptionsString) {
		return customOptionsString?.split(",")?.map((item) => ({
			value: item?.trim(),
			label: item?.trim(),
		}));
	}
	const dropdownChilds = editedOrderFields?.filter(
		(item) => item.dropDownParentId === element.item.fieldId,
	);
	return dropdownChilds?.map((item) => ({
		value: item?.[fieldBasedOnLanguage[language]],
		label: item?.name,
	}));
};

export const requestTypeConfig = {
	LOCATION: "location",
	POSTCODE: "postCode",
};

export const isCustomFieldChecked = (value) => value === "true" || value === true;
