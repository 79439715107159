import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as teamActions} from "@sagas/teams";
import {actions as userActions} from "@sagas/users/users";
import {ToastErrorComponent, ToastSuccesComponent} from "@common/ToastComponent/ToastComponent";
import {handleError} from "@src/utils/handleError";
import i18n from "../../../../i18n";
const logger = new Logger("Team create");

const teamInitialValues = {
	name: "",
	teamLeader: 0,
	users: [],
	id: "",
	from: null,
	to: null,
	headOfDepartment: {
		id: null,
		name: "",
	},
};

const PREFIX = "@app/team/create";
export const ADD_TEAM = `${PREFIX}ADD_TEAM`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const ADD_TEAM_SUCCESS = `${PREFIX}ADD_TEAM_SUCCESS`;
export const SHOW_MODAL = `${PREFIX}SHOW_MODAL`;
export const FILL_TEAM = `${PREFIX}FILL_TEAM`;
export const SET_SELECTED_USERS = `${PREFIX}SET_SELECTED_USERS`;
export const CLEAR_TEAM_VALUES = `${PREFIX}CLEAR_TEAM_VALUES`;

const _state = {
	loading: false,
	teamAdded: false,
	teamIntitialValues: {},
	showModal: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case ADD_TEAM_SUCCESS:
				draft.teamAdded = action.payload;
				break;
			case FILL_TEAM:
				const headOfDepartment = action.payload.members?.find(
					(member) =>
						member.id === action?.payload?.headOfDepartment ||
						member.id === action?.payload?.headOfDepartmentId,
				);
				const obj = {
					name: action.payload?.name,
					from: action.payload?.from,
					to: action.payload?.to,
					teamLeader: action.payload?.teamLeader || action.payload?.teamLeaderId,
					teamMembers: action.payload?.members
						.map((item) => ({
							...item,
							startDate: item.fromDate || null,
							endDate: item.toDate || null,
							teamLeader: item?.leader,
						}))
						.filter((item) => !item?.headOfDepartment),
					id: action.payload?.id,
				};
				if (headOfDepartment) {
					obj.headOfDepartment = {
						id: headOfDepartment?.id,
						firstName: headOfDepartment?.firstName,
						lastName: headOfDepartment?.lastName,
						value:
							headOfDepartment?.name ||
							`${headOfDepartment?.firstName} ${headOfDepartment?.lastName}` ||
							"",
						name:
							headOfDepartment?.name ||
							`${headOfDepartment?.firstName} ${headOfDepartment?.lastName}` ||
							"",
					};
				}
				draft.teamIntitialValues = obj;
				break;
			case CLEAR_TEAM_VALUES:
				draft.teamIntitialValues = {...teamInitialValues};
				break;
			case SHOW_MODAL:
				draft.showModal = action.payload;
				if (!action.payload) draft.teamIntitialValues = {...teamInitialValues};
				break;
			case SET_SELECTED_USERS:
				draft.teamIntitialValues = {
					...state.teamIntitialValues,
					users: action.payload || [],
				};
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	addTeam: (payload) => createAction(ADD_TEAM, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	addTeamSuccess: (payload) => createAction(ADD_TEAM_SUCCESS, {payload}),
	setShowModal: (payload) => createAction(SHOW_MODAL, {payload}),
	fillTeam: (payload) => createAction(FILL_TEAM, {payload}),
	setSelectedUsers: (payload) => createAction(SET_SELECTED_USERS, {payload}),
	clearTeamValues: (payload) => createAction(CLEAR_TEAM_VALUES, {payload}),
};

export const sagas = {
	*addTeam({payload}) {
		yield put(actions.setLoading(true));
		const {values, setModal} = payload;
		const teamLeaderId = values?.teamMembers?.find((item) => item?.teamLeader)?.id;
		const teamMembers = [
			...values?.teamMembers.map((e) => ({
				id: e?.id,
				trainerId: e?.trainerId || null,
				internship: e?.internship || false,
				trainer: e?.trainer || false,
				fromDate: e?.startDate || null,
				toDate: e?.endDate || null,
				headOfDepartment: e?.id === values?.headOfDepartment?.id,
				headOfDepartmentId: values?.headOfDepartment?.id,
				firstName: e?.firstName,
				lastName: e?.lastName,
				name: `${e?.firstName} ${e?.lastName}`,
				teamLeader: Boolean(e?.teamLeader),
				leader: Boolean(e?.teamLeader),
				teamLeaderId,
			})),
			...(values?.headOfDepartment
				? [
						{
							headOfDepartment: true,
							headOfDepartmentId: values?.headOfDepartment.id,
							id: values?.headOfDepartment.id,
							trainerId: values?.headOfDepartment.trainerId || null,
							internship: values?.headOfDepartment.internship || false,
							trainer: values?.headOfDepartment.trainer || false,
							fromDate: values?.headOfDepartment.startDate || null,
							toDate: values?.headOfDepartment.endDate || null,
							firstName: values?.headOfDepartment.firstName,
							lastName: values?.headOfDepartment.lastName,
							// eslint-disable-next-line max-len
							name: `${values?.headOfDepartment.firstName} ${values?.headOfDepartment.lastName}`,
							teamLeader: Boolean(values?.headOfDepartment.teamLeader),
							leader: Boolean(values?.headOfDepartment.leader),
							teamLeaderId,
						},
				  ]
				: []),
		];

		const createPayload = {
			name: values?.name,
			from: values?.from,
			to: values?.to,
			teamMembers,
			headOfDepartment: values?.headOfDepartment?.id,
			teamLeader: teamLeaderId,
		};
		const editPayload = {
			name: values?.name,
			from: values?.from,
			to: values?.to,
			headOfDepartmentId: values?.headOfDepartment?.id,
			teamLeaderId,
			teamMembers,
			id: values.id,
		};
		const interns = teamMembers.filter((item) => item?.internship);
		try {
			if (!teamLeaderId && !values?.headOfDepartment) {
				ToastErrorComponent(i18n.t("SelectTeamLeader"));
				return;
			}
			if (interns?.some((intern) => !intern?.fromDate)) {
				ToastErrorComponent(i18n.t("SetDateForInterns"));
				return;
			}
			if (values?.id) {
				yield axios.put(`/team/update`, editPayload);
				yield put(
					teamActions.editTeamOnState({
						...editPayload,
						members: teamMembers,
						teamLeaderId,
					}),
				);
			} else {
				const response = yield axios.post(`/team/create`, createPayload);
				yield put(
					teamActions.addTeamOnState({
						...editPayload,
						id: response.data.data,
						members: teamMembers,
						teamLeaderId,
					}),
				);
				yield put(teamActions.totalSizeIncrease());
			}
			ToastSuccesComponent(
				values?.id ? i18n.t("TeamUpdatedSuccessfully") : i18n.t("TeamCreatedSuccessfully"),
				yield put(userActions.getUsersNoTeam()),
			);
			setModal(false);
		} catch (error) {
			handleError({
				error: error?.response?.data?.message || error?.response?.data?.data,
			});
			logger.error(error);
			ToastErrorComponent(error?.response?.data?.message);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(ADD_TEAM, sagas.addTeam);
};
