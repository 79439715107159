/* eslint-disable no-unused-expressions */
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {actions as navigation} from "@sagas/navigation/";
import {actions as importsActions} from "@sagas/imports";
import {actions as orderActions} from "@sagas/orders/index";
import DataGrid from "react-data-grid";
import {withRouter} from "react-router";
import Back from "@assets/img/back__icon.svg";
import Loader from "@common/Loader";
import {actions as salesTrackerActions} from "@sagas/salesTracker/index";
import {useTranslation} from "react-i18next";
import HtmlTooltip from "@src/common/htmlTooltip";
import StatusBox from "@src/common/components/status-box";
import {EditRowIcon, MoveRightIcon, PreviewRowIcon} from "@src/assets/SvgComponents";
import {actions as editOrderActions} from "@sagas/orders/editOrder";
import Modal from "@common/modal";
import {statusData} from "./staticData";
import CreateEditOrder from "./createEditOrder";

const UpdatedStatuses = (props) => {
	const {
		getUpdatedStatusesById,
		updatedStatus,
		match,
		navigate,
		loading,
		setCurrentTab,
		toggleEditOrderModal,
		getOrderPreview,
		showEditModal,
		showTimeline,
	} = props;
	const {t} = useTranslation();
	const [previewRow, setPreviewRow] = useState(false);
	const {location} = window;
	useEffect(() => {
		getUpdatedStatusesById(match.params.id);
		// eslint-disable-next-line
	}, [match.params.id]);

	const hanldeBack = () => {
		if (location.href.includes("/admin/imports/order")) {
			navigate("/admin/imports/change-order-status");
		} else {
			setCurrentTab(t("statusHistory"));
			navigate("/admin/orders?tab=statusHistory");
		}
	};
	const statusChangeErrorRender = (message) => {
		const splittedMessage = message?.split("||");
		const messageToCheck = splittedMessage && splittedMessage[1] ? splittedMessage[1] : message;
		switch (messageToCheck) {
			case "status.can.not.change":
				return t("OrderStatusCantChange");
			case "finished.order.can.only.be.changed.to.cancelled":
				return t("FinishedOrderError");
			case "status.can.not.change.to.finished":
				return t("DeliveredOrderError");
			case "status.can.not.be.changed.from.cancelled":
				return t("CancelledOrderError");
			case "same.status":
				return t("SameOrdersError");
			case "tariff.commission.not.found":
				return t("TariffComissionNotFound");
			case "tariff.commission.table.not.found":
				return t("TariffComissionTableNotFound");
			case "tenant.not.found":
				return t("TenantNotFound");
			case "user.not.found":
				return t("UserNotFound");
			case "role.not.found":
				return t("RoleNotFound");
			case "tariff.not.found":
				return t("TariffNotFound");
			case "commission.not.found":
				return t("ComisionNotFound");
			case "tariff.is.deactivated":
				return "Tariff is deactivated";
			case "project.category.not.found":
				return "Project category not found";
			case "project.category.is.deactivated":
				return "Project category is deactivated";
			case "location.not.found":
				return "Location not found";
			case "location.is.deactivated":
				return "Location is deactivated";
			case "tariff.commision.table.not.found":
				return "Tariff commision table not found";
			case " product.is.deleted":
				return `${splittedMessage[0]} is deleted`;
			case " product.is.deactivated":
				return `${splittedMessage[0]} is deactivated`;
			case " product.provision.not.found":
				return `${splittedMessage[0]}"s provision not found`;
			case "order.have.to.revert":
				return t("orderHaveToRevert");
			default:
				return t("WrongStatus");
		}
	};
	const handlePreviewEdit = (id, type) => {
		if (type === "preview") {
			setPreviewRow(true);
		} else {
			setPreviewRow(false);
		}
		toggleEditOrderModal(true);
		getOrderPreview(id);
	};
	const statusFormater = ({row}) =>
		row?.errors !== null ? (
			<HtmlTooltip
				placement="top"
				title={
					<span style={{fontSize: "14px"}}>
						{statusChangeErrorRender(row?.errors?.message)}
					</span>
				}
			>
				<span>
					<StatusBox
						row={row?.errors !== null ? t("Failed") : t("Success")}
						statusData={statusData}
					/>
				</span>
			</HtmlTooltip>
		) : (
			<StatusBox
				row={row?.errors !== null ? t("Failed") : t("Success")}
				statusData={statusData}
			/>
		);
	const idRowFormatter = ({row}) => (
		<div className="custom__row-formatter">
			<span>{row?.orderId ? row?.orderId : ""}</span>
			<div className="custom__row-formatter-actions">
				{row?.errors !== null && (
					<HtmlTooltip placement="top" title={<span>{t("EditOrder")}</span>}>
						<span onClick={() => handlePreviewEdit(row?.orderId, "edit")}>
							<EditRowIcon height="14" />
						</span>
					</HtmlTooltip>
				)}
				<HtmlTooltip placement="top" title={<span>{t("PreviewOrder")}</span>}>
					<span onClick={() => handlePreviewEdit(row?.orderId, "preview")}>
						<PreviewRowIcon height="14" />
					</span>
				</HtmlTooltip>
			</div>
		</div>
	);
	const columns = [
		{
			name: t("Nr"),
			key: "index",
			formatter: ({rowIdx}) => <span>{rowIdx + 1}</span>,
			width: 50,
		},
		{
			name: t("ProgressStatus"),
			key: "errors",
			formatter: statusFormater,
		},
		{
			name: t("orderId"),
			key: "orderId",
			formatter: idRowFormatter,
		},
		{
			name: t("from"),
			key: "previousStatus",
			formatter: ({row}) => (
				<div
					style={{
						height: "100%",
						display: "flex",
						alignItems: "center",
					}}
				>
					<StatusBox
						row={row?.previousStatusName || row?.previousStatus}
						statusData={statusData}
					/>
					<MoveRightIcon />
				</div>
			),
		},
		{
			name: t("to"),
			key: "currentStatus",
			formatter: ({row}) => (
				<StatusBox
					row={row?.currentStatusName || row?.currentStatus}
					statusData={statusData}
				/>
			),
		},
	];

	return (
		<div className="imports__details__container">
			<div className="imports__details__top" style={{marginBottom: "10px"}}>
				<img onClick={() => hanldeBack()} src={Back} alt="" />
				{/* <h1>{singleImport.fileName} </h1> */}
			</div>

			<div className="loader__and__table-orderspage">
				{!loading && (
					<DataGrid
						className="rdg-light"
						columns={columns}
						rows={updatedStatus}
						// headerRowHeight={40}
					/>
				)}

				{loading && (
					<div className="loader__contianer">
						<Loader />
					</div>
				)}
			</div>
			<Modal
				className="add_edit_user preview"
				open={showEditModal}
				handleClose={() => toggleEditOrderModal(false)}
				width={showTimeline ? 1000 : 600}
				positionModal="right"
				overflow="hidden"
				border={previewRow && "none"}
				padding={previewRow && 0}
				display={previewRow && "flex"}
			>
				<CreateEditOrder setShowEditModal={toggleEditOrderModal} previewMode={previewRow} />
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	updatedStatus: state.app.orders.index.updatedStatus,
	loading: state.app.imports.index.loading,
	showEditModal: state.app.orders.edit.showModal,
	showTimeline: state.app.orders.edit.showTimeline,
});

const mapDispatchToProps = {
	navigate: navigation.navigate,
	getSingleImport: importsActions.getImportByIdSalesTracker,
	setActiveTab: salesTrackerActions.setActiveTab,
	getUpdatedStatusesById: orderActions.getUpdatedStatusesById,
	setCurrentTab: orderActions.setCurrentTab,
	toggleEditOrderModal: editOrderActions.toggleModal,
	getOrderPreview: orderActions.getOrderPreview,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdatedStatuses));
