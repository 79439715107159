import {useState} from "react";
import {axiosRequests} from "./getAgAxios";

const BASE_URL = "https://apps.emaillistverify.com/api/verifEmail";

const {REACT_APP_EMAIL_VALIDATION_SECRET} = process.env;

export default function useEmailValidation() {
	const [state, setState] = useState(null);
	const validateEmail = async (value) => {
		setState("started");
		try {
			const URL = `${BASE_URL}?secret=${REACT_APP_EMAIL_VALIDATION_SECRET}&email=${value}`;
			const {data} = await axiosRequests.getAgAxiosWithoutToken({
				url: URL,
				method: "get",
			});
			setState(data?.data === "ok" ? "success" : "error");
		} catch {
			setState("error");
		}
	};
	return {validateEmail, state, setState};
}
