import React, {useState, useEffect} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as createClientCategory} from "@sagas/projectCategories/createClientCategory";
import {actions as clientCategory} from "@sagas/projectCategories/clientCategory";
import {actions as deleteClientCategory} from "@sagas/projectCategories/deleteClientCategory";
import {actions as deletedCategoryActions} from "@sagas/projectCategories/deleted";
import {actions as restoreCategoryActions} from "@sagas/projectCategories/restore";
import DeleteModal from "@common/modal/deleteModal/deleteModal";
import ArrowUp from "@assets/img/arrow_upward.svg";
import ArrowDown from "@assets/img/arrow_downward.svg";
import {useTranslation} from "react-i18next";
import {checkRoles} from "@utils/checkRoles";
import {
	DeleteRowIcon,
	EditRowIcon,
	PreviewRowIcon,
	RestoreRowIcon,
} from "@src/assets/SvgComponents";
import Form from "@src/common/Form";
import DataGridTable from "@src/common/table/DataGridTable";
import HtmlTooltip from "@src/common/htmlTooltip";
import {useUrlParams} from "@src/utils/useUrlParams";
import {categoryElements} from "./categoryElements";

const ProjectCategories = (props) => {
	const {
		getProjectCategories,
		projectCategories,
		deleteCategory,
		size,
		editSize,
		search,
		page,
		editPage,
		totalSize,
		modals,
		loading,
		addCategory,
		fetchDeleted,
		deletedCategoriesData,
		editPageDeleted,
		editSizeDeleted,
		restoreCategory,
		changeProjectCategoryOrder,
		fetchLoading,
		deactivateCategory,
		clientCategoriesOrder,
		totalPages,
		toggleCategoryVisibility,
		deleteLoading,
		match,
		singleCategory,
	} = props;
	const {closeToggleModal, openToggleModal, currentTab, setCurrentTab} = modals;
	const {getUrlParams, setModal} = useUrlParams();
	const {modal, id} = getUrlParams("modal", "id");
	const handleCategoryModal = (show, id) => setModal(show, id);
	const [reEditPage, setReEditPage] = useState(true);
	const {columns, lists} = categoryElements;
	const [rows, setRows] = useState([]);
	const {t} = useTranslation();
	const {clientId} = match?.params;

	const changePrjCatOrder = (data) => {
		const itemIndex = projectCategories.findIndex((item) => item?.id === data?.id);
		if (
			(itemIndex !== 0 && data?.action === "up") ||
			(itemIndex + 1 < projectCategories?.length && data?.action === "down")
		) {
			// here we add this condition "(projectCategories?.[itemIndex - 1]?.parentId ? 2 : 1)"
			// because we have child data (baugo)
			// so we should check if parentId exist
			changeProjectCategoryOrder({
				id: data?.id,
				newRow:
					data?.action === "up"
						? projectCategories[
								itemIndex - (projectCategories?.[itemIndex - 1]?.parentId ? 2 : 1)
						  ]?.nr
						: projectCategories[
								itemIndex + (projectCategories?.[itemIndex + 1]?.parentId ? 2 : 1)
						  ]?.nr,
				action: data?.action,
			});
		}
	};
	// filtering out project categories that have a bonus inside them
	// because a project category cannot have 2 bonus categories assigned
	const optionsData = clientCategoriesOrder?.map((category) => ({
		id: category?.id,
		label: category?.name,
		value: category?.id,
	}));

	// we check this because we have bonuses and in array we have extra obj for bonuses
	// for ex. if the last item is bonus with parentId
	// we should take the next one and not that with parentId
	const checkParentId = projectCategories?.[projectCategories?.length - 1]?.parentId;
	const actionsFormatter = (row) => (
		<div className="table-actions-wrapper" style={{width: "180px", paddingRight: "20px"}}>
			{checkRoles("deactivateCategory") && !row?.parentId && (
				<HtmlTooltip
					title={<span>{!row?.isHidden ? t("SecondShow") : t("Hide")}</span>}
					placement="bottom-end"
					onClick={() => openToggleModal(row?.id)}
				>
					<span className={`preview__row ${!row?.isHidden && "hide"}`}>
						<PreviewRowIcon />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("changeOrderUpCategory") && !row?.parentId && (
				<HtmlTooltip title={<span>{t("Up")}</span>} placement="bottom-end">
					<img
						src={ArrowUp}
						alt=""
						onClick={() => changePrjCatOrder({id: row?.id, action: "up"})}
						style={{
							pointerEvents: projectCategories?.[0]?.id === row?.id ? "none" : "",
						}}
					/>
				</HtmlTooltip>
			)}
			{checkRoles("changeOrderDownCategory") && !row?.parentId && (
				<HtmlTooltip title={<span>{t("Down")}</span>} placement="bottom-end">
					<img
						src={ArrowDown}
						alt=""
						onClick={() => changePrjCatOrder({id: row?.id, action: "down"})}
						style={{
							pointerEvents:
								projectCategories?.[
									projectCategories?.length - (checkParentId ? 2 : 1)
								]?.id === row?.id
									? "none"
									: "",
						}}
					/>
				</HtmlTooltip>
			)}
			{checkRoles("editCategory") && (
				<HtmlTooltip title={<span>{t("editCategory")}</span>} placement="bottom-end">
					<span onClick={() => handleCategoryModal("category", row?.id)}>
						<EditRowIcon />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("deactivateCategory") && (
				<HtmlTooltip title={t("deleteCategory")} placement="bottom-end">
					<span onClick={() => handleCategoryModal("deactivate", row?.id)}>
						<DeleteRowIcon />
					</span>
				</HtmlTooltip>
			)}
		</div>
	);
	const actions = {
		key: "actions",
		name: t("Actions"),
		sortable: false,
		resizable: false,
		width: 180,
		formatter: ({row}) => actionsFormatter(row),
		headerCellClass: "headerStyles",
	};
	const actionsDeletedFormatter = (row) => (
		<div className="table-actions-wrapper">
			{checkRoles("activateCategory") && (!row?.parentId || row?.isRestorable) && (
				<HtmlTooltip title={<span> {t("ActivateCategory")}</span>} placement="bottom-end">
					<span onClick={() => handleCategoryModal("restore", row?.id)}>
						<RestoreRowIcon />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("deleteCategory") && (
				<HtmlTooltip
					title={<span>{t("PermanentDeleteCategory")}</span>}
					placement="bottom-end"
				>
					<span onClick={() => handleCategoryModal("delete", row?.id)}>
						<DeleteRowIcon />
					</span>
				</HtmlTooltip>
			)}
		</div>
	);
	const actionsDeleted = {
		key: "actions",
		name: t("Actions"),
		sortable: false,
		resizable: false,
		width: 50,
		formatter: ({row}) => actionsDeletedFormatter(row),
		headerCellClass: "headerStyles",
	};
	useEffect(() => {
		if (currentTab === "active") {
			getProjectCategories({clientId, search});
		}
	}, [projectCategories.length, page, size, search, currentTab]);
	useEffect(() => {
		if (currentTab === "inactive") {
			fetchDeleted({clientId, search});
		}
	}, [deletedCategoriesData.size, deletedCategoriesData.page, search, currentTab]);
	const correctCategories = projectCategories
		?.filter((item) => !item.parentId)
		?.map((item) => ({
			...item,
			expanded: false,
		}));
	useEffect(() => {
		const correctCategories = projectCategories
			?.filter((item) => !item.parentId)
			?.map((item) => ({
				...item,
				expanded: false,
			}));
		setRows(correctCategories);
	}, [projectCategories]);
	useEffect(() => {
		if (currentTab === "inactive") {
			setRows(deletedCategoriesData?.list);
		}
	}, [currentTab, deletedCategoriesData?.list]);
	const tableProps = {
		active: {
			columns: [...columns, actions],
			data: correctCategories,
			rows,
			editPage,
			editSize,
			size,
			page,
			totalSize,
			totalPages,
			loading: fetchLoading,
		},
		inactive: {
			columns: [...categoryElements?.columns, actionsDeleted],
			rows,
			data: deletedCategoriesData?.list,
			size: deletedCategoriesData?.size,
			page: deletedCategoriesData?.page,
			totalSize: deletedCategoriesData.totalSize,
			totalPages: deletedCategoriesData.totalPages,
			editPage: editPageDeleted,
			editSize: editSizeDeleted,
			loading: deletedCategoriesData.loading,
		},
	};
	const formData = {
		inputs: lists?.map((item) => {
			if (item.field === "projectCategories") {
				return {
					...item,
					options: optionsData,
					hidden: !!id,
				};
			}
			return item;
		}),
		id,
		title: id ? t("EditCategory") : t("AddCategory"),
		submitFn: (values, actions) =>
			addCategory({values: {...values, clientId}, formActions: actions, handleCategoryModal}),
		getUrl: "/projectCategory/:id",
	};
	const modalConfig = {
		deactivate: {
			confirm: () => {
				deactivateCategory({id, handleCategoryModal});
				setRows([]);
			},
			type: "deactivate",
		},
		restore: {
			confirm: () => {
				restoreCategory({id, handleCategoryModal});
				setRows([]);
			},
			type: "restore",
			modalType: "activate",
		},
		delete: {
			confirm: () => deleteCategory({id, client: clientId, handleCategoryModal}),
			type: "delete",
			modalType: "permanantDelete",
		},
		visibility: {
			confirm: () =>
				toggleCategoryVisibility({
					id: singleCategory?.id,
					closeModal: closeToggleModal,
					isHidden: !singleCategory?.isHidden,
				}),
			type: "visibility",
			newTitle: `${t(`AreYouSureTo${singleCategory?.isHidden ? "Show" : "Hide"}`)} "${
				singleCategory?.name
			}"?`,
			disabled: deleteLoading,
			calculationModal: true,
		},
	};
	const activeModal = modal ? modalConfig[modal] : null;
	return (
		<>
			{activeModal && (
				<DeleteModal
					calculationModal={activeModal?.calculationModal}
					setShowDeleteModal={() => handleCategoryModal(false)}
					confirm={activeModal.confirm}
					newTitle={activeModal?.newTitle}
					open={activeModal.type === modal}
					handleClose={() => handleCategoryModal(false)}
					disabled={activeModal.disabled}
					modalType={activeModal.modalType}
					word={t("thisCategory")}
				/>
			)}
			<Form
				open={modal === "category"}
				handleClose={() => handleCategoryModal(false)}
				loading={loading}
				formData={formData}
			/>
			<div className="loader__and__table">
				<DataGridTable
					{...tableProps[currentTab]}
					reEditPage={reEditPage}
					withTabs
					currentTab={currentTab}
					onTabClick={setCurrentTab}
					setReEditPage={setReEditPage}
					pagination
					onRowsChange={(rows, {indexes}) => {
						const updatedRows = [...rows];
						const row = rows[indexes[0]];
						if (row.expanded) {
							updatedRows.splice(indexes[0] + 1, 0, ...row.baugos);
							setRows(updatedRows);
						} else {
							const filteredArr = updatedRows?.filter((r) => r.parentId !== row?.id);
							setRows(filteredArr);
						}
					}}
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	client: state.app.client.index.client,
	activeTab: state.app.client.index.activeTab,
	projectCategories: state.app.projectCategories.clientCategory.clientCategories,
	singleCategory: state.app.projectCategories.clientCategory.singleCategory,
	totalSize: state.app.projectCategories.clientCategory.totalSize,
	size: state.app.projectCategories.clientCategory.size,
	page: state.app.projectCategories.clientCategory.page,
	fetchLoading: state.app.projectCategories.clientCategory.isLoading,
	deleteLoading: state.app.projectCategories.deleteClientCategory.loading,
	loading: state.app.projectCategories.createClientCategory.loading,
	deletedCategoriesData: state.app.projectCategories.deleted,
	clientCategoriesOrder: state.app.projectCategories.clientCategory.clientCategoriesOrder,
	totalPages: state.app.projectCategories.clientCategory.totalPages,
});

const mapDispatchToProps = {
	editPage: clientCategory.editPage,
	editSize: clientCategory.editSize,
	deleteCategory: deleteClientCategory.deleteCategory,
	deactivateCategory: deleteClientCategory.deactivateCategory,
	getProjectCategories: clientCategory.fetchClientCategories,
	addCategory: createClientCategory.addCategory,
	fetchDeleted: deletedCategoryActions.fetch,
	editPageDeleted: deletedCategoryActions.editPage,
	editSizeDeleted: deletedCategoryActions.editSize,
	restoreCategory: restoreCategoryActions.restore,
	changeProjectCategoryOrder: clientCategory.changeProjectCategoryOrder,
	toggleCategoryVisibility: clientCategory.toggleCategoryVisibility,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectCategories));
