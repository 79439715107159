/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-len */
import React, {useEffect, useState} from "react";
import Forms, {
	convertCustomFieldsToForm1,
} from "@components/dashboard/orders/createEditOrder/forms";
import {actions as editOrderActions} from "@sagas/orders/editOrder";
import {actions as clientActions} from "@sagas/client";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import CloseIcon from "@common/closeIcon/CloseIcon";
import {useTranslation} from "react-i18next";
import Loader from "@common/Loader";
import axios from "axios";
import {MoveRightIcon, TimelineIcon} from "@src/assets/SvgComponents";
import StatusBox from "@src/common/components/status-box";
import {checkRoles} from "@src/utils/checkRoles";
import {useUrlParams} from "@src/utils/useUrlParams";
import useGenericLocations from "@src/utils/useGenericLocations";
import _ from "lodash";
import {actions as createOrderActions} from "@sagas/orders/create";
import {statusData} from "../staticData";
import Timeline from "./Timeline";
import EditOrder from "./Edit";
import Preview from "./Preview";
import {clientWithoutGenericArr, requestTypeConfig} from "./helper";

function CreateEditOrder({
	setShowEditModal,
	order,
	fillOrder,
	previewMode = false,
	fetchGenericOrderByID,
	editedOrderFields,
	query,
	setShowTimeline,
	showTimeline,
	getActivities,
	activities,
	getActivitiesById,
	activity,
	setOrdersWithChanges,
	activitiesLoading,
	setReEditPage,
	changeOrdersStatus,
	changeStatusLoading,
}) {
	if (!order)
		return (
			<div className="full__width__loader">
				<Loader />
			</div>
		);
	const getObject = () => {
		if (
			(activity !== null && currentVersion !== selectedVersion?.id) ||
			selectedVersion?.isChangeStatus
		) {
			return activity;
		}
		return order;
	};
	const {getUrlParams} = useUrlParams();
	const {options, retrieveDataFromGetAg} = useGenericLocations();
	const {tab} = getUrlParams("tab");
	const language = localStorage.getItem("language");
	const [checked, setChecked] = useState(false);
	const [currentVersion, setCurrentVersion] = useState(null);
	const [selectedVersion, setSelectedVersion] = useState({id: null, isChangeStatus: false});
	const isGenericClient = !clientWithoutGenericArr.includes(getObject()?.clientName);
	const {t} = useTranslation();
	useEffect(() => {
		if (!showTimeline) {
			setSelectedVersion({id: null, isChangeStatus: false});
		}
	}, [showTimeline]);

	const handleShowChanges = (value) => {
		setChecked(value);
		setOrdersWithChanges(value);
	};
	const [confirmModal, setConfirmModal] = useState({
		file: null,
		open: false,
		customFieldId: null,
	});
	useEffect(() => () => fillOrder(null), []);
	useEffect(() => {
		if (isGenericClient) {
			if (tab === "energyOrders") {
				fetchGenericOrderByID({clientId: 0, clientContractType: "ENERGY"});
			} else {
				fetchGenericOrderByID(getObject()?.clientId.value);
			}
		}
	}, []);

	const handleGetAgRequests = () => {
		for (let i = 0; i < fieldMapping.length; i++) {
			const currentField = fieldMapping[i];
			const value = _.get(order, currentField.name);
			const {requestType, dependentFrom} = currentField.item;
			if (value && currentField.item.requestType && requestTypeConfig?.[requestType]) {
				let dependentValue;
				if (dependentFrom) {
					const foundedDep = fieldMapping.find((itm) => itm.item.name === dependentFrom);
					dependentValue = _.get(order, foundedDep?.name);
				}
				retrieveDataFromGetAg(
					{...currentField.item, type: requestTypeConfig?.[requestType]},
					value,
					dependentValue,
				);
			}
		}
	};

	// useEffect(() => {
	// 	if (order) handleGetAgRequests();
	// }, [order, editedOrderFields]);

	function downloadContract(image) {
		axios
			.get(image, {
				responseType: "blob",
			})
			.then((response) => {
				const a = document.createElement("a");
				const url = window.URL.createObjectURL(response.data);
				a.href = url;
				a.download = `${t("contractImage")}.png`;
				a.click();
			})
			.catch((err) => {
				// console.log("error", err);
			});
	}
	const filteredFields = editedOrderFields.filter(
		(item) => !item.hideWeb && item.parentNameEn !== "Section Hide",
	);

	const fieldMapping = isGenericClient
		? convertCustomFieldsToForm1(filteredFields)
		: Forms?.[getObject()?.clientName];
	const handleGetById = (item, index, firstMonth, act) => {
		setSelectedVersion({id: item?.id, isChangeStatus: item?.action === "CHANGE_STATUS"});
		getActivitiesById(item?.id);
		setChecked(false);
		if (index === 0 && firstMonth === act) {
			setCurrentVersion(item?.id);
		}
	};
	const getSelectedVersion = (item, index, firstMonth, act) => {
		if (selectedVersion?.id !== null) {
			if (selectedVersion?.id === item?.id) {
				return true;
			}
			return false;
		}
		return false;
	};
	return (
		<>
			<div
				className={previewMode && `timelineContainer${showTimeline ? "-show" : ""}`}
				style={{
					justifyContent: activitiesLoading && "center",
					alignItems: activitiesLoading && "center",
				}}
			>
				{showTimeline &&
					(activitiesLoading ? (
						<Loader />
					) : (
						<Timeline
							setShowTimeline={setShowTimeline}
							activities={activities}
							selectedVersion={selectedVersion}
							currentVersion={currentVersion}
							handleGetById={handleGetById}
							handleShowChanges={handleShowChanges}
							getSelectedVersion={getSelectedVersion}
							checked={checked}
						/>
					))}
			</div>
			<div className={previewMode && `previewContainer${showTimeline ? "-show" : ""}`}>
				<div className="orders__modal__icon__and__name">
					<span>{previewMode ? t("PreviewOrder") : t("EditOrder")}</span>
					<div className="icons-wrapper">
						<>
							<div className="icons-wrapper_status">
								{getObject()?.prevStatus?.changed && previewMode && (
									<>
										<StatusBox
											statusData={statusData}
											row={getObject()?.prevStatus?.value}
										/>
										<MoveRightIcon width={30} />
									</>
								)}
								<StatusBox
									statusData={statusData}
									editable={!showTimeline}
									orderIdsAndClientTypes={{
										id: order?.orderId,
										clientPayoutModel: order?.clientPayoutModel,
									}}
									changeOrdersStatus={changeOrdersStatus}
									loading={changeStatusLoading}
									row={
										getObject()?.status?.changed
											? getObject()?.status?.value
											: getObject()?.status
									}
								/>
								{getObject()?.status?.changed &&
									!getObject()?.prevStatus?.changed &&
									previewMode && (
										<>
											<MoveRightIcon width={30} />
											<StatusBox
												statusData={statusData}
												row={order?.status}
											/>
										</>
									)}
							</div>
							{checkRoles("seeTimeline") && previewMode && !order.isScheduleDeleted && (
								<div
									className="icons-wrapper_timeline"
									onClick={() => getActivities(order?.orderId)}
								>
									<TimelineIcon />
								</div>
							)}
						</>
						<CloseIcon
							className="close__modal__icon"
							click={() => setShowEditModal(false)}
						/>
					</div>
				</div>
				<div>
					{previewMode ? (
						<Preview
							fieldMapping={fieldMapping}
							previewMode={previewMode}
							language={language}
							setConfirmModal={setConfirmModal}
							confirmModal={confirmModal}
							downloadContract={downloadContract}
							order={getObject() !== null && getObject()}
							options={options}
						/>
					) : (
						<EditOrder
							fieldMapping={fieldMapping}
							previewMode={previewMode}
							language={language}
							setConfirmModal={setConfirmModal}
							confirmModal={confirmModal}
							downloadContract={downloadContract}
							query={query}
							setReEditPage={setReEditPage}
							tab={tab === "energyOrders" ? "ENERGY" : "FIBRE"}
							options={options}
							retrieveDataFromGetAg={retrieveDataFromGetAg}
						/>
					)}
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({
	order: state.app.orders.edit.order,
	editedOrderFields: state.app.client.index.editedOrderFields,
	showTimeline: state.app.orders.edit.showTimeline,
	activities: state.app.orders.edit.activities,
	activity: state.app.orders.edit.activity,
	activitiesLoading: state.app.orders.edit.activitiesLoading,
	changeStatusLoading: state.app.orders.create.loading,
});

const mapDispatchToProps = {
	fillOrder: editOrderActions.fillOrder,
	fetchGenericOrderByID: clientActions.fetchGenericOrderByID,
	setShowTimeline: editOrderActions.setShowTimeline,
	getActivities: editOrderActions.getActivities,
	getActivitiesById: editOrderActions.getActivitiesById,
	setOrdersWithChanges: editOrderActions.setOrdersWithChanges,
	changeOrdersStatus: createOrderActions.changeOrdersStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateEditOrder));
