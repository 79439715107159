import React from "react";
import i18n from "@src/i18n";
import {CommentIcon, CopyIcon, DeleteRowIcon, EditRowIcon} from "@src/assets/SvgComponents";
import LeadUsers from "../LeadUsers";

export const headerColumns = [
	{
		title: i18n.t("ZipCode"),
		field: "zipCode",
		customRender: true,
	},
	{
		title: i18n.t("SubLocation"),
		field: "partLocation",
		customRender: true,
	},
	{
		title: i18n.t("Location"),
		field: "location",
		customRender: true,
	},
];
export const leadTableColumns = [
	{
		name: "ID",
		key: "generateLeadId",
		width: 120,
		cellClass: "status-cell",
	},
	{
		name: i18n.t("firstAndLastName"),
		key: "name",
		resizable: true,
		cellClass: "status-cell",
		formatter: ({row}) => <span>{`${row?.firstName || ""} ${row?.lastName || ""}`}</span>,
	},
	{
		name: i18n.t("Status"),
		key: "status",
		resizable: true,
		cellClass: `status__box-cell status-cell`,
		formatter: ({row}) => (
			<span style={{backgroundColor: row?.leadColor}}>
				{i18n.language === "en" ? row?.statusLead : row?.statusDe}
			</span>
		),
	},
	{
		name: i18n.t("houseNumber"),
		key: "housenr",
		resizable: true,
		width: 100,
		cellClass: "status-cell",
		formatter: ({row}) => <span>{row?.viewAddress?.houseNumber}</span>,
	},
	{
		name: i18n.t("Comments"),
		key: "leadActivityCount",
		width: 120,
		resizable: true,
		cellClass: `activity_count-cell status-cell`,
		formatter: ({row}) => (
			<div className="activity_count-wrapper">
				<CommentIcon />
				<span>{row?.leadActivityCount}</span>
			</div>
		),
	},
	{
		name: i18n.t("weGrouping"),
		key: "weGrouping",
		width: 100,
		resizable: true,
		cellClass: "status-cell",
	},
	{
		name: i18n.t("assignedUsers"),
		key: "userIds",
		resizable: true,
		cellClass: `lead__users-cell status-cell`,
		formatter: ({row}) => <LeadUsers userIds={row?.userIds || []} />,
	},
];

export const popoverItems = [
	{
		key: "edit",
		icon: <EditRowIcon stroke="#252525" />,
		label: i18n.t("editLead"),
	},
	{
		key: "duplicate",
		icon: <CopyIcon stroke="#252525" width="18" height="20" />,
		label: i18n.t("duplicateLead"),
	},
	{
		key: "delete",
		icon: <DeleteRowIcon stroke="#252525" />,
		label: i18n.t("deleteLead"),
	},
];
