import produce from "immer";
import {put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {ENDPOINT} from "@src/utils/endpoint";
import { ToastSuccesComponent } from "@src/common/ToastComponent/ToastComponent";
import i18n from "@src/i18n";

const logger = new Logger("Sagas>Orders>Statuses");
const PREFIX = "@app/orders/statuses";

export const CREATE_STATUS = `${PREFIX}CREATE_STATUS`;
export const CREATE_STATUS_SUCCESS = `${PREFIX}CREATE_STATUS_SUCCESS`;
export const GET_ORDER_STATUSES = `${PREFIX}GET_ORDER_STATUSES`;
export const GET_ORDER_STATUSES_SUCCESS = `${PREFIX}GET_ORDER_STATUSES_SUCCESS`;
export const GET_ORDER_STATUSES_BY_ID = `${PREFIX}GET_ORDER_STATUSES_BY_ID`;
export const GET_ORDER_STATUSES_BY_ID_SUCCESS = `${PREFIX}GET_ORDER_STATUSES_BY_ID_SUCCESS`;
export const DELETE_ORDER_STATUS = `${PREFIX}DELETE_ORDER_STATUS`;
export const DELETE_ORDER_STATUS_SUCCESS = `${PREFIX}DELETE_ORDER_STATUS_SUCCESS`;
export const EDIT_STATUS = `${PREFIX}EDIT_STATUS`;
export const EDIT_STATUS_SUCCESS = `${PREFIX}EDIT_STATUS_SUCCESS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;

const _state = {
	statuses: [],
	status: null,
	loading: false,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case CREATE_STATUS_SUCCESS:
				draft.statuses = [{...payload}, ...state.statuses];
				break;
			case EDIT_STATUS_SUCCESS:
				draft.statuses = state.statuses.map((item) => {
					if (item?.id === payload?.id) {
						return {...payload};
					}
					return item;
				});
				break;
			case DELETE_ORDER_STATUS_SUCCESS:
				draft.statuses = state.statuses.filter((item) => item?.id !== payload);
				break;
			case GET_ORDER_STATUSES_SUCCESS:
				draft.statuses = payload;
				break;
			case GET_ORDER_STATUSES_BY_ID_SUCCESS:
				draft.status = payload;
				break;
			case SET_LOADING:
				draft.loading = payload;
				break;

			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	createStatus: (payload) => createAction(CREATE_STATUS, {payload}),
	createStatusSuccess: (payload) => createAction(CREATE_STATUS_SUCCESS, {payload}),
	getOrderStatuses: (payload) => createAction(GET_ORDER_STATUSES, {payload}),
	getOrderStatusesSuccess: (payload) => createAction(GET_ORDER_STATUSES_SUCCESS, {payload}),
	getOrderStatusesById: (payload) => createAction(GET_ORDER_STATUSES_BY_ID, {payload}),
	getOrderStatusesByIdSuccess: (payload) =>
		createAction(GET_ORDER_STATUSES_BY_ID_SUCCESS, {payload}),
	deleteOrderStatus: (payload) => createAction(DELETE_ORDER_STATUS, {payload}),
	deleteOrderStatusSuccess: (payload) => createAction(DELETE_ORDER_STATUS_SUCCESS, {payload}),
	editStatus: (payload) => createAction(EDIT_STATUS, {payload}),
	editStatusSuccess: (payload) => createAction(EDIT_STATUS_SUCCESS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
};

export const sagas = {
	*createStatus({payload}) {
		const {values, setModal} = payload;
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.post(ENDPOINT.CREATE_STATUS, values);
			setModal(false);
			yield put(
				actions.createStatusSuccess({
					...values,
					id: response.data.data,
					externalId: null,
					label: values?.name,
					value: response.data.data,
				}),
			);
			yield put(actions.setLoading(false));
			ToastSuccesComponent(i18n.t("StatusCreated"));
		} catch (error) {
			logger.error(error);
			yield put(actions.setLoading(false));
		}
	},
	*editStatus({payload}) {
		const {values, setModal} = payload;
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.post(ENDPOINT.UPDATE_STATUS, values);
			yield put(
				actions.editStatusSuccess({
					...values,
					id: response?.data?.data,
					externalId: null,
					label: values?.name,
					value: response?.data?.data,
				}),
			);
			yield put(actions.setLoading(false));
			setModal(false);
			ToastSuccesComponent(i18n.t("StatusEdited"));
		} catch (error) {
			logger.error(error);
			yield put(actions.setLoading(false));
		}
	},
	*getOrderStatuses() {
		try {
			const response = yield axios.get(ENDPOINT.GET_ALL_STATUS);
			yield put(actions.getOrderStatusesSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*getOrderStatusesById({payload}) {
		try {
			const response = yield axios.get(ENDPOINT.GET_BY_ID_STATUS.replace(":id", payload));
			yield put(actions.getOrderStatusesByIdSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*deleteOrderStatus({payload}) {
		yield put(actions.setLoading(true));
		try {
			yield axios.put(ENDPOINT.DELETE_STATUS.replace(":id", payload));
			yield put(actions.deleteOrderStatusSuccess(payload));
			yield put(actions.setLoading(false));
			ToastSuccesComponent(i18n.t("StatusDeleted"));
		} catch (error) {
			yield put(actions.setLoading(false));
			logger.error(error);
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(CREATE_STATUS, sagas.createStatus);
	yield takeLatest(GET_ORDER_STATUSES, sagas.getOrderStatuses);
	yield takeLatest(GET_ORDER_STATUSES_BY_ID, sagas.getOrderStatusesById);
	yield takeLatest(DELETE_ORDER_STATUS, sagas.deleteOrderStatus);
	yield takeLatest(EDIT_STATUS, sagas.editStatus);
};
