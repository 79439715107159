import produce from "immer";
import {put, takeLatest, call} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {convertToRaw, EditorState, convertFromHTML, ContentState} from "draft-js";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import draftToHtml from "draftjs-to-html";
import i18n from "../../../../i18n";
const logger = new Logger("Sagas>Delete>Index");

const PREFIX = "@app/tariffs/delete";

export const SET_BILLING_DETAILS = `${PREFIX}SET_BILLING_DETAILS`;
export const SET_BILLING_DETAILS_SUCCESS = `${PREFIX}SET_BILLING_DETAILS_SUCCESS`;
export const SET_EMAIL_SETTINGS = `${PREFIX}SET_EMAIL_SETTINGS`;
export const SET_EMAIL_SETTINGS_SUCCESS = `${PREFIX}SET_EMAIL_SETTINGS_SUCCESS`;
export const GET_BILLING_DETAILS = `${PREFIX}GET_BILLING_DETAILS`;
export const GET_EMAIL_SETTINGS = `${PREFIX}GET_EMAIL_SETTINGS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SET_SUBMIT_LOADING = `${PREFIX}SET_SUBMIT_LOADING`;
export const CHANGE_LOGO = `${PREFIX}CHANGE_LOGO`;
export const CHANGE_LOGO_SUCCESS = `${PREFIX}CHANGE_LOGO_SUCCESS`;
export const CHANGE_QRCODE_SUCCESS = `${PREFIX}CHANGE_QRCODE_SUCCESS`;

const _state = {
	submitLoading: false,
	loading: false,
	billingDetails: {},
	emailSettings: {},
	logoUrl: null,
	qrCode: null,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case SET_BILLING_DETAILS_SUCCESS:
				draft.billingDetails = action.payload;
				draft.qrCode = action?.payload?.qrCode;
				break;
			case SET_EMAIL_SETTINGS_SUCCESS:
				const {restoreTemplate, registerTemplate} = action.payload;
				const restoreContentState = ContentState.createFromBlockArray(
					convertFromHTML(restoreTemplate),
				);
				const registerContentState = ContentState.createFromBlockArray(
					convertFromHTML(registerTemplate),
				);
				const emailSettings = {
					restoreTemplate: EditorState.createWithContent(restoreContentState),
					registerTemplate: EditorState.createWithContent(registerContentState),
				};
				draft.emailSettings = emailSettings;
				break;
			case SET_SUBMIT_LOADING:
				draft.submitLoading = action.payload;
				break;
			case CHANGE_LOGO_SUCCESS:
				draft.logoUrl = action.payload;
				break;
			case CHANGE_QRCODE_SUCCESS:
				draft.qrCode = action.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	setBillingDetails: (payload) => createAction(SET_BILLING_DETAILS, {payload}),
	setBillingDetailsSuccess: (payload) => createAction(SET_BILLING_DETAILS_SUCCESS, {payload}),
	setEmailSettings: (payload) => createAction(SET_EMAIL_SETTINGS, {payload}),
	setEmailSettingsSuccess: (payload) => createAction(SET_EMAIL_SETTINGS_SUCCESS, {payload}),
	getBillingDetails: (payload) => createAction(GET_BILLING_DETAILS, {payload}),
	getEmailSettings: (payload) => createAction(GET_EMAIL_SETTINGS, {payload}),
	setSubmitLoading: (payload) => createAction(SET_SUBMIT_LOADING, {payload}),
	changeLogo: (payload) => createAction(CHANGE_LOGO, {payload}),
	changeLogoSuccess: (payload) => createAction(CHANGE_LOGO_SUCCESS, {payload}),
	changeQRCodeSuccess: (payload) => createAction(CHANGE_QRCODE_SUCCESS, {payload}),
};
export const convertBase64 = (file) =>
	new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);
		fileReader.onload = () => {
			resolve(fileReader.result);
		};
		fileReader.onerror = (error) => {
			reject(error);
		};
	});

const handleFileRead = async (event) => {
	const file = event;
	const base64 = await convertBase64(file);
	return base64;
};
export const sagas = {
	*setBillingDetails({payload}) {
		yield put(actions.setSubmitLoading(true));
		try {
			const currentTitle = localStorage.getItem("title");
			const {qrCode} = payload;
			let qrCodeBase64 = null;
			if (qrCode) {
				const formData = new FormData();
				formData.append("qrCode", qrCode);
				qrCodeBase64 = qrCode.type ? yield call(handleFileRead, qrCode) : qrCode;
			}
			const updatedPayload = {...payload, qrCode: qrCodeBase64};
			const response = yield axios.put(`tenant/update/tenantSetting`, updatedPayload);
			yield put(actions.setBillingDetailsSuccess(response?.data?.data));
			if (payload.title !== currentTitle) {
				localStorage.setItem("title", payload.title);
				document.title = payload.title;
			}
			ToastSuccesComponent(i18n.t("BillingDetailsUpdated"));
			document.documentElement.style.setProperty("--color-primary", payload.primaryColor);
			document.documentElement.style.setProperty("--color-secondary", payload.secondaryColor);
			document.documentElement.style.setProperty("--color-hover", payload.hoverColor);
			const colors = {
				primary: payload.primaryColor,
				secondary: payload.secondaryColor,
				hover: payload.hoverColor,
			};
			localStorage.setItem("theme", JSON.stringify(colors));
		} catch (error) {
			logger.error(error);
			ToastErrorComponent(error?.response?.data?.message);
		} finally {
			yield put(actions.setSubmitLoading(false));
		}
	},
	*getBillingDetails() {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`tenant/get/tenantSettings`);
			yield put(actions.setBillingDetailsSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*changeLogo({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {file, userId} = payload;
			const formData = new FormData();
			formData.append("file", file);
			const fileBase64 = yield call(handleFileRead, file);
			const response = yield axios.put(`tenant/logo/update`, {
				logo: fileBase64,
				userId,
			});
			const {data} = response.data;
			localStorage.setItem("tenantLogo", data);
			yield put(actions.changeLogoSuccess(data));
			ToastSuccesComponent(i18n.t("ImageChangeSuccess"));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*setEmailSettings({payload}) {
		yield put(actions.setSubmitLoading(true));
		try {
			const {registerTemplate, restoreTemplate} = payload;
			const obj = {
				registerTemplate: draftToHtml(convertToRaw(registerTemplate?.getCurrentContent())),
				restoreTemplate: draftToHtml(convertToRaw(restoreTemplate?.getCurrentContent())),
			};
			yield axios.put(`tenant/update/emailTemplate`, obj);
			yield put(actions.setEmailSettingsSuccess(obj));
			ToastSuccesComponent(i18n.t("emailTemplatesUpdated"));
		} catch (error) {
			logger.error(error);
			ToastErrorComponent(error?.response?.data?.message);
		} finally {
			yield put(actions.setSubmitLoading(false));
		}
	},
	*getEmailSettings() {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`tenant/get/emailTemplate`);
			yield put(actions.setEmailSettingsSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(SET_BILLING_DETAILS, sagas.setBillingDetails);
	yield takeLatest(SET_EMAIL_SETTINGS, sagas.setEmailSettings);
	yield takeLatest(GET_BILLING_DETAILS, sagas.getBillingDetails);
	yield takeLatest(GET_EMAIL_SETTINGS, sagas.getEmailSettings);
	yield takeLatest(CHANGE_LOGO, sagas.changeLogo);
};
